import React from 'react'
import { Alert } from '@myob/myob-widgets'
import { KycFailedAlert, ApplicationDeclinedAlert, RegisterPermissionAlert, RetryableAlert } from 'purs/Dashboard'
import { Link } from 'react-router-dom'

const DashboardFailedAlert = ({ alertType }) => {
  switch (alertType.constructor) {
    case ApplicationDeclinedAlert:
      return (
        <Alert type="warning">
          It looks like your business doesn’t meet the necessary requirements to
          enable payments. To onboard another business, head back to your{' '}
          <Link to={'/companies'}>business list page</Link>.
        </Alert>
      )
    case KycFailedAlert:
      return <KYCFailedAlert />
    case RegisterPermissionAlert:
      return (
        <Alert type="danger">
          Oops, looks like we couldn't confirm your permissions. 
        </Alert>
      )
    case RetryableAlert:
    default:
      return (
        <Alert type="danger">
          Oops, looks like something went wrong! Please try again later. Or contact us on{' '}
            <a href="tel:1300783674">1300 783 674</a>
        </Alert>
      )
  }
}

export const KYCFailedAlert = () => (
  <Alert type="warning">
    Due to the results of your identity check, your application will be
    manually reviewed once you’ve completed the final step.
  </Alert>
)

export default DashboardFailedAlert
