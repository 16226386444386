// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var initState = {
    stepUpError: Data_Maybe.Nothing.value,
    redirectUrl: Data_Maybe.Nothing.value
};
var _sendRequest = function (companyId) {
    return function (batchId) {
        var paymentUrl = "/company/" + (companyId + ("/batch/" + (batchId + "/payment")));
        return Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.post()(paymentUrl)("")({}));
    };
};
var _authoriseBatch = function (batchId) {
    return function (companyId) {
        return function (redirectData) {
            var url = function (status) {
                return "/batch/" + (batchId + ("#paymentresult=" + Data_Show.show(Data_Show.showInt)(status)));
            };
            var paymentResult = function (v) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    redirectUrl: Data_Maybe.Just.create(url(v.status)),
                    stepUpError: redirectData.stepUpError
                });
            };
            var error = function (v) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    redirectUrl: Data_Maybe.Just.create(url(500)),
                    stepUpError: redirectData.stepUpError
                });
            };
            return Control_Bind.bind(Effect_Aff.bindAff)(_sendRequest(companyId)(batchId))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(error)(paymentResult));
        };
    };
};
var sendAuthPayment = function (batchId) {
    return function (redirectData) {
        var error = function (v) {
            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.login(Data_Maybe.Nothing.value))(redirectData));
        };
        var auth = function (v) {
            return function (v1) {
                if (v === "") {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        stepUpError: new Data_Maybe.Just("empty batch ID"),
                        redirectUrl: redirectData.redirectUrl
                    });
                };
                return _authoriseBatch(v)(v1.companyId)(redirectData);
            };
        };
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompanyQuery))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(error)(auth(batchId)));
    };
};
module.exports = {
    sendAuthPayment: sendAuthPayment,
    initState: initState
};
