// Generated by purs version 0.13.8
"use strict";
var Config = require("../Config/index.js");
var Control_Error_Util = require("../Control.Error.Util/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Fetch = require("../Fetch/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var _saveUserIdInSession = (function () {
    var setSession = function (v) {
        if (v instanceof Data_Maybe.Just) {
            return Effect_Unsafe.unsafePerformEffect(SessionStorage.setItem(Config.config.userStorageName)(v.value0));
        };
        if (v instanceof Data_Maybe.Nothing) {
            return Data_Unit.unit;
        };
        throw new Error("Failed pattern match at App (line 26, column 5 - line 26, column 39): " + [ v.constructor.name ]);
    };
    var getUserId = function (resp) {
        if (resp.status === 200) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(function (x) {
                return x.id;
            })(Control_Error_Util.hush(Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "id";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()()))(resp.body)));
        };
        return Data_Maybe.Nothing.value;
    };
    return Data_Functor.map(Effect_Aff.functorAff)(Data_Functor.map(Data_Functor.functorFn)(setSession)(getUserId))(Fetch.get()("/user")({}));
})();
var unsafeSaveUserId = Effect_Aff.launchAff_(_saveUserIdInSession);
module.exports = {
    unsafeSaveUserId: unsafeSaveUserId
};
