// Generated by purs version 0.13.8
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Awaiting = (function () {
    function Awaiting() {

    };
    Awaiting.value = new Awaiting();
    return Awaiting;
})();
var $$Error = (function () {
    function $$Error() {

    };
    $$Error.value = new $$Error();
    return $$Error;
})();
var Unavailable = (function () {
    function Unavailable() {

    };
    Unavailable.value = new Unavailable();
    return Unavailable;
})();
var Declined = (function () {
    function Declined() {

    };
    Declined.value = new Declined();
    return Declined;
})();
var Processing = (function () {
    function Processing() {

    };
    Processing.value = new Processing();
    return Processing;
})();
var Refunded = (function () {
    function Refunded() {

    };
    Refunded.value = new Refunded();
    return Refunded;
})();
var Processed = (function () {
    function Processed() {

    };
    Processed.value = new Processed();
    return Processed;
})();
var genStatusDisplay = function (v) {
    if (v instanceof Processing) {
        return "Processing";
    };
    if (v instanceof Awaiting) {
        return "Needs authorisation";
    };
    if (v instanceof Processed) {
        return "Processed";
    };
    if (v instanceof Refunded) {
        return "Refunded";
    };
    if (v instanceof Declined) {
        return "Declined by authoriser";
    };
    if (v instanceof Unavailable) {
        return "Unavailable";
    };
    return "Errors";
};
var genColor = function (v) {
    if (v instanceof Processing) {
        return "blue";
    };
    if (v instanceof Awaiting) {
        return "purple";
    };
    if (v instanceof Processed) {
        return "green";
    };
    if (v instanceof Refunded) {
        return "green";
    };
    if (v instanceof Declined) {
        return "red";
    };
    return "orange";
};
var genBatchStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Awaiting) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof $$Error) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Unavailable) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof Declined) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof Processing) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    };
    if (x instanceof Refunded) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
    };
    if (x instanceof Processed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))));
    };
    throw new Error("Failed pattern match at Model.Status (line 20, column 1 - line 20, column 51): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Awaiting.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return $$Error.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return Unavailable.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return Declined.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return Processing.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return Refunded.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))) {
        return Processed.value;
    };
    throw new Error("Failed pattern match at Model.Status (line 20, column 1 - line 20, column 51): " + [ x.constructor.name ]);
});
var readForeignBatchStatus = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genBatchStatus)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Awaiting";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Error";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Unavailable";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Declined";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Processing";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Refunded";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Processed";
}))))))))));
var showBatchStatus = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genBatchStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Awaiting";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Error";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Unavailable";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Declined";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Processing";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Refunded";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Processed";
}))))))))));
var eqBatchStatus = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Awaiting && y instanceof Awaiting) {
            return true;
        };
        if (x instanceof $$Error && y instanceof $$Error) {
            return true;
        };
        if (x instanceof Unavailable && y instanceof Unavailable) {
            return true;
        };
        if (x instanceof Declined && y instanceof Declined) {
            return true;
        };
        if (x instanceof Processing && y instanceof Processing) {
            return true;
        };
        if (x instanceof Refunded && y instanceof Refunded) {
            return true;
        };
        if (x instanceof Processed && y instanceof Processed) {
            return true;
        };
        return false;
    };
});
var ordBatchStatus = new Data_Ord.Ord(function () {
    return eqBatchStatus;
}, function (x) {
    return function (y) {
        if (x instanceof Awaiting && y instanceof Awaiting) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Awaiting) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Awaiting) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof $$Error && y instanceof $$Error) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof $$Error) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof $$Error) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Unavailable && y instanceof Unavailable) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Unavailable) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Unavailable) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Declined && y instanceof Declined) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Declined) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Declined) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Processing && y instanceof Processing) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Processing) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Processing) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Refunded && y instanceof Refunded) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Refunded) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Refunded) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Processed && y instanceof Processed) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Model.Status (line 19, column 1 - line 19, column 45): " + [ x.constructor.name, y.constructor.name ]);
    };
});
module.exports = {
    Awaiting: Awaiting,
    "Error": $$Error,
    Unavailable: Unavailable,
    Declined: Declined,
    Processing: Processing,
    Refunded: Refunded,
    Processed: Processed,
    genStatusDisplay: genStatusDisplay,
    genColor: genColor,
    eqBatchStatus: eqBatchStatus,
    ordBatchStatus: ordBatchStatus,
    genBatchStatus: genBatchStatus,
    showBatchStatus: showBatchStatus,
    readForeignBatchStatus: readForeignBatchStatus
};
