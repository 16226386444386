import React, { useContext } from 'react'
import * as r from 'ramda'
import AlertBanner from './AlertBanner'
import { Danger, Success } from 'purs/AlertBanner'
import { context } from '../pages/BatchDetail'
import { Debit, Internal, Settlement } from 'purs/Model.PaymentError'
import { Just } from 'purs/Data.Maybe'

export const BatchDetailAlert = () => {
  const { state } = useContext(context)

  const error = r.path(['batch', 'value0', 'batchDetail', 'error'], state)

  if (error instanceof Just) {
    const errorSource = r.path(['source', 'value0'], error.value0)
    if (!errorSource) {
      return (
        <AlertBanner type={Danger.value}>
          <span>
            Oops, looks like your payment has failed. Please try again later.
          </span>
        </AlertBanner>
      )
    }

    switch (errorSource.constructor) {
      case Debit:
        const errorCodes = r.pathOr('', ['value0', 'code', 'value0'])(error).split(" && ")
        const errorMsgs = r.uniq(r.map(debitErrorMapping, errorCodes))
        return (
          <AlertBanner type={Danger.value}>
            {renderError(errorMsgs)}
          </AlertBanner>
        )
      case Settlement:
        return (
          <AlertBanner type={Danger.value}>
            <span>
              Oops, looks like your payment has failed. Please contact support
              on&nbsp;<a href="tel:1300783674">1300 783 674</a>
              &nbsp;for assistance.
            </span>
          </AlertBanner>
        )
      case Internal:
        return <PaymentAlert />
    }
  } else {
    return <PaymentAlert />
  }
}

const PaymentAlert = () => {
  const { state } = useContext(context)
  const response = state.paymentResponse
  if (response instanceof Just) {
    switch (response.value0) {
      case '204':
        return (
          <AlertBanner type={Success.value}>
            <span>Hooray! Your payment was successful.</span>
          </AlertBanner>
        )
      case '404':
        return (
          <AlertBanner type={Danger.value}>
            <span>
              Oops, looks like you don&apos;t have a card registered. Please
              update your payment methods and try again later.
            </span>
          </AlertBanner>
        )
      case '403':
        return (
          <AlertBanner type={Danger.value}>
            <span>
              Oops, looks like we couldn&apos;t confirm your permission. Chat to
              your administrator before trying again.
            </span>
          </AlertBanner>
        )
      case undefined:
      case '':
        return <></>
      default:
        return (
          <AlertBanner type={Danger.value}>
            <span>
              Oops, looks like your payment has failed. Please try again later.
            </span>
          </AlertBanner>
        )
    }
  } else {
    return <></>
  }
}

const renderError = errorMsgs => {
  return r.map(e => {
    return (
      <div>
        {e}
        <br/>
      </div>
    );
  }, errorMsgs);
};

const debitErrorMapping = code => {
  const msgTail = `(Error: ${code})`
  const expiredCardErrorMessage = `Oops, looks like your card has expired. Please update your details before retrying the payment.`
  switch (code) {
    case '33':
    case '54':
      return `${expiredCardErrorMessage} ${msgTail}`
    case 'A8':
      return `Oops, looks like you've entered an invalid amount. Please update your details before retrying the payment. ${msgTail}`
    case 'A9':
      return `Oops, looks like you've entered an invalid card number. Please update your details before retrying the payment. ${msgTail}`
    case 'AB':
      return `Oops, looks like you've entered an expired card number. Please update your details before retrying the payment. ${msgTail}`
    case 'AC':
      return `Oops, looks like the card number you've entered has expired. Please update your details before retrying the payment. ${msgTail}`
    case 'U9':
      return `Oops, looks like your session has expired. Please try again. ${msgTail}`
    case 'DE INVALID BSB':
    case 'DE NO ACCOUNT':
      return "Oops, looks like you've entered invalid account details. Please update your details before retrying the payment."
    case 'DE REFER TO CUST':
      return 'Oops, looks like your payment has failed due to insufficient funds. Please try again later.'
    case 'DE PAYMENT STOP':
    case "DE DEC'D CUST":
    case 'DE A/C CLOSED':
      return 'Oops, looks like your payment has failed. Please contact support on 1300 783 674 for assistance.'
    // npr error code
    case 'CARD_EXPIRED':
      return 'Oops, looks like the card you saved is expired. Please update your card details before retrying the payment.'
    case 'CARD_INVALID_NUMBER':
      return 'Oops, looks like the card you saved is invalid. Please update your card details before retrying the payment.'
    case 'CARD_INSUFFICIENT_FUNDS':
      return 'Oops, looks like your card has insufficient funds.'
    case 'CARD_GENERIC_ISSUE':
    case 'CARD_PAYMENT_TOKEN_NOT_FOUND':
      return 'Oops, looks like something went wrong with your card. Please update your card details before retrying the payment.'
    case 'CARD_PAYMENT_DUPLICATION':
      return 'Oops, looks like your payment has failed.'
    case 'ARG_NOT_VALID':
      return 'Oops, looks like your payment has failed. Please try again later.'
    case 'CARD_PAYMENT_PROCESSING':
    case 'CARD_GATEWAY_GENERIC':
    case 'CARD_PAYMENT_VIOLATION':
    case 'CARD_FRAUD_CHECK_VIOLATION':
      return 'We\'ve hit a roadblock! Please try again later.'
    default:
      return `Oops, looks like your payment has failed. Please try again later.`
  }
}
