// Generated by purs version 0.13.8
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Foreign = require("../Foreign/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Inactive = (function () {
    function Inactive() {

    };
    Inactive.value = new Inactive();
    return Inactive;
})();
var Active = (function () {
    function Active() {

    };
    Active.value = new Active();
    return Active;
})();
var RoleUpdated = (function () {
    function RoleUpdated() {

    };
    RoleUpdated.value = new RoleUpdated();
    return RoleUpdated;
})();
var RoleNominated = (function () {
    function RoleNominated() {

    };
    RoleNominated.value = new RoleNominated();
    return RoleNominated;
})();
var Administrator = (function () {
    function Administrator() {

    };
    Administrator.value = new Administrator();
    return Administrator;
})();
var Authoriser = (function () {
    function Authoriser() {

    };
    Authoriser.value = new Authoriser();
    return Authoriser;
})();
var Submitter = (function () {
    function Submitter() {

    };
    Submitter.value = new Submitter();
    return Submitter;
})();
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var NotRequired = (function () {
    function NotRequired() {

    };
    NotRequired.value = new NotRequired();
    return NotRequired;
})();
var Pending = (function () {
    function Pending() {

    };
    Pending.value = new Pending();
    return Pending;
})();
var Completed = (function () {
    function Completed() {

    };
    Completed.value = new Completed();
    return Completed;
})();
var roleActivityTypeShow = new Data_Show.Show(function (v) {
    if (v instanceof RoleUpdated) {
        return "Role updated";
    };
    if (v instanceof RoleNominated) {
        return "Role nominated";
    };
    throw new Error("Failed pattern match at Model.User (line 60, column 1 - line 62, column 40): " + [ v.constructor.name ]);
});
var kycStatusShow = new Data_Show.Show(function (v) {
    if (v instanceof Pending) {
        return "Pending";
    };
    return "Active";
});
var isPending = function (currentRole) {
    return function (intendedRole) {
        if (currentRole instanceof Administrator && intendedRole instanceof Authoriser) {
            return true;
        };
        if (currentRole instanceof Submitter && intendedRole instanceof Authoriser) {
            return true;
        };
        if (currentRole instanceof None && intendedRole instanceof Authoriser) {
            return true;
        };
        return false;
    };
};
var isUserPendingAuthorisation = function (a) {
    return function (b) {
        return Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(isPending(a))(b));
    };
};
var genericStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Inactive) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Active) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at Model.User (line 13, column 1 - line 13, column 50): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Inactive.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return Active.value;
    };
    throw new Error("Failed pattern match at Model.User (line 13, column 1 - line 13, column 50): " + [ x.constructor.name ]);
});
var statusReadForeign = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericStatus)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Inactive";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Active";
})))));
var statusShow = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Inactive";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Active";
})))));
var statusWriteForeign = new Simple_JSON.WriteForeign((function () {
    var $52 = Data_Generic_Rep_Show.genericShow(genericStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "Inactive";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "Active";
    }))));
    return function ($53) {
        return Foreign.unsafeToForeign($52($53));
    };
})());
var genericRoleActivityType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof RoleUpdated) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof RoleNominated) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at Model.User (line 57, column 1 - line 57, column 70): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return RoleUpdated.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return RoleNominated.value;
    };
    throw new Error("Failed pattern match at Model.User (line 57, column 1 - line 57, column 70): " + [ x.constructor.name ]);
});
var roleActivityTypeReadForeign = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericRoleActivityType)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "RoleUpdated";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "RoleNominated";
})))));
var genericRole = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Administrator) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Authoriser) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Submitter) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof None) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at Model.User (line 29, column 1 - line 29, column 46): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Administrator.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return Authoriser.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return Submitter.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return None.value;
    };
    throw new Error("Failed pattern match at Model.User (line 29, column 1 - line 29, column 46): " + [ x.constructor.name ]);
});
var roleReadForeign = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericRole)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Administrator";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Authoriser";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Submitter";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "None";
})))))));
var roleShow = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericRole)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Administrator";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Authoriser";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Submitter";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "None";
})))))));
var roleWriteForeign = new Simple_JSON.WriteForeign((function () {
    var $54 = Data_Generic_Rep_Show.genericShow(genericRole)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "Administrator";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "Authoriser";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "Submitter";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "None";
    }))))));
    return function ($55) {
        return Foreign.unsafeToForeign($54($55));
    };
})());
var genericKYCStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof NotRequired) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Pending) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Completed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at Model.User (line 43, column 1 - line 43, column 56): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return NotRequired.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return Pending.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return Completed.value;
    };
    throw new Error("Failed pattern match at Model.User (line 43, column 1 - line 43, column 56): " + [ x.constructor.name ]);
});
var kycStatusReadForeign = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericKYCStatus)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "NotRequired";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Pending";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Completed";
}))))));
var readUser = function (user) {
    return Simple_JSON.read(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "email";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "kycStatus";
    }))(kycStatusReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "nominatedRole";
    }))(roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "role";
    }))(roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "status";
    }))(statusReadForeign)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()()))(user);
};
var kycStatusWriteForeign = new Simple_JSON.WriteForeign((function () {
    var $56 = Data_Generic_Rep_Show.genericShow(genericKYCStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "NotRequired";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "Pending";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "Completed";
    })))));
    return function ($57) {
        return Foreign.unsafeToForeign($56($57));
    };
})());
var writeUser = function (user) {
    return Simple_JSON.write(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "email";
    }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "kycStatus";
    }))(kycStatusWriteForeign)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "name";
    }))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignString))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "nominatedRole";
    }))(roleWriteForeign)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "role";
    }))(roleWriteForeign)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "status";
    }))(statusWriteForeign)(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()()))(user);
};
var eqStatus = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Inactive && y instanceof Inactive) {
            return true;
        };
        if (x instanceof Active && y instanceof Active) {
            return true;
        };
        return false;
    };
});
var isActive = function (user) {
    return Data_Eq.eq(eqStatus)(user.status)(Active.value);
};
var eqRole = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Administrator && y instanceof Administrator) {
            return true;
        };
        if (x instanceof Authoriser && y instanceof Authoriser) {
            return true;
        };
        if (x instanceof Submitter && y instanceof Submitter) {
            return true;
        };
        if (x instanceof None && y instanceof None) {
            return true;
        };
        return false;
    };
});
var ordRole = new Data_Ord.Ord(function () {
    return eqRole;
}, function (x) {
    return function (y) {
        if (x instanceof Administrator && y instanceof Administrator) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Administrator) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Administrator) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Authoriser && y instanceof Authoriser) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Authoriser) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Authoriser) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Submitter && y instanceof Submitter) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Submitter) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Submitter) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof None && y instanceof None) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Model.User (line 27, column 1 - line 27, column 36): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var eqKYCStatus = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof NotRequired && y instanceof NotRequired) {
            return true;
        };
        if (x instanceof Pending && y instanceof Pending) {
            return true;
        };
        if (x instanceof Completed && y instanceof Completed) {
            return true;
        };
        return false;
    };
});
module.exports = {
    Inactive: Inactive,
    Active: Active,
    Administrator: Administrator,
    Authoriser: Authoriser,
    Submitter: Submitter,
    None: None,
    NotRequired: NotRequired,
    Pending: Pending,
    Completed: Completed,
    RoleUpdated: RoleUpdated,
    RoleNominated: RoleNominated,
    isActive: isActive,
    writeUser: writeUser,
    readUser: readUser,
    isUserPendingAuthorisation: isUserPendingAuthorisation,
    isPending: isPending,
    genericStatus: genericStatus,
    eqStatus: eqStatus,
    statusReadForeign: statusReadForeign,
    statusShow: statusShow,
    statusWriteForeign: statusWriteForeign,
    eqRole: eqRole,
    ordRole: ordRole,
    genericRole: genericRole,
    roleReadForeign: roleReadForeign,
    roleShow: roleShow,
    roleWriteForeign: roleWriteForeign,
    eqKYCStatus: eqKYCStatus,
    genericKYCStatus: genericKYCStatus,
    kycStatusReadForeign: kycStatusReadForeign,
    kycStatusWriteForeign: kycStatusWriteForeign,
    kycStatusShow: kycStatusShow,
    genericRoleActivityType: genericRoleActivityType,
    roleActivityTypeReadForeign: roleActivityTypeReadForeign,
    roleActivityTypeShow: roleActivityTypeShow
};
