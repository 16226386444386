// Generated by purs version 0.13.8
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Success = (function () {
    function Success() {

    };
    Success.value = new Success();
    return Success;
})();
var Warning = (function () {
    function Warning() {

    };
    Warning.value = new Warning();
    return Warning;
})();
var Danger = (function () {
    function Danger() {

    };
    Danger.value = new Danger();
    return Danger;
})();
var NoAlert = (function () {
    function NoAlert() {

    };
    NoAlert.value = new NoAlert();
    return NoAlert;
})();
var Info = (function () {
    function Info() {

    };
    Info.value = new Info();
    return Info;
})();
var showAlertType = new Data_Show.Show(function (v) {
    if (v instanceof Success) {
        return "success";
    };
    if (v instanceof Warning) {
        return "warning";
    };
    if (v instanceof Danger) {
        return "danger";
    };
    if (v instanceof NoAlert) {
        return "noAlert";
    };
    if (v instanceof Info) {
        return "info";
    };
    throw new Error("Failed pattern match at AlertBanner (line 6, column 1 - line 11, column 21): " + [ v.constructor.name ]);
});
var getDismissTime = function (v) {
    if (v.type instanceof Success && !v.dismissLater) {
        return new Data_Maybe.Just(99999999);
    };
    if (v.dismissLater) {
        return new Data_Maybe.Just(5000);
    };
    return Data_Maybe.Nothing.value;
};
module.exports = {
    Success: Success,
    Warning: Warning,
    Danger: Danger,
    NoAlert: NoAlert,
    Info: Info,
    getDismissTime: getDismissTime,
    showAlertType: showAlertType
};
