import React, { useContext } from 'react'
import NavBarForBusiness from '../components/NavBarForBusiness'
import { BaseTemplate } from '@myob/myob-widgets'
import { Card, Button, Icons, Spinner } from '@myob/myob-widgets'
import lock from '../img/lock.svg'
import DashboardHeader from '../components/DashboardHeader'
import './KYCDashboard.sass'
import withPsState from '../withPsState'
import {
  initState,
  updateRole,
  showModal,
  hideModal,
  SuccessResponse,
  ForbiddenToUpdateRole,
  RedirectFromKYCSuccess,
  RedirectFromKYCForbidden,
  handleResponse
} from 'purs/KYCDashboard'

import { KYCFailedAlert } from '../components/DashboardFailedAlert'
import AddContactDetails from '../components/AddContactModal'
import { isJust, Just } from 'purs/Data.Maybe'
import Error from '../components/Error'
import * as r from 'ramda'
import { kycDoneStatus, kycFailedStatus } from 'purs/OnboardingKYCModalContent'

const KYCContent = () => {
  const { state, dispatch } = useContext(context)
  return (
    <Card classes={['text-align-center', 'kyc-card']}>
      <div className="card1-content">
        <img
          className="card1-content__img"
          alt="Add contact details"
          src={lock}
        />
        <h3>Tell us about yourself</h3>
        <div>
          To gain the ability to authorise batch payments, please finish
          <br />
          your account setup by telling us a little about yourself.
        </div>
        <Button
          className="add-contact-btn"
          id="addContactBtn"
          type="link"
          icon={<Icons.Add />}
          onClick={() => {
            dispatch(state => showModal(state))
          }}
          disabled={state.kycModal.disabled}
        >
          Add contact details
        </Button>
      </div>
    </Card>
  )
}

const LoadContent = props => {
  const { state } = useContext(context)
  if (isJust(state.responseType)) {
    const responseCode = state.responseType.value0
    switch (responseCode.constructor) {
      case SuccessResponse:
        props.history.push('/', {
          kycResult: Just.create(RedirectFromKYCSuccess.value)
        })
        return <></>
      case ForbiddenToUpdateRole:
        props.history.push('/', {
          kycResult: Just.create(RedirectFromKYCForbidden.value)
        })
        return <></>
      default:
        return (
          <Card>
            <Error httpError={responseCode.value0.code} />
          </Card>
        )
    }
  } else {
    return <KYCContent />
  }
}

const KYCDashboard = props => {
  const { state, dispatch } = useContext(context)

  if (state.spinner.display) {
    return <Spinner />
  }

  const hasRoleUpdateResult = function(kycRespStatus) {
    return (
      kycRespStatus &&
      (kycRespStatus.status === kycDoneStatus ||
        kycRespStatus.status === kycFailedStatus)
    )
  }

  return (
    <div className="kyc-dashboard">
      <NavBarForBusiness />
      <BaseTemplate>
        {state.kycFailedAlert.display && <KYCFailedAlert />}
        <DashboardHeader />
        <LoadContent {...props} />
        <Button
          type="link"
          id="skipForNowBtn"
          className="skip-button"
          onClick={() => props.history.push('/')}
        >
          Skip for now
        </Button>
      </BaseTemplate>
      {state.kycModal.display && (
        <AddContactDetails
          callBack={kycRespStatus => {
            dispatch(hideModal)

            if (hasRoleUpdateResult(kycRespStatus)) {
              const companyId = r.pathOr(
                '',
                ['match', 'params', 'companyId'],
                props
              )
              dispatch(state =>
                handleResponse(companyId)(state)({
                  status: r.or(parseInt(kycRespStatus.body), 500),
                  body: ''
                })
              )
            }
          }}
          iframeUrl="/userkycmodal"
        />
      )}
    </div>
  )
}

const updateUserRole = props =>
  updateRole(r.pathOr('', ['match', 'params', 'companyId'], props))

export const { context, Component } = withPsState(
  KYCDashboard,
  initState,
  updateUserRole
)

export default Component
