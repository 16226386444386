import React, { useContext, useState } from 'react'
import * as r from 'ramda'
import { Spinner, StandardTemplate } from '@myob/myob-widgets'
import { Link } from 'react-router-dom'
import { createBatchDetailViewModel } from 'purs/Model.Batch'
import Status from './BatchTable.Status'
import FormattedAmount from './Batch.Amount'
import { renderTableBody, renderSortableTableHeader, Text } from './Batch.Table'
import Error from './Error'
import { context } from '../pages/Home'
import SecondaryTabs, { NavTabs, pageHead } from './SecondaryTab'
import './BatchTable.sass'
import { dateFormatter, timeFormatter } from '../helper/formatter'
import { sortBatchBy, faqsLink } from 'purs/BatchTable'
import { isJust } from 'purs/Data.Maybe'
import { AuthorisersList } from './AuthorisersList'
import ApplicationComplete from './ApplicationComplete'

function BatchName(props) {
  return <Link to={`/batch/${props.id}`}>{props.journalMemo}</Link>
}

const format = [
  {
    key: 'journalMemo',
    description: 'Batch name',
    sortable: true,
    width: 'flex-2',
    align: 'left',
    component: BatchName
  },
  {
    key: 'requestedBy',
    description: 'Submitted by',
    sortable: true,
    width: 'flex-2',
    align: 'left',
    component: Text('requestedBy')
  },
  {
    key: 'submittedDate',
    description: 'Submitted',
    sortable: true,
    width: 'flex-1',
    align: 'left',
    component: props => <span>{dateFormatter(props.submittedDate)}</span>
  },
  {
    key: 'submittedTime',
    description: 'Time',
    width: 'flex-1',
    align: 'left',
    component: props => <span>{timeFormatter(props.submittedTime)}</span>
  },
  {
    key: 'authorisedBy',
    description: 'Authorised by',
    sortable: false,
    width: 'flex-1',
    align: 'center',
    component: props => AuthorisersList(props.authorisedBy)
  },
  {
    key: 'authorisedDate',
    description: 'Authorised',
    sortable: true,
    width: 'flex-1',
    align: 'left',
    component: props => <span>{dateFormatter(props.authorisedDate)}</span>
  },
  {
    key: 'totalAmount',
    description: 'Amount ($)',
    sortable: true,
    width: 'flex-1',
    align: 'right',
    component: props => <FormattedAmount amount={props.totalAmount} />
  },
  {
    key: 'status',
    description: 'Status',
    sortable: true,
    width: 'flex-2',
    align: 'left',
    component: Status
  }
]

const BatchListHeader = (dispatch, sortIcon, setSortIcon) => {
  return renderSortableTableHeader(format, sortIcon, key => {
    const isDescending = key === sortIcon.column ? !sortIcon.descending : true
    dispatch(sortBatchBy(key)(isDescending))
    setSortIcon({ column: key, descending: isDescending })
  })
}

const BatchTable = () => {
  const { state, dispatch } = useContext(context)
  const [sortIcon, setSortIcon] = useState({
    column: 'submittedDate',
    descending: false
  })
  const { company } = state
  const paymentFaqsLink = faqsLink(company)

  return state.isLoading ? (
    <Spinner />
  ) : (
    <StandardTemplate
      pageHead={pageHead(r.pathOr('', ['value0', 'name'])(company))}
      tableHeader={
        <>
          <h1>Batch list</h1>
          {BatchListHeader(dispatch, sortIcon, setSortIcon)}
        </>
      }
      subHeadChildren={
        <SecondaryTabs tab={NavTabs.batchPayment} company={company} />
      }
      sticky="all"
      wcagAA={true}
    >
      {isJust(state.httpError) ? (
        <Error httpError={state.httpError.value0} />
      ) : state.batches && state.batches.length > 0 ? (
        renderTableBody(state.batches.map(createBatchDetailViewModel), format)
      ) : (
        <ApplicationComplete
          title={"You've successfully set up expense payments!"}
          description={
            'To make your first direct payment, visit the "Prepare electronic payments" page in the Banking or Payroll menu of the accounting software.'
          }
        >
          <a
            className="payment-faqs-link"
            href={paymentFaqsLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            View the FAQs for making payments
          </a>
        </ApplicationComplete>
      )}
    </StandardTemplate>
  )
}

export default BatchTable
