import React from 'react'
import { Input } from '@myob/myob-widgets'
import { AbnValidated, AbnUnsupported } from 'purs/Model.CompanyOnboardModal'
import './AbnDetail.sass'
import { maybe } from 'purs/Data.Maybe'
import * as r from 'ramda'

const AbnDetail = ({ content }) =>
  content instanceof AbnValidated || content instanceof AbnUnsupported ? (
    <div className="abn-detail">
      <h3>ABN details</h3>
      <p>Confirm these are the correct details for the ABN you’ve entered. </p>
      <div>
        {maybe(<></>)(acn =>
          r.isEmpty(acn) ? (
            <></>
          ) : (
            <Input
              value={acn}
              label="Australian Company Number (ACN)"
              name="acn"
              disabled
            />
          )
        )(content.value1.acn)}
      </div>

      <div>
        <Input
          id={'business-name'}
          value={content.value1.businessName}
          label="Business name"
          name="name"
          disabled
        />
      </div>

      <div>
        <Input
          id={'business-type'}
          value={content.value1.businessType}
          label="Business type"
          name="type"
          disabled
        />
      </div>

      <div>
        <Input
          id={'location'}
          value={content.value1.location}
          label="Location"
          name="location"
          disabled
        />
      </div>
    </div>
  ) : (
    <></>
  )

export default AbnDetail
