// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Foreign = require("../Foreign/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Attempt = function (x) {
    return x;
};
var readEither = function (dictReadForeign) {
    return new Simple_JSON.ReadForeign((function () {
        var recover = function ($9) {
            return Attempt(Control_Monad_Except.runExcept($9));
        };
        var $10 = Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity));
        var $11 = Simple_JSON.readImpl(dictReadForeign);
        return function ($12) {
            return $10(recover($11($12)));
        };
    })());
};
var partition = function (a) {
    var f = function (i) {
        return function (b) {
            return function (v) {
                if (v instanceof Data_Either.Left) {
                    return {
                        left: Data_Array.snoc(b.left)(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtIndex.create(i))(v.value0)),
                        right: b.right
                    };
                };
                if (v instanceof Data_Either.Right) {
                    return {
                        left: b.left,
                        right: Data_Array.snoc(b.right)(v.value0)
                    };
                };
                throw new Error("Failed pattern match at Helper.Attempt (line 17, column 5 - line 17, column 130): " + [ i.constructor.name, b.constructor.name, v.constructor.name ]);
            };
        };
    };
    return Data_FoldableWithIndex.foldlWithIndex(Data_FoldableWithIndex.foldableWithIndexArray)(f)({
        left: [  ],
        right: [  ]
    })(a);
};
module.exports = {
    Attempt: Attempt,
    partition: partition,
    readEither: readEither
};
