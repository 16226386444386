import * as React from 'react'
import './NavBar.sass'
import { LandlineIcon, Navigation, Icons, MYOBLogo, Tooltip } from '@myob/myob-widgets'
import { isNewEssentialsBusiness } from 'purs/Model.CompanySubscription'

export const Brand = (
  <Navigation.Brand url="#" width="73px">
    <MYOBLogo />
  </Navigation.Brand>
)

export const helpLink = (productType) => {
  const arlHelpLink = 'https://help.myob.com/wiki/x/PoBW'
  const newEssentialsHelpLink = 'https://help.myob.com/wiki/x/OxymAw'
  const productSpecificLink = isNewEssentialsBusiness(productType) ? newEssentialsHelpLink : arlHelpLink
  return (<li className="flx-navbar__menu-item" key="help_link">
    <a
      className="flx-navbar__menu-link"
      role="button"
      href={productSpecificLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      Help
    </a>
  </li>)
}

const createSupportLink = () => {
  const link = (
    <a
      id="support-link"
      className="flx-navbar__menu-link"
      role="button"
      href="tel:1300783674">
        <LandlineIcon size="16px" className="support-link-icon"/> 1300 783 674
    </a>
  )

  return (
    <li className="flx-navbar__menu-item" key="support_link">
      <Tooltip placement="bottom" triggerContent={link}>Call payments support team</Tooltip>
    </li>
  )
}

export const logoutBtn = (
  <Navigation.Link
    key="logout"
    url="/logout"
    label="Log out"
    icon={<Icons.SignOut />}
  />
)

const NavBar = () => (
  <Navigation
    brand={Brand}
    primary={[<Navigation.Separator key="primary" />]}
    secondary={[logoutBtn, createSupportLink()]}
  />
)

export default NavBar
