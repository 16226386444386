// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Maybe_Trans = require("../Control.Monad.Maybe.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Time_Duration = require("../Data.Time.Duration/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Random = require("../Effect.Random/index.js");
var $$Math = require("../Math/index.js");
var RetryStatus = function (x) {
    return x;
};
var RetryPolicyM = function (x) {
    return x;
};
var showRetryStatus = new Data_Show.Show(function (v) {
    return "RetryStatus { " + ("iterNumber: " + (Data_Show.show(Data_Show.showInt)(v.iterNumber) + (", " + ("cumulativeDelay: " + (Data_Show.show(Data_Time_Duration.showMilliseconds)(v.cumulativeDelay) + (", " + ("previousDelay: " + (Data_Show.show(Data_Maybe.showMaybe(Data_Time_Duration.showMilliseconds))(v.previousDelay) + "}"))))))));
});
var retryPolicySemigroup = function (dictMonad) {
    return new Data_Semigroup.Semigroup(function (v) {
        return function (v1) {
            return RetryPolicyM(function (n) {
                return Control_Monad_Maybe_Trans.runMaybeT(Control_Apply.apply(Control_Monad_Maybe_Trans.applyMaybeT(dictMonad))(Data_Functor.map(Control_Monad_Maybe_Trans.functorMaybeT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Ord.max(Data_Time_Duration.ordMilliseconds))(v(n)))(v1(n)));
            });
        };
    });
};
var retryPolicy = function (f) {
    return function (dictMonadAff) {
        var $85 = Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0());
        return function ($86) {
            return $85(f($86));
        };
    };
};
var retryPolicyMonoid = function (dictMonadAff) {
    return new Data_Monoid.Monoid(function () {
        return retryPolicySemigroup((dictMonadAff.MonadEffect0()).Monad0());
    }, retryPolicy(Data_Function["const"](Data_Maybe.Just.create(0)))(dictMonadAff));
};
var newtypeRetryStatus = new Data_Newtype.Newtype(function (n) {
    return n;
}, RetryStatus);
var limitRetriesByDelay = function (dictMonad) {
    return function (dictDuration) {
        return function (d) {
            return function (v) {
                var limit = function (delay) {
                    var $52 = Data_Ord.greaterThanOrEq(Data_Time_Duration.ordMilliseconds)(delay)(Data_Time_Duration.fromDuration(dictDuration)(d));
                    if ($52) {
                        return Data_Maybe.Nothing.value;
                    };
                    return new Data_Maybe.Just(delay);
                };
                return function (status) {
                    return Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(limit))(v(status));
                };
            };
        };
    };
};
var limitRetriesByCumulativeDelay = function (dictMonad) {
    return function (dictDuration) {
        return function (d) {
            return function (v) {
                var cumulativeDelay = Data_Time_Duration.fromDuration(dictDuration)(d);
                var limit = function (v1) {
                    return function (curDelay) {
                        if (Data_Ord.greaterThan(Data_Time_Duration.ordMilliseconds)(Data_Semigroup.append(Data_Time_Duration.semigroupMilliseconds)(v1.cumulativeDelay)(curDelay))(cumulativeDelay)) {
                            return Data_Maybe.Nothing.value;
                        };
                        if (Data_Boolean.otherwise) {
                            return new Data_Maybe.Just(curDelay);
                        };
                        throw new Error("Failed pattern match at Effect.Aff.Retry (line 125, column 5 - line 127, column 34): " + [ v1.constructor.name, curDelay.constructor.name ]);
                    };
                };
                return function (status) {
                    return Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(limit(status)))(v(status));
                };
            };
        };
    };
};
var limitRetries = function (i) {
    return function (dictMonadAff) {
        return retryPolicy(function (v) {
            var $58 = v.iterNumber >= i;
            if ($58) {
                return Data_Maybe.Nothing.value;
            };
            return new Data_Maybe.Just(Data_Monoid.mempty(Data_Time_Duration.monoidMilliseconds));
        })(dictMonadAff);
    };
};
var fullJitterBackoff = function (dictMonadAff) {
    return function (dictDuration) {
        return function (duration) {
            return function (v) {
                var v1 = Data_Time_Duration.fromDuration(dictDuration)(duration);
                var d = (v1 * $$Math.pow(2.0)(Data_Int.toNumber(v.iterNumber))) / 2.0;
                return Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(Effect_Class.liftEffect(dictMonadAff.MonadEffect0())(Effect_Random.randomRange(0)(d)))(function (rand) {
                    return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(Data_Maybe.Just.create(Data_Time_Duration.Milliseconds(d + rand)));
                });
            };
        };
    };
};
var fibonacciBackoff = function (dictDuration) {
    return function (duration) {
        return function (dictMonadAff) {
            var v = Data_Time_Duration.fromDuration(dictDuration)(duration);
            var fib = function ($copy_v1) {
                return function ($copy_v2) {
                    var $tco_var_v1 = $copy_v1;
                    var $tco_done = false;
                    var $tco_result;
                    function $tco_loop(v1, v2) {
                        if (v1 === 0.0) {
                            $tco_done = true;
                            return v2.a;
                        };
                        $tco_var_v1 = v1 - 1;
                        $copy_v2 = {
                            a: v2.b,
                            b: v2.a + v2.b
                        };
                        return;
                    };
                    while (!$tco_done) {
                        $tco_result = $tco_loop($tco_var_v1, $copy_v2);
                    };
                    return $tco_result;
                };
            };
            return retryPolicy(function (v1) {
                return Data_Maybe.Just.create(Data_Time_Duration.Milliseconds(fib(Data_Int.toNumber(v1.iterNumber) + 1)({
                    a: 0,
                    b: v
                })));
            })(dictMonadAff);
        };
    };
};
var exponentialBackoff = function (dictDuration) {
    return function (base) {
        return function (dictMonadAff) {
            return retryPolicy(function (v) {
                return Data_Maybe.Just.create(Data_Time_Duration.Milliseconds(Data_Newtype.unwrap(Data_Time_Duration.newtypeMilliseconds)(Data_Time_Duration.fromDuration(dictDuration)(base)) * $$Math.pow(2.0)(Data_Int.toNumber(v.iterNumber))));
            })(dictMonadAff);
        };
    };
};
var eqRetryStatus = new Data_Eq.Eq(function (x) {
    return function (y) {
        return Data_Eq.eq(Data_Time_Duration.eqMilliseconds)(x.cumulativeDelay)(y.cumulativeDelay) && x.iterNumber === y.iterNumber && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Time_Duration.eqMilliseconds))(x.previousDelay)(y.previousDelay);
    };
});
var defaultRetryStatus = {
    iterNumber: 0,
    cumulativeDelay: 0,
    previousDelay: Data_Maybe.Nothing.value
};
var constantDelay = function (dictDuration) {
    return function (d) {
        return function (dictMonadAff) {
            return retryPolicy(Data_Function["const"](Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Data_Time_Duration.fromDuration(dictDuration)(d))))(dictMonadAff);
        };
    };
};
var capDelay = function (dictMonad) {
    return function (dictDuration) {
        return function (limit) {
            return function (v) {
                return function (status) {
                    return Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Ord.min(Data_Time_Duration.ordMilliseconds)(Data_Time_Duration.fromDuration(dictDuration)(limit))))(v(status));
                };
            };
        };
    };
};
var applyPolicy = function (dictMonadAff) {
    return function (v) {
        return function (v1) {
            return Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(v(v1))(function (res) {
                if (res instanceof Data_Maybe.Just) {
                    return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(Data_Maybe.Just.create({
                        iterNumber: v1.iterNumber + 1 | 0,
                        cumulativeDelay: Data_Semigroup.append(Data_Time_Duration.semigroupMilliseconds)(v1.cumulativeDelay)(res.value0),
                        previousDelay: new Data_Maybe.Just(res.value0)
                    }));
                };
                if (res instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(Data_Maybe.Nothing.value);
                };
                throw new Error("Failed pattern match at Effect.Aff.Retry (line 193, column 3 - line 199, column 28): " + [ res.constructor.name ]);
            });
        };
    };
};
var applyAndDelay = function (dictMonadAff) {
    return function (policy) {
        return function (retryStatus) {
            return Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(applyPolicy(dictMonadAff)(policy)(retryStatus))(function (res) {
                if (res instanceof Data_Maybe.Just) {
                    return Data_Functor.voidLeft(((((dictMonadAff.MonadEffect0()).Monad0()).Bind1()).Apply0()).Functor0())(Data_Maybe.maybe(Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(Data_Unit.unit))((function () {
                        var $87 = Effect_Aff_Class.liftAff(dictMonadAff);
                        return function ($88) {
                            return $87(Effect_Aff.delay($88));
                        };
                    })())(res.value0.previousDelay))(new Data_Maybe.Just(res.value0));
                };
                if (res instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(Data_Maybe.Nothing.value);
                };
                throw new Error("Failed pattern match at Effect.Aff.Retry (line 209, column 5 - line 213, column 30): " + [ res.constructor.name ]);
            });
        };
    };
};
var recovering = function (dictMonadAff) {
    return function (dictMonadError) {
        return function (policy) {
            return function (checks) {
                return function (f) {
                    var go = function (status) {
                        var recover = function (chks) {
                            return function (e) {
                                return Data_Maybe.maybe(Control_Monad_Error_Class.throwError(dictMonadError.MonadThrow0())(e))(handle(e))(Data_Array.uncons(chks));
                            };
                        };
                        var handle = function (e) {
                            return function (hs) {
                                return Control_Bind.ifM(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(hs.head(status)(e))(Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(applyAndDelay(dictMonadAff)(policy)(status))(Data_Maybe.maybe(Control_Monad_Error_Class.throwError(dictMonadError.MonadThrow0())(e))(go)))(recover(hs.tail)(e));
                            };
                        };
                        return Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(Control_Monad_Error_Class["try"](dictMonadError)(f(status)))(Data_Either.either(recover(checks))(Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())));
                    };
                    return go(defaultRetryStatus);
                };
            };
        };
    };
};
var retrying = function (dictMonadAff) {
    return function (policy) {
        return function (check) {
            return function (action) {
                var go = function (status) {
                    return Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(action(status))(function (res) {
                        return Control_Bind.ifM(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(check(status)(res))(Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(applyAndDelay(dictMonadAff)(policy)(status))(Data_Maybe.maybe(Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(res))(go)))(Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(res));
                    });
                };
                return go(defaultRetryStatus);
            };
        };
    };
};
module.exports = {
    RetryStatus: RetryStatus,
    RetryPolicyM: RetryPolicyM,
    constantDelay: constantDelay,
    defaultRetryStatus: defaultRetryStatus,
    applyPolicy: applyPolicy,
    retryPolicy: retryPolicy,
    limitRetries: limitRetries,
    limitRetriesByDelay: limitRetriesByDelay,
    limitRetriesByCumulativeDelay: limitRetriesByCumulativeDelay,
    retrying: retrying,
    recovering: recovering,
    showRetryStatus: showRetryStatus,
    eqRetryStatus: eqRetryStatus,
    newtypeRetryStatus: newtypeRetryStatus,
    retryPolicySemigroup: retryPolicySemigroup,
    retryPolicyMonoid: retryPolicyMonoid
};
