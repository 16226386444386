/*tslint-disable */
/*eslint-disable */
const r = require('ramda')
require('whatwg-fetch')

function trace(cb) {
  return function(error) {
    console.error(error)
    cb(error)
  }
}

function sendRequstWithMethod(method, url, cors) {
  return function(body) {
    return function(headers) {
      return function(onError, onSuccess) {
        window
          .fetch(url, {
            method: method.toUpperCase(),
            body: body,
            credentials: cors,
            headers: r.merge(
              {
                'Content-Type': 'application/json',
                Authorization: ''
              },
              headers
            )
          })
          .then(function(res) {
            return res.text().then(function(body) {
              return { status: res.status, body: body }
            })
          })
          .then(onSuccess)
          .catch(trace(onError))
        return function(cancelError, cancelerError, cancelerSuccess) {
          cancelerSuccess()
        }
      }
    }
  }
}

function get(req) {
  return function(cors) {
    return function(headers) {
      return function(onError, onSuccess) {
        window
          .fetch(req, {
            method: 'GET',
            credentials: cors,
            headers: r.merge(
              {
                Authorization: ''
              },
              headers
            )
          })
          .then(function(res) {
            return res.text().then(function(body) {
              return { status: res.status, body: body }
            })
          })
          .then(onSuccess)
          .catch(trace(onError))
        return function(cancelError, cancelerError, cancelerSuccess) {
          cancelerSuccess()
        }
      }
    }
  }
}

function post(url) {
  return function(cors) {
    return sendRequstWithMethod('POST', url, cors)
  }
}

function put(url) {
  return function(cors) {
    return sendRequstWithMethod('PUT', url, cors)
  }
}

function _delete(url) {
  return function(cors) {
    return sendRequstWithMethod('DELETE', url, cors)('')
  }
}

exports._get = get
exports._post = post
exports._put = put
exports._delete = _delete
