// Generated by purs version 0.13.8
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var PayBills = (function () {
    function PayBills() {

    };
    PayBills.value = new PayBills();
    return PayBills;
})();
var PayEmployees = (function () {
    function PayEmployees() {

    };
    PayEmployees.value = new PayEmployees();
    return PayEmployees;
})();
var PayLiability = (function () {
    function PayLiability() {

    };
    PayLiability.value = new PayLiability();
    return PayLiability;
})();
var SpendMoney = (function () {
    function SpendMoney() {

    };
    SpendMoney.value = new SpendMoney();
    return SpendMoney;
})();
var showTransactionType = new Data_Show.Show(function (v) {
    if (v instanceof PayBills) {
        return "Pay Bills";
    };
    if (v instanceof SpendMoney) {
        return "Spend Money";
    };
    if (v instanceof PayEmployees) {
        return "Pay Employees";
    };
    if (v instanceof PayLiability) {
        return "Pay Liabilities";
    };
    throw new Error("Failed pattern match at Model.TransactionType (line 14, column 1 - line 18, column 45): " + [ v.constructor.name ]);
});
var genericStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof PayBills) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof PayEmployees) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof PayLiability) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof SpendMoney) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at Model.TransactionType (line 10, column 1 - line 10, column 59): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return PayBills.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return PayEmployees.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return PayLiability.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return SpendMoney.value;
    };
    throw new Error("Failed pattern match at Model.TransactionType (line 10, column 1 - line 10, column 59): " + [ x.constructor.name ]);
});
var readForeignTransactionType = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericStatus)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "PayBills";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "PayEmployees";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "PayLiability";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "SpendMoney";
})))))));
var eqTransactionType = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof PayBills && y instanceof PayBills) {
            return true;
        };
        if (x instanceof PayEmployees && y instanceof PayEmployees) {
            return true;
        };
        if (x instanceof PayLiability && y instanceof PayLiability) {
            return true;
        };
        if (x instanceof SpendMoney && y instanceof SpendMoney) {
            return true;
        };
        return false;
    };
});
var ordTransactionType = new Data_Ord.Ord(function () {
    return eqTransactionType;
}, function (x) {
    return function (y) {
        if (x instanceof PayBills && y instanceof PayBills) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof PayBills) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof PayBills) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof PayEmployees && y instanceof PayEmployees) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof PayEmployees) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof PayEmployees) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof PayLiability && y instanceof PayLiability) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof PayLiability) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof PayLiability) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof SpendMoney && y instanceof SpendMoney) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Model.TransactionType (line 21, column 1 - line 21, column 58): " + [ x.constructor.name, y.constructor.name ]);
    };
});
module.exports = {
    PayBills: PayBills,
    PayEmployees: PayEmployees,
    PayLiability: PayLiability,
    SpendMoney: SpendMoney,
    genericStatus: genericStatus,
    readForeignTransactionType: readForeignTransactionType,
    showTransactionType: showTransactionType,
    eqTransactionType: eqTransactionType,
    ordTransactionType: ordTransactionType
};
