import React from 'react'
import {Button, Modal, Alert, Spinner} from '@myob/myob-widgets'
import {CardChip, BankAccountChip} from './PaymentMethodChip'
import {useMutation} from 'react-query'
import axios from 'axios'

const SetupDefaultPaymentMethodModal = ({paymentMethod, companyId, onCancel, onSuccess}) => {
  const setAsDefault = useMutation(({methodType, methodHashIdRef}) => {
    return axios.post(`/api/company/${companyId}/default-payment-method`, {
      methodType, methodHashIdRef
    })
  })

  const {card, bankAccount} = paymentMethod
  const onConfirm = async () => {
    const methodType = card ? 'Card' : 'BankAccount'
    const methodHashIdRef = card ? card.id : bankAccount.id
    await setAsDefault.mutateAsync({methodType, methodHashIdRef}).then(onSuccess, () => {})
  }

  const ModalContent = () => (
    <>
      { setAsDefault.isError && <Alert type="danger">
          Oops, looks like something went wrong! Please refresh your browser. Or contact us on{' '}
          <a href="tel:1300783674">1300 783 674</a>
        </Alert>}
      <h3> Default payment method </h3>
      <p>By making this your default payment method, we will use the below details when you are making payments within the product.</p>
      {card && <CardChip card={card}/>}
      {bankAccount && <BankAccountChip bankAccount={bankAccount}/>}
    </>
  )

  return (
    <Modal modalId="confirm-default-payment" title="Confirm default payment method" onCancel={onCancel}>
      <Modal.Body>
        { setAsDefault.isLoading ? <Spinner/> : <ModalContent/> }
      </Modal.Body>
      <Modal.Footer>
        <Button type="secondary" className="btn btn-default" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="confirm-save-default" onClick={onConfirm} disabled={setAsDefault.isLoading}> Confirm and save </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SetupDefaultPaymentMethodModal
