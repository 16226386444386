// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Effect = require("../Effect/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var parentCookie = function (name) {
    return $foreign["_parentCookie"](Data_Either.Left.create)(Data_Either.Right.create)(name);
};
var parentCsrf = Control_Bind.bindFlipped(Effect.bindEffect)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Effect_Exception["throw"])(Control_Applicative.pure(Effect.applicativeEffect)))(parentCookie("tsec-csrf"));
var cookie = function (name) {
    return $foreign["_cookie"](Data_Either.Left.create)(Data_Either.Right.create)(name);
};
var csrf = Control_Bind.bindFlipped(Effect.bindEffect)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Effect_Exception["throw"])(Control_Applicative.pure(Effect.applicativeEffect)))(cookie("tsec-csrf"));
module.exports = {
    csrf: csrf,
    parentCsrf: parentCsrf,
    cookie: cookie,
    parentCookie: parentCookie,
    removeCookie: $foreign.removeCookie
};
