import * as r from 'ramda'

const removeWhiteSpaces = input => r.replace(/\s+/g, '', input)

export const formatABN = input => {
  if (!input) {
    return ''
  }
  const [firstTwoNumbers, remainingNumbers] = r.splitAt(2)(
    removeWhiteSpaces(input)
  )
  return [
    firstTwoNumbers,
    r
      .splitEvery(3)(remainingNumbers)
      .join(' ')
  ]
    .join(' ')
    .trim()
}
