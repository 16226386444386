import React from 'react'
import { Label } from '@myob/myob-widgets'
import { genColor, genStatusDisplay } from 'purs/Model.Status'
import './BatchTable.sass'

const Status = ({ status }) => {
  return (
    <Label color={genColor(status)} type="boxed">
      {genStatusDisplay(status)}
    </Label>
  )
}

export default Status
