import './RewardSignUpModal.sass'
import React from 'react'
import { Modal, Button } from '@myob/myob-widgets'
import config from '../config'
import RewardCards from '../img/reward-cards.png'
import { Danger } from 'purs/AlertBanner'
import AlertBanner from './AlertBanner'

const SelfEnrollmentModal = ({ onCancel, onContinue, isLoading, showErrorAlert }) => {
  const displayErrorAlert = () => {
    return showErrorAlert ? (
      <AlertBanner type={Danger.value}>
        <span>
          Oops, looks like something went wrong! Please try again later or
          contact support on{' '}<a href="tel:1300783674">1300 783 674</a>{' '}
          for assistance.
        </span>
      </AlertBanner>
    ) : (<></>)
  }

  return (
    <Modal
      modalId="self-enrollment"
      title="Join MYOB Rewards"
      onCancel={onCancel}
    >
      <Modal.Body>
        <div className="rewardsignup__body">
          {displayErrorAlert()}
          <h3>Earn MYOB Rewards points</h3>
          <p>
            Earn points for simply paying your business expenses with an eligible
            credit card. You can redeem points for business perks like gift cards
            from popular retailers.
          </p>
          <ul>
            <li>
              Earn 4 points per dollar spent with a MasterCard&reg; credit
              card
            </li>
            <li>
              Earn 3 points per dollar spent with a Visa&reg; credit card
            </li>
          </ul>
          <div className="image_container">
            <img src={RewardCards} alt="Master Icon" />
          </div>
          <h3>Ready to get started?</h3>
          <p>
            Join today to start earning points straight away. As the payments
            administrator, you&apos;ll be able to sign into the MYOB Rewards portal to
            redeem points on behalf of the business.
          </p>
          <div>
              By clicking &apos;Join MYOB Rewards&apos; you
              acknowledge that you have read and agreed to the&nbsp;
              <a
                href={config.masterCardRewardsTermsAndConditionsUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              &nbsp;for MYOB Rewards.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button id="btn-self-enroll-modal-cancel" type="secondary" className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button id="btn-self-enroll-modal-join" type="primary" className="continue-btn" onClick={onContinue} disabled={isLoading}>
          Join MYOB Rewards
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SelfEnrollmentModal
