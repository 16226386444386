// Generated by purs version 0.13.8
"use strict";
var Component_AboutBusinessModal = require("../Component.AboutBusinessModal/index.js");
var Config = require("../Config/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Monads_Class = require("../Effect.Aff.Monads.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Model_Owner = require("../Model.Owner/index.js");
var Model_Rewards = require("../Model.Rewards/index.js");
var Segment = require("../Segment/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var KycFailedAlert = (function () {
    function KycFailedAlert() {

    };
    KycFailedAlert.value = new KycFailedAlert();
    return KycFailedAlert;
})();
var ApplicationDeclinedAlert = (function () {
    function ApplicationDeclinedAlert() {

    };
    ApplicationDeclinedAlert.value = new ApplicationDeclinedAlert();
    return ApplicationDeclinedAlert;
})();
var RegisterPermissionAlert = (function () {
    function RegisterPermissionAlert() {

    };
    RegisterPermissionAlert.value = new RegisterPermissionAlert();
    return RegisterPermissionAlert;
})();
var RetryableAlert = (function () {
    function RetryableAlert() {

    };
    RetryableAlert.value = new RetryableAlert();
    return RetryableAlert;
})();
var updateFinishedFiles = function (anyFinishedFile) {
    return function (state) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            userFileSection: {
                anyFinishedFile: anyFinishedFile,
                display: state.userFileSection.display
            },
            aboutBusinessModal: state.aboutBusinessModal,
            alert: state.alert,
            applicationComplete: state.applicationComplete,
            company: state.company,
            errorCode: state.errorCode,
            kycModal: state.kycModal,
            newCardModal: state.newCardModal,
            ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
            rewardSignUpModal: state.rewardSignUpModal,
            spinner: state.spinner,
            uboModal: state.uboModal,
            verificationPausedModal: state.verificationPausedModal
        });
    };
};
var stepUpDashboard = function (state) {
    var continueLoading = {
        spinner: {
            display: true
        },
        aboutBusinessModal: state.aboutBusinessModal,
        alert: state.alert,
        applicationComplete: state.applicationComplete,
        company: state.company,
        errorCode: state.errorCode,
        kycModal: state.kycModal,
        newCardModal: state.newCardModal,
        ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
        rewardSignUpModal: state.rewardSignUpModal,
        uboModal: state.uboModal,
        userFileSection: state.userFileSection,
        verificationPausedModal: state.verificationPausedModal
    };
    var stepUpFail = function (v) {
        if (v === 401) {
            return continueLoading;
        };
        return {
            newCardModal: state.newCardModal,
            rewardSignUpModal: state.rewardSignUpModal,
            kycModal: state.kycModal,
            uboModal: state.uboModal,
            ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
            verificationPausedModal: state.verificationPausedModal,
            aboutBusinessModal: state.aboutBusinessModal,
            applicationComplete: state.applicationComplete,
            company: state.company,
            errorCode: new Data_Maybe.Just(500),
            spinner: {
                display: false
            },
            alert: state.alert,
            userFileSection: state.userFileSection
        };
    };
    var stepUpSuccess = function (v) {
        return continueLoading;
    };
    return Data_Functor.map(Effect_Aff.functorAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(stepUpFail)(stepUpSuccess))(Helper_AuthActions.stepUp()("/dashboard")({}));
};
var shouldRedirectTo = function (v) {
    if (v.status instanceof Model_CompanyStatus.Registered) {
        return new Data_Maybe.Just("/");
    };
    if (v.status instanceof Model_CompanyStatus.Delete) {
        return new Data_Maybe.Just("/notfound");
    };
    if (v.status instanceof Model_CompanyStatus.Unregistered) {
        return new Data_Maybe.Just("/notfound");
    };
    if (v.status instanceof Model_CompanyStatus.CreditCheckFailed) {
        return new Data_Maybe.Just("/notfound");
    };
    return Data_Maybe.Nothing.value;
};
var selectOwner = function (ownerId) {
    return function (state) {
        var selectedOwner = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.owner;
        })(Data_Foldable.find(Data_Foldable.foldableArray)(function (x) {
            return Data_Show.show(Data_Show.showInt)(x.owner.id) === ownerId;
        })(state.uboModal.owners));
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            kycModal: {
                owner: selectedOwner,
                isAdmin: false,
                display: state.kycModal.display
            },
            aboutBusinessModal: state.aboutBusinessModal,
            alert: state.alert,
            applicationComplete: state.applicationComplete,
            company: state.company,
            errorCode: state.errorCode,
            newCardModal: state.newCardModal,
            ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
            rewardSignUpModal: state.rewardSignUpModal,
            spinner: state.spinner,
            uboModal: state.uboModal,
            userFileSection: state.userFileSection,
            verificationPausedModal: state.verificationPausedModal
        });
    };
};
var initState = {
    newCardModal: {
        display: false
    },
    rewardSignUpModal: {
        display: false,
        spinner: {
            display: false
        },
        savingErrorAlert: {
            display: false
        },
        continueButton: {
            disabled: false
        }
    },
    kycModal: {
        display: false,
        owner: Data_Maybe.Nothing.value,
        isAdmin: true
    },
    uboModal: {
        display: false,
        isSoleTrader: false,
        spinner: {
            display: true
        },
        owners: [  ],
        errorCode: Data_Maybe.Nothing.value
    },
    ownerInfoGenerateFailedModal: {
        display: false
    },
    verificationPausedModal: {
        display: false
    },
    aboutBusinessModal: Component_AboutBusinessModal.initAboutBusinessModal,
    applicationComplete: {
        display: false
    },
    company: Data_Maybe.Nothing.value,
    errorCode: Data_Maybe.Nothing.value,
    spinner: {
        display: true
    },
    alert: {
        display: false,
        alertType: KycFailedAlert.value
    },
    userFileSection: {
        display: false,
        anyFinishedFile: false
    }
};
var getOwnerStatuses = function (companyId) {
    return function (entityType) {
        return function (state) {
            var sendRequest = Fetch.recover({
                status: 500,
                body: ""
            })(Fetch.get()("/company/" + (companyId + "/owner"))({}));
            var readBody = function (body) {
                return Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"]([  ]))(Control_Category.identity(Control_Category.categoryFn))(Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "kycResult";
                }))(Simple_JSON.readMaybe(Model_Owner.userKYCKindReadForeign))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "owner";
                }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "company";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "firstName";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "kind";
                }))(Model_Owner.readForeignCompanyOwnerKind)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "lastName";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "middleName";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsNil)()())()())()())()())()())()()))(Simple_JSON.readFieldsNil)()())()())))(body));
            };
            var finishedLoading = {
                newCardModal: state.newCardModal,
                rewardSignUpModal: state.rewardSignUpModal,
                kycModal: state.kycModal,
                uboModal: {
                    display: state.uboModal.display,
                    spinner: {
                        display: false
                    },
                    isSoleTrader: state.uboModal.isSoleTrader,
                    owners: state.uboModal.owners,
                    errorCode: state.uboModal.errorCode
                },
                ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
                verificationPausedModal: state.verificationPausedModal,
                aboutBusinessModal: state.aboutBusinessModal,
                applicationComplete: state.applicationComplete,
                company: state.company,
                errorCode: state.errorCode,
                spinner: state.spinner,
                alert: state.alert,
                userFileSection: state.userFileSection
            };
            var addOwnerStatuses = function (v) {
                if (v.length === 0) {
                    return {
                        newCardModal: finishedLoading.newCardModal,
                        rewardSignUpModal: finishedLoading.rewardSignUpModal,
                        kycModal: finishedLoading.kycModal,
                        uboModal: {
                            display: finishedLoading.uboModal.display,
                            spinner: finishedLoading.uboModal.spinner,
                            isSoleTrader: finishedLoading.uboModal.isSoleTrader,
                            owners: finishedLoading.uboModal.owners,
                            errorCode: new Data_Maybe.Just(500)
                        },
                        ownerInfoGenerateFailedModal: finishedLoading.ownerInfoGenerateFailedModal,
                        verificationPausedModal: finishedLoading.verificationPausedModal,
                        aboutBusinessModal: finishedLoading.aboutBusinessModal,
                        applicationComplete: finishedLoading.applicationComplete,
                        company: finishedLoading.company,
                        errorCode: finishedLoading.errorCode,
                        spinner: finishedLoading.spinner,
                        alert: finishedLoading.alert,
                        userFileSection: finishedLoading.userFileSection
                    };
                };
                if (entityType instanceof Data_Maybe.Just && entityType.value0 === "Individual/Sole Trader") {
                    return {
                        newCardModal: finishedLoading.newCardModal,
                        rewardSignUpModal: finishedLoading.rewardSignUpModal,
                        kycModal: {
                            display: finishedLoading.kycModal.display,
                            owner: Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                                return v2.owner;
                            })(Data_Array.head(v)),
                            isAdmin: finishedLoading.kycModal.isAdmin
                        },
                        uboModal: {
                            display: finishedLoading.uboModal.display,
                            spinner: finishedLoading.uboModal.spinner,
                            isSoleTrader: finishedLoading.uboModal.isSoleTrader,
                            owners: Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (x) {
                                return [ x ];
                            })(Data_Array.head(v))),
                            errorCode: finishedLoading.uboModal.errorCode
                        },
                        ownerInfoGenerateFailedModal: finishedLoading.ownerInfoGenerateFailedModal,
                        verificationPausedModal: finishedLoading.verificationPausedModal,
                        aboutBusinessModal: finishedLoading.aboutBusinessModal,
                        applicationComplete: finishedLoading.applicationComplete,
                        company: finishedLoading.company,
                        errorCode: finishedLoading.errorCode,
                        spinner: finishedLoading.spinner,
                        alert: finishedLoading.alert,
                        userFileSection: finishedLoading.userFileSection
                    };
                };
                return {
                    newCardModal: finishedLoading.newCardModal,
                    rewardSignUpModal: finishedLoading.rewardSignUpModal,
                    kycModal: finishedLoading.kycModal,
                    uboModal: {
                        display: finishedLoading.uboModal.display,
                        spinner: finishedLoading.uboModal.spinner,
                        isSoleTrader: finishedLoading.uboModal.isSoleTrader,
                        owners: v,
                        errorCode: finishedLoading.uboModal.errorCode
                    },
                    ownerInfoGenerateFailedModal: finishedLoading.ownerInfoGenerateFailedModal,
                    verificationPausedModal: finishedLoading.verificationPausedModal,
                    aboutBusinessModal: finishedLoading.aboutBusinessModal,
                    applicationComplete: finishedLoading.applicationComplete,
                    company: finishedLoading.company,
                    errorCode: finishedLoading.errorCode,
                    spinner: finishedLoading.spinner,
                    alert: finishedLoading.alert,
                    userFileSection: finishedLoading.userFileSection
                };
            };
            var parseResult = function (v) {
                if (v.status === 200) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(addOwnerStatuses(readBody(v.body)));
                };
                if (v.status === 401) {
                    return stepUpDashboard(state);
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    uboModal: {
                        errorCode: new Data_Maybe.Just(500),
                        display: finishedLoading.uboModal.display,
                        isSoleTrader: finishedLoading.uboModal.isSoleTrader,
                        owners: finishedLoading.uboModal.owners,
                        spinner: finishedLoading.uboModal.spinner
                    },
                    aboutBusinessModal: finishedLoading.aboutBusinessModal,
                    alert: finishedLoading.alert,
                    applicationComplete: finishedLoading.applicationComplete,
                    company: finishedLoading.company,
                    errorCode: finishedLoading.errorCode,
                    kycModal: finishedLoading.kycModal,
                    newCardModal: finishedLoading.newCardModal,
                    ownerInfoGenerateFailedModal: finishedLoading.ownerInfoGenerateFailedModal,
                    rewardSignUpModal: finishedLoading.rewardSignUpModal,
                    spinner: finishedLoading.spinner,
                    userFileSection: finishedLoading.userFileSection,
                    verificationPausedModal: finishedLoading.verificationPausedModal
                });
            };
            return Control_Bind.bindFlipped(Effect_Aff.bindAff)(parseResult)(sendRequest);
        };
    };
};
var getCompanyEntityTypeCode = function (company) {
    var maptToEntityTypeCode = function (v) {
        if (v === "Australian Private Company") {
            return "PRV";
        };
        if (v === "Individual/Sole Trader") {
            return "IND";
        };
        return "Unknown";
    };
    return Data_Functor.map(Data_Maybe.functorMaybe)(maptToEntityTypeCode)(company.entityType);
};
var getBusinessIndustry = function (state) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.get()("/business-industry")({})))(function (resp) {
        if (resp instanceof Data_Either.Right && resp.value0.status === 200) {
            var v = Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "category";
            }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "description";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "id";
            }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "type";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())))(resp.value0.body);
            if (v instanceof Data_Either.Right) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    aboutBusinessModal: {
                        requiredInfo: {
                            industrySection: {
                                allBusinessIndustry: Data_Functor.map(Data_Functor.functorArray)(function (x) {
                                    return {
                                        industryType: x.type,
                                        category: x.category
                                    };
                                })(v.value0),
                                businessCategory: state.aboutBusinessModal.requiredInfo.industrySection.businessCategory,
                                businessType: state.aboutBusinessModal.requiredInfo.industrySection.businessType,
                                required: state.aboutBusinessModal.requiredInfo.industrySection.required
                            },
                            addressSection: state.aboutBusinessModal.requiredInfo.addressSection,
                            businessDetailSection: state.aboutBusinessModal.requiredInfo.businessDetailSection
                        },
                        currentStep: state.aboutBusinessModal.currentStep,
                        display: state.aboutBusinessModal.display,
                        entityType: state.aboutBusinessModal.entityType,
                        httpError: state.aboutBusinessModal.httpError,
                        showContinueBtn: state.aboutBusinessModal.showContinueBtn
                    },
                    alert: state.alert,
                    applicationComplete: state.applicationComplete,
                    company: state.company,
                    errorCode: state.errorCode,
                    kycModal: state.kycModal,
                    newCardModal: state.newCardModal,
                    ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
                    rewardSignUpModal: state.rewardSignUpModal,
                    spinner: state.spinner,
                    uboModal: state.uboModal,
                    userFileSection: state.userFileSection,
                    verificationPausedModal: state.verificationPausedModal
                });
            };
            if (v instanceof Data_Either.Left) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    aboutBusinessModal: {
                        showContinueBtn: false,
                        httpError: new Data_Maybe.Just(500),
                        currentStep: state.aboutBusinessModal.currentStep,
                        display: state.aboutBusinessModal.display,
                        entityType: state.aboutBusinessModal.entityType,
                        requiredInfo: state.aboutBusinessModal.requiredInfo
                    },
                    alert: state.alert,
                    applicationComplete: state.applicationComplete,
                    company: state.company,
                    errorCode: state.errorCode,
                    kycModal: state.kycModal,
                    newCardModal: state.newCardModal,
                    ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
                    rewardSignUpModal: state.rewardSignUpModal,
                    spinner: state.spinner,
                    uboModal: state.uboModal,
                    userFileSection: state.userFileSection,
                    verificationPausedModal: state.verificationPausedModal
                });
            };
            throw new Error("Failed pattern match at Dashboard (line 182, column 7 - line 189, column 102): " + [ v.constructor.name ]);
        };
        if (resp instanceof Data_Either.Right && resp.value0.status === 401) {
            return stepUpDashboard(state);
        };
        if (resp instanceof Data_Either.Right) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                aboutBusinessModal: {
                    showContinueBtn: false,
                    httpError: new Data_Maybe.Just(resp.value0.status),
                    currentStep: state.aboutBusinessModal.currentStep,
                    display: state.aboutBusinessModal.display,
                    entityType: state.aboutBusinessModal.entityType,
                    requiredInfo: state.aboutBusinessModal.requiredInfo
                },
                alert: state.alert,
                applicationComplete: state.applicationComplete,
                company: state.company,
                errorCode: state.errorCode,
                kycModal: state.kycModal,
                newCardModal: state.newCardModal,
                ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
                rewardSignUpModal: state.rewardSignUpModal,
                spinner: state.spinner,
                uboModal: state.uboModal,
                userFileSection: state.userFileSection,
                verificationPausedModal: state.verificationPausedModal
            });
        };
        if (resp instanceof Data_Either.Left) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                aboutBusinessModal: {
                    showContinueBtn: false,
                    httpError: new Data_Maybe.Just(500),
                    currentStep: state.aboutBusinessModal.currentStep,
                    display: state.aboutBusinessModal.display,
                    entityType: state.aboutBusinessModal.entityType,
                    requiredInfo: state.aboutBusinessModal.requiredInfo
                },
                alert: state.alert,
                applicationComplete: state.applicationComplete,
                company: state.company,
                errorCode: state.errorCode,
                kycModal: state.kycModal,
                newCardModal: state.newCardModal,
                ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
                rewardSignUpModal: state.rewardSignUpModal,
                spinner: state.spinner,
                uboModal: state.uboModal,
                userFileSection: state.userFileSection,
                verificationPausedModal: state.verificationPausedModal
            });
        };
        throw new Error("Failed pattern match at Dashboard (line 180, column 3 - line 192, column 98): " + [ resp.constructor.name ]);
    });
};
var encodeOwnerInfo = function (owner) {
    return SessionStorage.base64Encode(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "company";
    }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "firstName";
    }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.writeForeignInt)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "kind";
    }))(Model_Owner.writeForeignCompanyStatus)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "lastName";
    }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "middleName";
    }))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignString))(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()()))(owner));
};
var iframeUrlWithOwnerInfo = function (state) {
    var ownerQuery = Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)((function () {
        var $126 = Data_Semigroup.append(Data_Semigroup.semigroupString)("&owner=");
        return function ($127) {
            return $126(encodeOwnerInfo($127));
        };
    })())(state.kycModal.owner));
    var maybeEntityTypeQuery = Control_Bind.bind(Data_Maybe.bindMaybe)(state.company)(function (businessDetail) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(getCompanyEntityTypeCode(businessDetail))(function (entityCode) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)("&entityType=" + entityCode);
        });
    });
    var isAdminQuery = "?isadmin=" + Data_Show.show(Data_Show.showBoolean)(state.kycModal.isAdmin);
    var companyEntityTypeQuery = Data_Maybe.fromMaybe("")(maybeEntityTypeQuery);
    return "/onboardkycmodal" + (isAdminQuery + (ownerQuery + companyEntityTypeQuery));
};
var enableSubmitBtn = function (state) {
    if (state.company instanceof Data_Maybe.Just && (state.company.value0.status instanceof Model_CompanyStatus.PaymentDetailsAdded && (state.company.value0.onboardOnBehalf instanceof Data_Maybe.Just && state.company.value0.onboardOnBehalf.value0))) {
        return state.userFileSection.anyFinishedFile;
    };
    if (state.company instanceof Data_Maybe.Just && state.company.value0.status instanceof Model_CompanyStatus.PaymentDetailsAdded) {
        return true;
    };
    return false;
};
var enableSavingStatus = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        rewardSignUpModal: {
            spinner: {
                display: true
            },
            continueButton: {
                disabled: true
            },
            display: state.rewardSignUpModal.display,
            savingErrorAlert: state.rewardSignUpModal.savingErrorAlert
        },
        aboutBusinessModal: state.aboutBusinessModal,
        alert: state.alert,
        applicationComplete: state.applicationComplete,
        company: state.company,
        errorCode: state.errorCode,
        kycModal: state.kycModal,
        newCardModal: state.newCardModal,
        ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
        spinner: state.spinner,
        uboModal: state.uboModal,
        userFileSection: state.userFileSection,
        verificationPausedModal: state.verificationPausedModal
    });
};
var closeAllModals = function (state) {
    return {
        newCardModal: {
            display: false
        },
        rewardSignUpModal: {
            display: false,
            spinner: state.rewardSignUpModal.spinner,
            savingErrorAlert: state.rewardSignUpModal.savingErrorAlert,
            continueButton: state.rewardSignUpModal.continueButton
        },
        kycModal: {
            display: false,
            owner: state.kycModal.owner,
            isAdmin: state.kycModal.isAdmin
        },
        uboModal: {
            display: false,
            spinner: state.uboModal.spinner,
            isSoleTrader: state.uboModal.isSoleTrader,
            owners: state.uboModal.owners,
            errorCode: state.uboModal.errorCode
        },
        ownerInfoGenerateFailedModal: {
            display: false
        },
        verificationPausedModal: {
            display: false
        },
        aboutBusinessModal: {
            display: false,
            entityType: state.aboutBusinessModal.entityType,
            showContinueBtn: state.aboutBusinessModal.showContinueBtn,
            requiredInfo: state.aboutBusinessModal.requiredInfo,
            currentStep: state.aboutBusinessModal.currentStep,
            httpError: state.aboutBusinessModal.httpError
        },
        applicationComplete: state.applicationComplete,
        company: state.company,
        errorCode: state.errorCode,
        spinner: state.spinner,
        alert: state.alert,
        userFileSection: state.userFileSection
    };
};
var showAboutBusinessModal = function (state) {
    var newState = (function () {
        var v = closeAllModals(state);
        return {
            aboutBusinessModal: {
                display: true,
                currentStep: v.aboutBusinessModal.currentStep,
                entityType: v.aboutBusinessModal.entityType,
                httpError: v.aboutBusinessModal.httpError,
                requiredInfo: v.aboutBusinessModal.requiredInfo,
                showContinueBtn: v.aboutBusinessModal.showContinueBtn
            },
            alert: v.alert,
            applicationComplete: v.applicationComplete,
            company: v.company,
            errorCode: v.errorCode,
            kycModal: v.kycModal,
            newCardModal: v.newCardModal,
            ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
            rewardSignUpModal: v.rewardSignUpModal,
            spinner: v.spinner,
            uboModal: v.uboModal,
            userFileSection: v.userFileSection,
            verificationPausedModal: v.verificationPausedModal
        };
    })();
    if (state.aboutBusinessModal.entityType instanceof Component_AboutBusinessModal.CompanyInfo) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(newState);
    };
    return getBusinessIndustry(newState);
};
var showKycModal = (function () {
    var $128 = Control_Applicative.pure(Effect_Aff.applicativeAff);
    return function ($129) {
        return $128((function (v) {
            return {
                kycModal: {
                    display: true,
                    isAdmin: v.kycModal.isAdmin,
                    owner: v.kycModal.owner
                },
                aboutBusinessModal: v.aboutBusinessModal,
                alert: v.alert,
                applicationComplete: v.applicationComplete,
                company: v.company,
                errorCode: v.errorCode,
                newCardModal: v.newCardModal,
                ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
                rewardSignUpModal: v.rewardSignUpModal,
                spinner: v.spinner,
                uboModal: v.uboModal,
                userFileSection: v.userFileSection,
                verificationPausedModal: v.verificationPausedModal
            };
        })(closeAllModals($129)));
    };
})();
var showNewCardModal = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
        var v = closeAllModals(state);
        return {
            newCardModal: {
                display: true
            },
            aboutBusinessModal: v.aboutBusinessModal,
            alert: v.alert,
            applicationComplete: v.applicationComplete,
            company: v.company,
            errorCode: v.errorCode,
            kycModal: v.kycModal,
            ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
            rewardSignUpModal: v.rewardSignUpModal,
            spinner: v.spinner,
            uboModal: v.uboModal,
            userFileSection: v.userFileSection,
            verificationPausedModal: v.verificationPausedModal
        };
    })());
};
var joinRewards = function (companyId) {
    return function (accept) {
        return function (state) {
            var resetSavingStatus = {
                rewardSignUpModal: {
                    spinner: {
                        display: false
                    },
                    continueButton: {
                        disabled: false
                    },
                    display: state.rewardSignUpModal.display,
                    savingErrorAlert: state.rewardSignUpModal.savingErrorAlert
                },
                aboutBusinessModal: state.aboutBusinessModal,
                alert: state.alert,
                applicationComplete: state.applicationComplete,
                company: state.company,
                errorCode: state.errorCode,
                kycModal: state.kycModal,
                newCardModal: state.newCardModal,
                ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
                spinner: state.spinner,
                uboModal: state.uboModal,
                userFileSection: state.userFileSection,
                verificationPausedModal: state.verificationPausedModal
            };
            return Control_Bind.bind(Effect_Aff.bindAff)(Model_Rewards.saveJoinRewards(companyId)(accept))(function (resp) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Segment.tracking(Segment.OnboardJoinRewards.create(Data_Show.show(Data_Show.showBoolean)(accept)))))(function () {
                    if (resp instanceof Data_Either.Right && resp.value0.status === 200) {
                        return showNewCardModal(resetSavingStatus);
                    };
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        rewardSignUpModal: {
                            savingErrorAlert: {
                                display: true
                            },
                            continueButton: resetSavingStatus.rewardSignUpModal.continueButton,
                            display: resetSavingStatus.rewardSignUpModal.display,
                            spinner: resetSavingStatus.rewardSignUpModal.spinner
                        },
                        aboutBusinessModal: resetSavingStatus.aboutBusinessModal,
                        alert: resetSavingStatus.alert,
                        applicationComplete: resetSavingStatus.applicationComplete,
                        company: resetSavingStatus.company,
                        errorCode: resetSavingStatus.errorCode,
                        kycModal: resetSavingStatus.kycModal,
                        newCardModal: resetSavingStatus.newCardModal,
                        ownerInfoGenerateFailedModal: resetSavingStatus.ownerInfoGenerateFailedModal,
                        spinner: resetSavingStatus.spinner,
                        uboModal: resetSavingStatus.uboModal,
                        userFileSection: resetSavingStatus.userFileSection,
                        verificationPausedModal: resetSavingStatus.verificationPausedModal
                    });
                });
            });
        };
    };
};
var showOwnerKycModal = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
        var v = closeAllModals(state);
        return {
            kycModal: {
                display: true,
                isAdmin: false,
                owner: v.kycModal.owner
            },
            aboutBusinessModal: v.aboutBusinessModal,
            alert: v.alert,
            applicationComplete: v.applicationComplete,
            company: v.company,
            errorCode: v.errorCode,
            newCardModal: v.newCardModal,
            ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
            rewardSignUpModal: v.rewardSignUpModal,
            spinner: v.spinner,
            uboModal: v.uboModal,
            userFileSection: v.userFileSection,
            verificationPausedModal: v.verificationPausedModal
        };
    })());
};
var showPageSpinner = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
        var v = closeAllModals(state);
        return {
            spinner: {
                display: true
            },
            aboutBusinessModal: v.aboutBusinessModal,
            alert: v.alert,
            applicationComplete: v.applicationComplete,
            company: v.company,
            errorCode: v.errorCode,
            kycModal: v.kycModal,
            newCardModal: v.newCardModal,
            ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
            rewardSignUpModal: v.rewardSignUpModal,
            uboModal: v.uboModal,
            userFileSection: v.userFileSection,
            verificationPausedModal: v.verificationPausedModal
        };
    })());
};
var showRewardSignUpModal = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
        var v = closeAllModals(state);
        return {
            rewardSignUpModal: {
                display: true,
                continueButton: v.rewardSignUpModal.continueButton,
                savingErrorAlert: v.rewardSignUpModal.savingErrorAlert,
                spinner: v.rewardSignUpModal.spinner
            },
            aboutBusinessModal: v.aboutBusinessModal,
            alert: v.alert,
            applicationComplete: v.applicationComplete,
            company: v.company,
            errorCode: v.errorCode,
            kycModal: v.kycModal,
            newCardModal: v.newCardModal,
            ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
            spinner: v.spinner,
            uboModal: v.uboModal,
            userFileSection: v.userFileSection,
            verificationPausedModal: v.verificationPausedModal
        };
    })());
};
var showVerificationPausedModal = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
        var v = closeAllModals(state);
        return {
            verificationPausedModal: {
                display: true
            },
            aboutBusinessModal: v.aboutBusinessModal,
            alert: v.alert,
            applicationComplete: v.applicationComplete,
            company: v.company,
            errorCode: v.errorCode,
            kycModal: v.kycModal,
            newCardModal: v.newCardModal,
            ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
            rewardSignUpModal: v.rewardSignUpModal,
            spinner: v.spinner,
            uboModal: v.uboModal,
            userFileSection: v.userFileSection
        };
    })());
};
var checkOwnersAndShowModal = function (state) {
    var onboardOnBehalfOf = function (company) {
        return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Maybe.isNothing)(company.onboardOnBehalf);
    };
    var showKYCModal = function (maybeCompany) {
        if (maybeCompany instanceof Data_Maybe.Just) {
            if (Data_Eq.eq(Model_CompanyStatus.eqCompanyStatus)(maybeCompany.value0.status)(Model_CompanyStatus.CreditCheckPassed.value) && onboardOnBehalfOf(maybeCompany.value0)) {
                return showKycModal(state);
            };
            if (Data_Eq.eq(Model_CompanyStatus.eqCompanyStatus)(maybeCompany.value0.status)(Model_CompanyStatus.ApplicantVerified.value) && onboardOnBehalfOf(maybeCompany.value0)) {
                return Data_Functor.map(Effect_Aff.functorAff)(function ($130) {
                    return (function (v) {
                        return {
                            uboModal: {
                                display: true,
                                errorCode: v.uboModal.errorCode,
                                isSoleTrader: v.uboModal.isSoleTrader,
                                owners: v.uboModal.owners,
                                spinner: v.uboModal.spinner
                            },
                            aboutBusinessModal: v.aboutBusinessModal,
                            alert: v.alert,
                            applicationComplete: v.applicationComplete,
                            company: v.company,
                            errorCode: v.errorCode,
                            kycModal: v.kycModal,
                            newCardModal: v.newCardModal,
                            ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
                            rewardSignUpModal: v.rewardSignUpModal,
                            spinner: v.spinner,
                            userFileSection: v.userFileSection,
                            verificationPausedModal: v.verificationPausedModal
                        };
                    })(closeAllModals($130));
                })(getOwnerStatuses(maybeCompany.value0.id)(maybeCompany.value0.entityType)(state));
            };
            if (Data_Foldable.elem(Data_Foldable.foldableArray)(Model_CompanyStatus.eqCompanyStatus)(maybeCompany.value0.status)([ Model_CompanyStatus.ApplicantVerified.value, Model_CompanyStatus.OwnerInfoGenerated.value ])) {
                return Control_Bind.bind(Effect_Aff.bindAff)(getOwnerStatuses(maybeCompany.value0.id)(maybeCompany.value0.entityType)(state))(function (stateWithOwner) {
                    if (stateWithOwner.uboModal.errorCode instanceof Data_Maybe.Nothing && Model_Owner.noneKycStarted(stateWithOwner.uboModal.owners)) {
                        return showKycModal(stateWithOwner);
                    };
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                        var v = closeAllModals(stateWithOwner);
                        return {
                            uboModal: {
                                display: true,
                                errorCode: v.uboModal.errorCode,
                                isSoleTrader: v.uboModal.isSoleTrader,
                                owners: v.uboModal.owners,
                                spinner: v.uboModal.spinner
                            },
                            aboutBusinessModal: v.aboutBusinessModal,
                            alert: v.alert,
                            applicationComplete: v.applicationComplete,
                            company: v.company,
                            errorCode: v.errorCode,
                            kycModal: v.kycModal,
                            newCardModal: v.newCardModal,
                            ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
                            rewardSignUpModal: v.rewardSignUpModal,
                            spinner: v.spinner,
                            userFileSection: v.userFileSection,
                            verificationPausedModal: v.verificationPausedModal
                        };
                    })());
                });
            };
            if (Data_Eq.eq(Model_CompanyStatus.eqCompanyStatus)(maybeCompany.value0.status)(Model_CompanyStatus.CreditCheckPassed.value)) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                    var v = closeAllModals(state);
                    return {
                        uboModal: {
                            display: true,
                            errorCode: v.uboModal.errorCode,
                            isSoleTrader: v.uboModal.isSoleTrader,
                            owners: v.uboModal.owners,
                            spinner: v.uboModal.spinner
                        },
                        aboutBusinessModal: v.aboutBusinessModal,
                        alert: v.alert,
                        applicationComplete: v.applicationComplete,
                        company: v.company,
                        errorCode: v.errorCode,
                        kycModal: v.kycModal,
                        newCardModal: v.newCardModal,
                        ownerInfoGenerateFailedModal: v.ownerInfoGenerateFailedModal,
                        rewardSignUpModal: v.rewardSignUpModal,
                        spinner: v.spinner,
                        userFileSection: v.userFileSection,
                        verificationPausedModal: v.verificationPausedModal
                    };
                })());
            };
            if (Data_Eq.eq(Model_CompanyStatus.eqCompanyStatus)(maybeCompany.value0.status)(Model_CompanyStatus.OwnerInfoGenerateFailed.value)) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                    var v = closeAllModals(state);
                    return {
                        ownerInfoGenerateFailedModal: {
                            display: true
                        },
                        aboutBusinessModal: v.aboutBusinessModal,
                        alert: v.alert,
                        applicationComplete: v.applicationComplete,
                        company: v.company,
                        errorCode: v.errorCode,
                        kycModal: v.kycModal,
                        newCardModal: v.newCardModal,
                        rewardSignUpModal: v.rewardSignUpModal,
                        spinner: v.spinner,
                        uboModal: v.uboModal,
                        userFileSection: v.userFileSection,
                        verificationPausedModal: v.verificationPausedModal
                    };
                })());
            };
        };
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(state);
    };
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Segment.tracking(Segment.OnboardStartKYC.value)))(function () {
        return showKYCModal(state.company);
    });
};
var fetchCompany = function (state) {
    var updateUBOModal = function (stateWithCompany) {
        if (stateWithCompany.company instanceof Data_Maybe.Just && (stateWithCompany.company.value0.entityType instanceof Data_Maybe.Just && stateWithCompany.company.value0.entityType.value0 === "Individual/Sole Trader")) {
            return {
                newCardModal: stateWithCompany.newCardModal,
                rewardSignUpModal: stateWithCompany.rewardSignUpModal,
                kycModal: stateWithCompany.kycModal,
                uboModal: {
                    display: stateWithCompany.uboModal.display,
                    spinner: stateWithCompany.uboModal.spinner,
                    isSoleTrader: true,
                    owners: stateWithCompany.uboModal.owners,
                    errorCode: stateWithCompany.uboModal.errorCode
                },
                ownerInfoGenerateFailedModal: stateWithCompany.ownerInfoGenerateFailedModal,
                verificationPausedModal: stateWithCompany.verificationPausedModal,
                aboutBusinessModal: stateWithCompany.aboutBusinessModal,
                applicationComplete: stateWithCompany.applicationComplete,
                company: stateWithCompany.company,
                errorCode: stateWithCompany.errorCode,
                spinner: stateWithCompany.spinner,
                alert: stateWithCompany.alert,
                userFileSection: stateWithCompany.userFileSection
            };
        };
        return {
            newCardModal: stateWithCompany.newCardModal,
            rewardSignUpModal: stateWithCompany.rewardSignUpModal,
            kycModal: stateWithCompany.kycModal,
            uboModal: {
                display: stateWithCompany.uboModal.display,
                spinner: stateWithCompany.uboModal.spinner,
                isSoleTrader: false,
                owners: stateWithCompany.uboModal.owners,
                errorCode: stateWithCompany.uboModal.errorCode
            },
            ownerInfoGenerateFailedModal: stateWithCompany.ownerInfoGenerateFailedModal,
            verificationPausedModal: stateWithCompany.verificationPausedModal,
            aboutBusinessModal: stateWithCompany.aboutBusinessModal,
            applicationComplete: stateWithCompany.applicationComplete,
            company: stateWithCompany.company,
            errorCode: stateWithCompany.errorCode,
            spinner: stateWithCompany.spinner,
            alert: stateWithCompany.alert,
            userFileSection: stateWithCompany.userFileSection
        };
    };
    var updateAboutBusinessModal = function (stateWithCompany) {
        if (stateWithCompany.company instanceof Data_Maybe.Just && (stateWithCompany.company.value0.entityType instanceof Data_Maybe.Just && stateWithCompany.company.value0.entityType.value0 === "Individual/Sole Trader")) {
            return {
                newCardModal: stateWithCompany.newCardModal,
                rewardSignUpModal: stateWithCompany.rewardSignUpModal,
                kycModal: stateWithCompany.kycModal,
                uboModal: stateWithCompany.uboModal,
                ownerInfoGenerateFailedModal: stateWithCompany.ownerInfoGenerateFailedModal,
                verificationPausedModal: stateWithCompany.verificationPausedModal,
                aboutBusinessModal: {
                    display: stateWithCompany.aboutBusinessModal.display,
                    entityType: Component_AboutBusinessModal.SoleTraderInfo.value,
                    showContinueBtn: stateWithCompany.aboutBusinessModal.showContinueBtn,
                    requiredInfo: Component_AboutBusinessModal.initSoleTraderInfo,
                    currentStep: stateWithCompany.aboutBusinessModal.currentStep,
                    httpError: stateWithCompany.aboutBusinessModal.httpError
                },
                applicationComplete: stateWithCompany.applicationComplete,
                company: stateWithCompany.company,
                errorCode: stateWithCompany.errorCode,
                spinner: stateWithCompany.spinner,
                alert: stateWithCompany.alert,
                userFileSection: stateWithCompany.userFileSection
            };
        };
        if (stateWithCompany.company instanceof Data_Maybe.Just && stateWithCompany.company.value0.industry instanceof Data_Maybe.Just) {
            return {
                newCardModal: stateWithCompany.newCardModal,
                rewardSignUpModal: stateWithCompany.rewardSignUpModal,
                kycModal: stateWithCompany.kycModal,
                uboModal: stateWithCompany.uboModal,
                ownerInfoGenerateFailedModal: stateWithCompany.ownerInfoGenerateFailedModal,
                verificationPausedModal: stateWithCompany.verificationPausedModal,
                aboutBusinessModal: {
                    display: stateWithCompany.aboutBusinessModal.display,
                    entityType: Component_AboutBusinessModal.CompanyInfo.value,
                    showContinueBtn: stateWithCompany.aboutBusinessModal.showContinueBtn,
                    requiredInfo: Component_AboutBusinessModal.initCompanyInfo,
                    currentStep: stateWithCompany.aboutBusinessModal.currentStep,
                    httpError: stateWithCompany.aboutBusinessModal.httpError
                },
                applicationComplete: stateWithCompany.applicationComplete,
                company: stateWithCompany.company,
                errorCode: stateWithCompany.errorCode,
                spinner: stateWithCompany.spinner,
                alert: stateWithCompany.alert,
                userFileSection: stateWithCompany.userFileSection
            };
        };
        if (stateWithCompany.company instanceof Data_Maybe.Just) {
            return {
                newCardModal: stateWithCompany.newCardModal,
                rewardSignUpModal: stateWithCompany.rewardSignUpModal,
                kycModal: stateWithCompany.kycModal,
                uboModal: stateWithCompany.uboModal,
                ownerInfoGenerateFailedModal: stateWithCompany.ownerInfoGenerateFailedModal,
                verificationPausedModal: stateWithCompany.verificationPausedModal,
                aboutBusinessModal: {
                    display: stateWithCompany.aboutBusinessModal.display,
                    entityType: Component_AboutBusinessModal.CompanyNoIndustryInfo.value,
                    showContinueBtn: stateWithCompany.aboutBusinessModal.showContinueBtn,
                    requiredInfo: Component_AboutBusinessModal.initCompanyNoIndustryInfo,
                    currentStep: stateWithCompany.aboutBusinessModal.currentStep,
                    httpError: stateWithCompany.aboutBusinessModal.httpError
                },
                applicationComplete: stateWithCompany.applicationComplete,
                company: stateWithCompany.company,
                errorCode: stateWithCompany.errorCode,
                spinner: stateWithCompany.spinner,
                alert: stateWithCompany.alert,
                userFileSection: stateWithCompany.userFileSection
            };
        };
        return stateWithCompany;
    };
    var justFinishKYC = Control_Apply.applyFirst(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(Data_Maybe.isJust)(SessionStorage.getItem(Config.config.kycResultStorageName)))(SessionStorage.removeItem(Config.config.kycResultStorageName));
    var finishLoading = {
        newCardModal: state.newCardModal,
        rewardSignUpModal: state.rewardSignUpModal,
        kycModal: state.kycModal,
        uboModal: state.uboModal,
        ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
        verificationPausedModal: state.verificationPausedModal,
        aboutBusinessModal: state.aboutBusinessModal,
        applicationComplete: state.applicationComplete,
        company: state.company,
        errorCode: state.errorCode,
        spinner: {
            display: false
        },
        alert: state.alert,
        userFileSection: state.userFileSection
    };
    var fileUploaderEnabled = function (v) {
        return Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(new Data_Maybe.Just(true)))([ v.onboardOnBehalf, v.requireMoreDocument ]);
    };
    return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Model_Company.getCompanyWithErrorHandle)(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("companyId not exists"))))(function (companyInStorage) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.recover({
            status: 500,
            body: ""
        })(Fetch.get()("/company/" + companyInStorage.companyId)({})))(function (resp) {
            return Control_Bind.bind(Effect_Aff.bindAff)((function () {
                if (resp.status === 200) {
                    var v = Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "abn";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "acn";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "entityType";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "id";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "industry";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsNil)))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "location";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "onboardOnBehalf";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "requireMoreDocument";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "status";
                    }))(Model_CompanyStatus.readForeignCompanyStatus)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()()))(resp.body);
                    if (v instanceof Data_Either.Right) {
                        var finishedLoadingWithCompany = updateUBOModal(updateAboutBusinessModal({
                            newCardModal: finishLoading.newCardModal,
                            rewardSignUpModal: finishLoading.rewardSignUpModal,
                            kycModal: finishLoading.kycModal,
                            uboModal: finishLoading.uboModal,
                            ownerInfoGenerateFailedModal: finishLoading.ownerInfoGenerateFailedModal,
                            verificationPausedModal: finishLoading.verificationPausedModal,
                            aboutBusinessModal: finishLoading.aboutBusinessModal,
                            applicationComplete: finishLoading.applicationComplete,
                            company: new Data_Maybe.Just(v.value0),
                            errorCode: finishLoading.errorCode,
                            spinner: finishLoading.spinner,
                            alert: finishLoading.alert,
                            userFileSection: {
                                display: fileUploaderEnabled(v.value0),
                                anyFinishedFile: finishLoading.userFileSection.anyFinishedFile
                            }
                        }));
                        return Control_Apply.applySecond(Effect_Aff.applyAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.updateCompanyStatus(v.value0.status)))((function () {
                            if (v.value0.status instanceof Model_CompanyStatus.KYCFailed) {
                                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                                    alert: {
                                        display: true,
                                        alertType: KycFailedAlert.value
                                    },
                                    aboutBusinessModal: finishedLoadingWithCompany.aboutBusinessModal,
                                    applicationComplete: finishedLoadingWithCompany.applicationComplete,
                                    company: finishedLoadingWithCompany.company,
                                    errorCode: finishedLoadingWithCompany.errorCode,
                                    kycModal: finishedLoadingWithCompany.kycModal,
                                    newCardModal: finishedLoadingWithCompany.newCardModal,
                                    ownerInfoGenerateFailedModal: finishedLoadingWithCompany.ownerInfoGenerateFailedModal,
                                    rewardSignUpModal: finishedLoadingWithCompany.rewardSignUpModal,
                                    spinner: finishedLoadingWithCompany.spinner,
                                    uboModal: finishedLoadingWithCompany.uboModal,
                                    userFileSection: finishedLoadingWithCompany.userFileSection,
                                    verificationPausedModal: finishedLoadingWithCompany.verificationPausedModal
                                });
                            };
                            if (v.value0.status instanceof Model_CompanyStatus.ApplicantVerified) {
                                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(justFinishKYC))(function (openModal) {
                                    if (openModal) {
                                        return checkOwnersAndShowModal(finishedLoadingWithCompany);
                                    };
                                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(finishedLoadingWithCompany);
                                });
                            };
                            if (v.value0.status instanceof Model_CompanyStatus.PendingAssessment) {
                                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                                    applicationComplete: {
                                        display: true
                                    },
                                    aboutBusinessModal: finishedLoadingWithCompany.aboutBusinessModal,
                                    alert: finishedLoadingWithCompany.alert,
                                    company: finishedLoadingWithCompany.company,
                                    errorCode: finishedLoadingWithCompany.errorCode,
                                    kycModal: finishedLoadingWithCompany.kycModal,
                                    newCardModal: finishedLoadingWithCompany.newCardModal,
                                    ownerInfoGenerateFailedModal: finishedLoadingWithCompany.ownerInfoGenerateFailedModal,
                                    rewardSignUpModal: finishedLoadingWithCompany.rewardSignUpModal,
                                    spinner: finishedLoadingWithCompany.spinner,
                                    uboModal: finishedLoadingWithCompany.uboModal,
                                    userFileSection: finishedLoadingWithCompany.userFileSection,
                                    verificationPausedModal: finishedLoadingWithCompany.verificationPausedModal
                                });
                            };
                            if (v.value0.status instanceof Model_CompanyStatus.Declined) {
                                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                                    alert: {
                                        display: true,
                                        alertType: ApplicationDeclinedAlert.value
                                    },
                                    aboutBusinessModal: finishedLoadingWithCompany.aboutBusinessModal,
                                    applicationComplete: finishedLoadingWithCompany.applicationComplete,
                                    company: finishedLoadingWithCompany.company,
                                    errorCode: finishedLoadingWithCompany.errorCode,
                                    kycModal: finishedLoadingWithCompany.kycModal,
                                    newCardModal: finishedLoadingWithCompany.newCardModal,
                                    ownerInfoGenerateFailedModal: finishedLoadingWithCompany.ownerInfoGenerateFailedModal,
                                    rewardSignUpModal: finishedLoadingWithCompany.rewardSignUpModal,
                                    spinner: finishedLoadingWithCompany.spinner,
                                    uboModal: finishedLoadingWithCompany.uboModal,
                                    userFileSection: finishedLoadingWithCompany.userFileSection,
                                    verificationPausedModal: finishedLoadingWithCompany.verificationPausedModal
                                });
                            };
                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(finishedLoadingWithCompany);
                        })());
                    };
                    if (v instanceof Data_Either.Left) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                            errorCode: new Data_Maybe.Just(500),
                            aboutBusinessModal: finishLoading.aboutBusinessModal,
                            alert: finishLoading.alert,
                            applicationComplete: finishLoading.applicationComplete,
                            company: finishLoading.company,
                            kycModal: finishLoading.kycModal,
                            newCardModal: finishLoading.newCardModal,
                            ownerInfoGenerateFailedModal: finishLoading.ownerInfoGenerateFailedModal,
                            rewardSignUpModal: finishLoading.rewardSignUpModal,
                            spinner: finishLoading.spinner,
                            uboModal: finishLoading.uboModal,
                            userFileSection: finishLoading.userFileSection,
                            verificationPausedModal: finishLoading.verificationPausedModal
                        });
                    };
                    throw new Error("Failed pattern match at Dashboard (line 314, column 7 - line 334, column 60): " + [ v.constructor.name ]);
                };
                if (resp.status === 401) {
                    return stepUpDashboard(state);
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    errorCode: new Data_Maybe.Just(resp.status),
                    aboutBusinessModal: finishLoading.aboutBusinessModal,
                    alert: finishLoading.alert,
                    applicationComplete: finishLoading.applicationComplete,
                    company: finishLoading.company,
                    kycModal: finishLoading.kycModal,
                    newCardModal: finishLoading.newCardModal,
                    ownerInfoGenerateFailedModal: finishLoading.ownerInfoGenerateFailedModal,
                    rewardSignUpModal: finishLoading.rewardSignUpModal,
                    spinner: finishLoading.spinner,
                    uboModal: finishLoading.uboModal,
                    userFileSection: finishLoading.userFileSection,
                    verificationPausedModal: finishLoading.verificationPausedModal
                });
            })())(function (result) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Segment.paging(Segment.DashboardPage.value)))(function () {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(result);
                });
            });
        });
    });
};
var refreshPage = function (v) {
    return fetchCompany(initState);
};
var submitOnboardApplication = function (state) {
    var finishQuerying = {
        newCardModal: state.newCardModal,
        rewardSignUpModal: state.rewardSignUpModal,
        kycModal: state.kycModal,
        uboModal: state.uboModal,
        ownerInfoGenerateFailedModal: state.ownerInfoGenerateFailedModal,
        verificationPausedModal: state.verificationPausedModal,
        aboutBusinessModal: state.aboutBusinessModal,
        applicationComplete: state.applicationComplete,
        company: state.company,
        errorCode: state.errorCode,
        spinner: {
            display: false
        },
        alert: state.alert,
        userFileSection: state.userFileSection
    };
    var generalErrorState = {
        newCardModal: finishQuerying.newCardModal,
        rewardSignUpModal: finishQuerying.rewardSignUpModal,
        kycModal: finishQuerying.kycModal,
        uboModal: finishQuerying.uboModal,
        ownerInfoGenerateFailedModal: finishQuerying.ownerInfoGenerateFailedModal,
        verificationPausedModal: finishQuerying.verificationPausedModal,
        aboutBusinessModal: finishQuerying.aboutBusinessModal,
        applicationComplete: finishQuerying.applicationComplete,
        company: finishQuerying.company,
        errorCode: finishQuerying.errorCode,
        spinner: finishQuerying.spinner,
        alert: {
            display: true,
            alertType: RetryableAlert.value
        },
        userFileSection: finishQuerying.userFileSection
    };
    return Fetch.recover(generalErrorState)(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Model_Company.getCompanyWithErrorHandle)(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("companyId not exists"))))(function (companyInStorage) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.post()("/company/" + (companyInStorage.companyId + "/register"))("")({}))(function (resp) {
            return Control_Bind.bind(Effect_Aff.bindAff)((function () {
                if (resp.status === 204) {
                    return refreshPage(state);
                };
                if (resp.status === 401) {
                    return stepUpDashboard(state);
                };
                if (resp.status === 403) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        alert: {
                            display: true,
                            alertType: RegisterPermissionAlert.value
                        },
                        aboutBusinessModal: finishQuerying.aboutBusinessModal,
                        applicationComplete: finishQuerying.applicationComplete,
                        company: finishQuerying.company,
                        errorCode: finishQuerying.errorCode,
                        kycModal: finishQuerying.kycModal,
                        newCardModal: finishQuerying.newCardModal,
                        ownerInfoGenerateFailedModal: finishQuerying.ownerInfoGenerateFailedModal,
                        rewardSignUpModal: finishQuerying.rewardSignUpModal,
                        spinner: finishQuerying.spinner,
                        uboModal: finishQuerying.uboModal,
                        userFileSection: finishQuerying.userFileSection,
                        verificationPausedModal: finishQuerying.verificationPausedModal
                    });
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(generalErrorState);
            })())(function (result) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(result);
            });
        });
    }));
};
var refreshCompanyAndShowUBOModal = Control_Bind.composeKleisliFlipped(Effect_Aff.bindAff)(checkOwnersAndShowModal)(fetchCompany);
var allSteps = function (status) {
    var step5done = Data_Foldable.elem(Data_Foldable.foldableArray)(Model_CompanyStatus.eqCompanyStatus)(status)([ Model_CompanyStatus.PaymentDetailsAdded.value, Model_CompanyStatus.PendingAssessment.value, Model_CompanyStatus.Registered.value, Model_CompanyStatus.Declined.value ]);
    var step4done = Data_Foldable.elem(Data_Foldable.foldableArray)(Model_CompanyStatus.eqCompanyStatus)(status)([ Model_CompanyStatus.BusinessDetailsAdded.value, Model_CompanyStatus.PaymentDetailsAdded.value, Model_CompanyStatus.PendingAssessment.value, Model_CompanyStatus.Registered.value, Model_CompanyStatus.Declined.value ]);
    var step3paused = Data_Eq.eq(Model_CompanyStatus.eqCompanyStatus)(status)(Model_CompanyStatus.FailedApplicantVerification.value);
    var step3done = Data_Foldable.elem(Data_Foldable.foldableArray)(Model_CompanyStatus.eqCompanyStatus)(status)([ Model_CompanyStatus.OwnershipVerified.value, Model_CompanyStatus.BusinessDetailsAdded.value, Model_CompanyStatus.PaymentDetailsAdded.value, Model_CompanyStatus.PendingAssessment.value, Model_CompanyStatus.Registered.value, Model_CompanyStatus.Declined.value ]);
    return [ {
        name: "Check eligibility",
        description: "Verify that your business can enable payments.",
        effort: "1 step \xb7 1 min",
        paused: false,
        done: true,
        clickStartNow: Control_Applicative.pure(Effect_Aff.applicativeAff),
        clickPaused: Control_Applicative.pure(Effect_Aff.applicativeAff),
        disabled: true,
        imageName: ""
    }, {
        name: "Set up two-factor authentication",
        description: "Link your account to the MYOB Secure app. This helps increase your account\u2019s security.",
        effort: "8 steps \xb7 8 mins",
        paused: false,
        done: true,
        clickStartNow: Control_Applicative.pure(Effect_Aff.applicativeAff),
        clickPaused: Control_Applicative.pure(Effect_Aff.applicativeAff),
        disabled: true,
        imageName: ""
    }, {
        name: "Identify owners and directors",
        description: "Submit government-issued identification for owners and directors of the business.",
        effort: "3 steps \xb7 5 mins",
        paused: step3paused,
        done: step3done,
        clickStartNow: checkOwnersAndShowModal,
        clickPaused: showVerificationPausedModal,
        disabled: step3done,
        imageName: "Lock"
    }, {
        name: "Tell us about your payments",
        description: "Provide details about your payments so we can keep a lookout for irregular transactions.",
        effort: "2 steps \xb7 2 mins",
        paused: false,
        done: step4done,
        clickStartNow: showAboutBusinessModal,
        clickPaused: Control_Applicative.pure(Effect_Aff.applicativeAff),
        disabled: !step3done || step4done,
        imageName: "Business"
    }, {
        name: "Enter card details",
        description: "Enter your card details to pay for bills and payroll, and enrol in MYOB rewards.",
        effort: "3 steps \xb7 2 mins",
        paused: false,
        done: step5done,
        clickStartNow: showRewardSignUpModal,
        clickPaused: Control_Applicative.pure(Effect_Aff.applicativeAff),
        disabled: !step4done || step5done,
        imageName: "AddCard"
    } ];
};
module.exports = {
    KycFailedAlert: KycFailedAlert,
    ApplicationDeclinedAlert: ApplicationDeclinedAlert,
    RegisterPermissionAlert: RegisterPermissionAlert,
    RetryableAlert: RetryableAlert,
    initState: initState,
    shouldRedirectTo: shouldRedirectTo,
    stepUpDashboard: stepUpDashboard,
    getOwnerStatuses: getOwnerStatuses,
    getBusinessIndustry: getBusinessIndustry,
    iframeUrlWithOwnerInfo: iframeUrlWithOwnerInfo,
    showNewCardModal: showNewCardModal,
    showRewardSignUpModal: showRewardSignUpModal,
    checkOwnersAndShowModal: checkOwnersAndShowModal,
    refreshCompanyAndShowUBOModal: refreshCompanyAndShowUBOModal,
    showKycModal: showKycModal,
    showOwnerKycModal: showOwnerKycModal,
    showVerificationPausedModal: showVerificationPausedModal,
    selectOwner: selectOwner,
    showAboutBusinessModal: showAboutBusinessModal,
    joinRewards: joinRewards,
    enableSavingStatus: enableSavingStatus,
    encodeOwnerInfo: encodeOwnerInfo,
    getCompanyEntityTypeCode: getCompanyEntityTypeCode,
    showPageSpinner: showPageSpinner,
    refreshPage: refreshPage,
    closeAllModals: closeAllModals,
    fetchCompany: fetchCompany,
    updateFinishedFiles: updateFinishedFiles,
    enableSubmitBtn: enableSubmitBtn,
    submitOnboardApplication: submitOnboardApplication,
    allSteps: allSteps
};
