// Generated by purs version 0.13.8
"use strict";
var Config = require("../Config/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_CompanyAccess = require("../Model.CompanyAccess/index.js");
var Model_CompanyAccount = require("../Model.CompanyAccount/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Model_CompanySubscription = require("../Model.CompanySubscription/index.js");
var Model_User = require("../Model.User/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var toCompany = function (aCompany) {
    return {
        id: aCompany.cdfId,
        companyId: aCompany.cdfId,
        name: aCompany.name,
        account: (function () {
            if (aCompany.active) {
                return Model_CompanyAccount.Active.value;
            };
            return Model_CompanyAccount.Inactive.value;
        })(),
        access: aCompany.userRole,
        country: aCompany.country,
        status: aCompany.status,
        abn: Data_Maybe.fromMaybe("")(aCompany.abn),
        userCurrentRole: aCompany.userCurrentRole,
        userNominatedRole: aCompany.userNominatedRole,
        onboardOnBehalf: aCompany.onboardOnBehalf,
        requireMoreDocument: aCompany.requireMoreDocument,
        serialNumber: aCompany.serialNumber,
        product: aCompany.product
    };
};
var saveRewardsBody = SessionStorage.saveItem(Simple_JSON.writeForeignArray(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "availablePoints";
}))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignInt))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "companyId";
}))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "enrolledAt";
}))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignString))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "isAccepted";
}))(Simple_JSON.writeForeignBoolean)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "userId";
}))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(Config.config.rewardsStorageName);
var saveCompanyQuery = SessionStorage.saveItem(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "companyId";
}))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()())()()()))(Config.config.companyStorageName);
var saveCompany = SessionStorage.saveItem(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "abn";
}))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "access";
}))(Model_CompanyAccess.writeForeignCompanyAccess)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "account";
}))(Model_CompanyAccount.companyAccountWriteForeign)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "companyId";
}))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "country";
}))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "onboardOnBehalf";
}))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignBoolean))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "product";
}))(Simple_JSON.writeForeignMaybe(Model_CompanySubscription.productTypeWriteForeign))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "requireMoreDocument";
}))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignBoolean))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "serialNumber";
}))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignString))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "status";
}))(Model_CompanyStatus.writeForeignCompanyStatus)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "userCurrentRole";
}))(Model_User.roleWriteForeign)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
    return "userNominatedRole";
}))(Simple_JSON.writeForeignMaybe(Model_User.roleWriteForeign))(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(Config.config.companyStorageName);
var updateCompanyProduct = function (company) {
    var hasProduct = function (c) {
        return Data_Maybe.isJust(c.product);
    };
    var getAndSaveProduct = function (c) {
        return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(Model_CompanySubscription.fetchCompanySubscription(c.companyId)(c.serialNumber))(function (companySubscription) {
            if (companySubscription instanceof Data_Maybe.Just) {
                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(saveCompany({
                    id: c.id,
                    companyId: c.companyId,
                    name: c.name,
                    account: c.account,
                    access: c.access,
                    country: c.country,
                    status: c.status,
                    abn: c.abn,
                    userCurrentRole: c.userCurrentRole,
                    userNominatedRole: c.userNominatedRole,
                    onboardOnBehalf: c.onboardOnBehalf,
                    requireMoreDocument: c.requireMoreDocument,
                    serialNumber: c.serialNumber,
                    product: new Data_Maybe.Just(companySubscription.value0.product)
                }));
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
        }));
    };
    var v = hasProduct(company);
    if (v) {
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
    return getAndSaveProduct(company);
};
var readRewardsDetails = SessionStorage.readItem(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "availablePoints";
}))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "companyId";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "enrolledAt";
}))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "isAccepted";
}))(Simple_JSON.readBoolean)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "userId";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(Config.config.rewardsStorageName);
var readCompanyQuery = SessionStorage.readItem(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "companyId";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()()))(Config.config.companyStorageName);
var readCompany = SessionStorage.readItem(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "abn";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "access";
}))(Model_CompanyAccess.readForeignCompanyStatus)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "account";
}))(Model_CompanyAccount.companyAccountReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "companyId";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "country";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "name";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "onboardOnBehalf";
}))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "product";
}))(Simple_JSON.readMaybe(Model_CompanySubscription.productTypeReadForeign))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "requireMoreDocument";
}))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "serialNumber";
}))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "status";
}))(Model_CompanyStatus.readForeignCompanyStatus)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "userCurrentRole";
}))(Model_User.roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "userNominatedRole";
}))(Simple_JSON.readMaybe(Model_User.roleReadForeign))(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()()))(Config.config.companyStorageName);
var readCompanyWithDefault = Data_Functor.map(Effect.functorEffect)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"]({
    name: "",
    companyId: "",
    product: Data_Maybe.Nothing.value
}))(function (c) {
    return {
        name: c.name,
        companyId: c.companyId,
        product: c.product
    };
}))(readCompany);
var updateCompanyStatus = function (status) {
    return Control_Bind.bind(Effect.bindEffect)(readCompany)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))(function ($10) {
        return saveCompany((function (v) {
            return {
                id: v.id,
                companyId: v.companyId,
                name: v.name,
                account: v.account,
                access: v.access,
                country: v.country,
                status: status,
                abn: v.abn,
                userCurrentRole: v.userCurrentRole,
                userNominatedRole: v.userNominatedRole,
                onboardOnBehalf: v.onboardOnBehalf,
                requireMoreDocument: v.requireMoreDocument,
                serialNumber: v.serialNumber,
                product: v.product
            };
        })($10));
    }));
};
var getRewardsPoints = (function () {
    var getAvailablePoints = function (detailLists) {
        return function (userId) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Either.hush(detailLists))(function (details) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Foldable.find(Data_Foldable.foldableArray)(function (d) {
                    return d.userId === userId;
                })(details))(function (pointDetail) {
                    return pointDetail.availablePoints;
                });
            });
        };
    };
    return function __do() {
        var rewardsDetails = readRewardsDetails();
        var maybeUserId = SessionStorage.getUserId();
        return Control_Bind.bind(Data_Maybe.bindMaybe)(maybeUserId)(getAvailablePoints(rewardsDetails));
    };
})();
var getCompanyWithErrorHandle = (function () {
    var redirectToCompanies = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Helper_AuthActions.redirectTo("/companies"));
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(readCompany))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](Data_Functor.voidLeft(Effect_Aff.functorAff)(redirectToCompanies)(Data_Maybe.Nothing.value)))((function () {
        var $11 = Control_Applicative.pure(Effect_Aff.applicativeAff);
        return function ($12) {
            return $11(Data_Maybe.Just.create($12));
        };
    })()));
})();
var deconstructRewardsDetails = (function () {
    var deconstruct = function (v) {
        if (v instanceof Data_Maybe.Just) {
            return Data_Array.head(v.value0);
        };
        return Data_Maybe.Nothing.value;
    };
    return function __do() {
        var detail = readRewardsDetails();
        return deconstruct(Data_Either.hush(detail));
    };
})();
var cleanRewardsDetails = SessionStorage.removeItem(Config.config.rewardsStorageName);
var cleanCompany = SessionStorage.removeItem(Config.config.companyStorageName);
module.exports = {
    toCompany: toCompany,
    readCompany: readCompany,
    readCompanyQuery: readCompanyQuery,
    readCompanyWithDefault: readCompanyWithDefault,
    readRewardsDetails: readRewardsDetails,
    deconstructRewardsDetails: deconstructRewardsDetails,
    getRewardsPoints: getRewardsPoints,
    saveCompany: saveCompany,
    saveCompanyQuery: saveCompanyQuery,
    updateCompanyStatus: updateCompanyStatus,
    saveRewardsBody: saveRewardsBody,
    cleanCompany: cleanCompany,
    cleanRewardsDetails: cleanRewardsDetails,
    getCompanyWithErrorHandle: getCompanyWithErrorHandle,
    updateCompanyProduct: updateCompanyProduct
};
