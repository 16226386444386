import React, { useContext } from 'react'
import './Preference.sass'
import {
  StandardTemplate,
  Input,
  Select,
  Tooltip,
  DetailHeader,
  Separator,
  Spinner,
  FormHorizontal,
  Button,
  ButtonRow,
  Card,
  PageHead
} from '@myob/myob-widgets'
import withState from '../withPsState'
import Error from '../components/Error'
import {
  initState,
  onPageLoad,
  updateNumAuthorisers,
  updatePreference,
  PreferenceUpdated,
  InsufficientAuthorisers,
  PendingBatches
} from 'purs/Page.Preference'
import { showCompanyStatus } from 'purs/Model.CompanyStatus'
import { isJust, isNothing } from 'purs/Data.Maybe'
import AlertBanner from '../components/AlertBanner'
import { Success, Warning } from 'purs/AlertBanner'
import { show } from 'purs/Data.Show'
import { Link } from 'react-router-dom'
import { maybe } from 'purs/Data.Maybe'

const CompanyPreferences = ({ company }) => {
  return (
    <div className="content-block">
      <DetailHeader
        className="detail-header"
        primary={<h1>Business details</h1>}
      />
      <FormHorizontal layout="primary">
        <Input id="abn" name="abn" label="ABN" disabled value={company.abn} />
        {maybe(<></>)(acn => (
          <Input value={acn} label="ACN" name="acn" disabled />
        ))(company.acn)}
        <Input
          id="name"
          name="name"
          label="Business name"
          disabled
          value={company.name}
        />
        <Input
          id="business-type"
          name="business-type"
          label="Business type"
          disabled
          value={company.entityType.value0}
        />
        <Input
          id="location"
          name="location"
          label="Location"
          disabled
          value={company.location.value0}
        />
        <Input
          id="status"
          name="status"
          label="Status"
          disabled
          value={show(showCompanyStatus)(company.status)}
        />
      </FormHorizontal>
    </div>
  )
}

const AuthoriserPreferences = ({ authoriserRequired }) => {
  const { dispatch } = useContext(context)
  return (
    <div className="content-block">
      <DetailHeader
        className="detail-header"
        primary={<h1>Batch authorisation</h1>}
      />
      <FormHorizontal layout="primary">
        <Select
          id="authoriser-required"
          name="authoriser-required"
          label="Authorisers required"
          defaultValue={authoriserRequired}
          onChange={x => dispatch(updateNumAuthorisers(x.target.value))}
          labelAccessory={
            <Tooltip>
              Select the number of required authorisers to process a batch
              payment. A maximum of 2 authorisers can be set.
            </Tooltip>
          }
          width="lg"
        >
          <Select.Option value="1" label="Single authoriser" />
          <Select.Option value="2" label="Dual authorisation" />
        </Select>
      </FormHorizontal>
    </div>
  )
}

const PreferencePageHeader = () => {
  const { dispatch, state } = useContext(context)
  const companyPreference = state.companyPreference.value0
  const hidden = companyPreference.authoriserRequired === state.numAuthorisers
  return (
    <PageHead title={state.companyName.value0}>
      {!hidden && (
        <ButtonRow>
          <Button id="btn-preference-cancel" type="secondary" onClick={_ => window.location.reload()}>
            Cancel
          </Button>
          <Button id="btn-perference-save" type="primary" onClick={_ => dispatch(updatePreference)}>
            Save
          </Button>
        </ButtonRow>
      )}
    </PageHead>
  )
}

const UpdateResultAlert = ({ alertType }) => {
  switch (alertType.constructor) {
    case PreferenceUpdated:
      return (
        <AlertBanner type={Success.value}>
          Hooray! Your details were successfully updated.
        </AlertBanner>
      )
    case InsufficientAuthorisers:
      return (
        <AlertBanner type={Warning.value}>
          Uh-oh! We are unable to change authorisation methods due to only one
          authoriser being linked to your account. Please add another and try
          again.&nbsp;&nbsp;
          <Button id="btn-preference-manage-users-link" type="link">
            <Link to="/manage/users">Manage Users</Link>
          </Button>
        </AlertBanner>
      )
    case PendingBatches:
      return (
        <AlertBanner type={Warning.value}>
          Uh-oh! We are unable to change authorisation methods while batches are
          awaiting authorisation. Make sure there are no batches waiting to be
          authorised and try again.
        </AlertBanner>
      )
  }
}

const Preference = () => {
  const { state } = useContext(context)
  if (state.spinner.display) {
    return <Spinner />
  }
  if (isJust(state.errorCode) || isNothing(state.companyPreference)) {
    return (
      <Card>
        <Error httpError={state.errorCode.value0} />
      </Card>
    )
  }

  const companyPreference = state.companyPreference.value0

  return (
    <div className="preference">
      {isJust(state.alert) && (
        <UpdateResultAlert alertType={state.alert.value0.alertType} />
      )}
      <StandardTemplate pageHead={<PreferencePageHeader />}>
        <CompanyPreferences company={companyPreference.businessDetails} />
        <Separator />
        <AuthoriserPreferences
          authoriserRequired={companyPreference.authoriserRequired}
        />
      </StandardTemplate>
    </div>
  )
}

export const { context, Component } = withState(
  Preference,
  initState,
  () => onPageLoad
)

export default Component
