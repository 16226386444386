function cookie(left) {
  return function(right) {
    return function(name) {
      return function() {
        const value = cookieFrom(document, name)
        return value
          ? right(value)
          : left(`${name} not found in parent cookie.`)
      }
    }
  }
}

function parentCookie(left) {
  return function(right) {
    return function(name) {
      return function() {
        const value = cookieFrom(window.parent.document, name)
        return value
          ? right(value)
          : left(`${name} not found in parent cookie.`)
      }
    }
  }
}

function cookieFrom(doc, name) {
  const matcher = new RegExp(
    '(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$'
  )
  return doc.cookie.replace(matcher, '$1')
}

function removeCookie(name) {
  return function() {
    const cleanWithPath = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path='
    document.cookie = cleanWithPath + window.location.pathname
    document.cookie =
      cleanWithPath + window.location.pathname.replace(/\/$/, '')
  }
}

exports._parentCookie = parentCookie
exports.removeCookie = removeCookie
exports._cookie = cookie
