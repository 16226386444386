import './RewardSignUpModal.sass'
import React, { useContext, useState } from 'react'
import { Modal, Button, Checkbox } from '@myob/myob-widgets'
import { isRight } from 'purs/Data.Either'
import { readJoinRewards } from 'purs/Model.Rewards'
import config from '../config'
import RewardCards from '../img/reward-cards.png'
import { Spinner } from '@myob/myob-widgets'
import { context } from '../pages/Dashboard'
import AlertBanner from './AlertBanner'
import { Danger } from 'purs/AlertBanner'

const RewardSignUpModal = ({ onCancel, onContinue }) => {
  const initJoinState = isRight(readJoinRewards())
    ? readJoinRewards().value0
    : true
  const [rewardsAgreed, setRewardsAgreed] = useState(initJoinState)
  const { state } = useContext(context)

  const showSavingErrorAlert = () => {
    return state.rewardSignUpModal.savingErrorAlert.display ? (
      <AlertBanner type={Danger.value}>
        <span>
          Oops, looks like something went wrong! Please try again later.
        </span>
      </AlertBanner>
    ) : (
      <></>
    )
  }
  return (
    <Modal
      modalId="reward-sign-up"
      title="Join MYOB Rewards"
      onCancel={() => onCancel()}
    >
      <Modal.Body>
        {state.rewardSignUpModal.spinner.display ? (
          <Spinner />
        ) : (
          <div className="rewardsignup__body">
            {showSavingErrorAlert()}
            <h3>Earn MYOB Rewards points</h3>
            <p>
              Earn points for simply paying your business expenses with an eligible
              credit card. You can redeem points for business perks like gift cards
              from popular retailers.
            </p>
            <ul>
              <li>
                Earn 4 points per dollar spent with a MasterCard&reg; credit
                card
              </li>
              <li>
                Earn 3 points per dollar spent with a Visa&reg; credit card
              </li>
            </ul>
            <div className="image_container">
              <img src={RewardCards} alt="Master Icon" />
            </div>
            <h3>Ready to get started?</h3>
            <p>
              Join today to start earning points straight away. As the payments 
              administrator, you&apos;ll be able to sign into the MYOB Rewards portal to 
              redeem points on behalf of the business.
            </p>
            <Checkbox
              className="join-rewards-checkbox"
              id="join-rewards-checkbox"
              onChange={() => setRewardsAgreed(!rewardsAgreed)}
              checked={rewardsAgreed}
              label={<p>Sign me up for MYOB Rewards. I have read and agreed to the Terms 
                and Conditions for MYOB Rewards.&nbsp;<a
                  href={config.masterCardRewardsTermsAndConditionsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Terms and Conditions
                </a></p>}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="btn-reward-signup-modal-continue"
          disabled={state.rewardSignUpModal.continueButton.disabled}
          onClick={() => onContinue(rewardsAgreed)}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RewardSignUpModal
