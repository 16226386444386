import { Modal } from '@myob/myob-widgets'
import React, { useContext } from 'react'
import { context } from '../pages/Dashboard'
import { refreshPage, showPageSpinner } from 'purs/Dashboard'
import { config } from '../psConfig'

const VerificationPausedModal = () => {
  const { dispatch } = useContext(context)

  return (
    <Modal
      title="Identity check"
      onCancel={() => {
        dispatch(showPageSpinner)
        dispatch(refreshPage)
      }}
      modalId="verification-paused-modal"
    >
      <Modal.Body>
        <p>
          Unfortunately we couldn&#39;t identify you as an Ultimate Beneficial
          Owner or Director.
        </p>
        <p>
          At this time, only directors and owners can enable direct payments in
          AccountRight Live for their business. Accountants, bookkeepers and
          employees will be able to sign up soon.
        </p>
        <p>
          <a
            href={config.subscribeUpdatesURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Subscribe for updates
          </a>
        </p>
        <p>
          If you think this is an error, please contact our specialised payments
          support team on <a href="tel:1300783674">1300 783 674</a>.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          id="btn-verification-paused-modal-close"
          onClick={() => {
            dispatch(showPageSpinner)
            dispatch(refreshPage)
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default VerificationPausedModal
