// Generated by purs version 0.13.8
"use strict";
var Config = require("../Config/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Model_Company = require("../Model.Company/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var saveJoinRewards = function (company) {
    return function (accepted) {
        var requestURL = "/company/" + (company + "/enroll-reward-offer");
        var requestBody = (function () {
            if (accepted) {
                return {
                    action: "accept"
                };
            };
            return {
                action: "reject"
            };
        })();
        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(SessionStorage.saveItem(Simple_JSON.writeForeignBoolean)(Config.config.joinRewardsStorageName)(accepted)))(function () {
            return Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.post()(requestURL)(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "action";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()()))(requestBody))({}));
        });
    };
};
var readJoinRewards = SessionStorage.readItem(Simple_JSON.readBoolean)(Config.config.joinRewardsStorageName);
var fetchAndSaveRewardsDetail = function (companyId) {
    var saveResp = function (details) {
        if (details instanceof Data_Maybe.Just) {
            return Model_Company.saveRewardsBody(details.value0);
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
    var readRewardsBody = function (body) {
        return Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "availablePoints";
        }))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "companyId";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "enrolledAt";
        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "isAccepted";
        }))(Simple_JSON.readBoolean)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "userId";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(body);
    };
    var extractData = function (resp) {
        if (resp.status === 200) {
            return Data_Either.hush(readRewardsBody(resp.body));
        };
        return Data_Maybe.Nothing.value;
    };
    return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.get()("/company/" + (companyId + "/rewards"))({}))(function (resp) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(saveResp(extractData(resp))))(function () {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(extractData(resp));
        });
    });
};
var cleanJoinRewards = SessionStorage.removeItem(Config.config.joinRewardsStorageName);
module.exports = {
    readJoinRewards: readJoinRewards,
    saveJoinRewards: saveJoinRewards,
    fetchAndSaveRewardsDetail: fetchAndSaveRewardsDetail,
    cleanJoinRewards: cleanJoinRewards
};
