// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Fetch = require("../Fetch/index.js");
var Foreign = require("../Foreign/index.js");
var Helper_Attempt = require("../Helper.Attempt/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyAccess = require("../Model.CompanyAccess/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Model_CompanySubscription = require("../Model.CompanySubscription/index.js");
var Model_User = require("../Model.User/index.js");
var Segment = require("../Segment/index.js");
var SignUpHelper = require("../SignUpHelper/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var stepUpDashboard = function (state) {
    var continueLoading = {
        isLoading: true,
        companyList: state.companyList,
        onboardModal: state.onboardModal,
        selectedCompany: state.selectedCompany,
        stepOnboardModal: state.stepOnboardModal
    };
    var stepUpFail = function (v) {
        return continueLoading;
    };
    var stepUpSuccess = function (v) {
        return continueLoading;
    };
    return Data_Functor.map(Effect_Aff.functorAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(stepUpFail)(stepUpSuccess))(Helper_AuthActions.stepUp()("/dashboard")({}));
};
var sendTelemetry = function (v) {
    return function __do() {
        Control_Monad_Error_Class["try"](Control_Monad_Error_Class.monadErrorEffect)(Segment.identifying(v.companyId)(v.status))();
        Control_Monad_Error_Class["try"](Control_Monad_Error_Class.monadErrorEffect)(Segment.grouping(v.companyId))();
        return Data_Unit.unit;
    };
};
var selectedCompany = function (companyId) {
    return Data_Foldable.find(Data_Foldable.foldableArray)((function () {
        var $31 = Data_Eq.eq(Data_Eq.eqString)(companyId);
        return function ($32) {
            return $31((function (v) {
                return v.id;
            })($32));
        };
    })());
};
var loadCompanies = function (companyId) {
    return function (s) {
        var updateState = function (v) {
            if (v instanceof Data_Either.Right) {
                return {
                    companyList: new Data_Either.Right(v.value0),
                    isLoading: false,
                    selectedCompany: Control_Bind.join(Data_Maybe.bindMaybe)(Control_Apply.lift2(Data_Maybe.applyMaybe)(selectedCompany)(companyId)(new Data_Maybe.Just(v.value0))),
                    onboardModal: SignUpHelper.initOnBoardModal(Control_Bind.join(Data_Maybe.bindMaybe)(Control_Apply.lift2(Data_Maybe.applyMaybe)(selectedCompany)(companyId)(new Data_Maybe.Just(v.value0)))),
                    stepOnboardModal: s.stepOnboardModal
                };
            };
            if (v instanceof Data_Either.Left) {
                return {
                    companyList: new Data_Either.Left(v.value0),
                    isLoading: v.value0 === 401,
                    selectedCompany: s.selectedCompany,
                    onboardModal: s.onboardModal,
                    stepOnboardModal: s.stepOnboardModal
                };
            };
            throw new Error("Failed pattern match at Company (line 56, column 7 - line 56, column 66): " + [ v.constructor.name ]);
        };
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.cleanCompany))(function () {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.cleanRewardsDetails))(function () {
                return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.get()("/company?au=true&online=true")({})))(function (resp) {
                    return Control_Bind.bind(Effect_Aff.bindAff)((function () {
                        if (resp instanceof Data_Either.Right && resp.value0.status === 200) {
                            var v = Simple_JSON.readJSON(Simple_JSON.readArray(Helper_Attempt.readEither(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "abn";
                            }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "active";
                            }))(Simple_JSON.readBoolean)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "cdfId";
                            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "country";
                            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "inTheCloud";
                            }))(Simple_JSON.readBoolean)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "name";
                            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "onboardOnBehalf";
                            }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "product";
                            }))(Simple_JSON.readMaybe(Model_CompanySubscription.productTypeReadForeign))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "requireMoreDocument";
                            }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "serialNumber";
                            }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "status";
                            }))(Model_CompanyStatus.readForeignCompanyStatus)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "userCurrentRole";
                            }))(Model_User.roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "userNominatedRole";
                            }))(Simple_JSON.readMaybe(Model_User.roleReadForeign))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                return "userRole";
                            }))(Model_CompanyAccess.readForeignCompanyStatus)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()()))))(resp.value0.body);
                            if (v instanceof Data_Either.Left) {
                                return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left(500));
                            };
                            if (v instanceof Data_Either.Right) {
                                var company = Helper_Attempt.partition(v.value0);
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Effect_Class_Console.errorShow(Effect_Class.monadEffectEffect)(Data_Show.showArray(Data_List_Types.showNonEmptyList(Foreign.showForeignError)))(company.left))(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Functor.map(Data_Functor.functorArray)(Model_Company.toCompany)(company.right))));
                            };
                            throw new Error("Failed pattern match at Company (line 46, column 34 - line 50, column 76): " + [ v.constructor.name ]);
                        };
                        if (resp instanceof Data_Either.Right && resp.value0.status === 401) {
                            return Data_Functor.voidLeft(Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Helper_AuthActions.login(Data_Maybe.Nothing.value)))(new Data_Either.Left(401));
                        };
                        if (resp instanceof Data_Either.Right) {
                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left(resp.value0.status));
                        };
                        if (resp instanceof Data_Either.Left) {
                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left(500));
                        };
                        throw new Error("Failed pattern match at Company (line 45, column 18 - line 53, column 30): " + [ resp.constructor.name ]);
                    })())(function (companyList) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(updateState(companyList));
                    });
                });
            });
        });
    };
};
var initState = {
    companyList: new Data_Either.Right([  ]),
    isLoading: true,
    selectedCompany: Data_Maybe.Nothing.value,
    onboardModal: SignUpHelper.initOnBoardModal(Data_Maybe.Nothing.value),
    stepOnboardModal: {
        display: false
    }
};
var companyStepUp = function (redirect) {
    return function (s) {
        var error = function (errorCode) {
            return {
                companyList: new Data_Either.Left(errorCode),
                isLoading: s.isLoading,
                selectedCompany: s.selectedCompany,
                onboardModal: s.onboardModal,
                stepOnboardModal: s.stepOnboardModal
            };
        };
        return Control_Bind.bind(Effect_Aff.bindAff)(Helper_AuthActions.stepUp()(redirect)({}))((function () {
            var $33 = Control_Applicative.pure(Effect_Aff.applicativeAff);
            var $34 = Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(error)(Data_Function["const"](s));
            return function ($35) {
                return $33($34($35));
            };
        })());
    };
};
var closeAllModals = function (state) {
    return {
        companyList: state.companyList,
        isLoading: state.isLoading,
        selectedCompany: state.selectedCompany,
        onboardModal: {
            display: false,
            spinner: state.onboardModal.spinner,
            userRole: state.onboardModal.userRole,
            content: state.onboardModal.content,
            verificationCheckbox: state.onboardModal.verificationCheckbox
        },
        stepOnboardModal: {
            display: false
        }
    };
};
var closeModal = (function () {
    var $36 = Data_Functor.voidLeft(Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Helper_AuthActions.redirectTo("/companies")));
    return function ($37) {
        return $36(closeAllModals($37));
    };
})();
var clickCompanyNameAction = function (c) {
    return Control_Apply.applySecond(Effect.applyEffect)(Control_Apply.applySecond(Effect.applyEffect)(Model_Company.saveCompany(c))(sendTelemetry(c)))(Model_Company.updateCompanyProduct(c));
};
module.exports = {
    selectedCompany: selectedCompany,
    initState: initState,
    companyStepUp: companyStepUp,
    loadCompanies: loadCompanies,
    closeAllModals: closeAllModals,
    closeModal: closeModal,
    stepUpDashboard: stepUpDashboard,
    sendTelemetry: sendTelemetry,
    clickCompanyNameAction: clickCompanyNameAction
};
