import React, { useEffect, useState } from 'react'
import { DropZone, FileChip , Modal, Button, DownloadIcon} from '@myob/myob-widgets'
import { fromMaybe, Just, Nothing} from 'purs/Data.Maybe'
import * as R from 'ramda'
import { updateFiles, uploadFile, enqueueFiles, deleteFile, downloadFile, listFiles, statuses } from 'purs/Component.UserFileUploader'
import * as Crypto from "crypto-js"
import { performEff } from "../withPsState"
import {maybe} from 'purs/Data.Maybe'

const pickFileMeta = R.pickAll(['name', 'size','type'])

function calcMd5(buffer) {
   const wordArray = Crypto.lib.WordArray.create(buffer)
   const hash = Crypto.MD5(wordArray)
   return Crypto.enc.Base64.stringify(hash)
}

const readUploadedFile = (inputFile) => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new DOMException("Problem parsing input file."))
    }

    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsArrayBuffer(inputFile);
  })
}

function appendMeta(state) {
  return readUploadedFile(state.file).then(b => {
    const withMeta = {...pickFileMeta(state.file), md5: Just.create(calcMd5(b)), buffer: Just.create(b)}
    return {...state, file: withMeta}
  }).catch(err => {
    console.error(`error in reading ${err}`)
    return state
  })
}
const UserFileUploader = ({existingFiles=[], onUpdate = R.identity}) => {
  const [files, setFiles] = useState(existingFiles)
  const [deletionConfirmModal, setDeletionConfirmModal] = useState({display: false, fileToDelete: Nothing.value})

  useEffect(()=>{
    performEff(listFiles, R.pipe(R.tap(onUpdate), setFiles))
  }, [])
  const onSelectFile = input => R.pipe(
      enqueueFiles(files),
      R.tap(setFiles),
      R.filter(R.propEq('state', statuses.queued)),
      R.map(appendMeta),
      queue => Promise.all(queue).then(
        R.map(f => performEff(
          uploadFile(f),
            files => setFiles(
              R.pipe(updateFiles([files]), R.tap(onUpdate))))))
      )(input)

  const toggleDeletionModal = (display) => (fileToDelete = Nothing.value) => setDeletionConfirmModal({display, fileToDelete})

  return <>
    <DropZone onDrop={onSelectFile} onFileSelected={onSelectFile}>
        {files.map(file => <FileChipItem {...file} onRemove={ () => {
           toggleDeletionModal(true)(Just.create(file))
        }
        } onDownload={() => {
          performEff(downloadFile(file)(loc => () => window.open(loc, '_blank')), setFiles)
        }}/>)}
    </DropZone>
    {deletionConfirmModal.display &&
    <Modal title="Delete this file?" onCancel={toggleDeletionModal(false)}>
      <Modal.Body>
        This can't be undone, or recovered later.
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="btn-file-upload-back"
          type="secondary"
          onClick={toggleDeletionModal(false)}
        >
          Go back
        </Button>
        <Button
          id="btn-file-upload-delete"
          type="delete"
          onClick={ () => {
            console.debug(deletionConfirmModal)
            maybe(toggleDeletionModal(false)())(fileToDelete=>{
            performEff(deleteFile(fileToDelete), file => setFiles(R.pipe(file, R.tap(onUpdate))))
            })(deletionConfirmModal.fileToDelete)
            toggleDeletionModal(false)()
          }
          }
        >
          Delete file
        </Button>
      </Modal.Footer>
    </Modal>
    }
  </>
}

const FileChipItem = props => {
  const error = fromMaybe("")(props.error)
  return <div data-testid={`${props.file.name}-${props.state}`}>
    <FileChip key={props.index} {...pickFileMeta(props.file)} error={error} state={props.state} onRemove={props.onRemove}>
    {maybe(<></>)(() => <Button type="secondary" className="btn-xs download-button" onClick={props.onDownload}><DownloadIcon /></Button>)(props.location)}
    </FileChip>
  </div>
}


export default UserFileUploader
