import React, { useContext } from 'react'
import { FieldGroup, Input, Card, Select, Icons } from '@myob/myob-widgets'
import { context } from '../pages/Manage.User.Details'
import { Administrator, roleReadForeign, roleShow } from 'purs/Model.User'
import {
  updateState,
  isPermissionActive,
  allPermissions
} from 'purs/Page.Manage.User.Details'
import { read } from 'purs/Simple.JSON'
import { show } from 'purs/Data.Show'

// TODO
// Remove me once API has been added
function getNameOrEmptyString(maybeName) {
  return maybeName && maybeName.value0 ? maybeName.value0 : ''
}

function ManageUserDetailsForm() {
  const { state, dispatch } = useContext(context)

  return (
    <Card>
      <FieldGroup label="User details">
        <Input
          id="manage-user-name"
          name="name"
          label="Name"
          disabled
          value={getNameOrEmptyString(state.user.name)}
        />
        <Input
          id="manage-user-email"
          name="email"
          label="Email"
          disabled
          value={state.user.email}
        />
      </FieldGroup>
      <FieldGroup label="Level of access">
        <Select
          id="manage-user-role"
          name="role"
          label="Role"
          labelAccessory="*"
          width="m"
          value={show(roleShow)(state.selectedRole)}
          disabled={state.user.role instanceof Administrator}
          onChange={({ target }) => {
            const role = read(roleReadForeign)(target.value).value0
            dispatch(updateState(role))
          }}
        >
          <Select.Option value="Administrator" label="Administrator" hidden />
          <Select.Option value="Authoriser" label="Authoriser" />
          <Select.Option value="Submitter" label="Submitter" />
          <Select.Option value="None" label="None" />
        </Select>
        <div className="form-group">
          <div className="form-group__label-group">Permissions</div>
          <div className="permission form-group__input-group">
            {allPermissions.map(permission => {
              return (
                <div
                  key={permission.desc}
                  className={
                    isPermissionActive(state.selectedRole)(permission)
                      ? 'permission__active'
                      : 'permission__inactive'
                  }
                >
                  <Icons.Success />
                  <div className="permission__description">
                    {permission.desc}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </FieldGroup>
    </Card>
  )
}

export default ManageUserDetailsForm
