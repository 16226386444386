import { Table } from '@myob/myob-widgets'
import React from 'react'
import { renderTableBody, renderTableHeader, Text, Show } from './Batch.Table'
import { dateFormatter, timeFormatter } from '../helper/formatter'
import './BatchDetailTable.sass'
import { show } from 'purs/Data.Show'
import { roleShow, roleActivityTypeShow } from 'purs/Model.User'

const format = [
  {
    key: 'created',
    description: 'Date',
    width: 'flex-2',
    align: 'left',
    component: userlog => <span>{dateFormatter(userlog['created'])}</span>
  },
  {
    key: 'created',
    description: 'Time',
    width: 'flex-2',
    align: 'left',
    component: userlog => <span>{timeFormatter(userlog['created'])}</span>
  },
  {
    key: 'userEmail',
    description: 'User',
    width: 'flex-3',
    align: 'left',
    component: Text('userEmail')
  },
  {
    key: 'kind',
    description: 'Type',
    width: 'flex-2',
    align: 'left',
    component: Show(show(roleActivityTypeShow))('kind')
  },
  {
    key: 'from',
    description: 'From',
    width: 'flex-2',
    align: 'left',
    component: Show(show(roleShow))('from')
  },
  {
    key: 'to',
    description: 'To',
    width: 'flex-2',
    align: 'left',
    component: Show(show(roleShow))('to')
  },
  {
    key: 'creatorEmail',
    description: 'Completed by',
    width: 'flex-3',
    align: 'left',
    component: Text('creatorEmail')
  }
]

function UserLogsTable({ logs }) {
  return (
    <Table className="user-activities-table">
      {renderTableHeader(format)}
      {renderTableBody(logs.map(log => ({ ...log, id: log.created })), format)}
    </Table>
  )
}

export default UserLogsTable
