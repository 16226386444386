// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Fetch = require("../Fetch/index.js");
var Foreign = require("../Foreign/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_User = require("../Model.User/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var UserTab = (function () {
    function UserTab(value0) {
        this.value0 = value0;
    };
    UserTab.create = function (value0) {
        return new UserTab(value0);
    };
    return UserTab;
})();
var ActivityTab = (function () {
    function ActivityTab(value0) {
        this.value0 = value0;
    };
    ActivityTab.create = function (value0) {
        return new ActivityTab(value0);
    };
    return ActivityTab;
})();
var RequestError = (function () {
    function RequestError(value0) {
        this.value0 = value0;
    };
    RequestError.create = function (value0) {
        return new RequestError(value0);
    };
    return RequestError;
})();
var InternalError = (function () {
    function InternalError(value0) {
        this.value0 = value0;
    };
    InternalError.create = function (value0) {
        return new InternalError(value0);
    };
    return InternalError;
})();
var NoCompanyFoundError = (function () {
    function NoCompanyFoundError() {

    };
    NoCompanyFoundError.value = new NoCompanyFoundError();
    return NoCompanyFoundError;
})();
var load = function (tab) {
    return function (state) {
        var urlTail = (function () {
            if (tab instanceof UserTab) {
                return "/user";
            };
            if (tab instanceof ActivityTab) {
                return "/user-activity";
            };
            throw new Error("Failed pattern match at Page.Manage.Users (line 61, column 15 - line 63, column 40): " + [ tab.constructor.name ]);
        })();
        var finishedLoading = {
            spinner: {
                display: false
            },
            tabContent: tab,
            errorType: Data_Maybe.Nothing.value
        };
        var parseResult = function (resp) {
            if (tab instanceof UserTab) {
                var v = Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "email";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "kycStatus";
                }))(Model_User.kycStatusReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "nominatedRole";
                }))(Model_User.roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "role";
                }))(Model_User.roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "status";
                }))(Model_User.statusReadForeign)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())))(resp.body);
                if (v instanceof Data_Either.Right) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        tabContent: new UserTab(v.value0),
                        errorType: finishedLoading.errorType,
                        spinner: finishedLoading.spinner
                    });
                };
                if (v instanceof Data_Either.Left) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        errorType: new Data_Maybe.Just(InternalError.create(Data_Show.show(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v.value0))),
                        spinner: finishedLoading.spinner,
                        tabContent: finishedLoading.tabContent
                    });
                };
                throw new Error("Failed pattern match at Page.Manage.Users (line 54, column 20 - line 56, column 86): " + [ v.constructor.name ]);
            };
            if (tab instanceof ActivityTab) {
                var v = Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "created";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "createdBy";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "creatorEmail";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "from";
                }))(Model_User.roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "kind";
                }))(Model_User.roleActivityTypeReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "to";
                }))(Model_User.roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "userEmail";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "userId";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())))(resp.body);
                if (v instanceof Data_Either.Right) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        tabContent: new ActivityTab(v.value0),
                        errorType: finishedLoading.errorType,
                        spinner: finishedLoading.spinner
                    });
                };
                if (v instanceof Data_Either.Left) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        errorType: new Data_Maybe.Just(InternalError.create(Data_Show.show(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v.value0))),
                        spinner: finishedLoading.spinner,
                        tabContent: finishedLoading.tabContent
                    });
                };
                throw new Error("Failed pattern match at Page.Manage.Users (line 57, column 24 - line 59, column 86): " + [ v.constructor.name ]);
            };
            throw new Error("Failed pattern match at Page.Manage.Users (line 53, column 24 - line 59, column 86): " + [ tab.constructor.name ]);
        };
        var stepUpFail = function (code) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                errorType: new Data_Maybe.Just(new RequestError({
                    code: code
                })),
                spinner: finishedLoading.spinner,
                tabContent: finishedLoading.tabContent
            });
        };
        var stepUpSuccess = function (v) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(finishedLoading);
        };
        var requestUserFor = function (companyId) {
            return Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Control_Bind.bind(Effect_Aff.bindAff)(Fetch.get()("/company/" + (companyId + urlTail))({}))(function (resp) {
                if (resp.status === 200) {
                    return parseResult(resp);
                };
                if (resp.status === 401) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(Helper_AuthActions.stepUp()("/manage/users")({}))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(stepUpFail)(stepUpSuccess));
                };
                if (resp.status < 500) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        errorType: new Data_Maybe.Just(new RequestError({
                            code: resp.status
                        })),
                        spinner: finishedLoading.spinner,
                        tabContent: finishedLoading.tabContent
                    });
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    errorType: new Data_Maybe.Just(new InternalError(resp.body)),
                    spinner: finishedLoading.spinner,
                    tabContent: finishedLoading.tabContent
                });
            }));
        };
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompany))(function (hasCompany) {
            if (hasCompany instanceof Data_Either.Right) {
                return Data_Functor.map(Effect_Aff.functorAff)(Data_Either.either(function (a) {
                    return {
                        errorType: new Data_Maybe.Just(new InternalError(Data_Show.show(Effect_Exception.showError)(a))),
                        spinner: finishedLoading.spinner,
                        tabContent: finishedLoading.tabContent
                    };
                })(Control_Category.identity(Control_Category.categoryFn)))(requestUserFor(hasCompany.value0.companyId));
            };
            if (hasCompany instanceof Data_Either.Left) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    errorType: new Data_Maybe.Just(NoCompanyFoundError.value),
                    spinner: finishedLoading.spinner,
                    tabContent: finishedLoading.tabContent
                });
            };
            throw new Error("Failed pattern match at Page.Manage.Users (line 40, column 3 - line 42, column 76): " + [ hasCompany.constructor.name ]);
        });
    };
};
var initState = {
    spinner: {
        display: true
    },
    tabContent: new UserTab([  ]),
    errorType: Data_Maybe.Nothing.value
};
var tabClick = function (v) {
    return function (v1) {
        if (v instanceof UserTab) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(initState);
        };
        if (v instanceof ActivityTab) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                tabContent: new ActivityTab([  ]),
                errorType: initState.errorType,
                spinner: initState.spinner
            });
        };
        throw new Error("Failed pattern match at Page.Manage.Users (line 33, column 1 - line 33, column 43): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
module.exports = {
    UserTab: UserTab,
    ActivityTab: ActivityTab,
    RequestError: RequestError,
    InternalError: InternalError,
    NoCompanyFoundError: NoCompanyFoundError,
    initState: initState,
    tabClick: tabClick,
    load: load
};
