exports.redirectTo = url => () => {
  window.location.href = url
}

// this is actually covered but when ps compile it into foreign somehow
// jest lost it
/* istanbul ignore next */
exports.replaceHistory = history => url => () => {
  if (history) history.replace(url)
}
