// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Config = require("../Config/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var DashboardPage = (function () {
    function DashboardPage() {

    };
    DashboardPage.value = new DashboardPage();
    return DashboardPage;
})();
var BatchListPage = (function () {
    function BatchListPage() {

    };
    BatchListPage.value = new BatchListPage();
    return BatchListPage;
})();
var GetStartedPage = (function () {
    function GetStartedPage() {

    };
    GetStartedPage.value = new GetStartedPage();
    return GetStartedPage;
})();
var OnboardStart = (function () {
    function OnboardStart() {

    };
    OnboardStart.value = new OnboardStart();
    return OnboardStart;
})();
var ViewIntro = (function () {
    function ViewIntro() {

    };
    ViewIntro.value = new ViewIntro();
    return ViewIntro;
})();
var ClickStart = (function () {
    function ClickStart() {

    };
    ClickStart.value = new ClickStart();
    return ClickStart;
})();
var OnboardConfirm = (function () {
    function OnboardConfirm(value0) {
        this.value0 = value0;
    };
    OnboardConfirm.create = function (value0) {
        return new OnboardConfirm(value0);
    };
    return OnboardConfirm;
})();
var OnboardSelectRole = (function () {
    function OnboardSelectRole(value0) {
        this.value0 = value0;
    };
    OnboardSelectRole.create = function (value0) {
        return new OnboardSelectRole(value0);
    };
    return OnboardSelectRole;
})();
var OnboardUploadFile = (function () {
    function OnboardUploadFile() {

    };
    OnboardUploadFile.value = new OnboardUploadFile();
    return OnboardUploadFile;
})();
var OnboardStartKYC = (function () {
    function OnboardStartKYC() {

    };
    OnboardStartKYC.value = new OnboardStartKYC();
    return OnboardStartKYC;
})();
var OnboardApplicantVerification = (function () {
    function OnboardApplicantVerification(value0) {
        this.value0 = value0;
    };
    OnboardApplicantVerification.create = function (value0) {
        return new OnboardApplicantVerification(value0);
    };
    return OnboardApplicantVerification;
})();
var OnboardOwnershipVerification = (function () {
    function OnboardOwnershipVerification(value0) {
        this.value0 = value0;
    };
    OnboardOwnershipVerification.create = function (value0) {
        return new OnboardOwnershipVerification(value0);
    };
    return OnboardOwnershipVerification;
})();
var OnboardJoinRewards = (function () {
    function OnboardJoinRewards(value0) {
        this.value0 = value0;
    };
    OnboardJoinRewards.create = function (value0) {
        return new OnboardJoinRewards(value0);
    };
    return OnboardJoinRewards;
})();
var OnboardComplete = (function () {
    function OnboardComplete(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    OnboardComplete.create = function (value0) {
        return function (value1) {
            return new OnboardComplete(value0, value1);
        };
    };
    return OnboardComplete;
})();
var getUserId = function (dictPartial) {
    return Data_Functor.map(Effect.functorEffect)(Data_Maybe.fromJust())(SessionStorage.getItem(Config.config.userStorageName));
};
var grouping = function (companyId) {
    var sendGroupAnalytics = Effect_Uncurried.runEffectFn2($foreign.group);
    return Effect_Exception.catchException((function () {
        var $30 = Effect_Class_Console.error(Effect_Class.monadEffectEffect);
        var $31 = Data_Show.show(Effect_Exception.showError);
        return function ($32) {
            return $30($31($32));
        };
    })())(function __do() {
        var userId = getUserId()();
        return sendGroupAnalytics(companyId)(userId)();
    });
};
var identifying = function (companyId) {
    return function (status) {
        var sendIdentifyAnalytics = Effect_Uncurried.runEffectFn3($foreign.identify);
        return Effect_Exception.catchException((function () {
            var $33 = Effect_Class_Console.error(Effect_Class.monadEffectEffect);
            var $34 = Data_Show.show(Effect_Exception.showError);
            return function ($35) {
                return $33($34($35));
            };
        })())(function __do() {
            var userId = getUserId()();
            return sendIdentifyAnalytics(companyId)(userId)(status)();
        });
    };
};
var getCompanyInfo = function (dictPartial) {
    return Data_Functor.map(Effect.functorEffect)(Data_Maybe.fromJust())(Data_Functor.map(Effect.functorEffect)(Data_Either.hush)(Model_Company.readCompany));
};
var generateSegmentProperity = function (segmentData) {
    if (segmentData instanceof OnboardStart) {
        return {
            event: "onboard",
            action: "startOnBoard",
            path: "payables/onboarding/start",
            result: "",
            errorMessage: ""
        };
    };
    if (segmentData instanceof ViewIntro) {
        return {
            event: "onboard",
            action: "viewIntro",
            path: "payables/onboarding/viewIntro",
            result: "",
            errorMessage: ""
        };
    };
    if (segmentData instanceof ClickStart) {
        return {
            event: "onboard",
            action: "clickStart",
            path: "payables/onboarding/clickStart",
            result: "",
            errorMessage: ""
        };
    };
    if (segmentData instanceof OnboardConfirm) {
        return {
            event: "onboard",
            action: "confirmRegistration",
            path: "payables/onboarding/abn",
            result: segmentData.value0,
            errorMessage: ""
        };
    };
    if (segmentData instanceof OnboardSelectRole) {
        return {
            event: "onboard",
            action: "selectRole",
            path: "payables/onboarding/select-role",
            result: segmentData.value0,
            errorMessage: ""
        };
    };
    if (segmentData instanceof OnboardUploadFile) {
        return {
            event: "onboard",
            action: "uploadFile",
            path: "payables/onboarding/select-role",
            result: "",
            errorMessage: ""
        };
    };
    if (segmentData instanceof OnboardStartKYC) {
        return {
            event: "onboard",
            action: "startKYC",
            path: "payables/onboarding/kyc",
            result: "",
            errorMessage: ""
        };
    };
    if (segmentData instanceof OnboardApplicantVerification) {
        return {
            event: "onboard",
            action: "completeApplicantVerification",
            path: "payables/onboarding/kyc",
            result: segmentData.value0,
            errorMessage: ""
        };
    };
    if (segmentData instanceof OnboardOwnershipVerification) {
        return {
            event: "onboard",
            action: "completeOwnershipVerification",
            path: "payables/onboarding/kyc",
            result: segmentData.value0,
            errorMessage: ""
        };
    };
    if (segmentData instanceof OnboardJoinRewards) {
        return {
            event: "onboard",
            action: "completeJoinRewards",
            path: "payables/onboarding/join-rewards",
            result: segmentData.value0,
            errorMessage: ""
        };
    };
    if (segmentData instanceof OnboardComplete) {
        return {
            event: "onboard",
            action: "addPaymentDetails",
            path: "payables/onboarding/paymentDetails",
            result: segmentData.value0,
            errorMessage: segmentData.value1
        };
    };
    throw new Error("Failed pattern match at Segment (line 116, column 40 - line 193, column 22): " + [ segmentData.constructor.name ]);
};
var generatePageData = function (v) {
    if (v instanceof DashboardPage) {
        return {
            pageName: "Dashboard",
            properties: {
                url: Config.config.uiHost + "/payables/dashboard",
                path: "/payables/dashboard"
            }
        };
    };
    if (v instanceof BatchListPage) {
        return {
            pageName: "BatchList",
            properties: {
                url: Config.config.uiHost + "/payables/batchList",
                path: "/payables/batchList"
            }
        };
    };
    if (v instanceof GetStartedPage) {
        return {
            pageName: "GetStarted",
            properties: {
                url: Config.config.uiHost + "/payables/getStarted",
                path: "/payables/getStarted"
            }
        };
    };
    throw new Error("Failed pattern match at Segment (line 195, column 1 - line 195, column 48): " + [ v.constructor.name ]);
};
var paging = function (pageData) {
    var sendPageAnalytics = Effect_Uncurried.runEffectFn3($foreign.page);
    return Effect_Exception.catchException((function () {
        var $36 = Effect_Class_Console.error(Effect_Class.monadEffectEffect);
        var $37 = Data_Show.show(Effect_Exception.showError);
        return function ($38) {
            return $36($37($38));
        };
    })())(function __do() {
        var userId = getUserId()();
        var companyInfo = getCompanyInfo()();
        return sendPageAnalytics(companyInfo)(userId)(generatePageData(pageData))();
    });
};
var unsafePaging = function ($39) {
    return Effect_Unsafe.unsafePerformEffect(paging($39));
};
var calcNextCompanyStatus = function (v) {
    return function (v1) {
        if (v1 instanceof OnboardConfirm && v1.value0 === "Passed") {
            return Data_Show.show(Model_CompanyStatus.showCompanyStatus)(Model_CompanyStatus.CreditCheckPassed.value);
        };
        if (v1 instanceof OnboardConfirm) {
            return Data_Show.show(Model_CompanyStatus.showCompanyStatus)(Model_CompanyStatus.Declined.value);
        };
        if (v1 instanceof OnboardComplete && v1.value0 === "Passed") {
            return Data_Show.show(Model_CompanyStatus.showCompanyStatus)(Model_CompanyStatus.PaymentDetailsAdded.value);
        };
        if (v1 instanceof OnboardComplete) {
            return Data_Show.show(Model_CompanyStatus.showCompanyStatus)(Model_CompanyStatus.BusinessDetailsAdded.value);
        };
        if (v instanceof Model_CompanyStatus.CreditCheckPassed && v1 instanceof OnboardApplicantVerification) {
            return Data_Show.show(Model_CompanyStatus.showCompanyStatus)(Model_CompanyStatus.ApplicantVerified.value);
        };
        if (v instanceof Model_CompanyStatus.OwnerInfoGenerated && v1 instanceof OnboardApplicantVerification) {
            return Data_Show.show(Model_CompanyStatus.showCompanyStatus)(Model_CompanyStatus.ApplicantVerified.value);
        };
        return Data_Show.show(Model_CompanyStatus.showCompanyStatus)(v);
    };
};
var trackingCompany = function (company) {
    return function (segmentData) {
        var sendTrackAnalytics = function (c) {
            return function (i) {
                return function (sdp) {
                    return function () {
                        return $foreign.track(c, i, sdp, calcNextCompanyStatus(c.status)(segmentData));
                    };
                };
            };
        };
        var companyInfo = Data_Maybe.fromJust()(company);
        return Effect_Exception.catchException((function () {
            var $40 = Effect_Class_Console.error(Effect_Class.monadEffectEffect);
            var $41 = Data_Show.show(Effect_Exception.showError);
            return function ($42) {
                return $40($41($42));
            };
        })())(function __do() {
            var userId = getUserId()();
            return sendTrackAnalytics(companyInfo)(userId)(generateSegmentProperity(segmentData))();
        });
    };
};
var tracking = function (segmentData) {
    return Effect_Exception.catchException((function () {
        var $43 = Effect_Class_Console.error(Effect_Class.monadEffectEffect);
        var $44 = Data_Show.show(Effect_Exception.showError);
        return function ($45) {
            return $43($44($45));
        };
    })())(function __do() {
        var company = Data_Functor.map(Effect.functorEffect)(Data_Either.hush)(Model_Company.readCompany)();
        return trackingCompany(company)(segmentData)();
    });
};
var unsafeTracking = function ($46) {
    return Effect_Unsafe.unsafePerformEffect(tracking($46));
};
var unsafeTrackingCompany = function (company) {
    return function (segmentData) {
        return Effect_Unsafe.unsafePerformEffect(trackingCompany(company)(segmentData));
    };
};
module.exports = {
    OnboardStart: OnboardStart,
    ViewIntro: ViewIntro,
    ClickStart: ClickStart,
    OnboardConfirm: OnboardConfirm,
    OnboardSelectRole: OnboardSelectRole,
    OnboardUploadFile: OnboardUploadFile,
    OnboardStartKYC: OnboardStartKYC,
    OnboardApplicantVerification: OnboardApplicantVerification,
    OnboardOwnershipVerification: OnboardOwnershipVerification,
    OnboardJoinRewards: OnboardJoinRewards,
    OnboardComplete: OnboardComplete,
    DashboardPage: DashboardPage,
    BatchListPage: BatchListPage,
    GetStartedPage: GetStartedPage,
    unsafePaging: unsafePaging,
    identifying: identifying,
    grouping: grouping,
    paging: paging,
    getUserId: getUserId,
    getCompanyInfo: getCompanyInfo,
    tracking: tracking,
    unsafeTracking: unsafeTracking,
    trackingCompany: trackingCompany,
    unsafeTrackingCompany: unsafeTrackingCompany,
    generateSegmentProperity: generateSegmentProperity,
    generatePageData: generatePageData,
    calcNextCompanyStatus: calcNextCompanyStatus,
    track: $foreign.track,
    page: $foreign.page,
    identify: $foreign.identify,
    group: $foreign.group
};
