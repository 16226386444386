import React from 'react'
import './Error.sass'
import errorTemp from '../img/error-temp.svg'
import error403 from '../img/error403.svg'
import emptyWarning from '../img/error-empty.svg'

const Error = props => {
  return (
    <div className="error-page purplepay--message">
      <DisplayDefaultError httpError={props.httpError} />
      {props.children}
    </div>
  )
}

function DisplayDefaultError({ httpError }) {
  switch (httpError) {
    case undefined:
      return <img className="message__image" src={emptyWarning} alt="" />
    case 403:
      return (
        <>
          <img className="message__image" src={error403} alt="" />
          <h1 className="message__title">
            We couldn&apos;t confirm your permission.
          </h1>
          <h3 className="message__subtitle">
            For access to this page, chat to your administrator.
          </h3>
        </>
      )
    case 404:
      return (
        <>
          <img className="message__image" src={errorTemp} alt="" />
          <h1 className="message__title">We&apos;ve hit a roadblock!</h1>
        </>
      )
    default:
      return (
        <>
          <img className="message__image" src={errorTemp} alt="" />
          <h1 className="message__title">Oops, something went wrong</h1>
          <div className="message__subtitle">
            We&apos;re currently looking into the issue. For more information,
            you can contact our support team on
            <br />
            <a href="tel:1300-783-674">1300 783 674</a>.
          </div>
        </>
      )
  }
}
export default Error
