import React from 'react'
import {Tooltip} from '@myob/myob-widgets'
import './FeesInfo.sass'
import config from '../config'
import BatchDetailMetadata from "./BatchDetailMetadata";

const FeesInfo = ({description, feesInfoType}) => {
  const cardFees = <>
    {config.creditFee}% (inc. GST) on credit card payments
    <br/>
    {config.debitFee}% (inc. GST) on debit card payments
  </>
  const bankAccountFees = <>
    {config.bankAccountPerTransactionFee} per transaction line + {config.bankAccountBatchFee} (inc. GST) on bank
    payments
  </>

  const feesBody = (feesInfoType) => {
    switch (feesInfoType) {
      case FeesInfo.All:
        return (
          <>
            {cardFees}
            <br/>
            {bankAccountFees}
          </>
        )
      case FeesInfo.BankAccountType:
        return bankAccountFees
      case FeesInfo.CardType:
        return cardFees
    }
  }
  return <Tooltip placement="top" id="Fees" className="fees-tooltip">
    {description && (
      <>
        <span>{description}</span>
        <br/>
        <br/>
      </>
    )}
    {feesBody(feesInfoType)}
  </Tooltip>
}

FeesInfo.CardType = "Card"
FeesInfo.BankAccountType = "BankAccount"
FeesInfo.All = "All"

export default FeesInfo
