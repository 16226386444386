import { format } from 'date-fns'
import * as r from 'ramda'

export function dateFormatter(date, defaultValue = '-') {
  return dateTimeFormatter(date, defaultValue, 'dd/MM/yyyy')
}

export function timeFormatter(date, defaultValue = '-') {
  return dateTimeFormatter(date, defaultValue, 'hh:mma')
}

export function cardFormatter(cardNo) {
  return cardNo.length === 16 ? r.takeLast(4, cardNo) : '-'
}

export function accountNumberFormatter(accountNo) {
  return accountNo.length >= 4 ? r.takeLast(4, accountNo) : '-'
}

function dateTimeFormatter(date, defaultValue, formatter) {
  const d = new Date(date)
  return isValidDate(d) ? format(d, formatter) : defaultValue
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date)
}
