import React from 'react'
import SuccessWithStars from '../img/success_with_stars.gif'
import './ApplicationComplete.sass'

const ApplicationComplete = ({ title, description, children }) => (
  <div className={'application-complete'}>
    <img className={'tick'} alt={title} src={SuccessWithStars} />
    <h3>{title}</h3>
    <p>{description}</p>
    {children}
  </div>
)

export default ApplicationComplete
