import React, { useContext } from 'react'
import { Modal } from '@myob/myob-widgets'
import { context } from '../pages/BusinessManage'
import { Spinner } from '@myob/myob-widgets'
import './AddCardModal.sass'
import Error from './Error'
import { isJust } from 'purs/Data.Maybe'
import { load } from 'purs/Page.BusinessManage'
import { showAddCardModal } from 'purs/CardManage'
import CardFormPanel from './CardFormPanel'

const redirect = l => () => {
  window.location = l
}
const NewCardModal = () => {
  const {
    state: { newCardModal },
    dispatch
  } = useContext(context)
  return (
    <Modal
      modalId="add-card"
      title="Add a Card"
      show={newCardModal.show}
      onCancel={() => {
        dispatch(showAddCardModal(false))
        dispatch(load(redirect))
      }}
    >
      <Modal.Body>
        {newCardModal.isLoading ? (
          <Spinner />
        ) : isJust(newCardModal.httpError) ? (
          <Error httpError={newCardModal.httpError.value0} />
        ) : (
          <CardFormPanel iframeLink={newCardModal.cardIframeLink.value0} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          id="btn-newcard-modal-close"
          type="button"
          className="btn btn-default"
          onClick={() => {
            dispatch(showAddCardModal(false))
            dispatch(load(redirect))
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewCardModal
