// Generated by purs version 0.13.8
"use strict";
var Config = require("../Config/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Time_Duration = require("../Data.Time.Duration/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var Effect_Aff_Retry = require("../Effect.Aff.Retry/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_RouteQuery = require("../Helper.RouteQuery/index.js");
var KYCDashboard = require("../KYCDashboard/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Model_Owner = require("../Model.Owner/index.js");
var Segment = require("../Segment/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var saveKycInfo = function (companyId) {
    return function (verificationId) {
        return function __do() {
            var timestamp = Data_Functor.map(Effect.functorEffect)(Data_JSDate.getTime)(Data_JSDate.now)();
            return SessionStorage.saveItem(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "companyId";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "created";
            }))(Simple_JSON.writeForeignNumber)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "verificationId";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()())()()())()()()))(Config.config.kycInfoStorageName)({
                companyId: companyId,
                verificationId: verificationId,
                created: timestamp
            })();
        };
    };
};
var retrieveKYCInfo = function __do() {
    var company = Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(Model_Company.readCompanyWithDefault)();
    var maybeKycInfo = Data_Functor.map(Effect.functorEffect)(Data_Either.hush)(SessionStorage.readItem(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "companyId";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "created";
    }))(Simple_JSON.readNumber)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "verificationId";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()()))(Config.config.kycInfoStorageName))();
    if (maybeKycInfo instanceof Data_Maybe.Just && maybeKycInfo.value0.companyId === company.companyId) {
        return maybeKycInfo;
    };
    return Data_Maybe.Nothing.value;
};
var parseOwnerInfo = function (query) {
    return Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](Data_Maybe.Nothing.value))((function () {
        var $24 = Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "company";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "firstName";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "id";
        }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "kind";
        }))(Model_Owner.readForeignCompanyOwnerKind)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "lastName";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "middleName";
        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsNil)()())()())()())()())()())()()));
        return function ($25) {
            return Data_Either.hush($24(SessionStorage.base64Decode($25)));
        };
    })())(Helper_RouteQuery.getKeyValueFromQuery(query)("owner"));
};
var parseCompanyEntityTypeInfo = function (query) {
    return Data_Either.hush(Helper_RouteQuery.getKeyValueFromQuery(query)("entityType"));
};
var parseAdminInfo = function (query) {
    var v = Data_Either.hush(Helper_RouteQuery.getKeyValueFromQuery(query)("isadmin"));
    if (v instanceof Data_Maybe.Just && v.value0 === "true") {
        return true;
    };
    return false;
};
var kycFailedStatus = 400;
var kycDoneStatus = 201;
var _runAff = function (callback) {
    return Effect_Aff.runAff_(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)((function () {
        var $26 = Data_Semigroup.append(Data_Semigroup.semigroupString)("_runAff error: ");
        var $27 = Data_Show.show(Effect_Exception.showError);
        return function ($28) {
            return Effect_Console.log($26($27($28)));
        };
    })())((function () {
        var $29 = Control_Applicative.pure(Effect.applicativeEffect);
        return function ($30) {
            return $29(callback($30));
        };
    })()));
};
var _recoverResp = Fetch.recover({
    status: 500,
    body: ""
});
var kycOwner = function (id) {
    return function (callback) {
        var sendSegmentData = function (v) {
            if (v.status === 204) {
                return Segment.tracking(new Segment.OnboardOwnershipVerification("Passed"));
            };
            return Segment.tracking(new Segment.OnboardOwnershipVerification("Failed"));
        };
        var sendRequest = function (companyId) {
            return Fetch.post()("/company/" + (companyId + "/owner-kyc"))(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "kycId";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()()))({
                kycId: id
            }))({});
        };
        return _runAff(callback)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompanyWithDefault))(function (company) {
            return Control_Bind.bind(Effect_Aff.bindAff)(_recoverResp(sendRequest(company.companyId)))(function (kycResult) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(SessionStorage.saveItem(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "body";
                }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "status";
                }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()()))(Config.config.kycResultStorageName)(kycResult)))(function () {
                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(sendSegmentData(kycResult))(kycResult));
                });
            });
        }));
    };
};
var userKyc = function (id) {
    return function (callback) {
        var updateToAuthResult = function (companyId) {
            return Data_Functor.map(Effect_Aff.functorAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](500))(function (v) {
                return v.status;
            }))(KYCDashboard.sendUpdateToAuthoriserRequest(companyId));
        };
        var sendRequest = function (companyId) {
            return Fetch.post()("/user/kyc")(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "company";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "id";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()())()()()))({
                id: id,
                company: companyId
            }))({});
        };
        return _runAff(callback)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompanyWithDefault))(function (company) {
            return Control_Bind.bind(Effect_Aff.bindAff)(_recoverResp(sendRequest(company.companyId)))(function (k) {
                return Control_Bind.bind(Effect_Aff.bindAff)((function () {
                    var $12 = k.status === kycDoneStatus;
                    if ($12) {
                        return updateToAuthResult(company.companyId);
                    };
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(500);
                })())(function (u) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        status: (function () {
                            var $13 = u === kycFailedStatus;
                            if ($13) {
                                return u;
                            };
                            return k.status;
                        })(),
                        body: Data_Show.show(Data_Show.showInt)(u)
                    });
                });
            });
        }));
    };
};
var waitForOwners = function (companyId) {
    var retryPolicy = Data_Semigroup.append(Effect_Aff_Retry.retryPolicySemigroup(Effect_Aff.monadAff))(Effect_Aff_Retry.constantDelay(Data_Time_Duration.durationMilliseconds)(5000.0)(Effect_Aff_Class.monadAffAff))(Effect_Aff_Retry.limitRetries(10)(Effect_Aff_Class.monadAffAff));
    var checks = Data_Function["const"]((function () {
        var $31 = Control_Applicative.pure(Effect_Aff.applicativeAff);
        return function ($32) {
            return $31(Data_Either.isLeft($32));
        };
    })());
    var action = function (v) {
        return Control_Bind.bind(Effect_Aff.bindAff)(_recoverResp(Fetch.get()("/company/" + companyId)({})))(function (resp) {
            return Control_Bind.bind(Effect_Aff.bindAff)((function () {
                if (resp.status === 200) {
                    var v1 = Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "abn";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "acn";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "entityType";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "id";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "industry";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsNil)))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "location";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "onboardOnBehalf";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "requireMoreDocument";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "status";
                    }))(Model_CompanyStatus.readForeignCompanyStatus)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()()))(resp.body);
                    if (v1 instanceof Data_Either.Right) {
                        if (Data_Eq.eq(Model_CompanyStatus.eqCompanyStatus)(v1.value0.status)(Model_CompanyStatus.OwnerInfoGenerated.value)) {
                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Right(v1.value0));
                        };
                        if (Data_Eq.eq(Model_CompanyStatus.eqCompanyStatus)(v1.value0.status)(Model_CompanyStatus.OwnerInfoGenerateFailed.value)) {
                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left("OwnerInfoGenerateFailed"));
                        };
                    };
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left("not ready yet"));
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left("not ready yet"));
            })())(function (result) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(result);
            });
        });
    };
    return Effect_Aff_Retry.retrying(Effect_Aff_Class.monadAffAff)(retryPolicy)(checks)(action);
};
var _waitForUBOReport = function (verificationId) {
    return function (companyId) {
        var sendSegmentData = function (v) {
            if (v.status === 204) {
                return Segment.tracking(new Segment.OnboardApplicantVerification("Passed"));
            };
            return Segment.tracking(new Segment.OnboardApplicantVerification("Failed"));
        };
        var sendRequest = function (cid) {
            return _recoverResp(Fetch.post()("/company/" + (cid + "/admin-kyc"))(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "kycId";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()()))({
                kycId: verificationId
            }))({}));
        };
        return Control_Bind.bind(Effect_Aff.bindAff)(waitForOwners(companyId))(function (retryResult) {
            if (retryResult instanceof Data_Either.Right) {
                return Control_Bind.bind(Effect_Aff.bindAff)(sendRequest(companyId))(function (kycResult) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(SessionStorage.saveItem(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                        return "body";
                    }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                        return "status";
                    }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()()))(Config.config.kycResultStorageName)(kycResult)))(function () {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(sendSegmentData(kycResult))(kycResult));
                    });
                });
            };
            if (retryResult instanceof Data_Either.Left) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    status: 500,
                    body: ""
                });
            };
            throw new Error("Failed pattern match at OnboardingKYCModalContent (line 63, column 3 - line 68, column 43): " + [ retryResult.constructor.name ]);
        });
    };
};
var onboardCompany = function (id) {
    return function (callback) {
        var generateUboReport = function (companyId) {
            return _recoverResp(Fetch.post()("/company/" + (companyId + "/generate-owner-info"))("")({}));
        };
        return _runAff(callback)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompanyWithDefault))(function (company) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(saveKycInfo(company.companyId)(id)))(function () {
                return Control_Bind.bind(Effect_Aff.bindAff)(generateUboReport(company.companyId))(function () {
                    return _waitForUBOReport(id)(company.companyId);
                });
            });
        }));
    };
};
var waitForUBOReport = function (verificationId) {
    return function (callback) {
        return _runAff(callback)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompanyWithDefault))(function (company) {
            return _waitForUBOReport(verificationId)(company.companyId);
        }));
    };
};
module.exports = {
    kycDoneStatus: kycDoneStatus,
    kycFailedStatus: kycFailedStatus,
    waitForUBOReport: waitForUBOReport,
    onboardCompany: onboardCompany,
    "_waitForUBOReport": _waitForUBOReport,
    waitForOwners: waitForOwners,
    kycOwner: kycOwner,
    userKyc: userKyc,
    parseOwnerInfo: parseOwnerInfo,
    parseAdminInfo: parseAdminInfo,
    parseCompanyEntityTypeInfo: parseCompanyEntityTypeInfo,
    "_recoverResp": _recoverResp,
    "_runAff": _runAff,
    saveKycInfo: saveKycInfo,
    retrieveKYCInfo: retrieveKYCInfo
};
