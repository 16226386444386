import config from '../config'

export const setUpAddressValidation = ({
  whereToDisplaySuggestions,
  addressField,
  onChange,
  onSelect
}) => {
  window.Harmony.useEnv('https://hosted.mastersoftgroup.com')

  // Init the client with the demo api user name and credential
  window.Harmony.init(
    config.harmonyId,
    config.harmonyToken,
    window.Harmony.AUSTRALIA
  )

  // Use the JSONP protocol
  window.Harmony.useProtocol(window.Harmony.JSONP)

  var opt = {
    // min 3 chars to trigger the lookup
    minLength: 3,
    // skip transaction call when address selected. You need to make your own call depending on your business flow.
    skipTransaction: true,
    change(event, ui) {
      onChange(ui.item)
    },
    // define your own call back function when address selected.
    onSelect: (event, ui) => {
      onSelect(ui.item)
    },
    appendTo: whereToDisplaySuggestions,
    position: {
      my: 'left top',
      at: 'left bottom',
      of: window.$(addressField),
      collision: 'none'
    }
  }

  // Configure the address lookup.
  window.Harmony.UI.addressLookup(window.$(addressField), 'GNAF', opt)
}
