// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Error_Util = require("../Control.Error.Util/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Helper_RouteQuery = require("../Helper.RouteQuery/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_PaymentError = require("../Model.PaymentError/index.js");
var Model_Rewards = require("../Model.Rewards/index.js");
var Model_Status = require("../Model.Status/index.js");
var Model_TransactionType = require("../Model.TransactionType/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Batch = (function () {
    function Batch(value0) {
        this.value0 = value0;
    };
    Batch.create = function (value0) {
        return new Batch(value0);
    };
    return Batch;
})();
var ArlBatch = (function () {
    function ArlBatch(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    ArlBatch.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new ArlBatch(value0, value1, value2);
            };
        };
    };
    return ArlBatch;
})();
var EmptyBatch = (function () {
    function EmptyBatch() {

    };
    EmptyBatch.value = new EmptyBatch();
    return EmptyBatch;
})();
var HttpError = (function () {
    function HttpError(value0) {
        this.value0 = value0;
    };
    HttpError.create = function (value0) {
        return new HttpError(value0);
    };
    return HttpError;
})();
var unsafeGetCompanyInfo = function (query) {
    var companyName = Data_Functor.map(Data_Either.functorEither)(SessionStorage.base64Decode)(Helper_RouteQuery.getKeyValueFromQuery(query)("companyName"));
    var companyInfoFromQuery = function (id) {
        return function (name) {
            return {
                companyId: id,
                name: name
            };
        };
    };
    var companyId = Helper_RouteQuery.getKeyValueFromQuery(query)("companyId");
    if (companyId instanceof Data_Either.Right && companyName instanceof Data_Either.Right) {
        return new Data_Either.Right(companyInfoFromQuery(companyId.value0)(companyName.value0));
    };
    if (companyId instanceof Data_Either.Right && companyName instanceof Data_Either.Left) {
        return new Data_Either.Left("no-enough-company-info");
    };
    if (companyId instanceof Data_Either.Left) {
        return Effect_Unsafe.unsafePerformEffect(Model_Company.readCompanyQuery);
    };
    throw new Error("Failed pattern match at Page.BatchDetail (line 97, column 30 - line 100, column 56): " + [ companyId.constructor.name, companyName.constructor.name ]);
};
var setSelfEnrollModalLoading = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        selfEnrollmentModal: {
            isLoading: true,
            display: state.selfEnrollmentModal.display,
            selfEnrollmentError: state.selfEnrollmentModal.selfEnrollmentError
        },
        batch: state.batch,
        company: state.company,
        confirmAuthPaymentModal: state.confirmAuthPaymentModal,
        isLoading: state.isLoading,
        paymentResponse: state.paymentResponse,
        rewardsThanksModal: state.rewardsThanksModal
    });
};
var saveCompanyInfo = function (query) {
    var saveWholeCompany = function (c) {
        return Model_Company.saveCompany(c);
    };
    var handleNoCompany = function (e) {
        return Model_Company.saveCompanyQuery(query);
    };
    return Control_Bind.bind(Effect.bindEffect)(Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(Model_Company.readCompany))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(handleNoCompany)(saveWholeCompany));
};
var renderAuthoriserNum = function (v) {
    return function (currentRequirement) {
        return function (alreadyApproved) {
            if (v instanceof Model_Status.Awaiting) {
                return Data_Ord.max(Data_Ord.ordInt)(1)(currentRequirement);
            };
            if (v instanceof Model_Status.Unavailable) {
                return Data_Ord.max(Data_Ord.ordInt)(1)(currentRequirement);
            };
            if (v instanceof Model_Status.Declined) {
                return Data_Ord.max(Data_Ord.ordInt)(1)(currentRequirement);
            };
            return Data_Ord.max(Data_Ord.ordInt)(1)(alreadyApproved);
        };
    };
};
var loadBatch = function (location) {
    return function (history) {
        return function (query) {
            return function (pathName) {
                return function (batchId) {
                    return function (s) {
                        var noCompanyFound = Control_Applicative.pure(Effect_Aff.applicativeAff)({
                            isLoading: false,
                            batch: new HttpError(500),
                            company: s.company,
                            confirmAuthPaymentModal: s.confirmAuthPaymentModal,
                            paymentResponse: s.paymentResponse,
                            rewardsThanksModal: s.rewardsThanksModal,
                            selfEnrollmentModal: s.selfEnrollmentModal
                        });
                        var company = unsafeGetCompanyInfo(query);
                        var stateWithPaymentResp = {
                            isLoading: s.isLoading,
                            selfEnrollmentModal: s.selfEnrollmentModal,
                            rewardsThanksModal: s.rewardsThanksModal,
                            confirmAuthPaymentModal: s.confirmAuthPaymentModal,
                            batch: s.batch,
                            company: company,
                            paymentResponse: Control_Error_Util.hush(Helper_RouteQuery.getKeyValueFromLocation(location)("paymentresult"))
                        };
                        var updateState = function (c) {
                            return function (v) {
                                return function (v1) {
                                    if (v1 instanceof Data_Either.Right && v1.value0.status === 200) {
                                        var v2 = Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "batchDetail";
                                        }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "authoriserDetails";
                                        }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "authorisedDate";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "email";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "id";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "authoriserRequired";
                                        }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "bankStatement";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "error";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "code";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "message";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "source";
                                        }))(Simple_JSON.readMaybe(Model_PaymentError.readForeignPaymentErrorSource))(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "estimatedRewardsPoints";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "fees";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "id";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "journalMemo";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "lodgementReference";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "paymentDetails";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "bankAccount";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "accountName";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "accountNumber";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "bsbNumber";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "id";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "card";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "expiry";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "id";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "number";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "scheme";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "type";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(Simple_JSON.readFieldsNil)()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "paymentId";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "processDate";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "remitterName";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "requestedBy";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "status";
                                        }))(Model_Status.readForeignBatchStatus)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "submittedDate";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "totalAmount";
                                        }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "lines";
                                        }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "amount";
                                        }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "bankAccountName";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "bankAccountNumber";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "bsbNumber";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "dateOccurred";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "id";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "lodgementReference";
                                        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "payeeId";
                                        }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "payeeName";
                                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                                            return "transactionType";
                                        }))(Model_TransactionType.readForeignTransactionType)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())))(Simple_JSON.readFieldsNil)()())()()))(v1.value0.body);
                                        if (v2 instanceof Data_Either.Right) {
                                            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                                                isLoading: false,
                                                batch: v(v2.value0),
                                                company: stateWithPaymentResp.company,
                                                confirmAuthPaymentModal: stateWithPaymentResp.confirmAuthPaymentModal,
                                                paymentResponse: stateWithPaymentResp.paymentResponse,
                                                rewardsThanksModal: stateWithPaymentResp.rewardsThanksModal,
                                                selfEnrollmentModal: stateWithPaymentResp.selfEnrollmentModal
                                            });
                                        };
                                        if (v2 instanceof Data_Either.Left) {
                                            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                                                isLoading: false,
                                                batch: EmptyBatch.value,
                                                company: stateWithPaymentResp.company,
                                                confirmAuthPaymentModal: stateWithPaymentResp.confirmAuthPaymentModal,
                                                paymentResponse: stateWithPaymentResp.paymentResponse,
                                                rewardsThanksModal: stateWithPaymentResp.rewardsThanksModal,
                                                selfEnrollmentModal: stateWithPaymentResp.selfEnrollmentModal
                                            });
                                        };
                                        throw new Error("Failed pattern match at Page.BatchDetail (line 141, column 7 - line 143, column 89): " + [ v2.constructor.name ]);
                                    };
                                    if (v1 instanceof Data_Either.Right && v1.value0.status === 401) {
                                        var encodeCompanyName = SessionStorage.base64Encode(c.name);
                                        var path = "/batch/" + (batchId + ("?companyId=" + (c.companyId + ("&companyName=" + encodeCompanyName))));
                                        return Control_Apply.applyFirst(Effect_Aff.applyAff)(Control_Applicative.pure(Effect_Aff.applicativeAff)({
                                            isLoading: false,
                                            batch: new HttpError(401),
                                            company: stateWithPaymentResp.company,
                                            confirmAuthPaymentModal: stateWithPaymentResp.confirmAuthPaymentModal,
                                            paymentResponse: stateWithPaymentResp.paymentResponse,
                                            rewardsThanksModal: stateWithPaymentResp.rewardsThanksModal,
                                            selfEnrollmentModal: stateWithPaymentResp.selfEnrollmentModal
                                        }))(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                            var $51 = SessionStorage.saveLocation(path)();
                                            return Helper_AuthActions.login(Data_Maybe.Just.create($51))();
                                        }));
                                    };
                                    if (v1 instanceof Data_Either.Right) {
                                        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                                            isLoading: false,
                                            batch: new HttpError(v1.value0.status),
                                            company: stateWithPaymentResp.company,
                                            confirmAuthPaymentModal: stateWithPaymentResp.confirmAuthPaymentModal,
                                            paymentResponse: stateWithPaymentResp.paymentResponse,
                                            rewardsThanksModal: stateWithPaymentResp.rewardsThanksModal,
                                            selfEnrollmentModal: stateWithPaymentResp.selfEnrollmentModal
                                        });
                                    };
                                    if (v1 instanceof Data_Either.Left) {
                                        return Data_Functor.voidLeft(Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Class_Console.error(Effect_Class.monadEffectEffect)("Error processing Response:" + Data_Show.show(Effect_Exception.showError)(v1.value0))))({
                                            isLoading: false,
                                            batch: new HttpError(500),
                                            company: stateWithPaymentResp.company,
                                            confirmAuthPaymentModal: stateWithPaymentResp.confirmAuthPaymentModal,
                                            paymentResponse: stateWithPaymentResp.paymentResponse,
                                            rewardsThanksModal: stateWithPaymentResp.rewardsThanksModal,
                                            selfEnrollmentModal: stateWithPaymentResp.selfEnrollmentModal
                                        });
                                    };
                                    throw new Error("Failed pattern match at Page.BatchDetail (line 139, column 5 - line 139, column 100): " + [ c.constructor.name, v.constructor.name, v1.constructor.name ]);
                                };
                            };
                        };
                        var loadFromArl = function (c) {
                            return function (token) {
                                var url = "/arl/company/" + (c.companyId + ("/batch/" + batchId));
                                var path = pathName + ("?companyId=" + c.companyId);
                                var header = {
                                    Authorization: "Bearer " + token
                                };
                                return Control_Apply.applySecond(Effect_Aff.applyAff)(Control_Apply.applySecond(Effect_Aff.applyAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(saveCompanyInfo(c)))(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Helper_AuthActions.replaceHistory(history)(path))))(Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.get()(url)(header)))(updateState(c)(function (b) {
                                    return new ArlBatch(b, c, header);
                                })));
                            };
                        };
                        var loadFromBrowser = function (c) {
                            var url = "/company/" + (c.companyId + ("/batch/" + batchId));
                            var header = {};
                            return Control_Bind.bind(Effect_Aff.bindAff)(Control_Apply.applySecond(Effect_Aff.applyAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(saveCompanyInfo(c)))(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.get()(url)(header))))(updateState(c)(function (b) {
                                return new Batch(b);
                            }));
                        };
                        var arlToken = Helper_RouteQuery.getKeyValueFromLocation(location)("token");
                        if (company instanceof Data_Either.Left) {
                            return noCompanyFound;
                        };
                        if (company instanceof Data_Either.Right && arlToken instanceof Data_Either.Right) {
                            return loadFromArl(company.value0)(arlToken.value0);
                        };
                        if (company instanceof Data_Either.Right && arlToken instanceof Data_Either.Left) {
                            return loadFromBrowser(company.value0);
                        };
                        throw new Error("Failed pattern match at Page.BatchDetail (line 111, column 55 - line 114, column 45): " + [ company.constructor.name, arlToken.constructor.name ]);
                    };
                };
            };
        };
    };
};
var initState = {
    isLoading: true,
    selfEnrollmentModal: {
        display: false,
        isLoading: false,
        selfEnrollmentError: {
            display: false
        }
    },
    rewardsThanksModal: {
        display: false
    },
    confirmAuthPaymentModal: {
        display: false,
        isFutureDate: false
    },
    batch: EmptyBatch.value,
    company: new Data_Either.Left("no company set yet"),
    paymentResponse: Data_Maybe.Nothing.value
};
var closeAllModals = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        selfEnrollmentModal: {
            display: false,
            isLoading: state.selfEnrollmentModal.isLoading,
            selfEnrollmentError: state.selfEnrollmentModal.selfEnrollmentError
        },
        rewardsThanksModal: {
            display: false
        },
        confirmAuthPaymentModal: {
            display: false,
            isFutureDate: state.confirmAuthPaymentModal.isFutureDate
        },
        batch: state.batch,
        company: state.company,
        isLoading: state.isLoading,
        paymentResponse: state.paymentResponse
    });
};
var selfEnrollment = function (companyId) {
    return function (state) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Model_Rewards.saveJoinRewards(companyId)(true))(function (resp) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Model_Rewards.fetchAndSaveRewardsDetail(companyId))(function () {
                if (resp instanceof Data_Either.Right && resp.value0.status === 200) {
                    return Data_Functor.map(Effect_Aff.functorAff)(function (s) {
                        return {
                            rewardsThanksModal: {
                                display: true
                            },
                            batch: s.batch,
                            company: s.company,
                            confirmAuthPaymentModal: s.confirmAuthPaymentModal,
                            isLoading: s.isLoading,
                            paymentResponse: s.paymentResponse,
                            selfEnrollmentModal: s.selfEnrollmentModal
                        };
                    })(closeAllModals(state));
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    selfEnrollmentModal: {
                        isLoading: false,
                        selfEnrollmentError: {
                            display: true
                        },
                        display: state.selfEnrollmentModal.display
                    },
                    batch: state.batch,
                    company: state.company,
                    confirmAuthPaymentModal: state.confirmAuthPaymentModal,
                    isLoading: state.isLoading,
                    paymentResponse: state.paymentResponse,
                    rewardsThanksModal: state.rewardsThanksModal
                });
            });
        });
    };
};
var showConfirmAuthPaymentModal = function (isFuture) {
    return function (state) {
        return Data_Functor.map(Effect_Aff.functorAff)(function (s) {
            return {
                confirmAuthPaymentModal: {
                    display: true,
                    isFutureDate: isFuture
                },
                batch: s.batch,
                company: s.company,
                isLoading: s.isLoading,
                paymentResponse: s.paymentResponse,
                rewardsThanksModal: s.rewardsThanksModal,
                selfEnrollmentModal: s.selfEnrollmentModal
            };
        })(closeAllModals(state));
    };
};
var showSelfEnrollmentModal = function (state) {
    return Data_Functor.map(Effect_Aff.functorAff)(function (s) {
        return {
            selfEnrollmentModal: {
                display: true,
                isLoading: false,
                selfEnrollmentError: {
                    display: false
                }
            },
            batch: s.batch,
            company: s.company,
            confirmAuthPaymentModal: s.confirmAuthPaymentModal,
            isLoading: s.isLoading,
            paymentResponse: s.paymentResponse,
            rewardsThanksModal: s.rewardsThanksModal
        };
    })(closeAllModals(state));
};
module.exports = {
    unsafeGetCompanyInfo: unsafeGetCompanyInfo,
    renderAuthoriserNum: renderAuthoriserNum,
    loadBatch: loadBatch,
    initState: initState,
    showSelfEnrollmentModal: showSelfEnrollmentModal,
    showConfirmAuthPaymentModal: showConfirmAuthPaymentModal,
    selfEnrollment: selfEnrollment,
    Batch: Batch,
    ArlBatch: ArlBatch,
    EmptyBatch: EmptyBatch,
    HttpError: HttpError,
    setSelfEnrollModalLoading: setSelfEnrollModalLoading,
    closeAllModals: closeAllModals
};
