// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Fetch = require("../Fetch/index.js");
var Foreign = require("../Foreign/index.js");
var Helper_Attempt = require("../Helper.Attempt/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Helper_RouteQuery = require("../Helper.RouteQuery/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_PaymentError = require("../Model.PaymentError/index.js");
var Model_Rewards = require("../Model.Rewards/index.js");
var Model_Status = require("../Model.Status/index.js");
var Segment = require("../Segment/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var shouldRedirect = (function () {
    var verify = (function () {
        var $27 = Data_Functor.map(Effect.functorEffect)(Control_Bind.join(Data_Maybe.bindMaybe));
        var $28 = Data_Traversable.sequence(Data_Traversable.traversableMaybe)(Effect.applicativeEffect);
        var $29 = Data_Functor.map(Data_Maybe.functorMaybe)(SessionStorage.verifyNonce);
        return function ($30) {
            return $27($28($29($30)));
        };
    })();
    var redirect = function (v) {
        if (v instanceof Data_Maybe.Just) {
            return Helper_AuthActions.redirectTo(v.value0);
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
    var query = (function () {
        var $31 = Data_Function.flip(Helper_RouteQuery.getKeyValueFromQuery)("state");
        return function ($32) {
            return Data_Either.hush($31($32));
        };
    })();
    return Control_Bind.composeKleisliFlipped(Effect.bindEffect)(redirect)(function ($33) {
        return verify(query($33));
    });
})();
var sendRewardsRequest = function (state) {
    var updateState = function (v) {
        if (v instanceof Data_Maybe.Just) {
            return {
                batches: state.batches,
                isLoading: state.isLoading,
                httpError: state.httpError,
                companyId: state.companyId,
                company: state.company,
                redirectFromKYC: state.redirectFromKYC,
                rewardsDetails: Data_Array.head(v.value0)
            };
        };
        return state;
    };
    if (state.companyId instanceof Data_Maybe.Just) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Model_Rewards.fetchAndSaveRewardsDetail(state.companyId.value0))(function (rewardsDetail) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(updateState(rewardsDetail));
        });
    };
    if (state.companyId instanceof Data_Maybe.Nothing) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(state);
    };
    throw new Error("Failed pattern match at Page.Home (line 86, column 28 - line 90, column 24): " + [ state.companyId.constructor.name ]);
};
var load = function (redirect) {
    return function (kyc) {
        return function (state) {
            var tracePage = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Segment.paging(Segment.BatchListPage.value));
            var readBody = function (body) {
                return Simple_JSON.readJSON(Simple_JSON.readArray(Helper_Attempt.readEither(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "authoriserDetails";
                }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "authorisedDate";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "email";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "authoriserRequired";
                }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "bankStatement";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "error";
                }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "code";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "message";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "source";
                }))(Simple_JSON.readMaybe(Model_PaymentError.readForeignPaymentErrorSource))(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "estimatedRewardsPoints";
                }))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "fees";
                }))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "journalMemo";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "lodgementReference";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "paymentDetails";
                }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "bankAccount";
                }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "accountName";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "accountNumber";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "bsbNumber";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "card";
                }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "expiry";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "id";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "number";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "scheme";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "type";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(Simple_JSON.readFieldsNil)()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "paymentId";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "processDate";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "remitterName";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "requestedBy";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "status";
                }))(Model_Status.readForeignBatchStatus)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "submittedDate";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "totalAmount";
                }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()()))))(body);
            };
            var sendRequest = function (c) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Fetch.get()("/company/" + (c.companyId + "/batch"))({}))(function (v) {
                    if (v.status === 200) {
                        var v1 = readBody(v.body);
                        if (v1 instanceof Data_Either.Left) {
                            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Effect_Console.errorShow(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v1.value0))({
                                isLoading: false,
                                batches: state.batches,
                                company: state.company,
                                companyId: state.companyId,
                                httpError: state.httpError,
                                redirectFromKYC: state.redirectFromKYC,
                                rewardsDetails: state.rewardsDetails
                            }));
                        };
                        if (v1 instanceof Data_Either.Right) {
                            var batch = Helper_Attempt.partition(v1.value0);
                            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Console.errorShow(Data_Show.showArray(Data_List_Types.showNonEmptyList(Foreign.showForeignError)))(batch.left)))(function () {
                                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                                    companyId: new Data_Maybe.Just(c.companyId),
                                    company: new Data_Maybe.Just(c),
                                    isLoading: false,
                                    batches: batch.right,
                                    redirectFromKYC: kyc,
                                    httpError: state.httpError,
                                    rewardsDetails: state.rewardsDetails
                                });
                            });
                        };
                        throw new Error("Failed pattern match at Page.Home (line 62, column 32 - line 73, column 14): " + [ v1.constructor.name ]);
                    };
                    if (v.status === 401) {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.login(Data_Maybe.Nothing.value))(state));
                    };
                    if (v.status === 404) {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(redirect("/dashboard"))(state));
                    };
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        isLoading: false,
                        httpError: new Data_Maybe.Just(v.status),
                        companyId: new Data_Maybe.Just(c.companyId),
                        company: new Data_Maybe.Just(c),
                        batches: state.batches,
                        redirectFromKYC: state.redirectFromKYC,
                        rewardsDetails: state.rewardsDetails
                    });
                }))(sendRewardsRequest);
            };
            var handleNoCompany = function (e) {
                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(redirect("/companies"))({
                    isLoading: false,
                    httpError: new Data_Maybe.Just(400),
                    batches: state.batches,
                    company: state.company,
                    companyId: state.companyId,
                    redirectFromKYC: state.redirectFromKYC,
                    rewardsDetails: state.rewardsDetails
                }));
            };
            var company = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompany);
            return Control_Apply.applyFirst(Effect_Aff.applyAff)(Control_Bind.bind(Effect_Aff.bindAff)(company)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(handleNoCompany)(sendRequest)))(tracePage);
        };
    };
};
var initState = {
    batches: [  ],
    isLoading: true,
    httpError: Data_Maybe.Nothing.value,
    companyId: Data_Maybe.Nothing.value,
    company: Data_Maybe.Nothing.value,
    redirectFromKYC: Data_Maybe.Nothing.value,
    rewardsDetails: Data_Maybe.Nothing.value
};
module.exports = {
    initState: initState,
    load: load,
    sendRewardsRequest: sendRewardsRequest,
    shouldRedirect: shouldRedirect
};
