import React from 'react'
import AddContactModalContent from '../components/AddContactModalContent'
import {
  onboardCompany,
  kycOwner,
  parseOwnerInfo,
  parseAdminInfo,
  parseCompanyEntityTypeInfo,
  waitForUBOReport,
  retrieveKYCInfo
} from 'purs/OnboardingKYCModalContent'
import { isJust, maybe } from 'purs/Data.Maybe'


const OnboardingKYCModalContent = () => {
  const companyEntityType = parseCompanyEntityTypeInfo(window.location.search)
  const maybeOwner = parseOwnerInfo(window.location.search)
  const isAdmin = parseAdminInfo(window.location.search)
  const entityCode = isJust(companyEntityType) ? companyEntityType.value0 : undefined

  const defaultWaitForData = {showError: false, waitForReport: undefined}
  const {showError, waitForReport} = isAdmin ? maybe(defaultWaitForData)(info => {
    const waitForReport = (callback) => {
      waitForUBOReport(info.verificationId)(callback)()
    }
    const isCreatedAfter60s = new Date().getTime() - info.created >= 60 * 1000 

    if(isCreatedAfter60s) {
      return {showError: true, waitForReport: undefined}
    } else {
      return {showError: false, waitForReport}
    }
  })(retrieveKYCInfo()) : defaultWaitForData

  const register = (verificationId, callback) => {
    return isAdmin
      ? onboardCompany(verificationId)(callback)()
      : kycOwner(verificationId)(callback)()
  }

  /* istanbul ignore next */
  return (
    <AddContactModalContent
      register={register}
      owner={isJust(maybeOwner) ? maybeOwner.value0 : undefined}
      isSelf={isAdmin}
      isUpdatingRole={false}
      companyEntityType={entityCode}
      showError={showError}
      waitForUBOReport={waitForReport}
    />
  )
}

export default OnboardingKYCModalContent
