// Generated by purs version 0.13.8
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Owner = (function () {
    function Owner() {

    };
    Owner.value = new Owner();
    return Owner;
})();
var Director = (function () {
    function Director() {

    };
    Director.value = new Director();
    return Director;
})();
var Employee = (function () {
    function Employee() {

    };
    Employee.value = new Employee();
    return Employee;
})();
var Bookkeeper = (function () {
    function Bookkeeper() {

    };
    Bookkeeper.value = new Bookkeeper();
    return Bookkeeper;
})();
var Accountant = (function () {
    function Accountant() {

    };
    Accountant.value = new Accountant();
    return Accountant;
})();
var Other = (function () {
    function Other() {

    };
    Other.value = new Other();
    return Other;
})();
var DirectorAndOwner = (function () {
    function DirectorAndOwner() {

    };
    DirectorAndOwner.value = new DirectorAndOwner();
    return DirectorAndOwner;
})();
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var AbnCancelled = (function () {
    function AbnCancelled() {

    };
    AbnCancelled.value = new AbnCancelled();
    return AbnCancelled;
})();
var Retryable = (function () {
    function Retryable() {

    };
    Retryable.value = new Retryable();
    return Retryable;
})();
var CreditScoreCheckFail = (function () {
    function CreditScoreCheckFail() {

    };
    CreditScoreCheckFail.value = new CreditScoreCheckFail();
    return CreditScoreCheckFail;
})();
var AbnValidated = (function () {
    function AbnValidated(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AbnValidated.create = function (value0) {
        return function (value1) {
            return new AbnValidated(value0, value1);
        };
    };
    return AbnValidated;
})();
var AbnUnsupported = (function () {
    function AbnUnsupported(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AbnUnsupported.create = function (value0) {
        return function (value1) {
            return new AbnUnsupported(value0, value1);
        };
    };
    return AbnUnsupported;
})();
var AbnError = (function () {
    function AbnError(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AbnError.create = function (value0) {
        return function (value1) {
            return new AbnError(value0, value1);
        };
    };
    return AbnError;
})();
var AbnInitial = (function () {
    function AbnInitial(value0) {
        this.value0 = value0;
    };
    AbnInitial.create = function (value0) {
        return new AbnInitial(value0);
    };
    return AbnInitial;
})();
var genericUserRole = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Owner) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Director) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Employee) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof Bookkeeper) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof Accountant) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    };
    if (x instanceof Other) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
    };
    if (x instanceof DirectorAndOwner) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
    };
    if (x instanceof None) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))));
    };
    throw new Error("Failed pattern match at Model.CompanyOnboardModal (line 53, column 1 - line 53, column 54): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Owner.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return Director.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return Employee.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return Bookkeeper.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return Accountant.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return Other.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return DirectorAndOwner.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))) {
        return None.value;
    };
    throw new Error("Failed pattern match at Model.CompanyOnboardModal (line 53, column 1 - line 53, column 54): " + [ x.constructor.name ]);
});
var userRoleReadForeign = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericUserRole)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Owner";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Director";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Employee";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Bookkeeper";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Accountant";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Other";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "DirectorAndOwner";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "None";
})))))))))));
var readUserRole = Simple_JSON.read(userRoleReadForeign);
var userRoleShow = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericUserRole)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Owner";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Director";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Employee";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Bookkeeper";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Accountant";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Other";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "DirectorAndOwner";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "None";
})))))))))));
var userRoleWriteForeign = new Simple_JSON.WriteForeign((function () {
    var $42 = Simple_JSON.write(Simple_JSON.writeForeignString);
    var $43 = Data_Show.show(userRoleShow);
    return function ($44) {
        return $42($43($44));
    };
})());
var eqUserRole = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Owner && y instanceof Owner) {
            return true;
        };
        if (x instanceof Director && y instanceof Director) {
            return true;
        };
        if (x instanceof Employee && y instanceof Employee) {
            return true;
        };
        if (x instanceof Bookkeeper && y instanceof Bookkeeper) {
            return true;
        };
        if (x instanceof Accountant && y instanceof Accountant) {
            return true;
        };
        if (x instanceof Other && y instanceof Other) {
            return true;
        };
        if (x instanceof DirectorAndOwner && y instanceof DirectorAndOwner) {
            return true;
        };
        if (x instanceof None && y instanceof None) {
            return true;
        };
        return false;
    };
});
module.exports = {
    Owner: Owner,
    Director: Director,
    Employee: Employee,
    Bookkeeper: Bookkeeper,
    Accountant: Accountant,
    Other: Other,
    DirectorAndOwner: DirectorAndOwner,
    None: None,
    AbnCancelled: AbnCancelled,
    Retryable: Retryable,
    CreditScoreCheckFail: CreditScoreCheckFail,
    AbnValidated: AbnValidated,
    AbnUnsupported: AbnUnsupported,
    AbnError: AbnError,
    AbnInitial: AbnInitial,
    readUserRole: readUserRole,
    genericUserRole: genericUserRole,
    eqUserRole: eqUserRole,
    userRoleShow: userRoleShow,
    userRoleReadForeign: userRoleReadForeign,
    userRoleWriteForeign: userRoleWriteForeign
};
