const productFamily = 'SME'
const model = require('purs/Model.CompanyStatus')
const s = require('purs/Data.Show')
const { match, compile } = require('path-to-regexp')

function track(company, userId, segmentData, nextStatusString) {
  const metaData = {
    title: 'MYOB Payments',
    domain: 'Payments',
    sourceSystem: 'Payables',
    productFamily: productFamily,
    productLine: 'AccountRight',
    product: 'Standard',
    category: productFamily,
    receivedAt: new Date().toISOString(),
    businessId: company.companyId,
    companyStatus: nextStatusString,
    userId: userId,
    label: segmentData.action,
    url: window.location.origin + '/' + segmentData.path,
    feature: 'Payables',
    companyStatusDate: new Date().toISOString()
  }
  const properties = Object.assign(metaData, segmentData)
  window.analytics.track(segmentData.event, properties)
}

function page(company, userId, pageData) {
  const metaData = {
    title: 'MYOB Payments',
    domain: 'Payments',
    sourceSystem: 'Payables',
    productFamily: productFamily,
    productLine: 'AccountRight',
    product: 'Standard',
    category: productFamily,
    businessId: company.companyId,
    userId: userId,
    companyStatus: s.show(model.showCompanyStatus)(company.status),
    feature: 'Payables'
  }
  const properties = Object.assign(metaData, pageData.properties)
  window.analytics.page(pageData.pageName, properties)
}

function identify(companyId, userId, companyStatus) {
  const metadata = {
    userId: userId,
    region: 'AU',
    registeredSourceSystem: 'Payables',
    businessId: companyId,
    accountStatus: s.show(model.showCompanyStatus)(companyStatus)
  }
  window.analytics.identify(userId, metadata)
}

function group(companyId, userId) {
  const metadata = {
    userId: userId,
    groupId: companyId
  }
  window.analytics.group(companyId, metadata)
}

function gaPathMidware(pathToTransform) {
  const transformPath = (path) => {
    const matchedPath = pathToTransform.find(p => match(p)(path))
    return matchedPath ? compile(matchedPath)({id: '{{ID}}'}) : path
  }
  return function({ payload, next }) {
    payload.obj.properties.path = payload.obj.properties.path && transformPath(payload.obj.properties.path)
    next(payload)
  }
}

function initGAPathOptMidware(pathToTransform) {
  window.analytics && window.analytics.addDestinationMiddleware && window.analytics.addDestinationMiddleware('Google Analytics', [gaPathMidware(pathToTransform)])
}

function dispatchRouteChangeEvent() {
  window.dataLayer && window.dataLayer.push({
    event: 'spRouteChangeSuccess'
  })
}

exports.track = track
exports.page = page
exports.identify = identify
exports.group = group
exports.initGAPathOptMidware = initGAPathOptMidware
exports.gaPathMidware = gaPathMidware
exports.dispatchRouteChangeEvent = dispatchRouteChangeEvent

