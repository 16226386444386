// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Lens_Prism = require("../Data.Lens.Prism/index.js");
var Data_Lens_Record = require("../Data.Lens.Record/index.js");
var Data_Lens_Setter = require("../Data.Lens.Setter/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_TransactionType = require("../Model.TransactionType/index.js");
var Page_BatchDetail = require("../Page.BatchDetail/index.js");
var isFutureBatchProcessDate = function (dateString) {
    return Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(Data_Ord.greaterThan(Data_JSDate.ordJSDate))(Data_JSDate.parse(dateString)))(Data_JSDate.now);
};
var checkAuthPayment = function (state) {
    var checkAuth = function (batch) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(isFutureBatchProcessDate(batch.batchDetail.processDate)))(function (isFutureDate) {
            return Page_BatchDetail.showConfirmAuthPaymentModal(isFutureDate)(state);
        });
    };
    if (state.batch instanceof Page_BatchDetail.ArlBatch) {
        return checkAuth(state.batch.value0);
    };
    if (state.batch instanceof Page_BatchDetail.Batch) {
        return checkAuth(state.batch.value0);
    };
    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Effect_Class_Console.log(Effect_Class.monadEffectEffect)("no batch to authorize"))(state));
};
var authorize = function (state) {
    var redirect = function (batch) {
        return "/batch/" + (batch.batchDetail.id + "/payment");
    };
    var handleError = function (code) {
        return Data_Functor.voidLeft(Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Class_Console.error(Effect_Class.monadEffectEffect)("ERROR Steping up:" + Data_Show.show(Data_Show.showInt)(code))))({
            batch: new Page_BatchDetail.HttpError(code),
            company: state.company,
            confirmAuthPaymentModal: state.confirmAuthPaymentModal,
            isLoading: state.isLoading,
            paymentResponse: state.paymentResponse,
            rewardsThanksModal: state.rewardsThanksModal,
            selfEnrollmentModal: state.selfEnrollmentModal
        });
    };
    if (state.batch instanceof Page_BatchDetail.ArlBatch) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Helper_AuthActions.stepUpFromArl()(redirect(state.batch.value0))(state.batch.value2))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(handleError)(Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(state))));
    };
    if (state.batch instanceof Page_BatchDetail.Batch) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Helper_AuthActions.stepUp()(redirect(state.batch.value0))({}))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(handleError)(Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(state))));
    };
    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Effect_Class_Console.log(Effect_Class.monadEffectEffect)("no batch to authorize"))(state));
};
var _selectSortField = function (v) {
    if (v === "transactionType") {
        return Data_Ord.comparing(Model_TransactionType.ordTransactionType)(function (v1) {
            return v1.transactionType;
        });
    };
    if (v === "amount") {
        return Data_Ord.comparing(Data_Ord.ordInt)(function (v1) {
            return v1.amount;
        });
    };
    return Data_Ord.comparing(Data_Ord.ordString)(function (v1) {
        return v1.payeeName;
    });
};
var sortBatchDetailsBy = function (key) {
    return function (descending) {
        var order = (function () {
            if (descending) {
                return Control_Category.identity(Control_Category.categoryFn);
            };
            return Data_Array.reverse;
        })();
        var linesField = function (dictStrong) {
            return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
                return "lines";
            }))()()(Data_Symbol.SProxy.value)(dictStrong);
        };
        var batchPrismField = function (dictChoice) {
            return Data_Lens_Prism["prism'"](Page_BatchDetail.Batch.create)(function (v) {
                if (v instanceof Page_BatchDetail.Batch) {
                    return new Data_Maybe.Just(v.value0);
                };
                if (v instanceof Page_BatchDetail.ArlBatch) {
                    return new Data_Maybe.Just(v.value0);
                };
                return Data_Maybe.Nothing.value;
            })(dictChoice);
        };
        var batchField = function (dictStrong) {
            return Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
                return "batch";
            }))()()(Data_Symbol.SProxy.value)(dictStrong);
        };
        var sort = Data_Lens_Setter.over((function () {
            var $26 = batchField(Data_Profunctor_Strong.strongFn);
            var $27 = batchPrismField(Data_Profunctor_Choice.choiceFn);
            var $28 = linesField(Data_Profunctor_Strong.strongFn);
            return function ($29) {
                return $26($27($28($29)));
            };
        })())((function () {
            var $30 = Data_Array.sortBy(_selectSortField(key));
            return function ($31) {
                return order($30($31));
            };
        })());
        var $32 = Control_Applicative.pure(Effect_Aff.applicativeAff);
        return function ($33) {
            return $32(sort($33));
        };
    };
};
module.exports = {
    sortBatchDetailsBy: sortBatchDetailsBy,
    authorize: authorize,
    checkAuthPayment: checkAuthPayment
};
