// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Model_CompanyAccount = require("../Model.CompanyAccount/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var sortField = function (v) {
    if (v === "account") {
        return Data_Ord.comparing(Model_CompanyAccount.ordCompanyStatus)(function (v1) {
            return v1.account;
        });
    };
    if (v === "status") {
        return Data_Ord.comparing(Model_CompanyStatus.ordCompanyStatus)(function (v1) {
            return v1.status;
        });
    };
    return Data_Ord.comparing(Data_Ord.ordString)(function (v1) {
        return v1.name;
    });
};
var sortDir = function (v) {
    if (v) {
        return Control_Category.identity(Control_Category.categoryFn);
    };
    if (!v) {
        return Data_Function.flip;
    };
    throw new Error("Failed pattern match at CompaniesPanel (line 16, column 1 - line 16, column 90): " + [ v.constructor.name ]);
};
var sortCompanyBy = function (key) {
    return function (desc) {
        return function (s) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                companyList: Data_Functor.map(Data_Either.functorEither)(Data_Array.sortBy(sortDir(desc)(sortField(key))))(s.companyList),
                isLoading: s.isLoading,
                onboardModal: s.onboardModal,
                selectedCompany: s.selectedCompany,
                stepOnboardModal: s.stepOnboardModal
            });
        };
    };
};
module.exports = {
    sortCompanyBy: sortCompanyBy
};
