import React, { useState } from 'react'
import { Separator, Card, Checkbox } from '@myob/myob-widgets'
import Visa from '../img/visa.svg'
import Master from '../img/master.svg'
import FeesInfo from './FeesInfo'
import config from '../config'

const CardFormHeader = () => {
  return (
    <div>
      <div>
        <h3>Adding a Card</h3>
        <div className="terms__description">
          In order to verify your card, we’ll need to add a temporary charge of
          $0.01. We do this so we can verify that your card is active.
          <br />
          <br />
          Once your card is verified, the amount will be automatically refunded.
        </div>
      </div>
      <br />
      <div>
        <div className="transaction__description">
          <span>Fees will be incurred per payment and billed alongside your MYOB subscription.&nbsp;</span>
          <FeesInfo feesInfoType={FeesInfo.CardType} />
        </div>
        <br />
        <br />
        <div>We do not currently support Visa debit cards.</div>
      </div>
      <br />
    </div>
  )
}

const CardIFrame = ({ iframeLink }) => {
  return (
    (
      <div>
        <Separator />
        <Card classes={['addcard__content']}>
          <div className="content-header">
            We accept
            <img src={Master} alt="Master Icon" />
            <img src={Visa} alt="Visa Icon" />
          </div>
          <iframe
            title="MYOB Payments"
            src={iframeLink}
            width="450"
            height="310"
          >
            Sorry your browser does not support inline frames.
          </iframe>
        </Card>
      </div>
    )
  )
}
const CardFormPanel = ({ iframeLink, enableTermsAndConditionCheck = true }) => {
  const [hasAcknowledgeTerms, setHasAcknowledgeTerms] = useState(false)
  return (
    <div className="addcardmodal__boday">
      <CardFormHeader/>
      {enableTermsAndConditionCheck ? (
        <Checkbox
          checked={hasAcknowledgeTerms}
          label={'I have read and agree to the '}
          onChange={() => { setHasAcknowledgeTerms(!hasAcknowledgeTerms) }}
          labelAccessory={
            <div className="terms__description terms__link">
              <a
                href={config.productDisclosureStatementUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Product Disclosure Statement.
              </a>
            </div>
          }
        />
      ) : <></>}
      {((enableTermsAndConditionCheck && hasAcknowledgeTerms) || (!enableTermsAndConditionCheck)) ? (<CardIFrame iframeLink={iframeLink}/>) : (
        <></>
      )}
    </div>
  )
}

export default CardFormPanel
