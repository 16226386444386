import { get } from 'lodash';
import React, { useState, useEffect } from 'react'
import {Input, Modal, FieldGroup, Box, Checkbox} from '@myob/myob-widgets'
import { useForm } from 'react-hook-form'
import {useMutation} from 'react-query'
import axios from 'axios'
import AlertBanner from './AlertBanner'
import { Danger } from 'purs/AlertBanner';
import config from "../config";

const DirectDebitAgreementModal = ({companyId, onClose}) => {
  const [hasAcknowledgeTerms, setHasAcknowledgeTerms] = useState(false)
  const [inputs, setInputs] = useState({});
  const { register, handleSubmit, setValue, formState: { errors, isValid } } = useForm();
  const  createDirectDebitAgreement =
    useMutation((directDebitAgreement) => axios.post(`/api/company/${companyId}/bank-account`, directDebitAgreement))
  const onSubmit = (values) => {}

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
    handleFieldValueChange(name, value.trim());
  }

  useEffect(() => {
    register('accountName', {
      required: {
        value: true,
        message: `Enter an account name`,
      },
      pattern: {
        value: /^([a-zA-Z0-9-,*./\s]{1,18})$/,
        message: `Account name contains invalid character`,
      },
      maxLength: {
        value: 18,
        message: `Account name can be maximum 18 characters`,
      }
    });

    register('bsbNumber', {
      required: {
        value: true,
        message: `Enter a BSB`,
      },
      pattern:{
        value: /^\s*\d{3}-?\d{3}\s*$/,
        message: `BSB is invalid`,
      }
    });

    register('accountNumber', {
      required: {
        value: true,
        message: `Enter an account number`,
      },
      pattern:{
        value: /^\s*\d{6,9}\s*$/,
        message: `Account number should be between 6 to 9 digits`,
      }
    });

    register('firstName', {
      required: {
        value: true,
        message: `Enter a first name`,
      },
      maxLength: {
        value: 255,
        message: `First name can be maximum 255 characters`,
      }
    });

    register('lastName', {
      required: {
        value: true,
        message: `Enter a last name`,
      },
      maxLength: {
        value: 255,
        message: `Last name can be maximum 255 characters`,
      }
    });

    register('emailAddress', {
      required: {
        value: true,
        message: `Enter an email address`,
      },
      pattern:{
        value: /^\s*[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+\s*$/,
        message: `Email is invalid`,
      }
    });
  })

  const getErrorMessage = (
    filedName,
    errors
) => {
     const fieldErrorMessage = get(errors, `${filedName}.message`);
    if (fieldErrorMessage) {
      return fieldErrorMessage;
    }
    return '';
  }

  const handleFieldValueChange = (fieldName, value) => {
    setValue(fieldName, value, { shouldValidate: true })
  };

  return (
    <Modal
      modalId="direct-debit-agreement"
      title="Add banking details and provide authority"
      onCancel={() => onClose()}
    >
      <form onSubmit={handleSubmit(onSubmit)} >
      <Modal.Body>
        {
          createDirectDebitAgreement.isError ? (
              <AlertBanner type={Danger.value}>
            <span>
              Oops, we couldn&#39;t confirm your permission. For access to
              payment methods, chat to your administrator or contact support on{' '}
              <a href="tel:1300783674">1300 783 674</a> for assistance.
            </span>
              </AlertBanner>
            ):
            ( <>
            <style>
              {`
          .direct_debit_outside_style {
            list-style-position: outside;
            margin-left: 3rem;
            margin-right: 3rem;
            margin-top: 2rem;
          }
        `}
            </style>
            <div className="direct_debit_outside_style" id="testAll">
              <FieldGroup label="Your banking details">
                <Input
                  name="accountName"
                  label="Account name"
                  requiredLabel="Account name is required"
                  onChange={handleChange}
                  errorMessage={getErrorMessage('accountName', errors)}
                />
                <Box display="flex" justifyContent="BSB*">
                  <Box
                    width="33%"
                    paddingLeft="10"
                    paddingRight="sm"
                    margin-top="0"
                  >
                    <Input
                      type="text"
                      name="bsbNumber"
                      label="BSB"
                      requiredLabel="BSB is required"
                      onChange={handleChange}
                      errorMessage={getErrorMessage('bsbNumber', errors)}
                    />
                  </Box>
                  <Box
                    width="67%"
                    paddingLeft="sm"
                    paddingRight="0"
                  >
                    <Input
                      type="text"
                      name="accountNumber"
                      label="Account number"
                      requiredLabel="Account number is required"
                      onChange={handleChange}
                      errorMessage={getErrorMessage('accountNumber', errors)}
                    />
                  </Box>
                </Box>
              </FieldGroup>
              <FieldGroup label="Bank account authoriser">
                <Box display="flex" height="7.4rem">
                  <Box
                    width="50%"
                    paddingLeft="0"
                    paddingRight="sm"
                  >
                    <Input
                      type="text"
                      name="firstName"
                      label="First name"
                      requiredLabel="First name is required"
                      onChange={handleChange}
                      errorMessage={getErrorMessage('firstName', errors)}
                    />
                  </Box>
                  <Box
                    width="50%"
                    paddingLeft="sm"
                    paddingRight="0"
                  >
                    <Input
                      type="text"
                      name="lastName"
                      label="Last name"
                      requiredLabel="Last name is required"
                      onChange={handleChange}
                      errorMessage={getErrorMessage('lastName', errors)}
                    />
                  </Box>
                </Box>
                <Input
                  type="text"
                  name="emailAddress"
                  label="Email address"
                  requiredLabel="Email address is required"
                  onChange={handleChange}
                  errorMessage={getErrorMessage('emailAddress', errors)}
                />
              </FieldGroup>
              <FieldGroup label="Confirmation of Direct Debit Authority">
                <style>
                  {`
          .acknowledge-list {
            max-width: 54ch;
            list-style-position: outside;
            margin-left: 5rem;
            margin-top: 1.2rem;
          }
          `}
                </style>
                <Checkbox
                  checked={hasAcknowledgeTerms}
                  name="acknowledge"
                  label="By signing and/or providing us with a valid instruction in respect to your Direct Debit Requests, you have confirmed that:"
                  onChange={() => {
                    setHasAcknowledgeTerms(!hasAcknowledgeTerms) }}
                />
                <ul className="acknowledge-list">
                  <li>You are authorised to operate on the nominated account</li>
                  <li>You have understood and agreed to the terms and conditions set out in this Request, the&nbsp;
                    <a href={config.financialServicesGuideUrl}
                       target="_blank"
                       rel="noopener noreferrer"
                    >Financial Services Guide</a>&nbsp;and the&nbsp;
                    <a href={config.productDisclosureStatementUrl}
                       target="_blank"
                       rel="noopener noreferrer"
                    >Product Disclosure Statement</a>&nbsp;(which includes your Direct Debit Request Service Agreement).
                  </li>
                  <li>You authorise and request MYOB Australia Pty Ltd (DE ID 622400) to debit your nominated account
                    for the
                    purpose set out in the terms.
                  </li>
                </ul>
              </FieldGroup>
            </div>
          </>)
        }


      </Modal.Body>
      <Modal.Footer>
         <button id="btn-newcard-modal-close" type="button" className="btn btn-default" onClick={() => onClose()}
                >          Cancel
        </button>
        <button
          id="btn-newcard-modal-send"
          type="submit"
          className="btn btn-primary"
          disabled={(!hasAcknowledgeTerms)||(createDirectDebitAgreement.isLoading)||(createDirectDebitAgreement.isError)}
          onClick={async () => {
            if(isValid){
              createDirectDebitAgreement
                .mutateAsync(
                { accountName: inputs.accountName,
                  accountNumber:inputs.accountNumber,
                  BSB:inputs.bsbNumber,
                  firstName:inputs.firstName,
                  lastName:inputs.lastName,
                  emailAddress: inputs.emailAddress})
                .then(onClose, () => {})
            }
          }}
        >
          Save
        </button>
      </Modal.Footer>
      </form>
    </Modal>
  )
}

export default DirectDebitAgreementModal
