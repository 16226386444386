import { Button, Icons, Popover } from '@myob/myob-widgets'
import './AuthorisersList.sass'
import React from 'react'

export function AuthorisersList(authorisers) {
  const emails = authorisers.map(e => <li key={e}>{e}</li>)
  return authorisers.length < 1 ? (
    <span />
  ) : (
    <Popover
      body={
        <ul className="authorised-by-list">
          <li key="AuthorisedBy">
            <b>Authorised by:</b>
          </li>
          {emails}
        </ul>
      }
      preferPlace="right"
      closeOnOuterAction={true}
    >
      <Button id="btn-authorisers-size" type="link" icon={<Icons.SharedWith />}>
        {authorisers.length === 1 ? '' : '+' + (authorisers.length - 1)}
      </Button>
    </Popover>
  )
}
