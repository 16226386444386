// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var validateInput = function (predicate) {
    return function (v) {
        return {
            value: v.value,
            isValid: Effect_Unsafe.unsafePerformEffect(predicate(v.value))
        };
    };
};
var isValidBirthDate = function (dateString) {
    return Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(Data_Ord.lessThanOrEq(Data_JSDate.ordJSDate))(Data_JSDate.parse(dateString)))(Data_JSDate.now);
};
var checkEmptyInput = validateInput((function () {
    var $5 = Control_Applicative.pure(Effect.applicativeEffect);
    var $6 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
    return function ($7) {
        return $5($6(Data_String_Common["null"]($7)));
    };
})());
var checkDateInput = validateInput(isValidBirthDate);
module.exports = {
    isValidBirthDate: isValidBirthDate,
    validateInput: validateInput,
    checkEmptyInput: checkEmptyInput,
    checkDateInput: checkDateInput
};
