import React, {useEffect} from 'react'
import * as r from 'ramda'
import {
  Columns,
  Navigation,
  PageHead,
  StandardTemplate,
  Separator,
  Alert
} from '@myob/myob-widgets'
import {Brand} from '../components/NavBar'
import {minimumSecondary} from '../components/NavBarForBusiness'
import {readCompany} from 'purs/Model.Company'
import './GetStarted.sass'
import MasterCardIcon from '../img/mastercard-logo.svg'
import VisaIcon from '../img/visa.svg'
import {GetStartedPage, unsafePaging, unsafeTrackingCompany, ViewIntro} from 'purs/Segment'
import { Just, Nothing } from 'purs/Data.Maybe'
import config from '../config'
import { isNewEssentialsBusiness } from 'purs/Model.CompanySubscription'

const arlHelpLink = 'https://help.myob.com/wiki/x/KoGFAg#expand-Whatarethefeesforusingdirectpayments'
const newEssentialsHelpLink = 'https://help.myob.com/wiki/x/4wG0Aw#expand-Whatarethefeesforusingdirectpayments'

const CardBodyOnBehalf = ({ helpLink }) => (
  <div className="to-start-list-onbehalf">
    <Columns type="three">
      <div className="col-1-3">
        <p>Sole Traders and Australian Private Companies using MYOB Accounting software
          can pay bills and payroll by debit card or credit card. <a href={`${helpLink}`} target='_blank' rel='noreferrer'>Fees apply</a></p>
        <ul>
          <li>Earn MYOB Rewards points for credit card payments</li>
          <li>Pay suppliers by card, even if they don't accept card</li>
          <li>Set up authorisers to approve payments</li>
        </ul>
      </div>
      <Columns className="col-3-3">
        <div>
          <img
            className="mastercard-img center"
            src={MasterCardIcon}
            alt="mastercard"
          />
          <div className="card-text">
            <span>CREDIT CARD</span>
          </div>
          <div className="card-text">
            <span>DEBIT CARD</span>
          </div>
        </div>
        <div>
          <img
            className="visa-img center"
            src={VisaIcon}
            alt="visa"
          />
          <div className="card-text">
            <span>CREDIT CARD</span>
          </div>
        </div>
      </Columns>
    </Columns>
  </div>
);

export const GetStarted = () => {
  const company = r.path(['value0'], readCompany())
  const name = r.propOr('Unknown business', 'name', company)
  const productType = r.propOr(Nothing.value, 'product', company)
  const helpLink = isNewEssentialsBusiness(productType) ? newEssentialsHelpLink : arlHelpLink
  const pageTitle = "Batch pay bills and payroll with card"

  useEffect(() => {
    unsafeTrackingCompany(Just.create(company))(ViewIntro.value)
    unsafePaging(GetStartedPage.value)
  }, [])

  const defClassName = "get-started-on-behalf"

  return (
  <div className={defClassName}>
    <Navigation
      brand={Brand}
      primary={[]}
      secondary={minimumSecondary(name, productType)}
    />
    <StandardTemplate
      sticky="none"
      pageHead={<PageHead title={pageTitle}/>}
    >
      <Alert type="danger">
        We are no longer taking new applications for this service.
      </Alert>
      <CardBodyOnBehalf helpLink={helpLink}/>
    </StandardTemplate>
  </div>
  )
}

export default GetStarted
