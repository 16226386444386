// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Model_Rewards = require("../Model.Rewards/index.js");
var Loading = (function () {
    function Loading() {

    };
    Loading.value = new Loading();
    return Loading;
})();
var $$Error = (function () {
    function $$Error() {

    };
    $$Error.value = new $$Error();
    return $$Error;
})();
var Ready = (function () {
    function Ready() {

    };
    Ready.value = new Ready();
    return Ready;
})();
var SelfEnrollmentPopup = (function () {
    function SelfEnrollmentPopup(value0) {
        this.value0 = value0;
    };
    SelfEnrollmentPopup.create = function (value0) {
        return new SelfEnrollmentPopup(value0);
    };
    return SelfEnrollmentPopup;
})();
var RewardsThanksPopup = (function () {
    function RewardsThanksPopup() {

    };
    RewardsThanksPopup.value = new RewardsThanksPopup();
    return RewardsThanksPopup;
})();
var showSelfEnrollmentModal = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        modal: Data_Maybe.Just.create(new SelfEnrollmentPopup({
            status: Ready.value
        }))
    });
};
var setSelfEnrollModalLoading = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        modal: Data_Maybe.Just.create(new SelfEnrollmentPopup({
            status: Loading.value
        }))
    });
};
var selfEnrollment = function (companyId) {
    return function (state) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Model_Rewards.saveJoinRewards(companyId)(true))(function (resp) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Model_Rewards.fetchAndSaveRewardsDetail(companyId))(function () {
                if (resp instanceof Data_Either.Right && resp.value0.status === 200) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        modal: new Data_Maybe.Just(RewardsThanksPopup.value)
                    });
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    modal: Data_Maybe.Just.create(new SelfEnrollmentPopup({
                        status: $$Error.value
                    }))
                });
            });
        });
    };
};
var initState = {
    modal: Data_Maybe.Nothing.value
};
var closeModal = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        modal: Data_Maybe.Nothing.value
    });
};
module.exports = {
    Loading: Loading,
    "Error": $$Error,
    Ready: Ready,
    SelfEnrollmentPopup: SelfEnrollmentPopup,
    RewardsThanksPopup: RewardsThanksPopup,
    initState: initState,
    showSelfEnrollmentModal: showSelfEnrollmentModal,
    setSelfEnrollModalLoading: setSelfEnrollModalLoading,
    selfEnrollment: selfEnrollment,
    closeModal: closeModal
};
