import React from 'react'
import { Modal, Stepper } from '@myob/myob-widgets'
import './StartOnboarding.sass'

const StartOnboardingModal = ({dispatch, closeModal, stepUpDashboard}) => {
  const close = () => dispatch(closeModal)

  return (
    <Modal
      modalId="start-onboarding"
      title="Start onboarding your business"
      show={true}
      onCancel={close}
    >
      <Modal.Body>
        <div className="startonboarding_body">
          <div>
            <h3 className="welcome-padding">Welcome!</h3>
            <p className="terms__description">
              Before we get started, let’s verify we have the right information
              about you and the business on file.
              <br />
              <br />
              If you haven’t already, we’ll walk you through how to set up{' '}
              <span className="two-factor_font">
                two factor authentication
              </span>{' '}
              on your device for added security.
            </p>
          </div>
          <div>
            <div className="stepper-info">
              <Stepper
                activeStepNumber={'2'}
                steps={[
                  {
                    number: '1',
                    title: 'Confirm eligibility',
                    type: 'complete'
                  },
                  {
                    number: '2',
                    title: 'Complete identity check'
                  },
                  {
                    number: '3',
                    title: 'Finalise business details',
                    type: 'incomplete'
                  }
                ]}
              />
            </div>
          </div>
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button id="btn-start-onboarding-modal-cancel" type="button" className="btn btn-default" onClick={close}>
          Cancel
        </button>
        <button
          id="btn-start-onboarding-modal-continue"
          type="button"
          className="btn btn-primary"
          onClick={() => dispatch(stepUpDashboard)}
        >
          Continue
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default StartOnboardingModal
