import * as React from 'react'
import { getKeyValueFromQuery } from 'purs/Helper.RouteQuery'
import { removeCookie } from 'purs/CookieJar'
import { isRight } from 'purs/Data.Either'
import { shouldRedirect } from 'purs/Page.Home'

export default function safeRedirect(WrappedComponent) {
  return props => {
    const redirectState = getKeyValueFromQuery(props.location.hash)('state')
    if (isRight(redirectState)) {
      removeCookie('state')()
    }
    return <WrappedComponent {...props} __redirectState={redirectState} />
  }
}

export function homepageRedirect(WrappedComponent) {
  return props => {
    shouldRedirect(props.location.hash)()
    return <WrappedComponent {...props} />
  }
}
