import { Modal } from '@myob/myob-widgets'
import { showDeleteCardModal, deleteCard } from 'purs/CardManage'
import React, { useContext } from 'react'
import { context } from '../pages/BusinessManage'

const DeleteCardModal = () => {
  const { state, dispatch } = useContext(context)
  return (
    <Modal
      title="Delete card"
      show={state.deleteCardModal.show}
      onCancel={() => dispatch(showDeleteCardModal(false))}
    >
      <Modal.Body>
        <p>
          Are you sure you want to delete this card? Once deleted the card will
          no longer be linked to payments.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          id="btn-delete-card-modal-cancel"
          onClick={() => dispatch(showDeleteCardModal(false))}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-default"
          id="btn-delete-card-modal-delete"
          onClick={() => {
            dispatch(deleteCard)
          }}
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteCardModal
