import { Modal } from '@myob/myob-widgets'
import React, {useContext, useState} from 'react'
import {useMutation} from "react-query";
import axios from "axios";
import {Danger} from "../purs/AlertBanner";
import AlertBanner from "./AlertBanner";

const DeleteBankAccountModal = ({companyId, bankAccountId,onClose}) => {
  const deleteBankAccount  =
    useMutation(
      () => axios.delete(`/api/company/${companyId}/bank-account/${bankAccountId}`)
    )
  return (
    <Modal
      modalId="delete-bank-account"
      title="Delete bank account"
      onCancel={() => onClose()}
    >
      <Modal.Body>
        {deleteBankAccount.isError &&
          (
            <AlertBanner type={new Danger()}>
            <span>
            Oops, looks like something went wrong! Please try again later.
            </span>
            </AlertBanner>
          )}
          <p>
          Are you sure you want to delete this bank account? Once deleted the bank account will
          no longer be linked to payments.
          </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          id="btn-delete-bank-account-modal-cancel"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-default"
          id="btn-delete-card-modal-delete"
          disabled={deleteBankAccount.isLoading}
          onClick={ () => deleteBankAccount.mutateAsync().then( onClose,() => {})
          }
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteBankAccountModal
