import React, { useContext } from 'react'
import { Card, Tooltip, Badge, ReadOnly, PageHead, Label, Button } from '@myob/myob-widgets'
import './BatchDetailMetadata.sass'
import * as r from 'ramda'
import {Awaiting, Error, genColor, genStatusDisplay, Processed, Processing} from 'purs/Model.Status'
import { context } from '../pages/BatchDetail'
import SecondaryTabs, { NavTabs } from './SecondaryTab'
import FormattedAmount from './Batch.Amount'
import {
  dateFormatter,
  timeFormatter,
  cardFormatter,
  accountNumberFormatter
} from '../helper/formatter'
import FeesInfo from './FeesInfo'
import { AuthorisersList } from './AuthorisersList'
import { renderAuthoriserNum, showSelfEnrollmentModal} from 'purs/Page.BatchDetail'
import { maybe, Nothing } from 'purs/Data.Maybe'
import { hush } from 'purs/Data.Either'
import { deconstructRewardsDetails, readCompany } from 'purs/Model.Company'
import { Administrator } from 'purs/Model.User'

const BatchNameAndStatus = () => {
  const { state } = useContext(context)
  const { batch } = state
  const status = r.pathOr('-', ['value0', 'batchDetail', 'status'])(batch)
  return (
    <div className="batch-detail__header">
      <PageHead
        title={r.pathOr('', ['value0', 'batchDetail', 'journalMemo'])(batch)}
      />
      <Label color={genColor(status)} type="boxed">
        {genStatusDisplay(status)}
      </Label>
    </div>
  )
}

const ResultAndAmountDue = () => {
  const { state } = useContext(context)
  const { batch } = state
  const results = r.pathOr([], ['value0', 'lines'])(batch).length
  const amountDue = r.pathOr(0, ['value0', 'batchDetail', 'totalAmount'])(batch)
  const fees = r.pathOr(Nothing.value, ['value0', 'batchDetail', 'fees'])(batch)
  const resultDescription = `result${results <= 1 ? '' : 's'}`
  const FeesAmount = () => maybe(<>Fees </>)(
      f => (<>Fees: $<FormattedAmount amount={f} /> </>)
  )(fees)

  const status = r.pathOr('-', ['value0', 'batchDetail', 'status'])(batch)
  const paymentDetails = r.path([
    'value0',
    'batchDetail',
    'paymentDetails',
    'value0'
  ])(batch)
  const cardNo = r.pathOr('', ['card', 'value0', 'number'])(paymentDetails)

  const calFeesInfoType = (status, cardNo) => {
    if (status instanceof Awaiting || status instanceof Error) return FeesInfo.All
    else if ((status instanceof Processing || status instanceof Processed) && cardNo === "") return FeesInfo.BankAccountType
    else return FeesInfo.CardType
  }

  return (
    <div className="results">
      <span className="result-number">
        {results} {resultDescription}
      </span>
      <div>
        <FeesAmount />
        <FeesInfo description="Fees will be incurred per payment and billed alongside your MYOB subscription."
                  feesInfoType={calFeesInfoType(status, cardNo)} />
        <span className="results__fees">
          Amount due: $<FormattedAmount amount={amountDue} />
        </span>
      </div>
    </div>
  )
}

const RewardsPointsTooltip = () => (
  <Tooltip placement="top">
    Earn points on payments
    made by credit card
    <br/>
    <br/>
    MasterCard $1 = 4 points
    Visa $1 = 3 points
    <br/>
    <br/>
    Estimated calculation of
    points is shown
  </Tooltip>
)

const RewardsPointsInfo = ({ batch }) => {
  const { dispatch } = useContext(context)
  const maybeRewardsPoints = r.pathOr(Nothing.value, ['value0', 'batchDetail', 'estimatedRewardsPoints'])(batch)
  const company = hush(readCompany())
  const userRole = r.pathOr('', ['value0', 'userCurrentRole'])(company)
  const status = r.pathOr('-', ['value0', 'batchDetail', 'status'])(batch)

  const showRewardsPoints = rewardsPoints =>
    <>
      {rewardsPoints === 0 ? '' : 'Est.'} <Badge color="light-grey" className="rewards-badge">{rewardsPoints}</Badge>
    </>
  const showRewardsEnrollmentLink = <Button id="meta-rewards-enrollment-link" className="rewards-enrollment-link" type="link" onClick={() => {dispatch(showSelfEnrollmentModal)}}>Enrol to earn</Button>
  const showNothing = <span>-</span>

  const isJoinedRewards = maybe(false)(detail => detail.isAccepted)(deconstructRewardsDetails())
  const isShowRewardsLink = status instanceof Awaiting && userRole instanceof Administrator && !isJoinedRewards
  const content = maybe(isShowRewardsLink ? showRewardsEnrollmentLink : showNothing)(showRewardsPoints)(maybeRewardsPoints)

  return <ReadOnly name="Rewards points" id="meta-rewards-points" label="Rewards points" labelAccessory={<RewardsPointsTooltip/>}>
    {content}
  </ReadOnly>
}

const PaymentMethodsInfo = ({batch}) =>{
  const status = r.pathOr('-', ['value0', 'batchDetail', 'status'])(batch)
  const paymentDetails = r.path([
    'value0',
    'batchDetail',
    'paymentDetails',
    'value0'
  ])(batch)
  const cardNo = r.pathOr('', ['card', 'value0', 'number'])(paymentDetails)
  const bankAccountNo = r.pathOr('', ['bankAccount', 'value0', 'accountNumber'])(paymentDetails)
  const shouldShowBankAccount = (status instanceof Processing || status instanceof Processed) && cardNo === ""
  return shouldShowBankAccount ?
    <>
      <ReadOnly name="Bank Account" id="meta-bank-account" label="Bank Account">
        <span>
          {accountNumberFormatter(bankAccountNo)}
        </span>
      </ReadOnly>
    </> :
    <>
      <ReadOnly name="Card" id="meta-card" label="Card">
        <span>
          {cardFormatter(cardNo)}
        </span>
      </ReadOnly>
    </>
}

export const AuthoriserNum = batch => {
  const status = r.pathOr(Error.value, ['value0', 'batchDetail', 'status'])(
    batch
  )
  const authoriserRequired = r.pathOr(0, [
    'value0',
    'batchDetail',
    'authoriserRequired'
  ])(batch)
  const alreadyApproved = r.pathOr(
    [],
    ['value0', 'batchDetail', 'authoriserDetails'],
    batch
  ).length
  return renderAuthoriserNum(status)(authoriserRequired)(alreadyApproved)
}

const BatchDetailMetadata = () => {
  const { state } = useContext(context)
  const { batch } = state

  const authoriserDetails = r.pathOr(
    [],
    ['value0', 'batchDetail', 'authoriserDetails']
  )(batch)

  return (
    <div className="batch-detail">
      <SecondaryTabs tab={NavTabs.batchPayment} />
      <Card
        header={<Card.Header child={<BatchNameAndStatus />} />}
        footer={<Card.Footer child={<ResultAndAmountDue />} />}
      >
        <ReadOnly
          name="Payment number"
          id="meta-payment-number"
          label="Payment number"
        >
          {r.pathOr('-', ['value0', 'batchDetail', 'paymentId'])(batch)}
        </ReadOnly>
        <ReadOnly
          name="Date submitted"
          id="meta-payment-submitted-date"
          label="Date submitted"
        >
          <span>
            {dateFormatter(
              r.pathOr('', [
                'value0',
                'batchDetail',
                'submittedDate',
                'value0'
              ])(batch)
            )}
          </span>
        </ReadOnly>
        <ReadOnly name="Time" id="meta-payment-time" label="Time">
          <span>
            {timeFormatter(
              r.pathOr('', [
                'value0',
                'batchDetail',
                'submittedDate',
                'value0'
              ])(batch)
            )}
          </span>
        </ReadOnly>
        <ReadOnly
          name="Authorisers required"
          id="meta-authorisers-req"
          label="Authorisers required"
        >
          <span>{AuthoriserNum(batch)}</span>
        </ReadOnly>
        <ReadOnly
          name="Authorised by"
          id="meta-authorised-by"
          label="Authorised by"
        >
          {AuthorisersList(authoriserDetails.map(e => e.email))}
        </ReadOnly>
        <ReadOnly
          name="Date authorised"
          id="meta-payment-authorised-date"
          label="Date authorised"
        >
          <span>
            {dateFormatter(
              r.pathOr('', ['authorisedDate'])(authoriserDetails[0])
            )}
          </span>
        </ReadOnly>
        <PaymentMethodsInfo batch={batch}/>
        <RewardsPointsInfo batch={batch}/>
      </Card>
    </div>
  )
}

export default BatchDetailMetadata
