import React, { useContext } from 'react'
import { Spinner } from '@myob/myob-widgets'
import withPsState from '../withPsState'
import * as r from 'ramda'
import { initState, sendAuthPayment } from 'purs/PaymentRedirect'
import * as maybe from 'purs/Data.Maybe'

const PaymentRedirect = ({ location, history }) => {
  const { state } = useContext(context)
  if (maybe.isJust(state.stepUpError)) {
    return (
      <div className="error">
        <span title={maybe.fromJust()(state.stepUpError)}>
          Error verifying your credentials
        </span>
      </div>
    )
  } else if (maybe.isJust(state.redirectUrl)) {
    history.replace(state.redirectUrl.value0)
    return <></>
  } else {
    return <Spinner />
  }
}

const initAction = props => {
  const batchId = r.pathOr('', ['match', 'params', 'batchId'])(props)
  return sendAuthPayment(batchId)
}

export const { context, Component } = withPsState(
  PaymentRedirect,
  initState,
  initAction
)

export default Component
