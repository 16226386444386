import * as React from 'react'
import * as r from 'ramda'
import { getKeyValueFromQuery } from 'purs/Helper.RouteQuery'
import { cookie, removeCookie } from 'purs/CookieJar'
import ErrorPage from '../pages/ErrorPage'
import { isRight, Right } from 'purs/Data.Either'

export default function unsafeRedirect(WrappedComponent) {
  return props => {
    const hashUrl = r.pathOr('', ['location', 'hash'])(props)
    const redirectState = getKeyValueFromQuery(hashUrl)('state')
    const cookieState = cookie('state')()

    removeCookie('state')()

    const urlState = isRight(redirectState) ? redirectState.value0 : ''
    if (r.equals(Right.create(urlState), cookieState)) {
      return <WrappedComponent {...props} __redirectState={redirectState} />
    } else return <ErrorPage errorCode={404} />
  }
}
