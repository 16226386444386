// Generated by purs version 0.13.8
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var KYCPassed = (function () {
    function KYCPassed() {

    };
    KYCPassed.value = new KYCPassed();
    return KYCPassed;
})();
var KYCFailed = (function () {
    function KYCFailed() {

    };
    KYCFailed.value = new KYCFailed();
    return KYCFailed;
})();
var KYCPassedForOwner = (function () {
    function KYCPassedForOwner() {

    };
    KYCPassedForOwner.value = new KYCPassedForOwner();
    return KYCPassedForOwner;
})();
var KYCFailedForOwner = (function () {
    function KYCFailedForOwner() {

    };
    KYCFailedForOwner.value = new KYCFailedForOwner();
    return KYCFailedForOwner;
})();
var UBO = (function () {
    function UBO() {

    };
    UBO.value = new UBO();
    return UBO;
})();
var Director = (function () {
    function Director() {

    };
    Director.value = new Director();
    return Director;
})();
var showOwnerName = function (owner) {
    var middle = Data_Maybe.fromMaybe(" ")(Data_Functor.map(Data_Maybe.functorMaybe)(function (x) {
        return " " + (x + " ");
    })(owner.middleName));
    return owner.firstName + (middle + owner.lastName);
};
var noneKycStarted = function (owners) {
    return Data_Foldable.all(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Maybe.isNothing)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.kycResult;
    })(owners));
};
var isSubmitted = function (ownerStatus) {
    if (ownerStatus.kycResult instanceof Data_Maybe.Just) {
        return true;
    };
    return false;
};
var genericUserKYCKind = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof KYCPassed) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof KYCFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof KYCPassedForOwner) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof KYCFailedForOwner) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
    };
    throw new Error("Failed pattern match at Model.Owner (line 14, column 1 - line 14, column 60): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return KYCPassed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return KYCFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return KYCPassedForOwner.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
        return KYCFailedForOwner.value;
    };
    throw new Error("Failed pattern match at Model.Owner (line 14, column 1 - line 14, column 60): " + [ x.constructor.name ]);
});
var userKYCKindReadForeign = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericUserKYCKind)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "KYCPassed";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "KYCFailed";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "KYCPassedForOwner";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "KYCFailedForOwner";
})))))));
var genericCompanyOwnerKind = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof UBO) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Director) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at Model.Owner (line 19, column 1 - line 19, column 70): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return UBO.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return Director.value;
    };
    throw new Error("Failed pattern match at Model.Owner (line 19, column 1 - line 19, column 70): " + [ x.constructor.name ]);
});
var readForeignCompanyOwnerKind = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericCompanyOwnerKind)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "UBO";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Director";
})))));
var showCompanyOwnerKind = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericCompanyOwnerKind)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "UBO";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Director";
})))));
var writeForeignCompanyStatus = new Simple_JSON.WriteForeign((function () {
    var $25 = Simple_JSON.write(Simple_JSON.writeForeignString);
    var $26 = Data_Show.show(showCompanyOwnerKind);
    return function ($27) {
        return $25($26($27));
    };
})());
var eqCompanyOwnerKind = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof UBO && y instanceof UBO) {
            return true;
        };
        if (x instanceof Director && y instanceof Director) {
            return true;
        };
        return false;
    };
});
var filterOwnerByKind = function (kind) {
    return function (owners) {
        return Data_Array.filter(function (x) {
            return Data_Eq.eq(eqCompanyOwnerKind)(x.owner.kind)(kind);
        })(owners);
    };
};
var directorNotNeeded = function (directors) {
    return function (ubos) {
        var uboNames = Data_Functor.map(Data_Functor.functorArray)(function (x) {
            return showOwnerName(x.owner);
        })(ubos);
        var directorNames = Data_Functor.map(Data_Functor.functorArray)(function (x) {
            return showOwnerName(x.owner);
        })(directors);
        return Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Maybe.isJust)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.kycResult;
        })(directors)) || Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(function (x) {
            return Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqString)(x)(uboNames);
        })(directorNames);
    };
};
module.exports = {
    KYCPassed: KYCPassed,
    KYCFailed: KYCFailed,
    KYCPassedForOwner: KYCPassedForOwner,
    KYCFailedForOwner: KYCFailedForOwner,
    UBO: UBO,
    Director: Director,
    showOwnerName: showOwnerName,
    isSubmitted: isSubmitted,
    noneKycStarted: noneKycStarted,
    filterOwnerByKind: filterOwnerByKind,
    directorNotNeeded: directorNotNeeded,
    genericUserKYCKind: genericUserKYCKind,
    userKYCKindReadForeign: userKYCKindReadForeign,
    genericCompanyOwnerKind: genericCompanyOwnerKind,
    eqCompanyOwnerKind: eqCompanyOwnerKind,
    showCompanyOwnerKind: showCompanyOwnerKind,
    readForeignCompanyOwnerKind: readForeignCompanyOwnerKind,
    writeForeignCompanyStatus: writeForeignCompanyStatus
};
