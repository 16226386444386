// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Fetch = require("../Fetch/index.js");
var Foreign = require("../Foreign/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var AccountRight = (function () {
    function AccountRight() {

    };
    AccountRight.value = new AccountRight();
    return AccountRight;
})();
var NewEssentials = (function () {
    function NewEssentials() {

    };
    NewEssentials.value = new NewEssentials();
    return NewEssentials;
})();
var Other = (function () {
    function Other() {

    };
    Other.value = new Other();
    return Other;
})();
var productTypeShow = new Data_Show.Show(function (v) {
    if (v instanceof AccountRight) {
        return "AccountRight";
    };
    if (v instanceof NewEssentials) {
        return "NewEssentials";
    };
    if (v instanceof Other) {
        return "Other";
    };
    throw new Error("Failed pattern match at Model.CompanySubscription (line 28, column 1 - line 31, column 23): " + [ v.constructor.name ]);
});
var genericProductType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof AccountRight) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof NewEssentials) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Other) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at Model.CompanySubscription (line 20, column 1 - line 20, column 60): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return AccountRight.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return NewEssentials.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return Other.value;
    };
    throw new Error("Failed pattern match at Model.CompanySubscription (line 20, column 1 - line 20, column 60): " + [ x.constructor.name ]);
});
var productTypeReadForeign = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericProductType)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "AccountRight";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "NewEssentials";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Other";
}))))));
var productTypeWriteForeign = new Simple_JSON.WriteForeign((function () {
    var $23 = Data_Generic_Rep_Show.genericShow(genericProductType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "AccountRight";
    })))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "NewEssentials";
    })))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
        return "Other";
    })))));
    return function ($24) {
        return Foreign.unsafeToForeign($23($24));
    };
})());
var fetchCompanySubscription = function (companyId) {
    return function (v) {
        if (v instanceof Data_Maybe.Just) {
            var readSubscriptionBody = function (body) {
                return Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "companyId";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "product";
                }))(productTypeReadForeign)(Simple_JSON.readFieldsNil)()())()()))(body);
            };
            var extractData = function (resp) {
                if (resp.status === 200) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Either.hush(readSubscriptionBody(resp.body)));
                };
                if (resp.status === 401) {
                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.login(Data_Maybe.Nothing.value))(Data_Maybe.Nothing.value));
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
            };
            return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.get()("/company/" + (companyId + ("/subscription/" + v.value0)))({}))(function (resp) {
                return extractData(resp);
            });
        };
        if (v instanceof Data_Maybe.Nothing) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
        };
        throw new Error("Failed pattern match at Model.CompanySubscription (line 41, column 1 - line 41, column 86): " + [ companyId.constructor.name, v.constructor.name ]);
    };
};
var eqProductType = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof AccountRight && y instanceof AccountRight) {
            return true;
        };
        if (x instanceof NewEssentials && y instanceof NewEssentials) {
            return true;
        };
        if (x instanceof Other && y instanceof Other) {
            return true;
        };
        return false;
    };
});
var isNewEssentialsBusiness = (function () {
    var $25 = Data_Maybe.fromMaybe(false);
    var $26 = Data_Functor.map(Data_Maybe.functorMaybe)(function (p) {
        return Data_Eq.eq(eqProductType)(p)(NewEssentials.value);
    });
    return function ($27) {
        return $25($26($27));
    };
})();
module.exports = {
    AccountRight: AccountRight,
    NewEssentials: NewEssentials,
    Other: Other,
    isNewEssentialsBusiness: isNewEssentialsBusiness,
    fetchCompanySubscription: fetchCompanySubscription,
    eqProductType: eqProductType,
    genericProductType: genericProductType,
    productTypeReadForeign: productTypeReadForeign,
    productTypeWriteForeign: productTypeWriteForeign,
    productTypeShow: productTypeShow
};
