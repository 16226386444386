import React from 'react'
import cardImg from '../img/card.png'
import tickImg from '../img/tick.svg'
import businessImg from '../img/business.png'
import lockImg from '../img/lock.png'
import { Button, Card, Label, Icons } from '@myob/myob-widgets'
import './DashboardStep.sass'

const getImage = imageName => {
  switch (imageName) {
    case 'Lock':
      return <img className="step-icon" alt="lock" src={lockImg} />
    case 'AddCard':
      return <img className="step-icon" alt="add-card" src={cardImg} />
    case 'Business':
      return <img className="step-icon" alt="business" src={businessImg} />
  }
}

const DashboardStep = ({
  id,
  imageName,
  name,
  description,
  effort,
  paused,
  done,
  disabled,
  onClick
}) => {
  const textClass = disabled ? 'disabled-text no-margin' : 'no-margin'
  const cardClass = done ? ['step-success'] : []
  const stepIcon = done ? (
    <span className="flx-step__counter">
      <img className="step-icon" alt="success" src={tickImg} />
    </span>
  ) : (
    getImage(imageName)
  )
  const stepAction = done ? (
    <Label color="green" type="boxed">
      Done!
    </Label>
  ) : (
    <Button
      id="btn-dashboard-step-start-pause"
      type="link"
      onClick={onClick}
      icon={<Icons.RightChevron />}
      iconRight={true}
      disabled={disabled}
    >
      {paused ? 'Paused' : 'Start now'}
    </Button>
  )

  return (
    <Card id={id} classes={cardClass}>
      <div className="step-content">
        <div className="step-tick">{stepIcon}</div>
        <div className="step-detail">
          <h3 className={textClass}>{name}</h3>
          <p className={textClass}>{description}</p>
        </div>
        <div className="step-effort">
          <p className={textClass}>{effort}</p>
        </div>
        <div className="step-status no-margin">{stepAction}</div>
      </div>
    </Card>
  )
}

export default DashboardStep
