import React, { useContext, useState } from 'react'
import { Alert, Button, ButtonRow, StandardTemplate } from '@myob/myob-widgets'
import { createLineViewModel } from 'purs/Model.Batch'
import FormattedAmount from './Batch.Amount'
import { renderTableBody, renderSortableTableHeader, Text } from './Batch.Table'
import BatchDetailMetadata from './BatchDetailMetadata'
import { Link } from 'react-router-dom'
import { context } from '../pages/BatchDetail'
import * as r from 'ramda'
import Error from './Error'
import './BatchDetailTable.sass'
import { pageHead } from './SecondaryTab'
import { sortBatchDetailsBy, checkAuthPayment } from 'purs/BatchDetailTable'
import { hush } from 'purs/Data.Either'
import { readCompany } from 'purs/Model.Company'
import { Awaiting, Processed, Refunded } from 'purs/Model.Status'
import { Submitter, None } from 'purs/Model.User'
import { Batch, EmptyBatch, HttpError, ArlBatch } from 'purs/Page.BatchDetail'
import { Just } from 'purs/Data.Maybe'

const format = [
  {
    key: 'payeeName',
    description: 'Account Name',
    width: 'flex-3',
    sortable: true,
    align: 'left',
    component: Text('payeeName')
  },
  {
    key: 'transactionType',
    description: 'Payment type',
    width: 'flex-3',
    sortable: true,
    align: 'left',
    component: Text('transactionType')
  },
  {
    key: 'bsbNumber',
    description: 'BSB',
    width: 'flex-2',
    align: 'left',
    component: Text('bsbNumber')
  },
  {
    key: 'bankAccountNumber',
    description: 'Acc Number',
    width: 'flex-2',
    align: 'left',
    component: Text('bankAccountNumber')
  },
  {
    key: 'lodgementReference',
    description: 'Lodgement ref',
    width: 'flex-3',
    align: 'left',
    component: Text('lodgementReference')
  },
  {
    key: 'amount',
    description: 'Amount ($)',
    width: 'flex-2',
    sortable: true,
    align: 'right',
    component: props => <FormattedAmount amount={props.amount} />
  }
]

const AuthoriseButton = () => {
  const { state, dispatch } = useContext(context)
  const company = hush(readCompany())
  const userRole = r.pathOr('', ['value0', 'userCurrentRole'])(company)
  const isDisableAuthRole = userRole instanceof Submitter || userRole instanceof None
  const isAwaiting =
    r.path(['value0', 'batchDetail', 'status'])(state.batch) instanceof Awaiting
  return isAwaiting && !isDisableAuthRole ? (
    <Button
      id="btn-batch-detail-table-authorise"
      type="primary"
      className="authorise-button"
      onClick={() => dispatch(checkAuthPayment)}
    >
      Authorise
    </Button>
  ) : (
    <></>
  )
}

const renderBody = state => {
  const batch = state.batch
  switch (batch.constructor) {
    case HttpError:
      return <Error httpError={batch.value0} />
    case EmptyBatch:
      return (
        <Error>
          <h1 className="message__title">Looks like theres nothing here!</h1>
        </Error>
      )
    case Batch:
    case ArlBatch:
      return renderTableBody(
        batch.value0.lines.map(createLineViewModel),
        format
      )
    default:
      return <Error httpError={404} />
  }
}

const header = (companyName, batchDetail) => {
  const status = r.path(['status'], batchDetail)
  const error = r.path(['error'], batchDetail)

  const PaymentTimeFrame = ()=> {
    const isError = error instanceof Just
    const isProcessed = status instanceof Processed || status instanceof Refunded
    const hideTimeFrame = isError || isProcessed

    return hideTimeFrame ? <></> : (
      <Alert type="info">
        <p>Payments typically take 1-3 business days to reach recipients.</p>
      </Alert>
    )
  }

  return (
    <div>
      <div className="flx-container__batch">
        {pageHead(companyName)}
        <ButtonRow>
          <Link className="btn btn-default batch-payments-btn" to="/">
            Batch payments
          </Link>
          <AuthoriseButton />
        </ButtonRow>
      </div>
    </div>
  )
}

const BatchDetailHeader = (dispatch, sortIcon, setSortIcon) => {
  return renderSortableTableHeader(format, sortIcon, key => {
    const isDescending = key === sortIcon.column ? !sortIcon.descending : true
    dispatch(sortBatchDetailsBy(key)(isDescending))
    setSortIcon({ column: key, descending: isDescending })
  })
}

function BatchDetailTable({ arlBusiness }) {
  const { state, dispatch } = useContext(context)
  const [sortIcon, setSortIcon] = useState({
    column: 'payeeName',
    descending: true
  })
  const companyName = r.propOr('', 'name')(arlBusiness)
  const batchDetail = r.path(['batch', 'value0', 'batchDetail'], state)

  return (
    <>
      <div className="no-sticky-top-padding">
        <StandardTemplate
          pageHead={header(companyName, batchDetail)}
          subHeadChildren={BatchDetailMetadata()}
          tableHeader={BatchDetailHeader(dispatch, sortIcon, setSortIcon)}
          sticky="all"
          wcagAA={true}
        >
          {renderBody(state)}
        </StandardTemplate>
      </div>
    </>
  )
}

export default BatchDetailTable
