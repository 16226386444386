import Master from '../img/master.svg'
import Visa from '../img/visa.svg'
import React from 'react'
import { Label } from '@myob/myob-widgets'

const cardScheme = props => {
  const scheme = props.scheme === 'mastercard' ? Master : Visa
  return <img className={props.style} src={scheme} alt={props.alt} />
}

const cardStatus = (cardStatus) => {
  return cardStatus.status === 'Expired' &&
    <Label color={cardStatus.color} type="boxed">
      {cardStatus.status}
    </Label>
}

const CardSchema = props => {
  return (
    <div className={`saved-card ${props.isDefault?'default-payment-method':null}`} >
      {props.isDefault && <div className="default-status"><Label color="purple" type="boxed">Default</Label></div>}
      <div className="card-status">{cardStatus(props.expiry)}</div>
      <span className="card-number">{props.number}</span>
      {cardScheme(props.scheme)}
    </div>
  )
}

export default CardSchema
