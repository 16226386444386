import { Success, Warning } from 'purs/AlertBanner'
import { Nothing } from 'purs/Data.Maybe'
import {
  RedirectFromKYCForbidden,
  RedirectFromKYCSuccess
} from 'purs/KYCDashboard'
import { initState, load } from 'purs/Page.Home'
import * as r from 'ramda'
import React, { useContext } from 'react'
import AlertBanner from '../components/AlertBanner'
import BatchTable from '../components/BatchTable'
import NavBarForBusiness from '../components/NavBarForBusiness'
import withState from '../withPsState'
import './Home.sass'

function KYCAlert({ redirectFromKYC }) {
  if (redirectFromKYC instanceof RedirectFromKYCSuccess) {
    return (
      <AlertBanner type={Success.value}>
        Hooray! Your details were successfully updated.
      </AlertBanner>
    )
  } else if (redirectFromKYC instanceof RedirectFromKYCForbidden) {
    return (
      <AlertBanner type={Warning.value}>
        {`Oops, looks like we couldn't confirm your permissions. Chat to your administrator about changes to your access.`}
      </AlertBanner>
    )
  } else {
    return <></>
  }
}

const HomePage = () => {
  const { state } = useContext(context)
  return (
    <div className="no-sticky-top-padding">
      <NavBarForBusiness
        arlBusiness={state.company.value0}
        rewards={state.rewardsDetails}
      />
      <KYCAlert redirectFromKYC={state.redirectFromKYC.value0} />
      <BatchTable />
    </div>
  )
}

export const { context, Component } = withState(HomePage, initState, props =>
  load(l => () => {
    props.history.push(l)
  })(r.pathOr(Nothing.value, ['location', 'state', 'kycResult'], props))
)

export default Component
