// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Bitraversable = require("../Data.Bitraversable/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyOnboardModal = require("../Model.CompanyOnboardModal/index.js");
var Segment = require("../Segment/index.js");
var SignUpHelper = require("../SignUpHelper/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var updateState = function (s) {
    return function (company) {
        return function (abn) {
            var putAbn = Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.put()("/company/" + company.companyId)(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "abn";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()()))({
                abn: SignUpHelper.rmSpaces(abn.number)
            }))({}));
            var parseResp = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Fetch.matchResp(204))(Data_Bifunctor.lmap(Data_Either.bifunctorEither)(Data_Function["const"](500)));
            var abnCheckPassed = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                Model_Company.saveCompany({
                    id: company.id,
                    companyId: company.companyId,
                    name: company.name,
                    account: company.account,
                    access: company.access,
                    country: company.country,
                    status: company.status,
                    abn: SignUpHelper.rmSpaces(abn.number),
                    userCurrentRole: company.userCurrentRole,
                    userNominatedRole: company.userNominatedRole,
                    onboardOnBehalf: company.onboardOnBehalf,
                    requireMoreDocument: company.requireMoreDocument,
                    serialNumber: company.serialNumber,
                    product: company.product
                })();
                Segment.trackingCompany(new Data_Maybe.Just(company))(new Segment.OnboardConfirm("Passed"))();
                return Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.redirectTo("/companies/" + (company.companyId + "/select-role")))({
                    onboardModal: {
                        spinner: {
                            display: true
                        },
                        content: s.onboardModal.content,
                        display: s.onboardModal.display,
                        userRole: s.onboardModal.userRole,
                        verificationCheckbox: s.onboardModal.verificationCheckbox
                    },
                    companyList: s.companyList,
                    isLoading: s.isLoading,
                    selectedCompany: s.selectedCompany,
                    stepOnboardModal: s.stepOnboardModal
                })();
            });
            var abnCheckFailed = function (status) {
                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Segment.trackingCompany(new Data_Maybe.Just(company))(new Segment.OnboardConfirm("Failed")))({
                    onboardModal: {
                        spinner: {
                            display: false
                        },
                        content: Model_CompanyOnboardModal.AbnError.create({
                            number: abn.number,
                            disabled: false,
                            isFormatted: true
                        })(SignUpHelper.parseAbnError(Model_CompanyOnboardModal.CreditScoreCheckFail.value)(status)),
                        display: s.onboardModal.display,
                        userRole: s.onboardModal.userRole,
                        verificationCheckbox: s.onboardModal.verificationCheckbox
                    },
                    companyList: s.companyList,
                    isLoading: s.isLoading,
                    selectedCompany: s.selectedCompany,
                    stepOnboardModal: s.stepOnboardModal
                }));
            };
            return Control_Bind.bind(Effect_Aff.bindAff)(putAbn)((function () {
                var $19 = Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(abnCheckFailed)(Data_Function["const"](abnCheckPassed));
                return function ($20) {
                    return $19(parseResp($20));
                };
            })());
        };
    };
};
var toggleVerificationCheckbox = function (s) {
    var toggledValue = !s.onboardModal.verificationCheckbox.checked;
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        onboardModal: {
            verificationCheckbox: {
                checked: toggledValue
            },
            content: s.onboardModal.content,
            display: s.onboardModal.display,
            spinner: s.onboardModal.spinner,
            userRole: s.onboardModal.userRole
        },
        companyList: s.companyList,
        isLoading: s.isLoading,
        selectedCompany: s.selectedCompany,
        stepOnboardModal: s.stepOnboardModal
    });
};
var signUp = function (s) {
    var finishedLoading = {
        companyList: s.companyList,
        isLoading: s.isLoading,
        selectedCompany: s.selectedCompany,
        onboardModal: {
            display: s.onboardModal.display,
            spinner: {
                display: false
            },
            userRole: s.onboardModal.userRole,
            content: s.onboardModal.content,
            verificationCheckbox: s.onboardModal.verificationCheckbox
        },
        stepOnboardModal: s.stepOnboardModal
    };
    var abnInput = function (v) {
        if (v instanceof Model_CompanyOnboardModal.AbnValidated) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(v.value0);
        };
        return Data_Maybe.Nothing.value;
    };
    var companyAndAbn = (function () {
        var $21 = Data_Bitraversable.bisequence(Data_Tuple.bitraversableTuple)(Data_Maybe.applicativeMaybe);
        var $22 = Data_Profunctor_Strong.fanout(Control_Category.categoryFn)(Data_Profunctor_Strong.strongFn)(function (v) {
            return v.selectedCompany;
        })(function ($24) {
            return abnInput((function (v) {
                return v.onboardModal.content;
            })($24));
        });
        return function ($23) {
            return $21($22($23));
        };
    })();
    return Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(finishedLoading))(Data_Tuple.uncurry(updateState(s)))(companyAndAbn(s));
};
var setModalLoading = (function () {
    var $25 = Control_Applicative.pure(Effect_Aff.applicativeAff);
    return function ($26) {
        return $25((function (v) {
            return {
                onboardModal: {
                    spinner: {
                        display: true
                    },
                    content: v.onboardModal.content,
                    display: v.onboardModal.display,
                    userRole: v.onboardModal.userRole,
                    verificationCheckbox: v.onboardModal.verificationCheckbox
                },
                companyList: v.companyList,
                isLoading: v.isLoading,
                selectedCompany: v.selectedCompany,
                stepOnboardModal: v.stepOnboardModal
            };
        })($26));
    };
})();
var resetVerificationCheckbox = function (s) {
    return {
        companyList: s.companyList,
        isLoading: s.isLoading,
        selectedCompany: s.selectedCompany,
        onboardModal: {
            display: s.onboardModal.display,
            spinner: s.onboardModal.spinner,
            userRole: s.onboardModal.userRole,
            content: s.onboardModal.content,
            verificationCheckbox: {
                checked: false
            }
        },
        stepOnboardModal: s.stepOnboardModal
    };
};
var updateAbnInput = function (v) {
    return function (state) {
        if (v === "") {
            var stateToResetCheckbox = resetVerificationCheckbox(state);
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                onboardModal: {
                    content: new Model_CompanyOnboardModal.AbnInitial({
                        number: "",
                        disabled: false,
                        isFormatted: true
                    }),
                    display: stateToResetCheckbox.onboardModal.display,
                    spinner: stateToResetCheckbox.onboardModal.spinner,
                    userRole: stateToResetCheckbox.onboardModal.userRole,
                    verificationCheckbox: stateToResetCheckbox.onboardModal.verificationCheckbox
                },
                companyList: stateToResetCheckbox.companyList,
                isLoading: stateToResetCheckbox.isLoading,
                selectedCompany: stateToResetCheckbox.selectedCompany,
                stepOnboardModal: stateToResetCheckbox.stepOnboardModal
            });
        };
        var stateToResetCheckbox = resetVerificationCheckbox(state);
        var $18 = SignUpHelper.hasFormatError(v);
        if ($18) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                onboardModal: {
                    content: new Model_CompanyOnboardModal.AbnInitial({
                        number: v,
                        disabled: false,
                        isFormatted: false
                    }),
                    display: stateToResetCheckbox.onboardModal.display,
                    spinner: stateToResetCheckbox.onboardModal.spinner,
                    userRole: stateToResetCheckbox.onboardModal.userRole,
                    verificationCheckbox: stateToResetCheckbox.onboardModal.verificationCheckbox
                },
                companyList: stateToResetCheckbox.companyList,
                isLoading: stateToResetCheckbox.isLoading,
                selectedCompany: stateToResetCheckbox.selectedCompany,
                stepOnboardModal: stateToResetCheckbox.stepOnboardModal
            });
        };
        return SignUpHelper.checkAbnOnline(v)(stateToResetCheckbox);
    };
};
module.exports = {
    signUp: signUp,
    setModalLoading: setModalLoading,
    updateAbnInput: updateAbnInput,
    toggleVerificationCheckbox: toggleVerificationCheckbox
};
