import { StandardTemplate, Spinner } from '@myob/myob-widgets'
import { hush } from 'purs/Data.Either'
import { maybe, isJust } from 'purs/Data.Maybe'
import { readCompany } from 'purs/Model.Company'
import { initState, load } from 'purs/Page.BusinessManage'
import * as r from 'ramda'
import React, { useContext } from 'react'
import CardManage from '../components/CardManage'
import DirectDebitManage from '../components/DirectDebitManage'
import Error from '../components/Error'
import NavBarForBusiness from '../components/NavBarForBusiness'
import SecondaryTabs, { NavTabs, pageHead } from '../components/SecondaryTab'
import withState from '../withPsState'
import './BusinessManage.sass'

export const BusinessManagePage = () => {
  const { state } = useContext(context)
  const company = hush(readCompany())
  return (
    <React.Fragment>
      <NavBarForBusiness arlBusiness={company.value0} />

      {maybe(<></>)(() => (
        <div className="error">
          <p>Error with step up</p>
        </div>
      ))(state.stepUpError)}

      <StandardTemplate
        pageHead={pageHead(r.pathOr('', ['value0', 'name'])(company))}
        sticky="none"
        subHeadChildren={
          <SecondaryTabs
            tab={NavTabs.paymentDetails}
            company={company.value0}
          />
        }
      >
        {state.fetchPaymentMethods.isLoading ? (
          <Spinner/>
        ) : isJust(state.fetchPaymentMethods.httpError) ? (
          <Error httpError={ state.fetchPaymentMethods.httpError.value0 }/>
        ) : (
          <>
            <CardManage />
            <DirectDebitManage />
          </>
        )}
      </StandardTemplate>
    </React.Fragment>
  )
}

export const { context, Component } = withState(
  BusinessManagePage,
  initState,
  props =>
    load(l => () => {
      props.history.push(l)
    })
)

export default Component
