// Generated by purs version 0.13.8
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Error_Util = require("../Control.Error.Util/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var getKeyValueFromQuery = function (query) {
    return function (key) {
        return Control_Bind.bind(Data_Either.bindEither)(Data_String_Regex.regex(key + "=([^&]+)")(Data_String_Regex_Flags.noFlags))(function (rx) {
            return Control_Error_Util.note(key + " does not exist")(Data_Foldable.fold(Data_Foldable.foldableMaybe)(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_String_Regex.match(rx)(query))(function (v) {
                return Data_Array_NonEmpty.index(v)(1);
            })));
        });
    };
};
var getKeyValueFromLocation = function (rawLocation) {
    return function (name) {
        var getValue = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(Data_Function.flip(getKeyValueFromQuery)(name))(Control_Error_Util.note("neither hash nor search was provided"));
        var locationToValue = function (v) {
            return Control_Alt.alt(Data_Either.altEither)(getValue(v.hash))(getValue(v.search));
        };
        return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Either.bifunctorEither)(Data_Function["const"]("invalid location"))(Simple_JSON.read(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "hash";
        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "search";
        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsNil)()())()()))(rawLocation)))(locationToValue);
    };
};
module.exports = {
    getKeyValueFromQuery: getKeyValueFromQuery,
    getKeyValueFromLocation: getKeyValueFromLocation
};
