import React, { Component } from 'react'
import * as r from 'ramda'
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Spinner,
  Stepper
} from '@myob/myob-widgets'
import GreenIdLogo from '../img/GreenID-logo.svg'
import { checkDateInput, checkEmptyInput } from 'purs/AddContactModal'
import config from '../config'
import PropTypes from 'prop-types'
import errorOwnerInfo from '../img/error-owner-info.svg'
import Hourglass from '../img/hourglass.svg'
import { isJust } from 'purs/Data.Maybe'
import './AddContactModalContent.sass'
import { setUpAddressValidation } from '../helper/AddressValidation'

const steps = () => [
  { number: '1', title: 'Confirm personal details', type: 'incomplete' },
  { number: '2', title: 'Verify identity', type: 'incomplete' },
  {
    number: '3',
    title: 'Thank you! Details have been submitted for verification.',
    type: 'incomplete'
  }
]

export const OnboardingKycErrorMessage = ({
  errorCode,
  companyEntityType,
  isSelf
}) => {
  switch (errorCode) {
    case 400:
      return <></>
    case 401:
      return (
        <div className="kycmodal__content">
          <div className="kycmodal__content__main error-message">
            <img className="img" src={Hourglass} alt="loading" />
            <h3 className="error-message__title">
              Looks like your session has expired!
            </h3>
            <span className="error-message__description">
              Please refresh your page.
            </span>
          </div>
        </div>
      )
    case 403:
      return companyEntityType === 'PRV' ? (
        isSelf ? (
          <div className="kycmodal-403-container">
            <Alert type="danger" inline>
              <p>
                Unfortunately we couldn&apos;t identify you as an Ultimate
                Beneficial Owner or Director.
              </p>
            </Alert>
            <p>
              At this time, only sole traders, directors and Ultimate Beneficial
              Owners (owners with at least 25% ownership) can enable direct
              payments in AccountRight Live for their business.
            </p>
            <p>
              Owners with less than 25% ownership, accountants, bookkeepers and
              employees will be able to sign up soon.&nbsp;
              <a
                href={config.subscribeUpdatesURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Subscribe for updates
              </a>
            </p>
            <p>
              If you think this is an error, please contact our specialised
              payments support team on <a href="tel:1300783674">1300 783 674</a>
              .
            </p>
          </div>
        ) : (
          <div>
            <Alert type="danger">
              Looks like some details aren&apos;t quite right. Make sure{' '}
              <b>First name</b>, <b>Middle name</b> and <b>Last name</b> are
              correct.
            </Alert>
            <p className="call-support-message">
              If you&apos;re stuck, please give our payments support team a call
              on <a href="tel:1300783674">1300 783 674</a> and they&apos;ll be
              happy to assist you.
            </p>
          </div>
        )
      ) : (
        <Alert type="danger">
          We couldn&apos;t automatically verify you as connected to this
          business.
        </Alert>
      )
    default:
      return (
        <div className="kycmodal__content">
          <div className="kycmodal__content__main error-message">
            <img className="img" src={errorOwnerInfo} alt="loading" />
            <h3 className="error-message__title">{`We've hit a roadblock!`}</h3>
            <p className="error-message__description">
              <br/>
              Please get in touch with support at <br/>
              <a href="tel:1300-783-674">1300 783 674</a> to continue your application.
            </p>
          </div>
        </div>
      )
  }
}

export const UserManagementKycErrorMessage = ({ errorCode }) => {
  switch (errorCode) {
    case 400:
      return (
        <Alert type="warning">
          {`Oops, looks like there was an issue verifying your details. For additional information you can contact our support team on`}
          {''}
          <a href="tel:1300783674">1300 783 674</a> for assistance.
        </Alert>
      )
    case 401:
      return (
        <div className="kycmodal__content">
          <div className="kycmodal__content__main error-message">
            <img className="img" src={Hourglass} alt="loading" />
            <h3 className="error-message__title">
              Looks like your session has expired!
            </h3>
            <span className="error-message__description">
              Please refresh your page.
            </span>
          </div>
        </div>
      )
    default:
      return (
        <div className="kycmodal__content">
          <div className="kycmodal__content__main error-message">
            <img className="img" src={errorOwnerInfo} alt="loading" />
            <h3 className="error-message__title">{`We've hit a roadblock!`}</h3>
            <p className="error-message__description">
              <br/>
              Please get in touch with support at <br/>
              <a href="tel:1300-783-674">1300 783 674</a> to continue your application.
            </p>
          </div>
        </div>
      )
  }
}

const ShowSpinner = () => {
  return (
    <div className="kycmodal__content">
      <div className="kycmodal__content__main">
        <Spinner size="medium" />
        <p className="center">Retrieving information for the next step</p>
        <p className="center">This can take 1 - 3 minutes.</p>
      </div>
    </div>
  )
}

export default class AddContactModalContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSelf: props.isSelf,
      isUpdatingRole: props.isUpdatingRole,
      firstName: {
        value: props.owner ? props.owner.firstName : '',
        isValid: true
      },
      lastName: {
        value: props.owner ? props.owner.lastName : '',
        isValid: true
      },
      middleName: {
        value:
          props.owner && isJust(props.owner.middleName)
            ? props.owner.middleName.value0
            : '',
        isValid: true
      },
      birthDate: {
        value: '',
        isValid: true
      },
      address: '',
      currentStep: '1',
      showContinueBtn: true,
      checked: false,
      submitFormId: 'greenIdform',
      verificationId: null,
      showSpinner: false,
      isCompleted: false,
      httpResponse: {
        status: null,
        body: null
      }
    }
  }

  register() {
    const { verificationId } = this.state
    this.setState({
      showSpinner: true,
      showContinueBtn: false,
      currentStep: steps().length.toString()
    })

    this.props.register(verificationId, response =>
      this.setState({
        showSpinner: false,
        isCompleted: true,
        httpResponse: response
      })
    )
  }

  componentDidMount() {
    if(this.props.waitForUBOReport) {
      this.setState({
        showSpinner: true,
        showContinueBtn: false,
        currentStep: steps().length.toString()
      })
      this.props.waitForUBOReport((response) => {
        this.setState({
          showSpinner: false,
          isCompleted: true,
          httpResponse: response
        })
      })
    }
    const agreementMsg = 'I agree to have the above information checked with the Issuer or Official Record Holder.'
    const idSources = ['actrego', 'passport', 'visa', 'citizenshipcertificate', 'immicard', 'medicare', 'centrelink'];
    const overrideAgreementMsg = r.mergeAll(r.map(key => ({[`${key}dvs_tandc_label`]: agreementMsg}), idSources))

    const onContinueBtnClick = () => {
      return validateAllFields()
    }

    const validateAllFields = () => {
      const { firstName, lastName, birthDate } = this.state

      const newState = {
        firstName: checkEmptyInput(firstName),
        lastName: checkEmptyInput(lastName),
        birthDate: checkDateInput(birthDate)
      }

      this.setState(newState)

      return r.all(v => !r.isEmpty(v.value))(r.values(newState))
    }

    window.greenidJQuery('document').ready(() => {
      window.greenidUI.setup({
        environment: config.greenIDEnv,
        formId: 'greenIdform',
        frameId: 'greenid-div',
        country: 'usethiscountry',
        showDialogWhenNoSourcesLeft: false,
        registerCallback: (verificationId, userData) => {
          this.setState({
            submitFormId: 'greenid-FieldsForm',
            currentStep: r.toString(r.inc(this.state.currentStep)),
            verificationId
          })
        },
        errorCallback: e => {},
        sessionCompleteCallback: (verificationToken, overallState) => {
          return this.register()
        },
        sourceAttemptCallback: e => {},
        sessionCancelledCallback: (verificationToken, overallState) => {
          return this.register()
        },
        preSubmitValidationCallback: () => onContinueBtnClick()
      })

      window.greenidConfig.setOverrides({
        enable_save_and_complete_later: false,
        intro_introText0: this.state.isSelf ? '<p class="lead">Enter your details exactly as they appear on your ID.</p>' : '<p class="lead">Enter the owner/director&apos;s details exactly as they appear on their ID.</p>'
        + '<p class="lead">Get started with your first ID source below.</p>',
        ...overrideAgreementMsg
      })
    })
  }

  componentWillUnmount() {
    window.greenidUI.abandon()
  }

  postModalCloseMessage(response) {
    window.parent.postMessage(
      { message: 'kycmodal-close', ...response },
      config.uiHost
    )
  }

  LoadContent() {
    const {
      isSelf,
      isUpdatingRole,
      firstName,
      lastName,
      birthDate,
      checked,
      currentStep,
      showSpinner,
      isCompleted,
      httpResponse
    } = this.state

    if (showSpinner) {
      return <ShowSpinner />
    }
    const isNameDisable = !isSelf
    const isError = httpResponse.status > 299 || this.props.showError
    const kycFailed = httpResponse.status == 400
    const is403Error = httpResponse.status === 403
    const showStepper = !isError || is403Error
    /* istanbul ignore next */
    setUpAddressValidation({
      whereToDisplaySuggestions: '#customer-address',
      addressField: '#fullAddress',
      onChange: addressItem => {
        this.setState({
          address: addressItem.fullAddress
        })
      },
      onSelect: addressItem => this.setState({
        address: addressItem.fullAddress
      })
    })

    return (
      <React.Fragment>
        {isUpdatingRole ? (
          <>
            {showStepper && (
              <Stepper
                activeStepNumber={currentStep}
                type="mobile"
                steps={steps()}
              />
            )}

            {isError && (
              <UserManagementKycErrorMessage errorCode={httpResponse.status} />
            )}
          </>
        ) : (
          <>
            {!(isError && !kycFailed) && (
              <Stepper
                activeStepNumber={currentStep}
                type="mobile"
                steps={steps()}
              />
            )}

            {isError && !kycFailed && (
              <OnboardingKycErrorMessage
                errorCode={httpResponse.status}
                isSelf={isSelf}
                companyEntityType={this.props.companyEntityType}
              />
            )}
          </>
        )}

        {!isCompleted && !isError && (
          <div id="greenid-div">
            <form
              method="POST"
              action="/verification/simpleui-finish.seam"
              id="greenIdform"
            >
              <input type="hidden" id="ruleId" value="payables" name="ruleId" />
              <input
                type="hidden"
                id="accountId"
                value={config.greenIDAccountId}
                name="accountId"
              />
              <input
                type="hidden"
                id="apiCode"
                value={config.greenIDApiCode}
                name="apiCode"
              />
              <input
                type="hidden"
                id="usethiscountry"
                value="AU"
                name="country"
              />
              {(isSelf ? (
                <p>{`Enter your details exactly as they appear on your ID. We use this information to
              verify your identity against state and federal government records.`}</p>
              ) : (
                <p>{`Enter the owner/director's details exactly as they appear on their ID. We use this information to
              verify your identity against state and federal government records.`}</p>
              ))
              }
              <Input
                id="givenNames"
                label="First given name"
                requiredLabel="This field is required"
                name="givenNames"
                value={this.state.firstName.value}
                disabled={isNameDisable}
                errorMessage={
                  firstName.isValid ? '' : 'Please enter your first name.'
                }
                onBlur={() =>
                  this.setState({
                    firstName: checkEmptyInput(firstName)
                  })
                }
                onChange={({ target: { value } }) =>
                  this.setState({
                    firstName: {
                      value: value,
                      isValid: !!value
                    }
                  })
                }
              />
              <Input
                id="middleNames"
                label="Middle name"
                name="middleNames"
                disabled={isNameDisable}
                value={this.state.middleName.value}
                onChange={({ target: { value } }) =>
                  this.setState({ middleName: { value } })
                }
              />
              <Input
                id="surname"
                label="Surname or family name"
                requiredLabel="This field is required"
                name="surname"
                disabled={isNameDisable}
                value={this.state.lastName.value}
                errorMessage={
                  lastName.isValid ? '' : 'Please enter your last name.'
                }
                onBlur={() =>
                  this.setState({
                    lastName: checkEmptyInput(lastName)
                  })
                }
                onChange={({ target: { value } }) =>
                  this.setState({
                    lastName: {
                      value: value,
                      isValid: !!value
                    }
                  })
                }
              />
              <div className="customer-address" id="customer-address">
                <Input
                  id="fullAddress"
                  label="Address"
                  requiredLabel="This field is required"
                  name="address"
                  placeholder="Start typing your address"
                  value={this.state.address.value}
                />
              </div>
              <div style={{ width: '128px' }}>
                <DatePicker
                  label="Date of birth"
                  name="dob"
                  id="dob"
                  requiredLabel="This field is required"
                  errorMessage={
                    birthDate.isValid ? '' : 'Please enter your date of birth.'
                  }
                  onSelect={({ value }) => {
                    this.setState({ birthDate: checkDateInput({ value }) })
                  }}
                />
              </div>
            </form>
            <Checkbox
              onChange={() => this.setState({ checked: !checked })}
              id="ackCheckbox"
              checked={checked}
              label={
                isSelf ? (
                  <p>
                    I confirm I am the person listed above and these are my
                    personal details
                  </p>
                ) : (
                  <p>
                    I confirm I am authorised to provide personal details of the owner/director.
                  </p>
                )
              }
            />
          </div>
        )}
      </React.Fragment>
    )
  }

  render() {
    const {
      showContinueBtn,
      submitFormId,
      httpResponse,
      checked,
      isSelf
    } = this.state
    const isOtherUbo403 = !isSelf && httpResponse.status == 403
    return (
      <React.Fragment>
        <Modal.Body>
          <div className="kycmodal-body" id="kycmodal-body">
            {this.LoadContent()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div id="kycmodal-footer">
            <div className="kycmodal-footer__logo">
              <span>Powered by &nbsp;</span>
              <img src={GreenIdLogo} alt="GreenId Logo" />
            </div>
            <Button
              id="btn-add-contact-modal-close"
              type={isOtherUbo403 ? 'primary' : 'secondary'}
              onClick={() => this.postModalCloseMessage(httpResponse)}
            >
              {isOtherUbo403 ? 'Try Again' : 'Close'}
            </Button>
            {showContinueBtn && (
              <button
                id="btn-add-contact-modal-submit"
                disabled={!checked}
                type="submit"
                form={submitFormId}
                className="btn btn-primary"
              >
                Continue
              </button>
            )}
          </div>
        </Modal.Footer>
      </React.Fragment>
    )
  }
}

AddContactModalContent.propTypes = {
  register: PropTypes.func.isRequired,
  owner: PropTypes.object,
  isSelf: PropTypes.bool.isRequired,
  companyEntityType: PropTypes.object,
  isUpdatingRole: PropTypes.bool.isRequired,
  showError: PropTypes.bool,
  waitForUBOReport: PropTypes.func
}
