import { Table, Button } from '@myob/myob-widgets'
import React from 'react'
import { renderTableBody, renderTableHeader, Show } from './Batch.Table'
import './BatchDetailTable.sass'
import { show } from 'purs/Data.Show'
import {
  roleShow,
  isActive,
  kycStatusShow,
  writeUser,
  Pending,
  Active
} from 'purs/Model.User'
import { Link } from 'react-router-dom'
import { Popover, Icons } from '@myob/myob-widgets'
import './Manage.Users.sass'

const ShowStatus = show => key => props => {
  switch (props[key].constructor) {
    case Pending:
      return (
        <Button type="link">
          <Popover
            body="User must complete identity verification before their role is made active."
            classes="status-popover"
            preferPlace="below"
            closeOnOuterAction={true}
            isOpen={false}
          >
            <span>{show(props[key])}</span>
          </Popover>
        </Button>
      )
    default:
      return <span>{show(props[key])}</span>
  }
}

const ShowAccountEnabled = key => props => {
  if (props[key] instanceof Active) {
    return (
      <span className="account-enabled">
        <Icons.Linked />
      </span>
    )
  } else {
    return (
      <span className="account-disabled">
        <Icons.UnLink />
      </span>
    )
  }
}

const tableFormat = [
  {
    key: 'name',
    description: 'Name',
    width: 'flex-3',
    align: 'left',
    component: Show(maybe => maybe.value0 || '')('name')
  },
  {
    key: 'email',
    description: 'Email',
    width: 'flex-3',
    align: 'left',
    component: props => {
      const userInfo = writeUser(props)
      return (
        <Button type="link">
          <Link
            to={{ pathname: `/manage/users/${userInfo.id}`, state: userInfo }}
          >
            {props['email']}
          </Link>
        </Button>
      )
    }
  },
  {
    key: 'role',
    description: 'Role',
    width: 'flex-2',
    align: 'left',
    component: Show(show(roleShow))('nominatedRole')
  },
  {
    key: 'status',
    description: 'Status',
    width: 'flex-1',
    align: 'left',
    component: ShowStatus(show(kycStatusShow))('kycStatus')
  },
  {
    key: 'account-enabled',
    description: 'Account Enabled',
    width: 'flex-2',
    align: 'center',
    component: ShowAccountEnabled('status')
  }
]

function ManageUsersTable({ users }) {
  return (
    <Table>
      {renderTableHeader(tableFormat)}
      {renderTableBody(
        users.map(user => ({ ...user, isInActive: !isActive(user) })),
        tableFormat
      )}
    </Table>
  )
}

export default ManageUsersTable
