import React, { useContext } from 'react'
import withPsState from '../withPsState'
import * as r from 'ramda'
import NavBarForBusiness from '../components/NavBarForBusiness'
import { BatchDetailAlert } from '../components/BatchDetailAlert'
import BatchDetailTable from '../components/BatchDetailTable'
import ConfirmAuthPaymentModal from '../components/ConfirmAuthPaymentModal'
import SelfEnrollmentModal from '../components/SelfEnrollmentModal'
import RewardsThanksModal from '../components/RewardsThanksModal'
import { Spinner } from '@myob/myob-widgets'
import { authorize } from 'purs/BatchDetailTable'
import { closeAllModals } from 'purs/Page.BatchDetail'
import { initState, loadBatch, selfEnrollment, setSelfEnrollModalLoading } from 'purs/Page.BatchDetail'
import { isRight } from 'purs/Data.Either'

const hideModal = () => {
  window.location.reload(false)
}

export const BatchDetailPage = () => {
  const { state, dispatch } = useContext(context)
  const arlBusiness = isRight(state.company) ? state.company.value0 : ''
  const companyId = arlBusiness.companyId

  return (
    <React.Fragment>
      <NavBarForBusiness arlBusiness={arlBusiness} />
      {state.isLoading ? (
        <Spinner />
      ) : (
        <>
          <BatchDetailAlert />
          <BatchDetailTable arlBusiness={arlBusiness} />
          {state.confirmAuthPaymentModal.display && (
            <ConfirmAuthPaymentModal
              onCancel={() => dispatch(closeAllModals)}
              onContinue={() => dispatch(authorize)}
              isFutureDate={state.confirmAuthPaymentModal.isFutureDate}
              companyId={companyId}
            />
          )}
          {state.selfEnrollmentModal.display && (
            <SelfEnrollmentModal
              isLoading={state.selfEnrollmentModal.isLoading}
              showErrorAlert={state.selfEnrollmentModal.selfEnrollmentError.display}
              onCancel={hideModal}
              onContinue={() => {
                dispatch(setSelfEnrollModalLoading)
                dispatch(selfEnrollment(companyId))
              }}
            />
          )}
          {state.rewardsThanksModal.display && (
            <RewardsThanksModal onCancel={hideModal}/>
          )}
        </>
      )}
    </React.Fragment>
  )
}

export const { context, Component } = withPsState(
  BatchDetailPage,
  initState,
  props => {
    const query = props.location.hash || props.location.search || ''
    const location = props.location
    const history = props.history
    const pathname = props.location.pathname || ''
    const batchId = r.path(['match', 'params', 'batchId'])(props)
    return loadBatch(location)(history)(query)(pathname)(batchId)
  }
)

export default Component
