function getItem(just) {
  return function(nothing) {
    return function(name) {
      return function() {
        var val = window.sessionStorage.getItem(name)
        return val ? just(val) : nothing
      }
    }
  }
}

function setItem(key) {
  return function(value) {
    return function() {
      window.sessionStorage.setItem(key, value)
    }
  }
}

function base64Decode(value) {
  return atob(value)
}

function base64Encode(value) {
  return btoa(value)
}

function genRandom() {
  return Math.random()
}

function removeItem(name) {
  return function() {
    window.sessionStorage.removeItem(name)
  }
}

exports.base64Decode = base64Decode
exports.base64Encode = base64Encode
exports.genRandom = genRandom
exports._getItem = getItem
exports._setItem = setItem
exports._removeItem = removeItem
