// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Class = require("../Effect.Aff.Class/index.js");
var MonadMaybe = function (MonadAff0, liftMaybe) {
    this.MonadAff0 = MonadAff0;
    this.liftMaybe = liftMaybe;
};
var MonadEither = function (MonadAff0, liftEither) {
    this.MonadAff0 = MonadAff0;
    this.liftEither = liftEither;
};
var monadMaybeAff = new MonadMaybe(function () {
    return Effect_Aff_Class.monadAffAff;
}, function (v) {
    return function (v1) {
        if (v1 instanceof Data_Maybe.Nothing) {
            return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(v);
        };
        if (v1 instanceof Data_Maybe.Just) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value0);
        };
        throw new Error("Failed pattern match at Effect.Aff.Monads.Class (line 19, column 1 - line 21, column 32): " + [ v.constructor.name, v1.constructor.name ]);
    };
});
var monadEitherAff = new MonadEither(function () {
    return Effect_Aff_Class.monadAffAff;
}, function (v) {
    if (v instanceof Data_Either.Left) {
        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(v.value0);
    };
    if (v instanceof Data_Either.Right) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0);
    };
    throw new Error("Failed pattern match at Effect.Aff.Monads.Class (line 15, column 1 - line 17, column 32): " + [ v.constructor.name ]);
});
var liftMaybe = function (dict) {
    return dict.liftMaybe;
};
var liftEither = function (dict) {
    return dict.liftEither;
};
module.exports = {
    liftEither: liftEither,
    liftMaybe: liftMaybe,
    MonadEither: MonadEither,
    MonadMaybe: MonadMaybe,
    monadEitherAff: monadEitherAff,
    monadMaybeAff: monadMaybeAff
};
