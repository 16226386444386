// Generated by purs version 0.13.8
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Foreign = require("../Foreign/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var EnumReadForeign = function (enumReadForeignImpl) {
    this.enumReadForeignImpl = enumReadForeignImpl;
};
var enumReadForeignImpl = function (dict) {
    return dict.enumReadForeignImpl;
};
var sumEnumReadForeign = function (dictEnumReadForeign) {
    return function (dictEnumReadForeign1) {
        return new EnumReadForeign(function (f) {
            return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.Inl.create)(enumReadForeignImpl(dictEnumReadForeign)(f)))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.Inr.create)(enumReadForeignImpl(dictEnumReadForeign1)(f)));
        });
    };
};
var enumReadForeign = function (dictGeneric) {
    return function (dictEnumReadForeign) {
        return function (f) {
            return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.to(dictGeneric))(enumReadForeignImpl(dictEnumReadForeign)(f));
        };
    };
};
var constructorEnumReadForeign = function (dictIsSymbol) {
    return new EnumReadForeign(function (f) {
        var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Simple_JSON.readImpl(Simple_JSON.readString)(f))(function (s) {
            var $5 = Data_String_Common.toLower(s) === Data_String_Common.toLower(name);
            if ($5) {
                return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Generic_Rep.NoArguments.value);
            };
            return Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity))(Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList)(Foreign.ForeignError.create("Enum string " + (s + (" did not match expected string " + name)))));
        });
    });
};
module.exports = {
    enumReadForeignImpl: enumReadForeignImpl,
    enumReadForeign: enumReadForeign,
    EnumReadForeign: EnumReadForeign,
    sumEnumReadForeign: sumEnumReadForeign,
    constructorEnumReadForeign: constructorEnumReadForeign
};
