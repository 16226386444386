// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_CompanyOnboardModal = require("../Model.CompanyOnboardModal/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var rmSpaces = Data_String_Common.replaceAll(" ")("");
var parseAbnError = function (v) {
    return function (v1) {
        if (v1 >= 500) {
            return Model_CompanyOnboardModal.Retryable.value;
        };
        return v;
    };
};
var initOnBoardModal = function (a) {
    var defaultState = {
        display: false,
        spinner: {
            display: false
        },
        verificationCheckbox: {
            checked: false
        },
        userRole: Data_Maybe.Nothing.value,
        content: new Model_CompanyOnboardModal.AbnInitial({
            number: "",
            disabled: false,
            isFormatted: true
        })
    };
    if (a instanceof Data_Maybe.Just && a.value0.status instanceof Model_CompanyStatus.Unregistered) {
        return {
            display: true,
            spinner: defaultState.spinner,
            userRole: defaultState.userRole,
            content: defaultState.content,
            verificationCheckbox: defaultState.verificationCheckbox
        };
    };
    if (a instanceof Data_Maybe.Just && a.value0.status instanceof Model_CompanyStatus.CreditCheckFailed) {
        return {
            display: true,
            spinner: defaultState.spinner,
            userRole: defaultState.userRole,
            content: new Model_CompanyOnboardModal.AbnError({
                number: a.value0.abn,
                disabled: false,
                isFormatted: true
            }, Model_CompanyOnboardModal.CreditScoreCheckFail.value),
            verificationCheckbox: defaultState.verificationCheckbox
        };
    };
    return defaultState;
};
var checkAbnOnline = function (abn) {
    return function (state) {
        var completedInput = {
            number: abn,
            disabled: false,
            isFormatted: true
        };
        var checkEntityType = function (v) {
            if (v instanceof Data_Either.Right && (v.value0.entityTypeCode === "IND" || v.value0.entityTypeCode === "PRV")) {
                return {
                    companyList: state.companyList,
                    isLoading: state.isLoading,
                    selectedCompany: state.selectedCompany,
                    onboardModal: {
                        display: state.onboardModal.display,
                        spinner: state.onboardModal.spinner,
                        userRole: state.onboardModal.userRole,
                        content: new Model_CompanyOnboardModal.AbnValidated(completedInput, v.value0),
                        verificationCheckbox: state.onboardModal.verificationCheckbox
                    },
                    stepOnboardModal: state.stepOnboardModal
                };
            };
            if (v instanceof Data_Either.Right) {
                return {
                    companyList: state.companyList,
                    isLoading: state.isLoading,
                    selectedCompany: state.selectedCompany,
                    onboardModal: {
                        display: state.onboardModal.display,
                        spinner: state.onboardModal.spinner,
                        userRole: state.onboardModal.userRole,
                        content: new Model_CompanyOnboardModal.AbnUnsupported(completedInput, v.value0),
                        verificationCheckbox: state.onboardModal.verificationCheckbox
                    },
                    stepOnboardModal: state.stepOnboardModal
                };
            };
            return {
                companyList: state.companyList,
                isLoading: state.isLoading,
                selectedCompany: state.selectedCompany,
                onboardModal: {
                    display: state.onboardModal.display,
                    spinner: state.onboardModal.spinner,
                    userRole: state.onboardModal.userRole,
                    content: new Model_CompanyOnboardModal.AbnError(completedInput, Model_CompanyOnboardModal.Retryable.value),
                    verificationCheckbox: state.onboardModal.verificationCheckbox
                },
                stepOnboardModal: state.stepOnboardModal
            };
        };
        var toViewModel = function (resp) {
            if (resp.status === 200) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(checkEntityType(Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "abn";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "acn";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "businessName";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "businessType";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "entityTypeCode";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "location";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())()()))(resp.body)));
            };
            if (resp.status === 401) {
                return Control_Apply.applyFirst(Effect_Aff.applyAff)(Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    onboardModal: {
                        content: Model_CompanyOnboardModal.AbnError.create(completedInput)(parseAbnError(Model_CompanyOnboardModal.Retryable.value)(401)),
                        display: state.onboardModal.display,
                        spinner: state.onboardModal.spinner,
                        userRole: state.onboardModal.userRole,
                        verificationCheckbox: state.onboardModal.verificationCheckbox
                    },
                    companyList: state.companyList,
                    isLoading: state.isLoading,
                    selectedCompany: state.selectedCompany,
                    stepOnboardModal: state.stepOnboardModal
                }))(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Helper_AuthActions.login(Data_Maybe.Nothing.value)));
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                onboardModal: {
                    content: Model_CompanyOnboardModal.AbnError.create(completedInput)(parseAbnError(Model_CompanyOnboardModal.AbnCancelled.value)(resp.status)),
                    display: state.onboardModal.display,
                    spinner: state.onboardModal.spinner,
                    userRole: state.onboardModal.userRole,
                    verificationCheckbox: state.onboardModal.verificationCheckbox
                },
                companyList: state.companyList,
                isLoading: state.isLoading,
                selectedCompany: state.selectedCompany,
                stepOnboardModal: state.stepOnboardModal
            });
        };
        return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.recover({
            body: "",
            status: 500
        })(Fetch.get()("/abn/" + rmSpaces(abn))({})))(function (resp) {
            return toViewModel(resp);
        });
    };
};
var checkAbnFormat = (function () {
    var hasLen11 = function (str) {
        return Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(Data_String_Regex.test)(Data_String_Regex.regex("^\\d{11}$")(Data_String_Regex_Flags.noFlags)))(Control_Applicative.pure(Data_Either.applicativeEither)(str));
    };
    return function ($29) {
        return hasLen11(rmSpaces($29));
    };
})();
var hasFormatError = function (abn) {
    return abn !== "" && (function () {
        var v = checkAbnFormat(abn);
        if (v instanceof Data_Either.Right && v.value0) {
            return false;
        };
        return true;
    })();
};
module.exports = {
    checkAbnOnline: checkAbnOnline,
    hasFormatError: hasFormatError,
    checkAbnFormat: checkAbnFormat,
    rmSpaces: rmSpaces,
    parseAbnError: parseAbnError,
    initOnBoardModal: initOnBoardModal
};
