import { Modal } from '@myob/myob-widgets'
import React, { useContext } from 'react'
import { context } from '../pages/Dashboard'
import { refreshPage, showPageSpinner } from 'purs/Dashboard'
import errorOwnerInfo from '../img/error-owner-info.svg'
import './OwnerInfoGenerateFailedModal.sass'

const OwnerInfoGenerateFailedModal = () => {
  const { dispatch } = useContext(context)
  return (
    <Modal
      title="Information wasn&#39;t available"
      onCancel={() => {
        dispatch(showPageSpinner)
        dispatch(refreshPage)
      }}
      modalId="owner-info-generated-failed-modal"
    >
      <Modal.Body>
        <img className="message__image" src={errorOwnerInfo} alt="" />
        <p>
          <b>We&#39;ve hit a roadblock!</b>
        </p>
        <p>
          Please get in touch with support at <br/>
          <a href="tel:1300-783-674">1300 783 674</a> to continue your application.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          id="btn-owner-info-failed-modal-confirm"
          onClick={() => {
            dispatch(showPageSpinner)
            dispatch(refreshPage)
          }}
        >
          Got it
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default OwnerInfoGenerateFailedModal
