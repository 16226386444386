// Generated by purs version 0.13.8
"use strict";
var Config = require("../Config/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Lens_Getter = require("../Data.Lens.Getter/index.js");
var Data_Lens_Internal_Forget = require("../Data.Lens.Internal.Forget/index.js");
var Data_Lens_Prism_Maybe = require("../Data.Lens.Prism.Maybe/index.js");
var Data_Lens_Record = require("../Data.Lens.Record/index.js");
var Data_Lens_Setter = require("../Data.Lens.Setter/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Model_CompanySubscription = require("../Model.CompanySubscription/index.js");
var Model_Status = require("../Model.Status/index.js");
var faqsLink = function (v) {
    if (v instanceof Data_Maybe.Just && Model_CompanySubscription.isNewEssentialsBusiness(v.value0.product)) {
        return Config.config.newEssentialPaymentFaqsUrl;
    };
    return Config.config.arlPaymentFaqsUrl;
};
var _selectSortField = function (v) {
    if (v === "requestedBy") {
        return Data_Ord.comparing(Data_Ord.ordString)(function (v1) {
            return v1.requestedBy;
        });
    };
    if (v === "submittedDate") {
        return Data_Ord.comparing(Data_Maybe.ordMaybe(Data_Ord.ordString))(function (v1) {
            return v1.submittedDate;
        });
    };
    if (v === "authorisedDate") {
        return Data_Ord.comparing(Data_Ord.ordString)((function () {
            var $13 = Data_Lens_Getter.view(Data_Lens_Prism_Maybe["_Just"](Data_Lens_Internal_Forget.choiceForget(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
                return "authorisedDate";
            }))(Data_Monoid.monoidString)()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
                return "email";
            }))(Data_Monoid.monoidString)()(Data_Monoid.monoidRecordCons(new Data_Symbol.IsSymbol(function () {
                return "id";
            }))(Data_Monoid.monoidString)()(Data_Monoid.monoidRecordNil)))))));
            return function ($14) {
                return (function (v1) {
                    return v1.authorisedDate;
                })($13(Data_Array.head((function (v1) {
                    return v1.authoriserDetails;
                })($14))));
            };
        })());
    };
    if (v === "totalAmount") {
        return Data_Ord.comparing(Data_Ord.ordInt)(function (v1) {
            return v1.totalAmount;
        });
    };
    if (v === "status") {
        return Data_Ord.comparing(Model_Status.ordBatchStatus)(function (v1) {
            return v1.status;
        });
    };
    return Data_Ord.comparing(Data_Ord.ordString)(function (v1) {
        return v1.journalMemo;
    });
};
var sortBatchBy = function (key) {
    return function (descending) {
        var order = (function () {
            if (descending) {
                return Control_Category.identity(Control_Category.categoryFn);
            };
            return Data_Array.reverse;
        })();
        var batchesField = Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
            return "batches";
        }))()()(Data_Symbol.SProxy.value);
        var sort = Data_Lens_Setter.over(batchesField(Data_Profunctor_Strong.strongFn))((function () {
            var $15 = Data_Array.sortBy(_selectSortField(key));
            return function ($16) {
                return order($15($16));
            };
        })());
        var $17 = Control_Applicative.pure(Effect_Aff.applicativeAff);
        return function ($18) {
            return $17(sort($18));
        };
    };
};
module.exports = {
    sortBatchBy: sortBatchBy,
    "_selectSortField": _selectSortField,
    faqsLink: faqsLink
};
