// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Monad_Maybe_Trans = require("../Control.Monad.Maybe.Trans/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var note = function (a) {
    return Data_Maybe.maybe(new Data_Either.Left(a))(Data_Either.Right.create);
};
var noteT = function (dictMonad) {
    return function (a) {
        var $6 = Control_Monad.liftM1(dictMonad)(note(a));
        return function ($7) {
            return Control_Monad_Except_Trans.ExceptT($6(Control_Monad_Maybe_Trans.runMaybeT($7)));
        };
    };
};
var hush = Data_Either.either(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
var hushT = function (dictMonad) {
    var $8 = Control_Monad.liftM1(dictMonad)(hush);
    return function ($9) {
        return Control_Monad_Maybe_Trans.MaybeT($8(Control_Monad_Except_Trans.runExceptT($9)));
    };
};
var hoistMaybe = function (dictMonad) {
    var $10 = Control_Applicative.pure(dictMonad.Applicative0());
    return function ($11) {
        return Control_Monad_Maybe_Trans.MaybeT($10($11));
    };
};
var fromMaybe$prime = Data_Function.flip(Data_Maybe.fromMaybe);
var exceptNoteM = function (dictApplicative) {
    return function (a) {
        return function (e) {
            return Control_Applicative.pure(dictApplicative)(note(e)(a));
        };
    };
};
var exceptNoteA = function (dictApply) {
    return function (a) {
        return function (e) {
            return Data_Functor.map(dictApply.Functor0())(note(e))(a);
        };
    };
};
var bool = function (a) {
    return function (b) {
        return function (c) {
            if (c) {
                return b;
            };
            return a;
        };
    };
};
module.exports = {
    hush: hush,
    hushT: hushT,
    note: note,
    noteT: noteT,
    hoistMaybe: hoistMaybe,
    "fromMaybe'": fromMaybe$prime,
    exceptNoteA: exceptNoteA,
    exceptNoteM: exceptNoteM,
    bool: bool
};
