import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from '@myob/myob-widgets'
import './AddCardModal.sass'
import { Danger } from 'purs/AlertBanner'
import AlertBanner from './AlertBanner'
import CardFormPanel from './CardFormPanel'
import { fetchPaymentLink } from 'purs/CardManage'
import { initState } from 'purs/Page.BusinessManage'
import { runAff_ } from 'purs/Effect.Aff'
import { Just } from 'purs/Data.Maybe'
import * as r from 'ramda'

const AddCardModal = ({ companyId, onClose }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [cardIframeLink, setCardIframeLink] = useState(null)

  useEffect(() => {
    runAff_(a => {
      let link = r.path(['value0', 'newCardModal', 'cardIframeLink', 'value0'])(
        a
      )
      if (link) {
        return () => {
          setIsLoading(false)
          setCardIframeLink(link)
          setError(null)
        }
      } else
        return () => {
          setIsLoading(false)
          setError(500)
        }
    })(fetchPaymentLink({ ...initState, companyId: Just.create(companyId) }))()
  }, [])
  return (
    <Modal modalId="add-card" title="Add a Card" onCancel={() => onClose()}>
      <Modal.Body>
        {isLoading ? (
          <Spinner />
        ) : error ? (
          <AlertBanner type={Danger.value}>
            <span>
              Oops, we couldn&#39;t confirm your permission. For access to
              payment methods, chat to your administrator or contact support on{' '}
              <a href="tel:1300783674">1300 783 674</a> for assistance.
            </span>
          </AlertBanner>
        ) : (
          <CardFormPanel
            iframeLink={cardIframeLink}
            enableTermsAndConditionCheck={false}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          id="btn-add-card-modal-close"
          type="button"
          className="btn btn-close"
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddCardModal
