import React from 'react'
import { LeanEngageSurvey } from '@myob/myob-widgets'
import './LeanEngage.sass'
import config from '../config'
import * as r from 'ramda'

const LeanEngage = state => {
  const productName = 'Payment service'

  if (state && !r.isEmpty(state.companyId) && !r.isNil(state.companyId)) {
    window.leanengage('create', config.leanEngageSurveyId)
    window.leanengage('identify', state.userId, {
      name: state.companyName || 'Username', // It should be username, not companyName
      email: state.userId,
      company: {
        company_id: state.companyId,
        name: state.companyName
      }
    })
  }

  return (
    <div className="flx-container">
      <LeanEngageSurvey
        className="lean-engage-item"
        productName={productName}
        surveyName="feedback"
        surveyType="survey"
      />
    </div>
  )
}

export default LeanEngage
