import React, { useContext } from 'react'
import { Spinner } from '@myob/myob-widgets'
import withPsState from '../withPsState'
import AlertBanner from '../components/AlertBanner'
import './NewCardRedirect.sass'
import {
  initState,
  finalizeOnBoarding,
  finalizeOnBoardingThroughNPR,
  UnsupportedPaymentError
} from 'purs/NewCardRedirect'
import * as maybe from 'purs/Data.Maybe'
import { Danger, Success } from 'purs/AlertBanner'

const SuccessAlert = () => (
  <AlertBanner type={Success.value} dismissLater={false}>
    <span>Hooray! Your payment method was successfully added.</span>
  </AlertBanner>
)

const FailedAlert = ({ error }) => {
  if (error instanceof UnsupportedPaymentError) {
    return (
      <AlertBanner type={Danger.value}>
        Oops, looks like we couldn&apos;t verify your card details. Please try a different card.
      </AlertBanner>
    )
  } else {
    return <AlertBanner type={Danger.value}>{error.value0}</AlertBanner>
  }
}

function SaveCardResp() {
  const { state } = useContext(context)
  if (maybe.isNothing(state.error)) {
    return <SuccessAlert />
  } else {
    return <FailedAlert error={state.error.value0} />
  }
}

function NewCardRedirect() {
  const { state } = useContext(context)
  return (
    <div className="card-result">
      {state.spinner.display ? <Spinner /> : <SaveCardResp />}
    </div>
  )
}

export const { context, Component } = withPsState(
  NewCardRedirect,
  initState,
  props => {
    const query = props.location.search
    return query.includes("payment-rails-transaction-id") ? finalizeOnBoardingThroughNPR(query) : finalizeOnBoarding(query)
  }
)

export default Component
