// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_User = require("../Model.User/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var ResponseError = (function () {
    function ResponseError(value0) {
        this.value0 = value0;
    };
    ResponseError.create = function (value0) {
        return new ResponseError(value0);
    };
    return ResponseError;
})();
var BadRequestError = (function () {
    function BadRequestError(value0) {
        this.value0 = value0;
    };
    BadRequestError.create = function (value0) {
        return new BadRequestError(value0);
    };
    return BadRequestError;
})();
var SuccessResponse = (function () {
    function SuccessResponse() {

    };
    SuccessResponse.value = new SuccessResponse();
    return SuccessResponse;
})();
var updateUserRole = function (state) {
    var sendRequest = function (company) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.put()("/company/" + (company + ("/user/" + state.user.id)))(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
            return "email";
        }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
            return "name";
        }))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignString))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
            return "role";
        }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()())()()())()()()))({
            email: state.user.email,
            role: Data_String_Common.toLower(Data_Show.show(Model_User.roleShow)(state.selectedRole)),
            name: state.user.name
        }))({}))(function (resp) {
            if (resp.status === 204) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    responseType: new Data_Maybe.Just(SuccessResponse.value),
                    selectedRole: state.selectedRole,
                    user: state.user
                });
            };
            if (resp.status === 400) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    responseType: new Data_Maybe.Just(new BadRequestError(resp.body)),
                    selectedRole: state.selectedRole,
                    user: state.user
                });
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                responseType: new Data_Maybe.Just(new ResponseError({
                    code: resp.status
                })),
                selectedRole: state.selectedRole,
                user: state.user
            });
        });
    };
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompany))(function (v) {
        if (v instanceof Data_Either.Right) {
            return sendRequest(v.value0.companyId);
        };
        if (v instanceof Data_Either.Left) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                responseType: new Data_Maybe.Just(new ResponseError({
                    code: 500
                })),
                selectedRole: state.selectedRole,
                user: state.user
            });
        };
        throw new Error("Failed pattern match at Page.Manage.User.Details (line 66, column 51 - line 68, column 77): " + [ v.constructor.name ]);
    });
};
var updateState = function (role) {
    return function (state) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            selectedRole: role,
            responseType: Data_Maybe.Nothing.value,
            user: state.user
        });
    };
};
var isUpdatingSelf = function (userToUpdate) {
    var rmQuotes = Data_String_Common.replaceAll("\"")("");
    var isUser = Data_Functor.map(Data_Maybe.functorMaybe)(function (user) {
        return rmQuotes(user) === userToUpdate;
    });
    return Data_Functor.map(Effect.functorEffect)((function () {
        var $11 = Data_Maybe.fromMaybe(false);
        return function ($12) {
            return $11(isUser($12));
        };
    })())(SessionStorage.getUserId);
};
var isPermissionActive = function (role) {
    return function (permission) {
        return Data_Ord.lessThanOrEq(Model_User.ordRole)(role)(permission.role);
    };
};
var initState = {
    user: {
        id: "",
        name: Data_Maybe.Nothing.value,
        email: "",
        role: Model_User.None.value,
        nominatedRole: Model_User.None.value,
        kycStatus: Model_User.NotRequired.value,
        status: Model_User.Active.value
    },
    selectedRole: Model_User.Submitter.value,
    responseType: Data_Maybe.Nothing.value
};
var setInitialState = function (user) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        user: user,
        selectedRole: user.nominatedRole,
        responseType: initState.responseType
    });
};
var allPermissions = [ {
    desc: "View Business List",
    role: Model_User.None.value
}, {
    desc: "View Batch List",
    role: Model_User.Submitter.value
}, {
    desc: "Submit Batch",
    role: Model_User.Submitter.value
}, {
    desc: "Authorise Payment",
    role: Model_User.Authoriser.value
}, {
    desc: "Manage Cards",
    role: Model_User.Authoriser.value
}, {
    desc: "Manage User Roles",
    role: Model_User.Administrator.value
}, {
    desc: "Manage Business Settings",
    role: Model_User.Administrator.value
} ];
module.exports = {
    ResponseError: ResponseError,
    BadRequestError: BadRequestError,
    SuccessResponse: SuccessResponse,
    allPermissions: allPermissions,
    initState: initState,
    setInitialState: setInitialState,
    updateState: updateState,
    updateUserRole: updateUserRole,
    isUpdatingSelf: isUpdatingSelf,
    isPermissionActive: isPermissionActive
};
