import React, { useEffect } from 'react'
import { Modal, Icons } from '@myob/myob-widgets'
import './AddContactModal.sass'
import PropTypes from 'prop-types'
import config from '../config'

/* istanbul ignore next */
const resizeIframe = event => {
  event.currentTarget.style.height =
    event.currentTarget.contentWindow.document.body.scrollHeight + 'px'
  event.currentTarget.contentWindow.document.body.classList.add(
    'kyciframe-body'
  )
}

const AddContactModal = ({ callBack, iframeUrl, isAdmin }) => {
  /* istanbul ignore next */
  const kycEventHandler = event => {
    if (event.origin !== config.uiHost) return
    if (event.data.message === 'kycmodal-close') {
      callBack({ status: event.data.status, body: event.data.body })
    }
  }

  window.addEventListener('message', kycEventHandler, false)

  /* istanbul ignore next */
  useEffect(() => () => {
    window.removeEventListener('message', kycEventHandler, false)
  })

  return (
    <Modal
      header={
        <div className="kycmodal__header">
          <span className="kycmodal__header__title">{isAdmin ? 'Your identity verification' : 'Identity Verification'}</span>
          <Icons.Lock />
          <button id="btn-add-contact-modal-close" className="close" onClick={() => callBack()}>
            <Icons.Close />
          </button>
        </div>
      }
      onCancel={() => callBack()}
    >
      <iframe
        title="kycmodal"
        id="kycmodal-iframe"
        width="100%"
        src={iframeUrl}
        onLoad={resizeIframe}
      />
    </Modal>
  )
}

AddContactModal.propTypes = {
  callBack: PropTypes.func.isRequired,
  iframeUrl: PropTypes.string.isRequired
}

export default AddContactModal
