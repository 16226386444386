import React from "react";
import {CardChip, BankAccountChip} from './PaymentMethodChip'

const DefaultPaymentMethods = ({paymentMethods}) => {
  const allCards = paymentMethods?.cards || []
  const allBankAccounts = paymentMethods?.bankAccounts || []

  const card = allCards.filter(i => i.isDefault).map(c => (<CardChip card={c} />))
  const bankAccount = allBankAccounts.filter(i => i.isDefault).map(b => <BankAccountChip bankAccount={b}/>)
  return <> {card} {bankAccount} </>
}

export default DefaultPaymentMethods
