// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var PreferenceUpdated = (function () {
    function PreferenceUpdated() {

    };
    PreferenceUpdated.value = new PreferenceUpdated();
    return PreferenceUpdated;
})();
var InsufficientAuthorisers = (function () {
    function InsufficientAuthorisers() {

    };
    InsufficientAuthorisers.value = new InsufficientAuthorisers();
    return InsufficientAuthorisers;
})();
var PendingBatches = (function () {
    function PendingBatches() {

    };
    PendingBatches.value = new PendingBatches();
    return PendingBatches;
})();
var updateNumAuthorisers = function (num) {
    return function (s) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            numAuthorisers: num,
            alert: Data_Maybe.Nothing.value,
            companyName: s.companyName,
            companyPreference: s.companyPreference,
            errorCode: s.errorCode,
            spinner: s.spinner
        });
    };
};
var initState = {
    companyName: Data_Maybe.Nothing.value,
    companyPreference: Data_Maybe.Nothing.value,
    spinner: {
        display: true
    },
    numAuthorisers: 1,
    alert: Data_Maybe.Nothing.value,
    errorCode: Data_Maybe.Nothing.value
};
var getCompany = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompany);
var genAlertType = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof PreferenceUpdated) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof InsufficientAuthorisers) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof PendingBatches) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at Page.Preference (line 27, column 1 - line 27, column 52): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return PreferenceUpdated.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return InsufficientAuthorisers.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return PendingBatches.value;
    };
    throw new Error("Failed pattern match at Page.Preference (line 27, column 1 - line 27, column 52): " + [ x.constructor.name ]);
});
var readForeignAlertType = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genAlertType)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "PreferenceUpdated";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "InsufficientAuthorisers";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "PendingBatches";
}))))));
var _stepUp = function (s) {
    return Data_Functor.map(Effect_Aff.functorAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"]({
        errorCode: new Data_Maybe.Just(500),
        alert: s.alert,
        companyName: s.companyName,
        companyPreference: s.companyPreference,
        numAuthorisers: s.numAuthorisers,
        spinner: s.spinner
    }))(Data_Function["const"](s)))(Helper_AuthActions.stepUp()("/business-details")({}));
};
var getPreference = function (s) {
    return function (company) {
        var sendRequest = Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.get()("/company/" + (company.companyId + "/preferences"))({}));
        var companyNameStatus = {
            companyName: new Data_Maybe.Just(company.name),
            alert: s.alert,
            companyPreference: s.companyPreference,
            errorCode: s.errorCode,
            numAuthorisers: s.numAuthorisers,
            spinner: s.spinner
        };
        var hideSpinnerStatus = {
            spinner: {
                display: false
            },
            alert: companyNameStatus.alert,
            companyName: companyNameStatus.companyName,
            companyPreference: companyNameStatus.companyPreference,
            errorCode: companyNameStatus.errorCode,
            numAuthorisers: companyNameStatus.numAuthorisers
        };
        var errorState = function (v) {
            return {
                errorCode: new Data_Maybe.Just(500),
                alert: hideSpinnerStatus.alert,
                companyName: hideSpinnerStatus.companyName,
                companyPreference: hideSpinnerStatus.companyPreference,
                numAuthorisers: hideSpinnerStatus.numAuthorisers,
                spinner: hideSpinnerStatus.spinner
            };
        };
        var successState = function (p) {
            return {
                companyPreference: new Data_Maybe.Just(p),
                errorCode: Data_Maybe.Nothing.value,
                numAuthorisers: p.authoriserRequired,
                alert: hideSpinnerStatus.alert,
                companyName: hideSpinnerStatus.companyName,
                spinner: hideSpinnerStatus.spinner
            };
        };
        var parseResult = function (v) {
            if (v instanceof Data_Either.Right && v.value0.status === 200) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(errorState)(successState)(Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "authoriserRequired";
                }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "businessDetails";
                }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "abn";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "acn";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "entityType";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "location";
                }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "status";
                }))(Model_CompanyStatus.readForeignCompanyStatus)(Simple_JSON.readFieldsNil)()())()())()())()())()())()()))(Simple_JSON.readFieldsNil)()())()()))(v.value0.body)));
            };
            if (v instanceof Data_Either.Right && v.value0.status === 401) {
                return _stepUp(hideSpinnerStatus);
            };
            if (v instanceof Data_Either.Right) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    errorCode: new Data_Maybe.Just(v.value0.status),
                    alert: hideSpinnerStatus.alert,
                    companyName: hideSpinnerStatus.companyName,
                    companyPreference: hideSpinnerStatus.companyPreference,
                    numAuthorisers: hideSpinnerStatus.numAuthorisers,
                    spinner: hideSpinnerStatus.spinner
                });
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                errorCode: new Data_Maybe.Just(500),
                alert: hideSpinnerStatus.alert,
                companyName: hideSpinnerStatus.companyName,
                companyPreference: hideSpinnerStatus.companyPreference,
                numAuthorisers: hideSpinnerStatus.numAuthorisers,
                spinner: hideSpinnerStatus.spinner
            });
        };
        return Control_Bind.bindFlipped(Effect_Aff.bindAff)(parseResult)(sendRequest);
    };
};
var onPageLoad = function (s) {
    return Control_Bind.bind(Effect_Aff.bindAff)(Model_Company.getCompanyWithErrorHandle)((function () {
        var $46 = Data_Maybe.fromMaybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(s));
        var $47 = Data_Functor.map(Data_Maybe.functorMaybe)(getPreference(s));
        return function ($48) {
            return $46($47($48));
        };
    })());
};
var updatePreference = function (s) {
    var sendRequest = function (companyId) {
        return Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.post()("/company/" + (companyId + "/preferences"))(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
            return "authoriserRequired";
        }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()()))({
            authoriserRequired: s.numAuthorisers
        }))({}));
    };
    var hideSpinnerAndAlert = {
        spinner: {
            display: false
        },
        alert: Data_Maybe.Nothing.value,
        companyName: s.companyName,
        companyPreference: s.companyPreference,
        errorCode: s.errorCode,
        numAuthorisers: s.numAuthorisers
    };
    var successState = function (p) {
        return {
            alert: new Data_Maybe.Just({
                alertType: p
            }),
            spinner: hideSpinnerAndAlert.spinner,
            companyName: hideSpinnerAndAlert.companyName,
            companyPreference: hideSpinnerAndAlert.companyPreference,
            errorCode: hideSpinnerAndAlert.errorCode,
            numAuthorisers: hideSpinnerAndAlert.numAuthorisers
        };
    };
    var errorState = function (v) {
        return {
            errorCode: new Data_Maybe.Just(500),
            alert: hideSpinnerAndAlert.alert,
            companyName: hideSpinnerAndAlert.companyName,
            companyPreference: hideSpinnerAndAlert.companyPreference,
            numAuthorisers: hideSpinnerAndAlert.numAuthorisers,
            spinner: hideSpinnerAndAlert.spinner
        };
    };
    var parseResult = function (v) {
        if (v instanceof Data_Either.Right && v.value0.status === 204) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                companyPreference: Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                    return {
                        authoriserRequired: s.numAuthorisers,
                        businessDetails: v2.businessDetails
                    };
                })(s.companyPreference),
                alert: new Data_Maybe.Just({
                    alertType: PreferenceUpdated.value
                }),
                errorCode: Data_Maybe.Nothing.value,
                companyName: hideSpinnerAndAlert.companyName,
                numAuthorisers: hideSpinnerAndAlert.numAuthorisers,
                spinner: hideSpinnerAndAlert.spinner
            });
        };
        if (v instanceof Data_Either.Right && v.value0.status === 401) {
            return _stepUp(s);
        };
        if (v instanceof Data_Either.Right && v.value0.status === 400) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(errorState)(successState)(Simple_JSON.readJSON(readForeignAlertType)(v.value0.body)));
        };
        if (v instanceof Data_Either.Right) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                errorCode: new Data_Maybe.Just(v.value0.status),
                alert: hideSpinnerAndAlert.alert,
                companyName: hideSpinnerAndAlert.companyName,
                companyPreference: hideSpinnerAndAlert.companyPreference,
                numAuthorisers: hideSpinnerAndAlert.numAuthorisers,
                spinner: hideSpinnerAndAlert.spinner
            });
        };
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            errorCode: new Data_Maybe.Just(500),
            alert: hideSpinnerAndAlert.alert,
            companyName: hideSpinnerAndAlert.companyName,
            companyPreference: hideSpinnerAndAlert.companyPreference,
            numAuthorisers: hideSpinnerAndAlert.numAuthorisers,
            spinner: hideSpinnerAndAlert.spinner
        });
    };
    var update = (function () {
        var $49 = Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(s)))(Control_Bind.composeKleisli(Effect_Aff.bindAff)(function ($52) {
            return sendRequest((function (v) {
                return v.companyId;
            })($52));
        })(parseResult));
        var $50 = Data_Either.note(Data_Unit.unit);
        return function ($51) {
            return $49($50($51));
        };
    })();
    return Control_Bind.join(Effect_Aff.bindAff)(Data_Functor.map(Effect_Aff.functorAff)(update)(Model_Company.getCompanyWithErrorHandle));
};
module.exports = {
    initState: initState,
    PreferenceUpdated: PreferenceUpdated,
    InsufficientAuthorisers: InsufficientAuthorisers,
    PendingBatches: PendingBatches,
    onPageLoad: onPageLoad,
    updatePreference: updatePreference,
    updateNumAuthorisers: updateNumAuthorisers,
    genAlertType: genAlertType,
    readForeignAlertType: readForeignAlertType
};
