// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Fetch = require("../Fetch/index.js");
var Foreign = require("../Foreign/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Record = require("../Record/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Range = (function () {
    function Range(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Range.create = function (value0) {
        return function (value1) {
            return new Range(value0, value1);
        };
    };
    return Range;
})();
var SoleTraderInfo = (function () {
    function SoleTraderInfo() {

    };
    SoleTraderInfo.value = new SoleTraderInfo();
    return SoleTraderInfo;
})();
var CompanyInfo = (function () {
    function CompanyInfo() {

    };
    CompanyInfo.value = new CompanyInfo();
    return CompanyInfo;
})();
var CompanyNoIndustryInfo = (function () {
    function CompanyNoIndustryInfo() {

    };
    CompanyNoIndustryInfo.value = new CompanyNoIndustryInfo();
    return CompanyNoIndustryInfo;
})();
var SelectRange = (function () {
    function SelectRange(value0) {
        this.value0 = value0;
    };
    SelectRange.create = function (value0) {
        return new SelectRange(value0);
    };
    return SelectRange;
})();
var EnterSmallInt = (function () {
    function EnterSmallInt(value0) {
        this.value0 = value0;
    };
    EnterSmallInt.create = function (value0) {
        return new EnterSmallInt(value0);
    };
    return EnterSmallInt;
})();
var SelectBusinessType = (function () {
    function SelectBusinessType(value0) {
        this.value0 = value0;
    };
    SelectBusinessType.create = function (value0) {
        return new SelectBusinessType(value0);
    };
    return SelectBusinessType;
})();
var SelectBusinessCategory = (function () {
    function SelectBusinessCategory(value0) {
        this.value0 = value0;
    };
    SelectBusinessCategory.create = function (value0) {
        return new SelectBusinessCategory(value0);
    };
    return SelectBusinessCategory;
})();
var validateInputs = function (modal) {
    var industryValid = (function () {
        if (modal.requiredInfo.industrySection.required) {
            return Data_Maybe.isJust(modal.requiredInfo.industrySection.businessType) && Data_Maybe.isJust(modal.requiredInfo.industrySection.businessCategory);
        };
        if (!modal.requiredInfo.industrySection.required) {
            return true;
        };
        throw new Error("Failed pattern match at Component.AboutBusinessModal (line 179, column 21 - line 181, column 20): " + [ modal.requiredInfo.industrySection.required.constructor.name ]);
    })();
    var detailValid = (function () {
        if (modal.requiredInfo.businessDetailSection.paymentFrequency instanceof Data_Maybe.Just && (modal.requiredInfo.businessDetailSection.averagePayment instanceof Data_Maybe.Just && modal.requiredInfo.businessDetailSection.numTransactions instanceof Data_Maybe.Just)) {
            return true;
        };
        return false;
    })();
    var addressValid = (function () {
        if (modal.requiredInfo.addressSection.required) {
            return Data_Maybe.isJust(modal.requiredInfo.addressSection.address);
        };
        if (!modal.requiredInfo.addressSection.required) {
            return true;
        };
        throw new Error("Failed pattern match at Component.AboutBusinessModal (line 175, column 20 - line 177, column 20): " + [ modal.requiredInfo.addressSection.required.constructor.name ]);
    })();
    if (modal.entityType instanceof SoleTraderInfo && modal.currentStep === 1) {
        return addressValid && industryValid;
    };
    if (modal.entityType instanceof CompanyNoIndustryInfo && modal.currentStep === 1) {
        return addressValid && industryValid;
    };
    return addressValid && (industryValid && detailValid);
};
var updateAddress = function (address) {
    return function (s) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
            var $36 = {};
            for (var $37 in s) {
                if ({}.hasOwnProperty.call(s, $37)) {
                    $36[$37] = s[$37];
                };
            };
            $36.aboutBusinessModal = {
                requiredInfo: {
                    addressSection: {
                        address: (function () {
                            var $35 = Data_String_CodePoints.length(address) > 0;
                            if ($35) {
                                return new Data_Maybe.Just(address);
                            };
                            return Data_Maybe.Nothing.value;
                        })(),
                        required: s.aboutBusinessModal.requiredInfo.addressSection.required
                    },
                    businessDetailSection: s.aboutBusinessModal.requiredInfo.businessDetailSection,
                    industrySection: s.aboutBusinessModal.requiredInfo.industrySection
                },
                currentStep: s.aboutBusinessModal.currentStep,
                display: s.aboutBusinessModal.display,
                entityType: s.aboutBusinessModal.entityType,
                httpError: s.aboutBusinessModal.httpError,
                showContinueBtn: s.aboutBusinessModal.showContinueBtn
            };
            return $36;
        })());
    };
};
var showRange = new Simple_JSON.WriteForeign(function (v) {
    if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Nothing) {
        return Foreign.unsafeToForeign({});
    };
    if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Just) {
        return Foreign.unsafeToForeign({
            max: v.value1.value0
        });
    };
    if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Nothing) {
        return Foreign.unsafeToForeign({
            min: v.value0.value0
        });
    };
    if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Just) {
        return Foreign.unsafeToForeign({
            min: v.value0.value0,
            max: v.value1.value0
        });
    };
    throw new Error("Failed pattern match at Component.AboutBusinessModal (line 134, column 1 - line 139, column 73): " + [ v.constructor.name ]);
});
var postBody = function (businessContent) {
    var genBody = function (v) {
        return function (r) {
            if (v.addressSection.address instanceof Data_Maybe.Just && v.industrySection.businessCategory instanceof Data_Maybe.Just) {
                return Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "address";
                }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "averagePayment";
                }))(showRange)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "industry";
                }))(Simple_JSON.writeForeignInt)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "numberOfPayments";
                }))(showRange)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "paymentFrequency";
                }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))(Record.merge()()(r)({
                    address: v.addressSection.address.value0,
                    industry: v.industrySection.businessCategory.value0.id
                }));
            };
            if (v.industrySection.businessCategory instanceof Data_Maybe.Just) {
                return Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "averagePayment";
                }))(showRange)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "industry";
                }))(Simple_JSON.writeForeignInt)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "numberOfPayments";
                }))(showRange)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                    return "paymentFrequency";
                }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()()))(Record.merge()()(r)({
                    industry: v.industrySection.businessCategory.value0.id
                }));
            };
            return Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "averagePayment";
            }))(showRange)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "numberOfPayments";
            }))(showRange)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "paymentFrequency";
            }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()())()()()))(r);
        };
    };
    var detailBody = function (detail) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(detail.paymentFrequency)(function (f) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(detail.averagePayment)(function (p) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(detail.numTransactions)(function (t) {
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                        paymentFrequency: f,
                        averagePayment: p,
                        numberOfPayments: t
                    });
                });
            });
        });
    };
    return Data_Functor.map(Data_Maybe.functorMaybe)(genBody(businessContent))(detailBody(businessContent.businessDetailSection));
};
var saveDetails = function (companyId) {
    return function (s) {
        var saveStep = (function () {
            if (s.aboutBusinessModal.entityType instanceof CompanyInfo) {
                return true;
            };
            if (s.aboutBusinessModal.entityType instanceof SoleTraderInfo && s.aboutBusinessModal.currentStep === 2) {
                return true;
            };
            if (s.aboutBusinessModal.entityType instanceof CompanyNoIndustryInfo && s.aboutBusinessModal.currentStep === 2) {
                return true;
            };
            return false;
        })();
        var reqBody = Data_Maybe.fromMaybe("")(postBody(s.aboutBusinessModal.requiredInfo));
        var sendPost = Data_Functor.map(Effect_Aff.functorAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](500))(function (v) {
            return v.status;
        }))(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.post()("/company/" + (companyId + "/about-your-business"))(reqBody)({})));
        var parseResult = function (v) {
            if (v === 201) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                    var $67 = {};
                    for (var $68 in s) {
                        if ({}.hasOwnProperty.call(s, $68)) {
                            $67[$68] = s[$68];
                        };
                    };
                    $67.aboutBusinessModal = {
                        showContinueBtn: false,
                        httpError: Data_Maybe.Nothing.value,
                        currentStep: s.aboutBusinessModal.currentStep,
                        display: s.aboutBusinessModal.display,
                        entityType: s.aboutBusinessModal.entityType,
                        requiredInfo: s.aboutBusinessModal.requiredInfo
                    };
                    return $67;
                })());
            };
            if (v === 401) {
                return Data_Functor.voidLeft(Effect_Aff.functorAff)(Helper_AuthActions.stepUp()("/dashboard")({}))((function () {
                    var $70 = {};
                    for (var $71 in s) {
                        if ({}.hasOwnProperty.call(s, $71)) {
                            $70[$71] = s[$71];
                        };
                    };
                    $70.aboutBusinessModal = {
                        showContinueBtn: false,
                        httpError: new Data_Maybe.Just(401),
                        currentStep: s.aboutBusinessModal.currentStep,
                        display: s.aboutBusinessModal.display,
                        entityType: s.aboutBusinessModal.entityType,
                        requiredInfo: s.aboutBusinessModal.requiredInfo
                    };
                    return $70;
                })());
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                var $73 = {};
                for (var $74 in s) {
                    if ({}.hasOwnProperty.call(s, $74)) {
                        $73[$74] = s[$74];
                    };
                };
                $73.aboutBusinessModal = {
                    showContinueBtn: false,
                    httpError: new Data_Maybe.Just(v),
                    currentStep: s.aboutBusinessModal.currentStep,
                    display: s.aboutBusinessModal.display,
                    entityType: s.aboutBusinessModal.entityType,
                    requiredInfo: s.aboutBusinessModal.requiredInfo
                };
                return $73;
            })());
        };
        var save = function (v) {
            if (v) {
                return Control_Bind.bind(Effect_Aff.bindAff)(sendPost)(parseResult);
            };
            if (!v) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                    var $77 = {};
                    for (var $78 in s) {
                        if ({}.hasOwnProperty.call(s, $78)) {
                            $77[$78] = s[$78];
                        };
                    };
                    $77.aboutBusinessModal = {
                        showContinueBtn: true,
                        currentStep: s.aboutBusinessModal.currentStep,
                        display: s.aboutBusinessModal.display,
                        entityType: s.aboutBusinessModal.entityType,
                        httpError: s.aboutBusinessModal.httpError,
                        requiredInfo: s.aboutBusinessModal.requiredInfo
                    };
                    return $77;
                })());
            };
            throw new Error("Failed pattern match at Component.AboutBusinessModal (line 219, column 5 - line 219, column 55): " + [ v.constructor.name ]);
        };
        var addOneStep = function (state) {
            var $80 = {};
            for (var $81 in state) {
                if ({}.hasOwnProperty.call(state, $81)) {
                    $80[$81] = state[$81];
                };
            };
            $80.aboutBusinessModal = {
                display: state.aboutBusinessModal.display,
                entityType: state.aboutBusinessModal.entityType,
                showContinueBtn: state.aboutBusinessModal.showContinueBtn,
                requiredInfo: state.aboutBusinessModal.requiredInfo,
                currentStep: state.aboutBusinessModal.currentStep + 1 | 0,
                httpError: state.aboutBusinessModal.httpError
            };
            return $80;
        };
        return Data_Functor.map(Effect_Aff.functorAff)(addOneStep)(save(saveStep));
    };
};
var paymentFrequency = function (s) {
    var update = function (value) {
        return function (state) {
            var newVal = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(value))(function (n) {
                var $83 = n > 0 && n <= 32268;
                if ($83) {
                    return new Data_Maybe.Just(n);
                };
                return Data_Maybe.Nothing.value;
            });
            return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                var $84 = {};
                for (var $85 in state) {
                    if ({}.hasOwnProperty.call(state, $85)) {
                        $84[$85] = state[$85];
                    };
                };
                $84.aboutBusinessModal = {
                    requiredInfo: {
                        businessDetailSection: {
                            paymentFrequency: newVal,
                            averagePayment: state.aboutBusinessModal.requiredInfo.businessDetailSection.averagePayment,
                            numTransactions: state.aboutBusinessModal.requiredInfo.businessDetailSection.numTransactions
                        },
                        addressSection: state.aboutBusinessModal.requiredInfo.addressSection,
                        industrySection: state.aboutBusinessModal.requiredInfo.industrySection
                    },
                    currentStep: state.aboutBusinessModal.currentStep,
                    display: state.aboutBusinessModal.display,
                    entityType: state.aboutBusinessModal.entityType,
                    httpError: state.aboutBusinessModal.httpError,
                    showContinueBtn: state.aboutBusinessModal.showContinueBtn
                };
                return $84;
            })());
        };
    };
    return {
        name: "paymentFrequency",
        question: "What is the average number of batch payments / ABA files the business processes in a month? *",
        answer: new EnterSmallInt({
            update: update,
            value: Data_Maybe.maybe("")(Data_Show.show(Data_Show.showInt))(s.aboutBusinessModal.requiredInfo.businessDetailSection.paymentFrequency)
        })
    };
};
var numberWithComma = function (n) {
    return Data_Either.either(Data_Function["const"](""))(Control_Category.identity(Control_Category.categoryFn))(Control_Apply.apply(Data_Either.applyEither)(Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(Data_String_Regex.replace)(Data_String_Regex.regex("(\\d)(?=(\\d{3})+(?!\\d))")(Data_String_Regex_Flags.noFlags)))(Control_Applicative.pure(Data_Either.applicativeEither)("$1,")))(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Show.show(Data_Show.showInt)(n))));
};
var initIndustrySection = {
    required: true,
    allBusinessIndustry: [  ],
    businessType: Data_Maybe.Nothing.value,
    businessCategory: Data_Maybe.Nothing.value
};
var initBusinessDetailSection = {
    paymentFrequency: Data_Maybe.Nothing.value,
    averagePayment: Data_Maybe.Nothing.value,
    numTransactions: Data_Maybe.Nothing.value
};
var initAddressSection = {
    required: true,
    address: Data_Maybe.Nothing.value
};
var initCompanyInfo = {
    addressSection: {
        required: false,
        address: initAddressSection.address
    },
    industrySection: {
        required: false,
        allBusinessIndustry: initIndustrySection.allBusinessIndustry,
        businessType: initIndustrySection.businessType,
        businessCategory: initIndustrySection.businessCategory
    },
    businessDetailSection: initBusinessDetailSection
};
var initCompanyNoIndustryInfo = {
    addressSection: {
        required: false,
        address: initAddressSection.address
    },
    industrySection: initIndustrySection,
    businessDetailSection: initBusinessDetailSection
};
var initSoleTraderInfo = {
    addressSection: initAddressSection,
    industrySection: initIndustrySection,
    businessDetailSection: initBusinessDetailSection
};
var initAboutBusinessModal = {
    display: false,
    entityType: SoleTraderInfo.value,
    showContinueBtn: true,
    requiredInfo: initSoleTraderInfo,
    currentStep: 1,
    httpError: Data_Maybe.Nothing.value
};
var format2 = function (v) {
    if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Nothing) {
        return "";
    };
    if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Just) {
        return "Under $" + numberWithComma(v.value1.value0 - 1 | 0);
    };
    if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Nothing) {
        return "Over $" + numberWithComma(v.value0.value0);
    };
    if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Just) {
        return "$" + (numberWithComma(v.value0.value0) + (" to $" + numberWithComma(v.value1.value0 - 1 | 0)));
    };
    throw new Error("Failed pattern match at Component.AboutBusinessModal (line 361, column 1 - line 361, column 27): " + [ v.constructor.name ]);
};
var format1 = function (v) {
    if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Nothing) {
        return "";
    };
    if (v.value0 instanceof Data_Maybe.Nothing && v.value1 instanceof Data_Maybe.Just) {
        return "<" + Data_Show.show(Data_Show.showInt)(v.value1.value0);
    };
    if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Nothing) {
        return ">" + Data_Show.show(Data_Show.showInt)(v.value0.value0);
    };
    if (v.value0 instanceof Data_Maybe.Just && v.value1 instanceof Data_Maybe.Just) {
        return Data_Show.show(Data_Show.showInt)(v.value0.value0) + ("-" + Data_Show.show(Data_Show.showInt)(v.value1.value0));
    };
    throw new Error("Failed pattern match at Component.AboutBusinessModal (line 355, column 1 - line 355, column 27): " + [ v.constructor.name ]);
};
var numTransactions = function (s) {
    var possibleValues = [ new Range(new Data_Maybe.Just(1), new Data_Maybe.Just(5)), new Range(new Data_Maybe.Just(6), new Data_Maybe.Just(10)), new Range(new Data_Maybe.Just(11), new Data_Maybe.Just(20)), new Range(new Data_Maybe.Just(21), new Data_Maybe.Just(30)), new Range(new Data_Maybe.Just(31), new Data_Maybe.Just(50)), new Range(new Data_Maybe.Just(50), Data_Maybe.Nothing.value) ];
    var update = function (v) {
        return function (state) {
            var newVal = Data_Foldable.find(Data_Foldable.foldableArray)((function () {
                var $125 = Data_Eq.eq(Data_Eq.eqString)(v);
                return function ($126) {
                    return $125(format1($126));
                };
            })())(possibleValues);
            return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                var $113 = {};
                for (var $114 in state) {
                    if ({}.hasOwnProperty.call(state, $114)) {
                        $113[$114] = state[$114];
                    };
                };
                $113.aboutBusinessModal = {
                    requiredInfo: {
                        businessDetailSection: {
                            numTransactions: newVal,
                            averagePayment: state.aboutBusinessModal.requiredInfo.businessDetailSection.averagePayment,
                            paymentFrequency: state.aboutBusinessModal.requiredInfo.businessDetailSection.paymentFrequency
                        },
                        addressSection: state.aboutBusinessModal.requiredInfo.addressSection,
                        industrySection: state.aboutBusinessModal.requiredInfo.industrySection
                    },
                    currentStep: state.aboutBusinessModal.currentStep,
                    display: state.aboutBusinessModal.display,
                    entityType: state.aboutBusinessModal.entityType,
                    httpError: state.aboutBusinessModal.httpError,
                    showContinueBtn: state.aboutBusinessModal.showContinueBtn
                };
                return $113;
            })());
        };
    };
    return {
        name: "numTransactions",
        question: "How many unique businesses or people do you pay in a month? *",
        answer: new SelectRange({
            allValues: Data_Functor.map(Data_Functor.functorArray)(format1)(possibleValues),
            update: update,
            value: Data_Maybe.maybe("")(format1)(s.aboutBusinessModal.requiredInfo.businessDetailSection.numTransactions)
        })
    };
};
var businessType = function (s) {
    var possibleValues = Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.industryType;
    })(s.aboutBusinessModal.requiredInfo.industrySection.allBusinessIndustry);
    var update = function (v) {
        return function (state) {
            var newVal = Data_Foldable.find(Data_Foldable.foldableArray)(Data_Eq.eq(Data_Eq.eqString)(v))(possibleValues);
            return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                var $116 = {};
                for (var $117 in state) {
                    if ({}.hasOwnProperty.call(state, $117)) {
                        $116[$117] = state[$117];
                    };
                };
                $116.aboutBusinessModal = {
                    requiredInfo: {
                        industrySection: {
                            businessType: newVal,
                            businessCategory: Data_Maybe.Nothing.value,
                            allBusinessIndustry: state.aboutBusinessModal.requiredInfo.industrySection.allBusinessIndustry,
                            required: state.aboutBusinessModal.requiredInfo.industrySection.required
                        },
                        addressSection: state.aboutBusinessModal.requiredInfo.addressSection,
                        businessDetailSection: state.aboutBusinessModal.requiredInfo.businessDetailSection
                    },
                    currentStep: state.aboutBusinessModal.currentStep,
                    display: state.aboutBusinessModal.display,
                    entityType: state.aboutBusinessModal.entityType,
                    httpError: state.aboutBusinessModal.httpError,
                    showContinueBtn: state.aboutBusinessModal.showContinueBtn
                };
                return $116;
            })());
        };
    };
    return {
        name: "businessType",
        question: "What type of business do you operate?",
        answer: new SelectBusinessType({
            allValues: Data_Array.sort(Data_Ord.ordString)(possibleValues),
            update: update,
            value: Data_Maybe.fromMaybe("")(s.aboutBusinessModal.requiredInfo.industrySection.businessType)
        })
    };
};
var businessCategory = function (s) {
    var codesAndDescription = Data_Maybe.maybe([  ])(function (v) {
        return v.category;
    })(Data_Foldable.find(Data_Foldable.foldableArray)(function (y) {
        return y.industryType === Data_Maybe.fromMaybe("")(s.aboutBusinessModal.requiredInfo.industrySection.businessType);
    })(s.aboutBusinessModal.requiredInfo.industrySection.allBusinessIndustry));
    var update = function (value) {
        return function (state) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                var $119 = {};
                for (var $120 in state) {
                    if ({}.hasOwnProperty.call(state, $120)) {
                        $119[$120] = state[$120];
                    };
                };
                $119.aboutBusinessModal = {
                    requiredInfo: {
                        industrySection: {
                            businessCategory: Data_Foldable.find(Data_Foldable.foldableArray)((function () {
                                var $127 = Data_Eq.eq(Data_Eq.eqString)(value);
                                return function ($128) {
                                    return $127((function (v1) {
                                        return v1.description;
                                    })($128));
                                };
                            })())(codesAndDescription),
                            allBusinessIndustry: state.aboutBusinessModal.requiredInfo.industrySection.allBusinessIndustry,
                            businessType: state.aboutBusinessModal.requiredInfo.industrySection.businessType,
                            required: state.aboutBusinessModal.requiredInfo.industrySection.required
                        },
                        addressSection: state.aboutBusinessModal.requiredInfo.addressSection,
                        businessDetailSection: state.aboutBusinessModal.requiredInfo.businessDetailSection
                    },
                    currentStep: state.aboutBusinessModal.currentStep,
                    display: state.aboutBusinessModal.display,
                    entityType: state.aboutBusinessModal.entityType,
                    httpError: state.aboutBusinessModal.httpError,
                    showContinueBtn: state.aboutBusinessModal.showContinueBtn
                };
                return $119;
            })());
        };
    };
    return {
        name: "businessCategory",
        question: "What category best describes your business?",
        answer: new SelectBusinessCategory({
            disabled: Data_Maybe.isNothing(s.aboutBusinessModal.requiredInfo.industrySection.businessType),
            allValues: Data_Array.sort(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()(new Data_Symbol.IsSymbol(function () {
                return "id";
            }))(Data_Ord.ordInt))()(new Data_Symbol.IsSymbol(function () {
                return "description";
            }))(Data_Ord.ordString)))(codesAndDescription),
            update: update,
            value: Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                return v.description;
            })(s.aboutBusinessModal.requiredInfo.industrySection.businessCategory))
        })
    };
};
var industryQuestionsToAsk = function (s) {
    return [ businessType(s), businessCategory(s) ];
};
var averagePayment = function (s) {
    var possibleValues = [ new Range(Data_Maybe.Nothing.value, new Data_Maybe.Just(10000)), new Range(new Data_Maybe.Just(10000), new Data_Maybe.Just(50000)), new Range(new Data_Maybe.Just(50000), new Data_Maybe.Just(250000)), new Range(new Data_Maybe.Just(250000), Data_Maybe.Nothing.value) ];
    var update = function (v) {
        return function (state) {
            var newVal = Data_Foldable.find(Data_Foldable.foldableArray)((function () {
                var $129 = Data_Eq.eq(Data_Eq.eqString)(v);
                return function ($130) {
                    return $129(format2($130));
                };
            })())(possibleValues);
            return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                var $122 = {};
                for (var $123 in state) {
                    if ({}.hasOwnProperty.call(state, $123)) {
                        $122[$123] = state[$123];
                    };
                };
                $122.aboutBusinessModal = {
                    requiredInfo: {
                        businessDetailSection: {
                            averagePayment: newVal,
                            numTransactions: state.aboutBusinessModal.requiredInfo.businessDetailSection.numTransactions,
                            paymentFrequency: state.aboutBusinessModal.requiredInfo.businessDetailSection.paymentFrequency
                        },
                        addressSection: state.aboutBusinessModal.requiredInfo.addressSection,
                        industrySection: state.aboutBusinessModal.requiredInfo.industrySection
                    },
                    currentStep: state.aboutBusinessModal.currentStep,
                    display: state.aboutBusinessModal.display,
                    entityType: state.aboutBusinessModal.entityType,
                    httpError: state.aboutBusinessModal.httpError,
                    showContinueBtn: state.aboutBusinessModal.showContinueBtn
                };
                return $122;
            })());
        };
    };
    return {
        name: "averagePayment",
        question: "What is the average monthly amount paid by the business? *",
        answer: new SelectRange({
            allValues: Data_Functor.map(Data_Functor.functorArray)(format2)(possibleValues),
            update: update,
            value: Data_Maybe.maybe("")(format2)(s.aboutBusinessModal.requiredInfo.businessDetailSection.averagePayment)
        })
    };
};
var questionsToAsk = function (s) {
    return [ paymentFrequency(s), averagePayment(s), numTransactions(s) ];
};
module.exports = {
    initAddressSection: initAddressSection,
    initIndustrySection: initIndustrySection,
    initBusinessDetailSection: initBusinessDetailSection,
    SoleTraderInfo: SoleTraderInfo,
    CompanyInfo: CompanyInfo,
    CompanyNoIndustryInfo: CompanyNoIndustryInfo,
    initSoleTraderInfo: initSoleTraderInfo,
    initCompanyInfo: initCompanyInfo,
    initCompanyNoIndustryInfo: initCompanyNoIndustryInfo,
    initAboutBusinessModal: initAboutBusinessModal,
    Range: Range,
    SelectRange: SelectRange,
    EnterSmallInt: EnterSmallInt,
    SelectBusinessType: SelectBusinessType,
    SelectBusinessCategory: SelectBusinessCategory,
    validateInputs: validateInputs,
    postBody: postBody,
    saveDetails: saveDetails,
    updateAddress: updateAddress,
    questionsToAsk: questionsToAsk,
    industryQuestionsToAsk: industryQuestionsToAsk,
    businessType: businessType,
    businessCategory: businessCategory,
    paymentFrequency: paymentFrequency,
    averagePayment: averagePayment,
    numTransactions: numTransactions,
    format1: format1,
    format2: format2,
    numberWithComma: numberWithComma,
    showRange: showRange
};
