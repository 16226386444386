// Generated by purs version 0.13.8
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Foreign = require("../Foreign/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Active = (function () {
    function Active() {

    };
    Active.value = new Active();
    return Active;
})();
var Inactive = (function () {
    function Inactive() {

    };
    Inactive.value = new Inactive();
    return Inactive;
})();
var genericStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Active) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Inactive) {
        return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
    };
    throw new Error("Failed pattern match at Model.CompanyAccount (line 14, column 1 - line 14, column 58): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Active.value;
    };
    if (x instanceof Data_Generic_Rep.Inr) {
        return Inactive.value;
    };
    throw new Error("Failed pattern match at Model.CompanyAccount (line 14, column 1 - line 14, column 58): " + [ x.constructor.name ]);
});
var showCompanyAccount = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Active";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Inactive";
})))));
var eqCompanyStatus = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Active && y instanceof Active) {
            return true;
        };
        if (x instanceof Inactive && y instanceof Inactive) {
            return true;
        };
        return false;
    };
});
var ordCompanyStatus = new Data_Ord.Ord(function () {
    return eqCompanyStatus;
}, function (x) {
    return function (y) {
        if (x instanceof Active && y instanceof Active) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Active) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Active) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Inactive && y instanceof Inactive) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Model.CompanyAccount (line 13, column 1 - line 13, column 55): " + [ x.constructor.name, y.constructor.name ]);
    };
});
var companyAccountWriteForeign = new Simple_JSON.WriteForeign((function () {
    var $13 = Data_Show.show(showCompanyAccount);
    return function ($14) {
        return Foreign.unsafeToForeign($13($14));
    };
})());
var companyAccountReadForeign = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericStatus)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Active";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Inactive";
})))));
module.exports = {
    Active: Active,
    Inactive: Inactive,
    eqCompanyStatus: eqCompanyStatus,
    ordCompanyStatus: ordCompanyStatus,
    genericStatus: genericStatus,
    companyAccountReadForeign: companyAccountReadForeign,
    showCompanyAccount: showCompanyAccount,
    companyAccountWriteForeign: companyAccountWriteForeign
};
