// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Config = require("../Config/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Error_Util = require("../Control.Error.Util/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Foreign = require("../Foreign/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var setItem = $foreign["_setItem"];
var saveItem = function (dictWriteForeign) {
    return function (name) {
        var $4 = setItem(name);
        var $5 = Simple_JSON.writeJSON(dictWriteForeign);
        return function ($6) {
            return $4($5($6));
        };
    };
};
var removeItem = $foreign["_removeItem"];
var getItem = function (name) {
    return $foreign["_getItem"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value)(name);
};
var getUserId = getItem(Config.config.userStorageName);
var getUserIdOrEmpty = Data_Functor.map(Effect.functorEffect)(Data_Maybe.fromMaybe(""))(getUserId);
var readItem = function (dictReadForeign) {
    return function (name) {
        var parseJSON = Control_Bind.bindFlipped(Data_Either.bindEither)((function () {
            var $7 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(Data_Show.show(Data_List_Types.showNonEmptyList(Foreign.showForeignError)));
            var $8 = Simple_JSON.readJSON(dictReadForeign);
            return function ($9) {
                return $7($8($9));
            };
        })());
        return Data_Functor.map(Effect.functorEffect)((function () {
            var $10 = Control_Error_Util.note(name + " not found in storage");
            return function ($11) {
                return parseJSON($10($11));
            };
        })())(getItem(name));
    };
};
var verifyNonce = function (state) {
    var getNonce = function __do() {
        var v = getItem(Config.config.nonceStorageName)();
        return Data_Functor.voidLeft(Effect.functorEffect)($foreign["_removeItem"](Config.config.nonceStorageName))(v)();
    };
    var decodeState = (function () {
        var $12 = Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "location";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "nonce";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()()));
        return function ($13) {
            return Data_Either.hush($12($foreign.base64Decode($13)));
        };
    })();
    var compare = function (obj) {
        return function (nonce) {
            var $3 = obj.nonce === nonce;
            if ($3) {
                return new Data_Maybe.Just(obj.location);
            };
            return Data_Maybe.Nothing.value;
        };
    };
    var verify = function (obj) {
        return function (nonce) {
            return Control_Bind.join(Data_Maybe.bindMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(compare)(obj))(nonce));
        };
    };
    return Data_Functor.map(Effect.functorEffect)(verify(decodeState(state)))(getNonce);
};
var genNonce = function __do() {
    var n = Data_JSDate.now();
    var r = $foreign.genRandom();
    return Data_Number_Format.toString(Data_JSDate.getTime(n) * r);
};
var saveLocation = function (url) {
    return function __do() {
        var nonce = genNonce();
        setItem(Config.config.nonceStorageName)(nonce)();
        return $foreign.base64Encode(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
            return "location";
        }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
            return "nonce";
        }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()())()()()))({
            nonce: nonce,
            location: url
        }));
    };
};
module.exports = {
    verifyNonce: verifyNonce,
    genNonce: genNonce,
    saveLocation: saveLocation,
    getItem: getItem,
    readItem: readItem,
    setItem: setItem,
    saveItem: saveItem,
    removeItem: removeItem,
    getUserId: getUserId,
    getUserIdOrEmpty: getUserIdOrEmpty,
    base64Decode: $foreign.base64Decode,
    base64Encode: $foreign.base64Encode,
    genRandom: $foreign.genRandom,
    "_getItem": $foreign["_getItem"],
    "_removeItem": $foreign["_removeItem"],
    "_setItem": $foreign["_setItem"]
};
