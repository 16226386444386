import React, { useContext } from 'react'
import { StandardTemplate, Tabs, Spinner } from '@myob/myob-widgets'
import AlertBanner from '../components/AlertBanner'
import { Success, Warning } from 'purs/AlertBanner'
import withPsState from '../withPsState'
import {
  initState,
  load,
  tabClick,
  UserTab,
  ActivityTab,
  RequestError,
  NoCompanyFoundError
} from 'purs/Page.Manage.Users'
import ManageUserTable from '../components/Manage.Users'
import UserLogsTable from '../components/Manage.UserLogs'
import NavBarForBusiness from '../components/NavBarForBusiness'
import Error from '../components/Error'
import { isJust } from 'purs/Data.Maybe'
import { Pending, NotRequired } from 'purs/Model.User'
import './Manage.Users.sass'
import { Redirect } from 'react-router-dom'

const ManageTabs = ({ selectedType }) => {
  const { dispatch } = useContext(context)
  const items = [
    { id: 'users', label: 'Users' },
    { id: 'activities', label: 'Activity history' }
  ]
  const selected = selectedType === UserTab ? 'users' : 'activities'
  const selectTab = tab => {
    switch (tab) {
      case 'users':
        if (selectedType === ActivityTab) {
          const userTabContent = UserTab.create([])
          dispatch(tabClick(userTabContent))
          dispatch(load(userTabContent))
        }
        return
      default:
        if (selectedType === UserTab) {
          const activityTabContent = ActivityTab.create([])
          dispatch(tabClick(activityTabContent))
          dispatch(load(activityTabContent))
        }
        return
    }
  }

  return <Tabs items={items} selected={selected} onSelected={selectTab} />
}

const RenderError = ({ errorType }) => {
  switch (errorType.constructor) {
    case RequestError:
      switch (errorType.value0.code) {
        case 404:
          return <Redirect to={{ pathname: '/dashboard', state: null }} />
        case 403:
          return <Error httpError={403} />
        default:
          return (
            <div data-error={errorType.value0.code}>
              <Error httpError={errorType.value0.code} />
            </div>
          )
      }
    case NoCompanyFoundError:
      return <Redirect to={{ pathname: '/companies', state: null }} />
    default:
      return (
        <div data-error={errorType.value0}>
          <Error httpError={500} />
        </div>
      )
  }
}

const UpdateAlert = ({ updateResult }) =>
  updateResult === Pending.value ? (
    <AlertBanner type={new Warning()}>
      The user has been notified of the changes to their role via email. Until
      they complete their account setup, the change to their level of access
      will remain pending.
    </AlertBanner>
  ) : updateResult === NotRequired.value ? (
    <AlertBanner type={new Success()}>
      Success! The user details have been successfully updated.
    </AlertBanner>
  ) : (
    <></>
  )

const RenderTabContent = ({ tab }) =>
  tab.constructor === UserTab ? (
    <ManageUserTable users={tab.value0} />
  ) : (
    <UserLogsTable logs={tab.value0} />
  )

export const ManageUsersPage = ({ history }) => {
  const { state } = useContext(context)
  return (
    <>
      <NavBarForBusiness />
      <UpdateAlert updateResult={history.location.state} />
      <StandardTemplate pageHead="Manage users" sticky="none">
        <ManageTabs selectedType={state.tabContent.constructor} />
        {state.spinner.display ? (
          <Spinner />
        ) : isJust(state.errorType) ? (
          <RenderError errorType={state.errorType.value0} />
        ) : (
          <RenderTabContent tab={state.tabContent} />
        )}
      </StandardTemplate>
    </>
  )
}

export const { context, Component } = withPsState(
  ManageUsersPage,
  initState,
  props => load(UserTab.create([]))
)

export default Component
