import DirectDebitGenericLogo from '../img/direct-debit-generic-logo.svg'
import React from 'react'
import {Label} from "@myob/myob-widgets";

const DirectDebitSchema = ({ bsbNumber, accountNumber, isDefault}) => {
  return (
    <div className={`saved-direct-debit ${isDefault?'default-payment-method':null}`}>
      {isDefault && <div className="default-status"><Label color="purple" type="boxed">Default</Label></div>}
      <div className="direct-debit-name">
        <span className="bsb-number">BSB</span>
        <span className="account-number">ACCOUNT</span>
      </div>
      <div className="direct-debit-value">
        <span className="bsb-number-value">{bsbNumber}</span>
        <span className="account-number-value">{accountNumber}</span>
        <img className="direct-debit-generic-logo" src={DirectDebitGenericLogo} alt="Bank Account Generic Icon" />
      </div>
    </div>
  )
}

export default DirectDebitSchema
