import React, { useContext, useEffect, useState } from 'react'
import '../pages/BusinessManage.sass'
import { context } from '../pages/BusinessManage'
import { reload } from 'purs/Page.BusinessManage'
import * as r from 'ramda'
import DirectDebitSchema from './DirectDebitManage.Schema'
import { Button, Icons } from '@myob/myob-widgets'
import DirectDebitAgreementModal from './DirectDebitAgreementModal'
import DeleteBankAccountModal from './DeleteBankAccountModal'
import SetupDefaultPaymentMethodModal from './SetupDefaultPaymentMethodModal'

const EmptyBankAccount = ({companyId, onAdded})=> {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className="direct-debit-container">
      <div className="no-direct-debit">
        <Button
          id="btn-card-manage-add-direct-debit"
          className="add-direct-debit-btn"
          type="link"
          icon={<Icons.Add />}
          onClick={() => setShowModal(true)}
        >
            Add a bank account
        </Button>
      </div>
      {showModal && <DirectDebitAgreementModal companyId={companyId}
                                               onClose={() => {
                                                 setShowModal(false)
                                                 onAdded()
                                               }} />}
    </div>
  )
}

const BankAccount = ({ bankAccount }) => {
  const dots = digits => r.repeat('•', digits).join('')
  const maskBsb = [dots(3), '-', bankAccount.bsbNumber.slice(-3)].join('')
  const maskAccount = [dots(4), ' ', bankAccount.accountNumber.slice(-4)].join('')

  return (<DirectDebitSchema bsbNumber={maskBsb} accountNumber={maskAccount} isDefault={bankAccount.isDefault} />)
}

const BankAccountRender = ({bankAccount, companyId, onRefresh}) => {
  const isDefault = bankAccount.isDefault
  const [showSetDefault, setShowSetDefault] = React.useState(false)
  const paymentMethod = { bankAccount }
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [enableRemoveBankAccount, setEnableRemoveBankAccount] = useState(false)
  useEffect(() => {
    const enableRemoveBankAccountToggle = window.sessionStorage.getItem('enableRemoveBankAccount')
    setEnableRemoveBankAccount(enableRemoveBankAccountToggle === 'true')
  }, [])

  return (
      <>
      <div className="direct-debit-container">
        <BankAccount bankAccount={bankAccount} />
        {enableRemoveBankAccount &&
          (<Button id="btn-direct-debit-manage-delete-bank-account"
            type="link"
            className="delete-bank-account-link"
            onClick={() => setShowDeleteModal(true)}
            icon={<Icons.Delete/>}
          >
            Remove
          </Button>)}
        {!isDefault && (
          <Button type="link" className="set-default-link" icon={<Icons.Add />} onClick={() => setShowSetDefault(true)}>
            Set as default
          </Button>
        )}
        {showDeleteModal && <DeleteBankAccountModal companyId={companyId} bankAccountId={bankAccount.id}
                                                       onClose={() => {
                                                         setShowDeleteModal(false)
                                                         onRefresh()
                                                       }}
        />}
      </div>
      { showSetDefault && (
        <SetupDefaultPaymentMethodModal paymentMethod={paymentMethod} companyId={companyId}
          onCancel={() => setShowSetDefault(false)}
          onSuccess={() => {setShowSetDefault(false); onRefresh()}}/>)
      }
    </>
  )
}

const DirectDebitManage = () => {
  const { state, dispatch } = useContext(context)
  const [enableAddBankAccount, setEnableAddBankAccount] = useState(false)
  const companyId = r.pathOr('', ['companyId', 'value0'], state)
  useEffect(() => {
    const enableAddBankAccount = window.sessionStorage.getItem('enableAddBankAccount')
    setEnableAddBankAccount(enableAddBankAccount === 'true')
  }, [])

  return r.isEmpty(state.paymentMethods.bankAccounts) ? (
    enableAddBankAccount ? <EmptyBankAccount companyId={companyId} onAdded={() => dispatch(reload)}/> : <></>
  ) : <BankAccountRender bankAccount={r.head(state.paymentMethods.bankAccounts)} companyId={companyId}
                         onRefresh={() => dispatch(reload)}/>
}

export default DirectDebitManage
