// Generated by purs version 0.13.8
"use strict";
var Data_Int = require("../Data.Int/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var centsToDollars = function (cents) {
    return Data_Number_Format.toStringWith(Data_Number_Format.fixed(2))(cents / Data_Int.toNumber(100));
};
module.exports = {
    centsToDollars: centsToDollars
};
