import {capitalize} from "lodash";
import Master from "../img/master.svg";
import Visa from "../img/visa.svg";
import DirectDebitGenericLogo from "../img/direct-debit-generic-logo.svg";
import React from "react";
import './PaymentMethodChip.sass'

const PaymentMethodChip = ({children, title, subtitle}) => (
  <div className="flx-chip" style={{gridTemplateColumns: "45px 1fr auto"}}>
    <div className="flx-chip__icon">
      {children}
    </div>
    <div className="flx-chip__details">
      <div className="flx-chip__title-group">
        <span className="flx-chip__main-title">{title}</span>
        <span className="flx-chip__sub-title">{subtitle}</span>
      </div>
    </div>
  </div>
)

export const CardChip = ({card}) => {
  const title = [card.scheme, card.type, 'card'].map(capitalize).join(' ')
  const subtitle = '**** ' + card.number.substr(-4)
  const icon = card.scheme === 'mastercard' ? Master : Visa
  return (
    <PaymentMethodChip key={card.number} title={title} subtitle={subtitle}>
      <img src={icon} alt={card.scheme}/>
    </PaymentMethodChip>
  )
}

export const BankAccountChip = ({bankAccount}) => {
  const bankAccountNum = '****' + bankAccount.accountNumber.substr(-4)
  const subtitle = [bankAccount.bsbNumber, bankAccountNum].join(' ')
  return (
    <PaymentMethodChip key={bankAccount.accountNumber} title="Bank Account" subtitle={subtitle}>
      <img src={DirectDebitGenericLogo} alt="Bank Account" className="bank-logo"/>
    </PaymentMethodChip>
  )
}

