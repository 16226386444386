import React from 'react'
import {
  Modal,
  Stepper,
  Button,
  Input,
  Select,
  FieldGroup
} from '@myob/myob-widgets'
import { useContext } from 'react'
import {
  validateInputs,
  updateAddress,
  saveDetails,
  questionsToAsk,
  industryQuestionsToAsk,
  SelectRange,
  EnterSmallInt,
  SelectBusinessType,
  SelectBusinessCategory,
  CompanyInfo
} from 'purs/Component.AboutBusinessModal'
import { isJust, isNothing } from 'purs/Data.Maybe'
import { refreshPage, showPageSpinner } from 'purs/Dashboard'
import { context } from '../pages/Dashboard'
import { setUpAddressValidation } from '../helper/AddressValidation'
import './AboutBusinessModal.sass'
import * as R from 'ramda'
import Error from './Error'

const AddBusinessAddress = () => {
  const { dispatch } = useContext(context)

  /* istanbul ignore next */
  setUpAddressValidation({
    whereToDisplaySuggestions: '#business-info',
    addressField: '#address',
    onChange: address => {
      if (address == null) dispatch(updateAddress(''))
    },
    onSelect: address => dispatch(updateAddress(address.fullAddress))
  })

  return (
    <FieldGroup label="Add business address">
      <p>
        Before you register your business, we just have to verify a few pieces
        of information. To start, we will need the main business address of your
        business.
      </p>
      <Input
        id="address"
        label="Main business address"
        name="address"
        placeholder="Start typing your address"
        onChange={
          /* istanbul ignore next */
          _ => {
            dispatch(updateAddress(''))
          }
        }
      />
    </FieldGroup>
  )
}

const AddBusinessIndustry = () => {
  const { state, dispatch } = useContext(context)
  return (
    <FieldGroup label="Add business industry information">
      {industryQuestionsToAsk(state).map(q => {
        switch (q.answer.constructor) {
          case SelectBusinessType:
            return (
              <Select
                id={q.name}
                key={q.name}
                name={q.name}
                label={q.question}
                width="m"
                value={q.answer.value0.value}
                onChange={({ target }) =>
                  dispatch(q.answer.value0.update(target.value))
                }
              >
                <Select.Option value="" label="- Select business type -" />
                {q.answer.value0.allValues.map(v => (
                  <Select.Option key={v} value={v} label={v} />
                ))}
              </Select>
            )
          case SelectBusinessCategory:
            return (
              <Select
                id={q.name}
                key={q.name}
                name={q.name}
                label={q.question}
                disabled={q.answer.value0.disabled}
                width="m"
                value={q.answer.value0.value}
                onChange={({ target }) =>
                  dispatch(q.answer.value0.update(target.value))
                }
              >
                <Select.Option value="" label="- Select business category -" />
                {q.answer.value0.allValues.map(v => (
                  <Select.Option
                    key={v.id}
                    value={v.description}
                    label={v.description}
                  />
                ))}
              </Select>
            )
        }
      })}
    </FieldGroup>
  )
}

const AddTransactionDetails = () => {
  const { state, dispatch } = useContext(context)
  return (
    <FieldGroup label="Add business transaction details">
      <p>
        To help us identify any irregular transactions by telling us some
        information about your payment history.
      </p>
      {questionsToAsk(state).map(q => {
        switch (q.answer.constructor) {
          case EnterSmallInt:
            return (
              <Input
                id={q.name}
                name={q.name}
                label={q.question}
                type="number"
                min="0"
                max="32767"
                step="1"
                value={q.answer.value0.value}
                onChange={({ target }) =>
                  dispatch(q.answer.value0.update(target.value))
                }
              />
            )
          case SelectRange:
            return (
              <Select
                id={q.name}
                key={q.name}
                name={q.name}
                label={q.question}
                width="m"
                value={q.answer.value0.value}
                onChange={({ target }) =>
                  dispatch(q.answer.value0.update(target.value))
                }
              >
                <Select.Option value="" label="Please Select" />
                {q.answer.value0.allValues.map(v => (
                  <Select.Option key={v} value={v} label={v} />
                ))}
              </Select>
            )
        }
      })}
    </FieldGroup>
  )
}

const componentGenerator = aboutBusinessModal => {
  switch (aboutBusinessModal.entityType.constructor) {
    case CompanyInfo:
      return [<AddTransactionDetails key={'transaction-details'} />]
    default:
      return [
        AddressAndIndustryContent(
          aboutBusinessModal.requiredInfo.addressSection.required
        ),
        <AddTransactionDetails key={'transaction-details'} />
      ]
  }
}

const stepGenerator = noOfSteps => {
  const allSteps = R.map(
    i => ({
      number: i.toString(),
      title: 'Add details about this business',
      type: 'incomplete'
    }),
    R.range(1, noOfSteps)
  )

  const lastStep = {
    number: noOfSteps.toString(),
    title: 'Your details were successfully saved',
    type: 'incomplete'
  }
  return R.append(lastStep, allSteps)
}

const AddressAndIndustryContent = addressRequired => {
  return (
    <div className="business-address" id="business-info">
      {addressRequired && <AddBusinessAddress />}
      <AddBusinessIndustry />
    </div>
  )
}

const StepperWithContent = () => {
  const {
    state: { aboutBusinessModal }
  } = useContext(context)
  const components = componentGenerator(aboutBusinessModal)
  const modalContent = R.nth(aboutBusinessModal.currentStep - 1, components)

  return (
    <div className="about-your-business-body">
      <Stepper
        activeStepNumber={aboutBusinessModal.currentStep.toString()}
        type="mobile"
        steps={stepGenerator(components.length + 1)}
      />
      {modalContent || <></>}
    </div>
  )
}

const AboutBusinessModal = ({ companyId }) => {
  const { state, dispatch } = useContext(context)

  const disableSubmit =
    !validateInputs(state.aboutBusinessModal) ||
    !isNothing(state.aboutBusinessModal.httpError)
  return (
    <Modal
      modalId="about-your-business"
      title="About the business"
      onCancel={() => {
        dispatch(showPageSpinner)
        dispatch(refreshPage)
      }}
    >
      <Modal.Body>
        {isJust(state.aboutBusinessModal.httpError) ? (
          <Error httpError={state.aboutBusinessModal.httpError.value0} />
        ) : (
          <StepperWithContent />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            id="btn-about-business-modal-close"
            type="secondary"
            onClick={() => {
              dispatch(showPageSpinner)
              dispatch(refreshPage)
            }}
          >
            Close
          </Button>
          {state.aboutBusinessModal.showContinueBtn && (
            <Button
              id="btn-about-business-modal-continue"
              type="primary"
              onClick={() => dispatch(saveDetails(companyId))}
              disabled={disableSubmit}
            >
              Continue
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AboutBusinessModal
