import React from 'react'
import { Table, Tooltip, HeaderSort } from '@myob/myob-widgets'

export const Text = key => props => <span>{props[key]}</span>

export const Show = show => key => props => <span>{show(props[key])}</span>

function ColumnText(props) {
  return props.tooltip ? (
    <React.Fragment>
      {props.description} <Tooltip>{props.tooltip}</Tooltip>
    </React.Fragment>
  ) : (
    <React.Fragment>{props.description}</React.Fragment>
  )
}
export function renderTableHeader(format) {
  return (
    <Table.Header hasActions={true}>
      {format.map(col => (
        <Table.HeaderItem width={col.width} key={col.key} align={col.align}>
          <span className="system-fonts table-data__container">
            <ColumnText description={col.description} tooltip={col.tooltip} />
          </span>
        </Table.HeaderItem>
      ))}
    </Table.Header>
  )
}

export function renderSortableTableHeader(format, activeSort, onSort) {
  return (
    <Table.Header hasActions={true}>
      {format.map(col => (
        <Table.HeaderItem width={col.width} key={col.key} align={col.align}>
          <span className="system-fonts table-data__container">
            {col.sortable ? (
              <HeaderSort
                key={col.key}
                title={col.description}
                sortName={col.key}
                activeSort={activeSort}
                onSort={onSort}
              />
            ) : (
              <ColumnText description={col.description} tooltip={col.tooltip} />
            )}
          </span>
        </Table.HeaderItem>
      ))}
    </Table.Header>
  )
}

function renderTableRow(prop, format) {
  return (
    <Table.Row key={prop.id} isInactive={prop.isInActive}>
      {format.map(col => (
        <Table.RowItem
          key={col.key}
          width={col.width}
          align={col.align}
          columnName={col.description}
          cellRole={col.cellRole}
          valign={col.valign}
        >
          <col.component {...prop} />
        </Table.RowItem>
      ))}
    </Table.Row>
  )
}

export function renderTableBody(tableData, format) {
  return (
    <Table.Body>
      {tableData.map(record => renderTableRow(record, format))}
    </Table.Body>
  )
}
