import { Modal, Spinner, RadioButtonGroup } from '@myob/myob-widgets'
import React, { useContext } from 'react'
import { isNothing, Nothing, Just, maybe } from 'purs/Data.Maybe'
import { show } from 'purs/Data.Show'
import { disableFinalButton, fetchCompany, initState, selectUserRole, roleConfirm, roleCancel, redirectToCompanyListPage, userRoleOptions, saveOnboardRole, stepUpDashboard, enableOnBehalfConfirmNextButton} from 'purs/Page.OnboardUserRoleModal'
import withPsState from '../withPsState'
import { userRoleShow } from 'purs/Model.CompanyOnboardModal'
import UserFileUploader from '../components/UserFileUploader'
import StartOnboardingModal from '../components/StartOnboardingModal'
import * as r from 'ramda'
import config from '../config'

export const AuthorityFileUploadModal = () => {
    const { state, dispatch } = useContext(context)
    return (
        <Modal
            modalId="file-upload"
            title="Upload proof of authority"
            show={true}
            onCancel={()=> dispatch(redirectToCompanyListPage)}
        >
            <Modal.Body>
            <p>You&#39;ll need a couple of things to complete set up.</p>
            <ol>
            <li>Your personal ID, such as passport or driver licence</li>
            <li>The IDs of all business owners and one director (if applicable)</li>
            </ol>
            <br />
            <p>But first, you need to upload a proof of authority to set up payments for this business. Upload below:</p>
            <div className="step-upload-container-column">
            <UserFileUploader onUpdate={files => {
                dispatch(enableOnBehalfConfirmNextButton(r.any(r.propEq('state', 'finished'), files)))
            }} />
            </div>
            <br />
            <p>Upload your files as <b>PDF, DOC, JPEG, PNG or TIFF</b> and make sure it&#39;s below <b>10MB</b>.</p>
            <p>Don&#39;t have one on hand? Download this <a href={config.letterOfAuthorityURL} target="_blank">letter of authority</a> and get it filled out by the owners/directors.</p>
            </Modal.Body>
            <Modal.Footer>
            <button
                id="btn-role-select-file-upload-back"
                type="button"
                className="btn btn-default"
                onClick={() => dispatch(roleCancel)}
            >
                Back
            </button>
            <button
                id="btn-role-select-file-upload-submit"
                disabled={state.onBehalfConfirmModal.nextButton.disabled}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                    dispatch(enableOnBehalfConfirmNextButton(false))
                    dispatch(saveOnboardRole)
                }}
            >
                Submit
            </button>
            </Modal.Footer>
        </Modal>
    )
}

const RequiredInfoModal = () => {
    const { state, dispatch } = useContext(context)
    return (
        <Modal
            modalId="required-info"
            title="What you&#39;ll need"
            show={true}
            onCancel={()=> dispatch(redirectToCompanyListPage)}
        >
            <Modal.Body>
                <p>You&#39;ll need a couple of things to complete set up.</p>
                <ol>
                    <li>Your personal ID, such as passport or driver licence</li>
                    <li>The IDs of all business owners and one director (if applicable)</li>
                </ol>
                <br />
                <p>But first, grab your phone and click Next, to set up authentication.</p>
            </Modal.Body>
            <Modal.Footer>
            <button
                id="btn-role-select-required-info-back"
                type="button"
                className="btn btn-default"
                onClick={() => dispatch(roleCancel)}
            >
                Back
            </button>
            <button
                id="btn-role-select-required-info-next"
                type="button"
                className="btn btn-primary"
                disabled={state.uboConfirmModal.disableButton}
                onClick={() => {
                    dispatch(disableFinalButton)
                    dispatch(saveOnboardRole)}}
            >
                Next
            </button>
            </Modal.Footer>
        </Modal>
    )
}


const RoleSelectModal = () => {
    const { state, dispatch } = useContext(context)
    const {roleSelectModal} = state
    return (
        <Modal
            modalId="role-select"
            title="Your role"
            show={true}
            onCancel={()=> dispatch(redirectToCompanyListPage)}
        >
            {roleSelectModal.spinner.display ? <Spinner /> : (<>
            <Modal.Body>
                <RadioButtonGroup
                    label="How would you best describe your role? *"
                    name="onboard-user-role"
                    options={userRoleOptions(state)}
                    onChange={({value}) => dispatch(selectUserRole(value))}
                    value={maybe("")(show(userRoleShow))(roleSelectModal.selectedRole)}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                id="btn-role-select-next"
                disabled={isNothing(roleSelectModal.selectedRole)}
                type="button"
                className="btn btn-primary"
                onClick={() => dispatch(roleConfirm)}
                >
                Next
                </button>
            </Modal.Footer>
            </>)}
        </Modal>
    )
}

const OnboardUserRoleModal = () => {
    const { state, dispatch } = useContext(context)
    return (<>
        {state.roleSelectModal.display && <RoleSelectModal />}
        {state.uboConfirmModal.display && <RequiredInfoModal />}
        {state.onBehalfConfirmModal.display && <AuthorityFileUploadModal />}
        {state.stepUpModal.display && <StartOnboardingModal dispatch={dispatch} closeModal={redirectToCompanyListPage} stepUpDashboard={stepUpDashboard} />}
    </>)
}

export const { context, Component } = withPsState(
    OnboardUserRoleModal,
    initState,
    props => {
        const companyIdToOnboard = r.path(['match', 'params', 'companyId'], props)
        const companyId = r.isNil(companyIdToOnboard)
          ? Nothing.value
          : Just.create(companyIdToOnboard)
        return fetchCompany(companyId)
      }
)

export default Component

