// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Bounded = require("../Data.Bounded/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Data_Row = require("../Type.Data.Row/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Unvariant = function (x) {
    return x;
};
var VariantShows = function (variantShows) {
    this.variantShows = variantShows;
};
var VariantOrds = function (variantOrds) {
    this.variantOrds = variantOrds;
};
var VariantEqs = function (variantEqs) {
    this.variantEqs = variantEqs;
};
var VariantBounded = function (variantBounded) {
    this.variantBounded = variantBounded;
};
var VariantBoundedEnums = function (VariantBounded0, variantBoundedEnums) {
    this.VariantBounded0 = VariantBounded0;
    this.variantBoundedEnums = variantBoundedEnums;
};
var variantShows = function (dict) {
    return dict.variantShows;
};
var variantOrds = function (dict) {
    return dict.variantOrds;
};
var variantEqs = function (dict) {
    return dict.variantEqs;
};
var variantBoundedEnums = function (dict) {
    return dict.variantBoundedEnums;
};
var variantBounded = function (dict) {
    return dict.variantBounded;
};
var unvariant = function (v) {
    return function (f) {
        return (function (dictIsSymbol) {
            return function (dictCons) {
                return f(dictIsSymbol)();
            };
        })({
            reflectSymbol: Data_Function["const"](v.type)
        })({})(Data_Symbol.SProxy.value)(v.value);
    };
};
var showVariantNil = new VariantShows(function (v) {
    return Data_List_Types.Nil.value;
});
var showVariantCons = function (dictVariantShows) {
    return function (dictShow) {
        return new VariantShows(function (v) {
            return new Data_List_Types.Cons(Data_Show.show(dictShow), variantShows(dictVariantShows)(Type_Data_RowList.RLProxy.value));
        });
    };
};
var showVariant = function (dictRowToList) {
    return function (dictVariantTags) {
        return function (dictVariantShows) {
            return new Data_Show.Show(function (v1) {
                var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                var shows = variantShows(dictVariantShows)(Type_Data_RowList.RLProxy.value);
                var body = Data_Variant_Internal.lookup("show")(v1.type)(tags)(shows)(v1.value);
                return "(inj @" + (Data_Show.show(Data_Show.showString)(v1.type) + (" " + (body + ")")));
            });
        };
    };
};
var ordVariantNil = new VariantOrds(function (v) {
    return Data_List_Types.Nil.value;
});
var ordVariantCons = function (dictVariantOrds) {
    return function (dictOrd) {
        return new VariantOrds(function (v) {
            return new Data_List_Types.Cons(Data_Ord.compare(dictOrd), variantOrds(dictVariantOrds)(Type_Data_RowList.RLProxy.value));
        });
    };
};
var onMatch = function (dictRowToList) {
    return function (dictVariantMatchCases) {
        return function (dictUnion) {
            return function (r) {
                return function (k) {
                    return function (v) {
                        if (Record_Unsafe.unsafeHas(v.type)(r)) {
                            return Record_Unsafe.unsafeGet(v.type)(r)(v.value);
                        };
                        return k(v);
                    };
                };
            };
        };
    };
};
var on = function (dictCons) {
    return function (dictIsSymbol) {
        return function (p) {
            return function (f) {
                return function (g) {
                    return function (r) {
                        if (r.type === Data_Symbol.reflectSymbol(dictIsSymbol)(p)) {
                            return f(r.value);
                        };
                        return g(r);
                    };
                };
            };
        };
    };
};
var prj = function (dictCons) {
    return function (dictIsSymbol) {
        return function (dictAlternative) {
            return function (p) {
                return on()(dictIsSymbol)(p)(Control_Applicative.pure(dictAlternative.Applicative0()))(Data_Function["const"](Control_Plus.empty(dictAlternative.Plus1())));
            };
        };
    };
};
var inj = function (dictCons) {
    return function (dictIsSymbol) {
        return function (p) {
            return function (value) {
                return {
                    type: Data_Symbol.reflectSymbol(dictIsSymbol)(p),
                    value: value
                };
            };
        };
    };
};
var revariant = function (v) {
    return v(function (dictIsSymbol) {
        return function (dictCons) {
            return inj()(dictIsSymbol);
        };
    });
};
var expand = function (dictUnion) {
    return Unsafe_Coerce.unsafeCoerce;
};
var eqVariantNil = new VariantEqs(function (v) {
    return Data_List_Types.Nil.value;
});
var eqVariantCons = function (dictVariantEqs) {
    return function (dictEq) {
        return new VariantEqs(function (v) {
            return new Data_List_Types.Cons(Data_Eq.eq(dictEq), variantEqs(dictVariantEqs)(Type_Data_RowList.RLProxy.value));
        });
    };
};
var eqVariant = function (dictRowToList) {
    return function (dictVariantTags) {
        return function (dictVariantEqs) {
            return new Data_Eq.Eq(function (v1) {
                return function (v2) {
                    var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                    var eqs = variantEqs(dictVariantEqs)(Type_Data_RowList.RLProxy.value);
                    return Data_Variant_Internal.lookupEq(tags)(eqs)(v1)(v2);
                };
            });
        };
    };
};
var ordVariant = function (dictRowToList) {
    return function (dictVariantTags) {
        return function (dictVariantEqs) {
            return function (dictVariantOrds) {
                return new Data_Ord.Ord(function () {
                    return eqVariant()(dictVariantTags)(dictVariantEqs);
                }, function (v1) {
                    return function (v2) {
                        var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                        var ords = variantOrds(dictVariantOrds)(Type_Data_RowList.RLProxy.value);
                        return Data_Variant_Internal.lookupOrd(tags)(ords)(v1)(v2);
                    };
                });
            };
        };
    };
};
var enumVariant = function (dictRowToList) {
    return function (dictVariantTags) {
        return function (dictVariantEqs) {
            return function (dictVariantOrds) {
                return function (dictVariantBoundedEnums) {
                    return new Data_Enum.Enum(function () {
                        return ordVariant()(dictVariantTags)(dictVariantEqs)(dictVariantOrds);
                    }, function (a) {
                        var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                        var dicts = variantBoundedEnums(dictVariantBoundedEnums)(Type_Data_RowList.RLProxy.value);
                        var bounds = variantBounded(dictVariantBoundedEnums.VariantBounded0())(Type_Data_RowList.RLProxy.value);
                        return Data_Variant_Internal.lookupPred(a)(tags)(bounds)(dicts);
                    }, function (a) {
                        var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                        var dicts = variantBoundedEnums(dictVariantBoundedEnums)(Type_Data_RowList.RLProxy.value);
                        var bounds = variantBounded(dictVariantBoundedEnums.VariantBounded0())(Type_Data_RowList.RLProxy.value);
                        return Data_Variant_Internal.lookupSucc(a)(tags)(bounds)(dicts);
                    });
                };
            };
        };
    };
};
var $$default = function (a) {
    return function (v) {
        return a;
    };
};
var contract = function (dictAlternative) {
    return function (dictContractable) {
        return function (v) {
            return Data_Variant_Internal.contractWith(dictContractable)(dictAlternative)(Type_Data_Row.RProxy.value)(Type_Data_Row.RProxy.value)(v.type)(v);
        };
    };
};
var case_ = function (r) {
    return Partial_Unsafe.unsafeCrashWith("Data.Variant: pattern match failure [" + (r.type + "]"));
};
var match = function (dictRowToList) {
    return function (dictVariantMatchCases) {
        return function (dictUnion) {
            return function (r) {
                return onMatch()()()(r)(case_);
            };
        };
    };
};
var boundedVariantNil = new VariantBounded(function (v) {
    return Data_List_Types.Nil.value;
});
var enumVariantNil = new VariantBoundedEnums(function () {
    return boundedVariantNil;
}, function (v) {
    return Data_List_Types.Nil.value;
});
var boundedVariantCons = function (dictVariantBounded) {
    return function (dictBounded) {
        return new VariantBounded(function (v) {
            var dict = {
                top: Data_Bounded.top(dictBounded),
                bottom: Data_Bounded.bottom(dictBounded)
            };
            return new Data_List_Types.Cons(dict, variantBounded(dictVariantBounded)(Type_Data_RowList.RLProxy.value));
        });
    };
};
var enumVariantCons = function (dictVariantBoundedEnums) {
    return function (dictBoundedEnum) {
        return new VariantBoundedEnums(function () {
            return boundedVariantCons(dictVariantBoundedEnums.VariantBounded0())(dictBoundedEnum.Bounded0());
        }, function (v) {
            var dict = {
                pred: Data_Enum.pred(dictBoundedEnum.Enum1()),
                succ: Data_Enum.succ(dictBoundedEnum.Enum1()),
                fromEnum: Data_Enum.fromEnum(dictBoundedEnum),
                toEnum: Data_Enum.toEnum(dictBoundedEnum),
                cardinality: Data_Enum.cardinality(dictBoundedEnum)
            };
            return new Data_List_Types.Cons(dict, variantBoundedEnums(dictVariantBoundedEnums)(Type_Data_RowList.RLProxy.value));
        });
    };
};
var boundedVariant = function (dictRowToList) {
    return function (dictVariantTags) {
        return function (dictVariantEqs) {
            return function (dictVariantOrds) {
                return function (dictVariantBounded) {
                    return new Data_Bounded.Bounded(function () {
                        return ordVariant()(dictVariantTags)(dictVariantEqs)(dictVariantOrds);
                    }, (function () {
                        var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                        var dicts = variantBounded(dictVariantBounded)(Type_Data_RowList.RLProxy.value);
                        return Data_Variant_Internal.VariantRep(Data_Variant_Internal.lookupFirst("bottom")(function (v) {
                            return v.bottom;
                        })(tags)(dicts));
                    })(), (function () {
                        var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                        var dicts = variantBounded(dictVariantBounded)(Type_Data_RowList.RLProxy.value);
                        return Data_Variant_Internal.VariantRep(Data_Variant_Internal.lookupLast("top")(function (v) {
                            return v.top;
                        })(tags)(dicts));
                    })());
                };
            };
        };
    };
};
var boundedEnumVariant = function (dictRowToList) {
    return function (dictVariantTags) {
        return function (dictVariantEqs) {
            return function (dictVariantOrds) {
                return function (dictVariantBoundedEnums) {
                    return new Data_Enum.BoundedEnum(function () {
                        return boundedVariant()(dictVariantTags)(dictVariantEqs)(dictVariantOrds)(dictVariantBoundedEnums.VariantBounded0());
                    }, function () {
                        return enumVariant()(dictVariantTags)(dictVariantEqs)(dictVariantOrds)(dictVariantBoundedEnums);
                    }, Data_Enum.Cardinality(Data_Variant_Internal.lookupCardinality(variantBoundedEnums(dictVariantBoundedEnums)(Type_Data_RowList.RLProxy.value))), function (a) {
                        var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                        var dicts = variantBoundedEnums(dictVariantBoundedEnums)(Type_Data_RowList.RLProxy.value);
                        return Data_Variant_Internal.lookupFromEnum(a)(tags)(dicts);
                    }, function (n) {
                        var tags = Data_Variant_Internal.variantTags(dictVariantTags)(Type_Data_RowList.RLProxy.value);
                        var dicts = variantBoundedEnums(dictVariantBoundedEnums)(Type_Data_RowList.RLProxy.value);
                        return Data_Variant_Internal.lookupToEnum(n)(tags)(dicts);
                    });
                };
            };
        };
    };
};
module.exports = {
    inj: inj,
    prj: prj,
    on: on,
    onMatch: onMatch,
    case_: case_,
    match: match,
    "default": $$default,
    expand: expand,
    contract: contract,
    Unvariant: Unvariant,
    unvariant: unvariant,
    revariant: revariant,
    VariantEqs: VariantEqs,
    variantEqs: variantEqs,
    VariantOrds: VariantOrds,
    variantOrds: variantOrds,
    VariantShows: VariantShows,
    variantShows: variantShows,
    VariantBounded: VariantBounded,
    variantBounded: variantBounded,
    VariantBoundedEnums: VariantBoundedEnums,
    variantBoundedEnums: variantBoundedEnums,
    eqVariantNil: eqVariantNil,
    eqVariantCons: eqVariantCons,
    eqVariant: eqVariant,
    boundedVariantNil: boundedVariantNil,
    boundedVariantCons: boundedVariantCons,
    boundedVariant: boundedVariant,
    enumVariantNil: enumVariantNil,
    enumVariantCons: enumVariantCons,
    enumVariant: enumVariant,
    boundedEnumVariant: boundedEnumVariant,
    ordVariantNil: ordVariantNil,
    ordVariantCons: ordVariantCons,
    ordVariant: ordVariant,
    showVariantNil: showVariantNil,
    showVariantCons: showVariantCons,
    showVariant: showVariant
};
