import React from 'react'
import { getKeyValueFromQuery } from 'purs/Helper.RouteQuery'
import { isLeft } from 'purs/Data.Either'

const getValue = either => {
  return isLeft(either) ? undefined : either.value0
}

const Triage = props => {
  const search = props.location.search.toLowerCase()
  const hash = props.location.hash
  const offline = getValue(getKeyValueFromQuery(search)('offline'))
  const region = getValue(getKeyValueFromQuery(search)('region'))
  const companyId = getValue(getKeyValueFromQuery(hash)('companyId'))
  if (offline === 'true') {
    return (
      <div>
        Hi, you are in offline mode now, please upgrade your package to online
        mode
      </div>
    )
  } else if (region === 'au') {
    return <div>Onboarding page coming soon</div>
  } else if (region) {
    return (
      <div>
        Hi, your region is not supported yet, please contact ******* for latest
        news
      </div>
    )
  } else if (companyId) {
    return <div>{`try to sign up companyId ${companyId}`}</div>
  } else {
    return <div>Oops!</div>
  }
}

export default Triage
