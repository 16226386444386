// Generated by purs version 0.13.8
"use strict";
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Show = require("../Data.Show/index.js");
var Foreign = require("../Foreign/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Owner = (function () {
    function Owner() {

    };
    Owner.value = new Owner();
    return Owner;
})();
var FileUser = (function () {
    function FileUser() {

    };
    FileUser.value = new FileUser();
    return FileUser;
})();
var Administrator = (function () {
    function Administrator() {

    };
    Administrator.value = new Administrator();
    return Administrator;
})();
var Other = (function () {
    function Other(value0) {
        this.value0 = value0;
    };
    Other.create = function (value0) {
        return new Other(value0);
    };
    return Other;
})();
var showCompanyAccess = new Data_Show.Show(function (v) {
    if (v instanceof Owner) {
        return "Owner";
    };
    if (v instanceof FileUser) {
        return "File User";
    };
    if (v instanceof Administrator) {
        return "Administrator";
    };
    if (v instanceof Other) {
        return v.value0;
    };
    throw new Error("Failed pattern match at Model.CompanyAccess (line 12, column 1 - line 16, column 21): " + [ v.constructor.name ]);
});
var writeForeignCompanyAccess = new Simple_JSON.WriteForeign((function () {
    var $8 = Simple_JSON.write(Simple_JSON.writeForeignString);
    var $9 = Data_Show.show(showCompanyAccess);
    return function ($10) {
        return $8($9($10));
    };
})());
var readForeignCompanyStatus = new Simple_JSON.ReadForeign((function () {
    var getVal = function (v) {
        if (v === "Owner") {
            return Owner.value;
        };
        if (v === "File User") {
            return FileUser.value;
        };
        if (v === "Administrator") {
            return Administrator.value;
        };
        return new Other(v);
    };
    var $11 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(getVal);
    return function ($12) {
        return $11(Foreign.readString($12));
    };
})());
var isAdmin = function (v) {
    if (v instanceof Owner) {
        return true;
    };
    if (v instanceof Administrator) {
        return true;
    };
    if (v instanceof FileUser) {
        return false;
    };
    if (v instanceof Other) {
        return false;
    };
    throw new Error("Failed pattern match at Model.CompanyAccess (line 27, column 1 - line 27, column 36): " + [ v.constructor.name ]);
};
module.exports = {
    Owner: Owner,
    FileUser: FileUser,
    Administrator: Administrator,
    Other: Other,
    isAdmin: isAdmin,
    writeForeignCompanyAccess: writeForeignCompanyAccess,
    showCompanyAccess: showCompanyAccess,
    readForeignCompanyStatus: readForeignCompanyStatus
};
