// Generated by purs version 0.13.8
"use strict";
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Debit = (function () {
    function Debit() {

    };
    Debit.value = new Debit();
    return Debit;
})();
var Internal = (function () {
    function Internal() {

    };
    Internal.value = new Internal();
    return Internal;
})();
var Settlement = (function () {
    function Settlement() {

    };
    Settlement.value = new Settlement();
    return Settlement;
})();
var genericStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Debit) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof Internal) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof Settlement) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
    };
    throw new Error("Failed pattern match at Model.PaymentError (line 16, column 1 - line 16, column 62): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Debit.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return Internal.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
        return Settlement.value;
    };
    throw new Error("Failed pattern match at Model.PaymentError (line 16, column 1 - line 16, column 62): " + [ x.constructor.name ]);
});
var readForeignPaymentErrorSource = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genericStatus)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Debit";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Internal";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Settlement";
}))))));
module.exports = {
    Debit: Debit,
    Internal: Internal,
    Settlement: Settlement,
    genericStatus: genericStatus,
    readForeignPaymentErrorSource: readForeignPaymentErrorSource
};
