import React, { useContext } from 'react'
import * as r from 'ramda'
import { Spinner } from '@myob/myob-widgets'
import withPsState from '../withPsState'
import NavBar from '../components/NavBar'
import './Companies.sass'
import CompaniesPanel from '../components/CompaniesPanel'
import { loadCompanies, initState, closeModal, stepUpDashboard } from 'purs/Company'
import { Just, Nothing } from 'purs/Data.Maybe'
import StartOnboardingModal from '../components/StartOnboardingModal'
import SignUpModal from '../components/SignUpModal'

export const Companies = () => {
  const { state, dispatch } = useContext(context)
  return (
    <React.Fragment>
      <NavBar />
      {state.isLoading ? <Spinner /> : <CompaniesPanel />}
      <SignUpModal />
      {state.stepOnboardModal.display && <StartOnboardingModal dispatch={dispatch} closeModal={closeModal} stepUpDashboard={stepUpDashboard} />}
    </React.Fragment>
  )
}

export const { context, Component } = withPsState(
  Companies,
  initState,
  props => {
    const companyIdToOnboard = r.path(['match', 'params', 'companyId'], props)
    const companyId = r.isNil(companyIdToOnboard)
      ? Nothing.value
      : Just.create(companyIdToOnboard)
    return loadCompanies(companyId)
  }
)

export default Component
