// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Config = require("../Config/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var CookieJar = require("../CookieJar/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Compat = require("../Effect.Aff.Compat/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Foreign = require("../Foreign/index.js");
var Record = require("../Record/index.js");
var recover = function (defaultValue) {
    return function (ma) {
        return Control_Monad_Error_Class.catchError(Effect_Aff.monadErrorAff)(ma)(function (e) {
            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Effect_Console.errorShow(Effect_Exception.showError)(e))(defaultValue));
        });
    };
};
var matchResp = function (expected) {
    return function (v) {
        var $13 = v.status === expected;
        if ($13) {
            return new Data_Either.Right(v.body);
        };
        return new Data_Either.Left(v.status);
    };
};
var csrf_ = Data_Symbol.SProxy.value;
var insertCsrf = function (dictLacks) {
    return function (csrf) {
        return function (headers) {
            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.map(Effect.functorEffect)(Data_Function.flip(Record.insert(new Data_Symbol.IsSymbol(function () {
                return "X-TSec-Csrf";
            }))()()(csrf_))(headers))(csrf));
        };
    };
};
var get = function (dictLacks) {
    return function (url) {
        return function (headers) {
            var sendGetRequest = (function () {
                var $16 = $foreign["_get"](Config.config.apiHost + url)(Config.config.cors);
                return function ($17) {
                    return Effect_Aff_Compat.fromEffectFnAff($16($17));
                };
            })();
            return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(insertCsrf()(CookieJar.csrf)(headers)))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](sendGetRequest(headers)))(sendGetRequest));
        };
    };
};
var _requestIframe = function (dictLacks) {
    return function (method) {
        return Control_Bind.composeKleisliFlipped(Effect_Aff.bindAff)(function ($18) {
            return Effect_Aff_Compat.fromEffectFnAff(method($18));
        })(insertCsrf()(CookieJar.parentCsrf));
    };
};
var postIframe = function (dictLacks) {
    return function (url) {
        return function (body) {
            return _requestIframe()($foreign["_post"](Config.config.apiHost + url)(Config.config.cors)(Foreign.unsafeToForeign(body)));
        };
    };
};
var putIframe = function (dictLacks) {
    return function (url) {
        var $19 = _requestIframe();
        var $20 = $foreign["_put"](Config.config.apiHost + url)(Config.config.cors);
        return function ($21) {
            return $19($20($21));
        };
    };
};
var _request = function (dictLacks) {
    return function (method) {
        return Control_Bind.composeKleisliFlipped(Effect_Aff.bindAff)(function ($22) {
            return Effect_Aff_Compat.fromEffectFnAff(method($22));
        })(insertCsrf()(CookieJar.csrf));
    };
};
var $$delete = function (dictLacks) {
    return function (url) {
        return _request()($foreign["_delete"](Config.config.apiHost + url)(Config.config.cors));
    };
};
var post = function (dictLacks) {
    return function (url) {
        return function (body) {
            return _request()($foreign["_post"](Config.config.apiHost + url)(Config.config.cors)(Foreign.unsafeToForeign(body)));
        };
    };
};
var postForeign = function (dictLacks) {
    return function (url) {
        var $23 = _request();
        var $24 = $foreign["_post"](url)(Config.config.cors);
        return function ($25) {
            return $23($24($25));
        };
    };
};
var put = function (dictLacks) {
    return function (url) {
        var $26 = _request();
        var $27 = $foreign["_put"](Config.config.apiHost + url)(Config.config.cors);
        return function ($28) {
            return $26($27($28));
        };
    };
};
module.exports = {
    get: get,
    matchResp: matchResp,
    post: post,
    postForeign: postForeign,
    put: put,
    "delete": $$delete,
    recover: recover,
    postIframe: postIframe,
    putIframe: putIframe
};
