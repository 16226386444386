import React, { useState } from 'react'
import { Alert } from '@myob/myob-widgets'
import { getDismissTime, showAlertType } from 'purs/AlertBanner'
import * as maybe from 'purs/Data.Maybe'
import { show } from 'purs/Data.Show'

const AlertBanner = props => {
  const [toggles, setToggles] = useState({ dismissed: false })
  const dismissTime = getDismissTime(props)
  return toggles.dismissed ? (
    <></>
  ) : (
    <div className={props.containerOff ? '' : 'flx-container'}>
      <Alert
        type={props.type ? show(showAlertType)(props.type) : 'info'}
        dismissAfter={
          maybe.isNothing(dismissTime) ? undefined : dismissTime.value0
        }
        onDismiss={() => setToggles({ dismissed: true })}
      >
        {props.children}
      </Alert>
    </div>
  )
}

export default AlertBanner
