import {
  Alert,
  Icons,
  Input,
  Modal,
  Separator,
  Spinner,
  Checkbox
} from '@myob/myob-widgets'
import { Danger, Warning } from 'purs/AlertBanner'
import {
  AbnValidated,
  AbnUnsupported,
  AbnError,
  AbnCancelled,
  CreditScoreCheckFail
} from 'purs/Model.CompanyOnboardModal'
import {
  signUp,
  setModalLoading,
  updateAbnInput,
  toggleVerificationCheckbox
} from 'purs/Component.SignUpModal'
import { closeModal } from 'purs/Company'
import { rmSpaces } from 'purs/SignUpHelper'
import React, { useContext, useEffect } from 'react'
import { context } from '../pages/Companies'
import AbnDetail from './AbnDetail'
import AlertBanner from './AlertBanner'
import { formatABN } from './SignUpModal.AbnHelper'
import './SignUpModal.sass'
import config from '../config'
import { unsafeTrackingCompany, OnboardStart } from 'purs/Segment'
import { maybe } from 'purs/Data.Maybe'

const AbnInput = ({content}) => {
  return(
    <>
      <AbnInputBox content={content} />
      <AbnDetail content={content} />
      <VerificationCheckbox />
      {content instanceof AbnValidated && <Disclaimer />}
    </>
  )
}

const checkingSpinner = () => (
  <div className="quick-checking">
    <Spinner className="medium" />
    <p className="center">Checking…</p>
    <p className="center">
      Don’t close this page or <br /> refresh your browser.
    </p>
  </div>
)

const abnInputError = content => {
  if (content instanceof AbnError && content.value1 instanceof AbnCancelled)
    return ' '

  return content.value0.isFormatted ? '' : 'The ABN must be 11 digits.'
}

const AbnInputBox = ({ content }) => {
  const tick = content instanceof AbnValidated
  const { dispatch } = useContext(context)

  return (
    <div className="abn-input">
      <Input
        name="ABN"
        id={'abn-input'}
        data-testid="abn-input"
        label="Australian Business Number (ABN) *"
        value={formatABN(content.value0.number)}
        errorMessage={abnInputError(content)}
        disabled={content.value0.disabled}
        onChange={abnInput => dispatch(updateAbnInput(abnInput.target.value))}
      />
      <div className="icon icon__valid">{tick ? <Icons.Tick /> : <></>}</div>
      <a
        href="https://abr.business.gov.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Forgot your ABN?
      </a>
    </div>
  )
}

const ModalAlert = () => {
  const { state } = useContext(context)
  const content = state.onboardModal.content
  switch (content.constructor) {
    case AbnUnsupported:
      return (
        <AlertBanner type={Warning.value}>
          <span>
            Sorry, we don&apos;t support this business type yet, but we&apos;re
            working on it. To get the latest updates about payments for your
            business type visit our official{' '}
            <a
              href={config.paymentSignupUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              payments page
            </a>
            .
          </span>
        </AlertBanner>
      )
    case AbnError:
      switch (content.value1.constructor) {
        case CreditScoreCheckFail:
          return (
            <AlertBanner type={Warning.value}>
              <span>
                It looks like this business doesn&apos;t meet the minimum credit
                requirements to enable payments. For further information, you
                can contact our support team at{' '}
                <a href={'tel:1300783674'}>1300 783 674</a>.
              </span>
            </AlertBanner>
          )
        case AbnCancelled:
          return (
            <Alert type="danger" inline>
              <p className="fatal-alert-body">
                This ABN appears to be cancelled. View the Australian Business
                Register&apos;s website for more details.&nbsp;
              </p>
              <a
                href={`https://abr.business.gov.au/ABN/View?abn=${rmSpaces(
                  content.value0.number
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View more details
              </a>
            </Alert>
          )
        default:
          return (
            <AlertBanner type={Danger.value}>
              <span>
                Oops, looks like something went wrong! Please try again later.
              </span>
            </AlertBanner>
          )
      }
    default:
      return <></>
  }
}

const Disclaimer = () => (
  <>
    <Separator />
    <div className="pds">
      By clicking &quot;Confirm&quot; you acknowledge that you have read and
      agree to the{' '}
      <a
        href={config.productDisclosureStatementUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        MYOB Payment Services Product Disclosure Statement
      </a>{' '}
      and{' '}
      <a
        href={config.financialServicesGuideUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        MYOB Payment Services Financial Services Guide
      </a>
      . You&apos;re also consenting to receive communications, including email.
      We value your privacy, please see our{' '}
      <a
        href={config.privacyDisclosureStatementUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Disclosure Statement
      </a>{' '}
      for information on how we collect and handle your personal information.
    </div>
  </>
)

const VerificationCheckbox = () => {
  const { state, dispatch } = useContext(context)
  const { content, verificationCheckbox } = state.onboardModal

  return (
    content instanceof AbnValidated && (
      <Checkbox
        name="verification-checkbox"
        label="I verify that these business details are correct"
        checked={verificationCheckbox.checked}
        onChange={() => dispatch(toggleVerificationCheckbox)}
      />
    )
  )
}

const SignUpModal = () => {
  const { state, dispatch } = useContext(context)
  const close = () => dispatch(closeModal)

  const {
    display,
    spinner,
    content,
    verificationCheckbox
  } = state.onboardModal

  useEffect(() => {
    const company = state.selectedCompany
    if (display) unsafeTrackingCompany(company)(OnboardStart.value)
  }, [])

  const abnNotValidated = !(content instanceof AbnValidated) || spinner.display
  const shouldDisableConfirmButton = abnNotValidated || !verificationCheckbox.checked

  const companyName = maybe('')(company => company.name)(state.selectedCompany)

  return (
    <Modal
      modalId="eligibility-check"
      title="Eligibility check"
      show={display}
      onCancel={close}
    >
      {spinner.display ? (
        checkingSpinner()
      ) : (
        <Modal.Body>
          <ModalAlert />
          <h3>Let&apos;s get started</h3>
          <p className="signup-modal-desc-onbehalf">
            To ensure we&apos;re able to proceed, please enter the ABN for {companyName}.
          </p>
          <AbnInput content={content}/>
        </Modal.Body>
      )}
      <Modal.Footer>
        <button id="btn-signup-modal-cancel" type="button" className="btn btn-default" onClick={close}>
          Cancel
        </button>
        <button
          id="btn-signup-modal-confirm"
          disabled={shouldDisableConfirmButton}
          type="button"
          className="btn btn-primary"
          onClick={() => {
            dispatch(setModalLoading)
            dispatch(signUp)
          }}
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SignUpModal
