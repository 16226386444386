import './RewardsThanksModal.sass'
import React from 'react'
import { Modal, Button } from '@myob/myob-widgets'
import HiFive from '../img/hi_five.svg'


const RewardsThanksModal = ({ onCancel }) => {
  return (
    <Modal
      modalId="rewards-thanks"
      title="Join MYOB Rewards"
      onCancel={onCancel}
    >
      <Modal.Body>
        <div className="rewardsthanks__body">
          <div className="image_container">
            <img src={HiFive} alt="Master Icon" />
          </div>
          <h3>Welcome to the wonderful world of rewards</h3>

          <p>From now on, you'll earn points on every payment made by credit card.</p>

          <p>
            Points will typically appear in your MYOB Rewards account within 14
            days after payments are authorised.
          </p>
          <br/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button id="btn-rewards-thx-modal-confirm" onClick={onCancel}>
          Got it
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RewardsThanksModal
