// Generated by purs version 0.13.8
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_EitherR = require("../Data.EitherR/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_RouteQuery = require("../Helper.RouteQuery/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Segment = require("../Segment/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var RequestError = (function () {
    function RequestError(value0) {
        this.value0 = value0;
    };
    RequestError.create = function (value0) {
        return new RequestError(value0);
    };
    return RequestError;
})();
var UnsupportedPaymentError = (function () {
    function UnsupportedPaymentError() {

    };
    UnsupportedPaymentError.value = new UnsupportedPaymentError();
    return UnsupportedPaymentError;
})();
var initState = {
    spinner: {
        display: true
    },
    error: Data_Maybe.Nothing.value
};
var genericError = "Oops, looks like something went wrong! Please close and try again later.";
var _sendMetrics = function (result) {
    return function (errorMessage) {
        var segmentData = new Segment.OnboardComplete(result, errorMessage);
        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
            var comp = Model_Company.readCompany();
            if (comp instanceof Data_Either.Right && Data_Eq.notEq(Model_CompanyStatus.eqCompanyStatus)(comp.value0.status)(Model_CompanyStatus.Registered.value)) {
                return Segment.trackingCompany(Data_Either.hush(comp))(segmentData)();
            };
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))();
        });
    };
};
var _sendAddCardRequest = function (company) {
    return function (query) {
        return Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.postIframe()("/company/" + (company + "/card-on-behalf-of"))(Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
            return "reqid";
        }))(Simple_JSON.writeForeignString)(Simple_JSON.nilWriteForeignFields)()()()))({
            reqid: query
        }))({}));
    };
};
var _getLeft = function ($29) {
    return Data_Either.hush(Data_EitherR.flipEither($29));
};
var _verifyNPRQuery = function (query) {
    var readQuery = Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(function (v) {
        return function (v1) {
            return {
                reqId: v,
                companyId: v1
            };
        };
    })(Helper_RouteQuery.getKeyValueFromQuery(query)("payment-rails-transaction-id")))(Helper_RouteQuery.getKeyValueFromQuery(query)("companyId"));
    return Data_Functor.mapFlipped(Effect.functorEffect)(Model_Company.readCompany)(function (v) {
        if (readQuery instanceof Data_Either.Right && (v instanceof Data_Either.Right && readQuery.value0.companyId === v.value0.companyId)) {
            return new Data_Either.Right(readQuery.value0);
        };
        return Data_Either.Left.create(Data_Maybe.fromMaybe("CompanyIds do not match")(Control_Alt.alt(Data_Maybe.altMaybe)(_getLeft(readQuery))(_getLeft(v))));
    });
};
var _verifyQuery = function (query) {
    var readQuery = Control_Apply.apply(Data_Either.applyEither)(Data_Functor.map(Data_Either.functorEither)(function (v) {
        return function (v1) {
            return {
                reqId: v,
                companyId: v1
            };
        };
    })(Helper_RouteQuery.getKeyValueFromQuery(query)("reqid")))(Helper_RouteQuery.getKeyValueFromQuery(query)("clientRef"));
    return Data_Functor.mapFlipped(Effect.functorEffect)(Model_Company.readCompany)(function (v) {
        if (readQuery instanceof Data_Either.Right && (v instanceof Data_Either.Right && readQuery.value0.companyId === v.value0.companyId)) {
            return new Data_Either.Right(readQuery.value0);
        };
        return Data_Either.Left.create(Data_Maybe.fromMaybe("CompanyIds do not match")(Control_Alt.alt(Data_Maybe.altMaybe)(_getLeft(readQuery))(_getLeft(v))));
    });
};
var _createCard = function (state) {
    return function (result) {
        if (result instanceof Data_Either.Left) {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                spinner: {
                    display: false
                },
                error: new Data_Maybe.Just(new RequestError(genericError))
            });
        };
        if (result instanceof Data_Either.Right) {
            return Control_Bind.bind(Effect_Aff.bindAff)(_sendAddCardRequest(result.value0.companyId)(result.value0.reqId))(function (resp) {
                if (resp instanceof Data_Either.Right && resp.value0.status === 201) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(_sendMetrics("Passed")(""))(function () {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                            spinner: {
                                display: false
                            },
                            error: Data_Maybe.Nothing.value
                        });
                    });
                };
                if (resp instanceof Data_Either.Right && resp.value0.status === 400) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(_sendMetrics("Failed")("Oops, looks like we don't support this payment method. Please try again."))(function () {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                            spinner: {
                                display: false
                            },
                            error: new Data_Maybe.Just(UnsupportedPaymentError.value)
                        });
                    });
                };
                if (resp instanceof Data_Either.Right && resp.value0.status === 503) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(_sendMetrics("Failed")("We've hit a roadblock! Please try again later."))(function () {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                            spinner: {
                                display: false
                            },
                            error: new Data_Maybe.Just(new RequestError("We've hit a roadblock! Please try again later."))
                        });
                    });
                };
                return Control_Bind.bind(Effect_Aff.bindAff)(_sendMetrics("Failed")(genericError))(function () {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        spinner: {
                            display: false
                        },
                        error: new Data_Maybe.Just(new RequestError(genericError))
                    });
                });
            });
        };
        throw new Error("Failed pattern match at NewCardRedirect (line 58, column 3 - line 95, column 12): " + [ result.constructor.name ]);
    };
};
var finalizeOnBoarding = function (query) {
    return function (state) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(_verifyQuery(query)))(_createCard(state));
    };
};
var finalizeOnBoardingThroughNPR = function (query) {
    return function (state) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(_verifyNPRQuery(query)))(_createCard(state));
    };
};
module.exports = {
    initState: initState,
    genericError: genericError,
    finalizeOnBoarding: finalizeOnBoarding,
    finalizeOnBoardingThroughNPR: finalizeOnBoardingThroughNPR,
    RequestError: RequestError,
    UnsupportedPaymentError: UnsupportedPaymentError
};
