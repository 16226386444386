import { Button, Icons } from '@myob/myob-widgets'
import { Danger } from 'purs/AlertBanner'
import {
  checkCard,
  fetchPaymentLink,
  showDeleteCardModal,
  showAddCardModal,

} from 'purs/CardManage'
import { isJust } from 'purs/Data.Maybe'
import * as r from 'ramda'
import { reload } from 'purs/Page.BusinessManage'
import React, { useContext } from 'react'
import { context } from '../pages/BusinessManage'
import AlertBanner from './AlertBanner'
import CardSchema from './CardManage.Schema'
import DeleteCardModal from './DeleteCardModal'
import NewCardModal from './NewCardModal'
import SetupDefaultPaymentMethodModal from './SetupDefaultPaymentMethodModal'

const renderEmptyCard = dispatch => {
  return (
    <div className="card-container">
      <div className="no-card">
        <Button
          id="btn-card-manage-add-card"
          className="add-card-btn"
          type="link"
          icon={<Icons.Add />}
          onClick={() => {
            dispatch(showAddCardModal(true))
            dispatch(fetchPaymentLink)
          }}
        >
          Add a card
        </Button>
      </div>
      <NewCardModal />
    </div>
  )
}

const RenderActiveCard = ({card, companyId, dispatch}) => {
  const isDefault = card.isDefault
  const [showSetDefault, setShowSetDefault] = React.useState(false)
  return (
    <div className="card-container">
      <CardSchema {...checkCard(card)(Date.now())} isDefault={isDefault} />
      <Button
        id="btn-card-manage-delete-card"
        type="link"
        className="delete-card-link"
        onClick={() => dispatch(showDeleteCardModal(true))}
        icon={<Icons.Delete />}
      >
        Remove
      </Button>
       {!isDefault && (
         <Button type="link" onClick={() => setShowSetDefault(true)}
           className="set-default-link" icon={<Icons.Add />}
         >
         Set as default
         </Button>
       )}
      { showSetDefault && (
        <SetupDefaultPaymentMethodModal paymentMethod={{card}}
          companyId={companyId}
          onCancel={() => setShowSetDefault(false)}
          onSuccess={() => {setShowSetDefault(false); dispatch(reload)}}/>)
      }
    </div>
  )
}

const DeleteCardAlert = () => {
  const { state } = useContext(context)
  return (
    <div className="delete-card-alert">
      {isJust(state.deleteCardModal.httpError) ? (
        <AlertBanner type={new Danger()}>
          <span>
            Oops, looks like something went wrong! Please try again later.
          </span>
        </AlertBanner>
      ) : (
        <></>
      )}
    </div>
  )
}

const CardDisplay = () => {
  const { state, dispatch } = useContext(context)
  const companyId = r.pathOr('', ['companyId', 'value0'], state)
  return r.isEmpty(state.paymentMethods.cards) ? (
    renderEmptyCard(dispatch)
  ) : (
    <RenderActiveCard card={r.head(state.paymentMethods.cards)}
                      dispatch={dispatch} companyId={companyId}/>
  )
}

const CardManage = () => {
  return (
    <>
      <DeleteCardAlert />
      <CardDisplay />
      <DeleteCardModal />
    </>
  )
}

export default CardManage
