// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Fetch = require("../Fetch/index.js");
var Foreign = require("../Foreign/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var initState = {
    fetchPaymentMethods: {
        isLoading: true,
        httpError: Data_Maybe.Nothing.value
    },
    newCardModal: {
        isLoading: false,
        show: false,
        cardIframeLink: Data_Maybe.Nothing.value,
        httpError: Data_Maybe.Nothing.value
    },
    deleteCardModal: {
        show: false,
        httpError: Data_Maybe.Nothing.value
    },
    companyId: Data_Maybe.Nothing.value,
    stepUpError: Data_Maybe.Nothing.value,
    paymentMethods: {
        cards: [  ],
        bankAccounts: [  ]
    }
};
var load = function (redirect) {
    return function (state) {
        var readBody = function (body) {
            return Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "bankAccounts";
            }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "accountName";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "accountNumber";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "bsbNumber";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "id";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "isDefault";
            }))(Simple_JSON.readBoolean)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "cards";
            }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "expiry";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "id";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "isDefault";
            }))(Simple_JSON.readBoolean)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "number";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "scheme";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "type";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())))(Simple_JSON.readFieldsNil)()())()()))(body);
        };
        var sendRequest = function (c) {
            var defaultState = {
                companyId: new Data_Maybe.Just(c.companyId),
                deleteCardModal: initState.deleteCardModal,
                fetchPaymentMethods: initState.fetchPaymentMethods,
                newCardModal: initState.newCardModal,
                paymentMethods: initState.paymentMethods,
                stepUpError: initState.stepUpError
            };
            return Fetch.recover({
                fetchPaymentMethods: {
                    isLoading: false,
                    httpError: new Data_Maybe.Just(500)
                },
                companyId: defaultState.companyId,
                deleteCardModal: defaultState.deleteCardModal,
                newCardModal: defaultState.newCardModal,
                paymentMethods: defaultState.paymentMethods,
                stepUpError: defaultState.stepUpError
            })(Control_Bind.bind(Effect_Aff.bindAff)(Fetch.get()("/company/" + (c.companyId + "/payment-method"))({}))(function (v) {
                if (v.status === 200) {
                    var v1 = readBody(v.body);
                    if (v1 instanceof Data_Either.Left) {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Effect_Console.errorShow(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v1.value0))(defaultState));
                    };
                    if (v1 instanceof Data_Either.Right) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                            paymentMethods: v1.value0,
                            fetchPaymentMethods: {
                                isLoading: false,
                                httpError: Data_Maybe.Nothing.value
                            },
                            companyId: defaultState.companyId,
                            deleteCardModal: defaultState.deleteCardModal,
                            newCardModal: defaultState.newCardModal,
                            stepUpError: defaultState.stepUpError
                        });
                    };
                    throw new Error("Failed pattern match at Page.BusinessManage (line 70, column 32 - line 72, column 125): " + [ v1.constructor.name ]);
                };
                if (v.status === 401) {
                    return Data_Functor.voidLeft(Effect_Aff.functorAff)(Helper_AuthActions.stepUp()("/manage")({}))(defaultState);
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    fetchPaymentMethods: {
                        isLoading: false,
                        httpError: new Data_Maybe.Just(v.status)
                    },
                    companyId: defaultState.companyId,
                    deleteCardModal: defaultState.deleteCardModal,
                    newCardModal: defaultState.newCardModal,
                    paymentMethods: defaultState.paymentMethods,
                    stepUpError: defaultState.stepUpError
                });
            }));
        };
        var handleNoCompany = function (e) {
            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Control_Apply.applySecond(Effect.applyEffect)(Effect_Console.errorShow(Data_Show.showString)(e))(redirect("/companies")))(state));
        };
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Model_Company.readCompany))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(handleNoCompany)(sendRequest));
    };
};
var reload = load(Helper_AuthActions.redirectTo);
module.exports = {
    initState: initState,
    load: load,
    reload: reload
};
