import {
  Modal,
  RadioButtonGroup,
  Spinner,
  RadioButton,
  Label,
  Tooltip,
  Icons,
  Button,
  Popover
} from '@myob/myob-widgets'
import React, { useContext, useEffect } from 'react'
import { context } from '../pages/Dashboard'
import Error from './Error'
import { isJust, maybe } from 'purs/Data.Maybe'
import * as R from 'ramda'
import {
  showOwnerName,
  isSubmitted,
  filterOwnerByKind,
  directorNotNeeded,
  Director,
  UBO
} from 'purs/Model.Owner'
import {
  selectOwner,
  showOwnerKycModal,
  refreshPage,
  showPageSpinner,
  refreshCompanyAndShowUBOModal
} from 'purs/Dashboard'
import './CompanyUBOModal.sass'

const OwnerLabelAccessory = owner =>
  isSubmitted(owner) && (
    <Label color="green" type="boxed" className="submitted-label">
      Submitted
    </Label>
  )

const OwnerRadios = (owners, selectedOwner) => disable => ({ value, id, ...props }) => {
  const isChecked = (itemValue) => maybe(false)(owner => owner.id === itemValue)(selectedOwner)
  return (
    <>
      {R.map(o => (
        <RadioButton
          {...props}
          name={showOwnerName(o.owner)}
          key={o.owner.id}
          checked={isChecked(o.owner.id)}
          value={o.owner.id.toString()}
          disabled={isJust(o.kycResult) || disable}
          label={showOwnerName(o.owner)}
          labelAccessory={OwnerLabelAccessory(o)}
        />
      ))(owners)}
    </>
  )
}

const ExtraInfoPopover = () => {
  const popoverBody = (
    <div>
      If you can&apos;t see the current owners and directors here, please update
      these names with&nbsp;
      <a href="https://asic.gov.au/" target="_blank" rel="noopener noreferrer">
        ASIC
      </a>
      . Once updated, let us know by phone&nbsp;
      <a href="tel:1300783674">1300 783 674</a>&nbsp;or email&nbsp;
      <a href="mailto: payments_support@myob.com">payments_support@myob.com</a>
      &nbsp;so we can refresh the names shown here.
    </div>
  )

  return (
    <Button id="btn-ubo-modal-additional-info" type="link" icon={<Icons.Help />} iconRight={true}>
      <Popover
        id={'additional-info-popover'}
        body={popoverBody}
        preferPlace="below"
        closeOnOuterAction={true}
        isOpen={false}
        appendTarget={'#ubo-list-modal'}
      >
        'Why are these names listed?'
      </Popover>
    </Button>
  )
}

const CompanyUBOModal = () => {
  const { state, dispatch } = useContext(context)
  const ubos = filterOwnerByKind(UBO.value)(state.uboModal.owners)
  const directors = filterOwnerByKind(Director.value)(state.uboModal.owners)
  const isSoleTrader = state.uboModal.isSoleTrader

  useEffect(() => {
    if (state.uboModal.spinner.display) {
      const interval = setInterval(() => {
        dispatch(refreshCompanyAndShowUBOModal)
      }, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  })

  const contentDescription = (
    <p>
      Next, identity checks are required for owners of the business and one
      director (if applicable). Please select a person to submit their details.
    </p>
  )

  const confirmButtonDescription = isSoleTrader ? "Next" : "Select"

  return (
      <Modal
          title="Identity Verification"
          underlayProps={{ id: 'ubo-list-modal' }}
          modalId="ubo-modal"
          onCancel={() => {
              dispatch(showPageSpinner)
              dispatch(refreshPage)
          }}
      >
          <Modal.Body>
              {state.uboModal.spinner.display ? (
                  <div className="loading-section">
                      <Spinner />
                      <p>We're retrieving information for this next step.</p>
                      <p>This may take a little while.</p>
                  </div>
              ) : isJust(state.uboModal.errorCode) ? (
                  <Error httpError={state.uboModal.errorCode.value0} />
              ) : (
                  <div>
                      {contentDescription}
                  <RadioButtonGroup
                      label={`${isSoleTrader ? 'Business owner' : 'Beneficial owners'} - ${
                R.filter(isSubmitted)(ubos).length
              } of ${ubos.length} submitted`}
                      name="choice"
                      onChange={({ value }) => dispatch(selectOwner(value))}
                      renderRadios={OwnerRadios(ubos, state.kycModal.owner)(false)}
                      labelAccessory={
                          <Tooltip triggerContent={<Icons.Info />} placement="right">
                              { isSoleTrader ? 'Business owner must have ID submitted.' :
                                'Every beneficial owner (individuals who own 25% or more) must have ID submitted.' }
                          </Tooltip>
                      }
                  />
                  {!R.isEmpty(directors) && (
                      <RadioButtonGroup
                          label={`Directors - ${
                  R.filter(isSubmitted)(directors).length
                } of 1 submitted`}
                          name="choice"
                          onChange={({ value }) => dispatch(selectOwner(value))}
                          renderRadios={OwnerRadios(directors, state.kycModal.owner)(
                              directorNotNeeded(directors)(ubos)
                          )}
                          labelAccessory={
                              <Tooltip triggerContent={<Icons.Info />} placement="right">
                                  One director must have ID submitted.
                              </Tooltip>
                          }
                      />
                  )}
                  <ExtraInfoPopover />
              </div>
              )}
          </Modal.Body>
          <Modal.Footer>
              <button
                  type="button"
                  className="btn btn-default"
                  id="btn-company-ubo-modal-cancel"
                  onClick={() => {
                      dispatch(showPageSpinner)
                      dispatch(refreshPage)
                  }}
              >
                  Save and close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!isJust(state.kycModal.owner)}
                  id="btn-company-ubo-modal-confirm"
                  onClick={() => dispatch(showOwnerKycModal)}
              >
                  {confirmButtonDescription}
              </button>
          </Modal.Footer>
      </Modal>
  )
}

export default CompanyUBOModal
