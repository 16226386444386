import * as React from 'react'
import * as r from 'ramda'
import { Alert, Label, Tabs } from '@myob/myob-widgets'
import './SecondaryTab.sass'

export const NavTabs = {
  paymentDetails: 'paymentDetails',
  batchPayment: 'batchPayment'
}

const navToTab = select =>
  r.pipe(
    r.find(x => x.id === select),
    r.propOr('#', 'url')
  )

export const pageHead = companyName => {
  return (
    <div className="secondary-tab">
      <Label size="large">Hi, {companyName}</Label>
      <h3 className="h3-subtitle">Welcome to Payments</h3>
    </div>
  )
}
const tabs = [
  {
    id: NavTabs.batchPayment,
    label: 'Batch payments',
    url: '/'
  },
  {
    id: NavTabs.paymentDetails,
    label: 'Payment methods',
    url: '/manage'
  }
]
const SecondaryTab = ({ tab }) => (
  <div>
    <Alert type="info">
      <p>
        MYOB Direct Payments will no longer be available starting Thursday, 10 October. For assistance please contact <a href="mailto:payments_support@myob.com">payments_support@myob.com</a>
      </p>
    </Alert>
    <Tabs
      items={tabs}
      selected={tab}
      onSelected={select => {
        return select === tab
          ? (window.location.href = '#')
          : (window.location.href = navToTab(select)(tabs))
      }}
    />
  </div>
)

export default SecondaryTab
