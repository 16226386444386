// Generated by purs version 0.13.8
"use strict";
var Config = require("../Config/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Aff_Monads_Class = require("../Effect.Aff.Monads.Class/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Fetch = require("../Fetch/index.js");
var Global = require("../Global/index.js");
var Model_Company = require("../Model.Company/index.js");
var Record = require("../Record/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Token = function (x) {
    return x;
};
var uploadError = Effect_Exception.error("Failed to upload, please try again.");
var supportedFileTypes = [ "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "image/png", "image/jpeg", "image/gif", "image/bmp" ];
var statuses = {
    queued: "queued",
    finished: "finished",
    failed: "failed"
};
var sendUploadReq = function (file) {
    return function (v) {
        var readBody = function (body) {
            return Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "file";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()()))(body);
        };
        if (file.file.buffer instanceof Data_Maybe.Nothing) {
            return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(uploadError);
        };
        if (file.file.buffer instanceof Data_Maybe.Just) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.recover({
                status: 500,
                body: ""
            })(Fetch.postForeign()(Config.config.storageHost + "/upload")(file.file.buffer.value0)({
                Authorization: "Bearer " + v,
                "Content-Type": "application/octet-stream"
            })))(function (resp) {
                if (resp.status === 200) {
                    var v1 = readBody(resp.body);
                    if (v1 instanceof Data_Either.Right) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                            name: file.file.name,
                            location: v1.value0.file,
                            size: file.file.size,
                            fileType: new Data_Maybe.Just(file.file.type),
                            md5: file.file.md5
                        });
                    };
                    return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(uploadError);
                };
                return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(uploadError);
            });
        };
        throw new Error("Failed pattern match at Component.UserFileUploader (line 181, column 36 - line 189, column 47): " + [ file.file.buffer.constructor.name ]);
    };
};
var reportUpload = function (companyId) {
    return function (userId) {
        return function (v) {
            var reqBody = Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "fileLocation";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "fileType";
            }))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignString))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "filename";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "md5";
            }))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignString))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "size";
            }))(Simple_JSON.writeForeignInt)(Simple_JSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))({
                filename: v.name,
                fileLocation: v.location,
                size: v.size,
                fileType: v.fileType,
                md5: v.md5
            });
            return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.recover({
                status: 500,
                body: ""
            })(Fetch.post()("/company/" + (companyId + ("/user/" + (userId + "/document"))))(reqBody)({})))(function (resp) {
                if (resp.status === 201) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                };
                return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(uploadError);
            });
        };
    };
};
var listFiles = (function () {
    var toState = function (index) {
        return function (v) {
            return {
                index: index,
                file: {
                    name: v.name,
                    size: v.size,
                    type: Data_Maybe.fromMaybe("unknown")(v.fileType),
                    md5: v.md5,
                    buffer: Data_Maybe.Nothing.value
                },
                state: statuses.finished,
                error: Data_Maybe.Nothing.value,
                location: new Data_Maybe.Just(v.location)
            };
        };
    };
    var readBody = function (body) {
        return Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "fileType";
        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "location";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "md5";
        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "name";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "size";
        }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(body);
    };
    return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(SessionStorage.getUserId))(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("userId not exists"))))(function (userId) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Model_Company.getCompanyWithErrorHandle)(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("companyId not exists"))))(function (company) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.get()("/company/" + (company.id + ("/user/" + (userId + "/document"))))({}))(function (resp) {
                if (resp.status === 200) {
                    var v = readBody(resp.body);
                    if (v instanceof Data_Either.Left) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)([  ]);
                    };
                    if (v instanceof Data_Either.Right) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Array.mapWithIndex(toState)(v.value0));
                    };
                    throw new Error("Failed pattern match at Component.UserFileUploader (line 88, column 30 - line 90, column 60): " + [ v.constructor.name ]);
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)([  ]);
            });
        });
    });
})();
var index_ = Data_Symbol.SProxy.value;
var updateFiles = function (newState) {
    return function (currentState) {
        return Data_Array.sortWith(Data_Ord.ordInt)(Record.get(new Data_Symbol.IsSymbol(function () {
            return "index";
        }))()(index_))(Data_Array.unionBy(function (newFiles) {
            return function (originalFiles) {
                return newFiles.index === originalFiles.index;
            };
        })(newState)(currentState));
    };
};
var genFileUploadToken = function (companyId) {
    return function (userId) {
        return function (file) {
            var reqBody = Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "filename";
            }))(Simple_JSON.writeForeignString)(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
                return "md5";
            }))(Simple_JSON.writeForeignMaybe(Simple_JSON.writeForeignString))(Simple_JSON.nilWriteForeignFields)()()())()()()))({
                filename: file.file.name,
                md5: file.file.md5
            });
            var readBody = function (body) {
                return Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                    return "token";
                }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()()))(body);
            };
            return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.recover({
                status: 500,
                body: ""
            })(Fetch.post()("/company/" + (companyId + ("/user/" + (userId + "/file-token"))))(reqBody)({})))(function (resp) {
                if (resp.status === 201) {
                    var v = readBody(resp.body);
                    if (v instanceof Data_Either.Right) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(v.value0.token);
                    };
                    return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(uploadError);
                };
                return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(uploadError);
            });
        };
    };
};
var uploadSingleFile = function (companyId) {
    return function (userId) {
        return function (file) {
            var processUpload = Control_Bind.bind(Effect_Aff.bindAff)(genFileUploadToken(companyId)(userId)(file))(function (token) {
                return Control_Bind.bind(Effect_Aff.bindAff)(sendUploadReq(file)(token))(function (uploaded) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(reportUpload(companyId)(userId)(uploaded))(function () {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                            state: statuses.finished,
                            location: new Data_Maybe.Just(uploaded.location),
                            error: file.error,
                            file: file.file,
                            index: file.index
                        });
                    });
                });
            });
            return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(processUpload))(function (result) {
                if (result instanceof Data_Either.Left) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                        state: statuses.failed,
                        error: new Data_Maybe.Just(Effect_Exception.message(result.value0)),
                        file: file.file,
                        index: file.index,
                        location: file.location
                    });
                };
                if (result instanceof Data_Either.Right) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(result.value0);
                };
                throw new Error("Failed pattern match at Component.UserFileUploader (line 127, column 5 - line 129, column 42): " + [ result.constructor.name ]);
            });
        };
    };
};
var uploadFile = function (file) {
    return Fetch.recover({
        state: statuses.failed,
        error: new Data_Maybe.Just(Effect_Exception.message(uploadError)),
        file: file.file,
        index: file.index,
        location: file.location
    })(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(SessionStorage.getUserId))(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("userId not exists"))))(function (userId) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Model_Company.getCompanyWithErrorHandle)(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("companyId not exists"))))(function (company) {
            return uploadSingleFile(company.id)(userId)(file);
        });
    }));
};
var fileSizeLimit = (10 * 1024 | 0) * 1024 | 0;
var _setAsError = function (index) {
    return function (state) {
        if (state.index === index) {
            return {
                index: state.index,
                file: state.file,
                state: statuses.failed,
                error: new Data_Maybe.Just("something is wrong"),
                location: state.location
            };
        };
        if (Data_Boolean.otherwise) {
            return state;
        };
        throw new Error("Failed pattern match at Component.UserFileUploader (line 161, column 1 - line 161, column 36): " + [ index.constructor.name, state.constructor.name ]);
    };
};
var deleteFile = function (v) {
    if (v.location instanceof Data_Maybe.Just) {
        return Fetch.recover(Data_Functor.map(Data_Functor.functorArray)(_setAsError(v.index)))(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(SessionStorage.getUserId))(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("userId not exists"))))(function (userId) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Model_Company.getCompanyWithErrorHandle)(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("companyId not exists"))))(function (company) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error(v.location.value0 + " isn't a URI component"))(Global["encodeURIComponent"](v.location.value0)))(function (filePath) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(Fetch["delete"]()("/company/" + (company.id + ("/user/" + (userId + ("/document/" + filePath)))))({}))(function (resp) {
                        if (resp.status === 204) {
                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Array.filter((function () {
                                var $80 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
                                var $81 = Data_Eq.eq(Data_Eq.eqInt)(v.index);
                                var $82 = Record.get(new Data_Symbol.IsSymbol(function () {
                                    return "index";
                                }))()(index_);
                                return function ($83) {
                                    return $80($81($82($83)));
                                };
                            })()));
                        };
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Functor.map(Data_Functor.functorArray)(_setAsError(v.index)));
                    });
                });
            });
        }));
    };
    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Array.filter((function () {
        var $84 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
        var $85 = Data_Eq.eq(Data_Eq.eqInt)(v.index);
        var $86 = Record.get(new Data_Symbol.IsSymbol(function () {
            return "index";
        }))()(index_);
        return function ($87) {
            return $84($85($86($87)));
        };
    })()));
};
var _offset = function (state) {
    return Data_Maybe.maybe(0)((function () {
        var $88 = Data_Semiring.add(Data_Semiring.semiringInt)(1);
        var $89 = Record.get(new Data_Symbol.IsSymbol(function () {
            return "index";
        }))()(index_);
        return function ($90) {
            return $88($89($90));
        };
    })())(Data_Array.last(state));
};
var enqueueFiles = function (state) {
    return function (files) {
        var offset = _offset(state);
        var validateFile = function (index) {
            return function (file) {
                if (file.size > fileSizeLimit) {
                    return {
                        index: index + offset | 0,
                        file: file,
                        state: statuses.failed,
                        error: new Data_Maybe.Just("File size exceeds limit 10M."),
                        location: Data_Maybe.Nothing.value
                    };
                };
                if (Data_Foldable.notElem(Data_Foldable.foldableArray)(Data_Eq.eqString)(file.type)(supportedFileTypes)) {
                    return {
                        index: index + offset | 0,
                        file: file,
                        state: statuses.failed,
                        error: new Data_Maybe.Just("File type is not supported."),
                        location: Data_Maybe.Nothing.value
                    };
                };
                if (Data_Boolean.otherwise) {
                    return {
                        index: index + offset | 0,
                        file: file,
                        state: statuses.queued,
                        error: Data_Maybe.Nothing.value,
                        location: Data_Maybe.Nothing.value
                    };
                };
                throw new Error("Failed pattern match at Component.UserFileUploader (line 112, column 5 - line 112, column 41): " + [ index.constructor.name, file.constructor.name ]);
            };
        };
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(state)(Data_Array.mapWithIndex(validateFile)(files));
    };
};
var _failSingleFile = function (v) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Functor.map(Data_Functor.functorArray)(_setAsError(v.index)));
};
var downloadFile = function (v) {
    return function (v1) {
        if (v.location instanceof Data_Maybe.Just) {
            return Fetch.recover(Data_Functor.map(Data_Functor.functorArray)(_setAsError(v.index)))(Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(SessionStorage.getUserId))(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("userId not exists"))))(function (userId) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Model_Company.getCompanyWithErrorHandle)(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error("companyId not exists"))))(function (company) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff_Monads_Class.liftMaybe(Effect_Aff_Monads_Class.monadMaybeAff)(Effect_Exception.error(v.location.value0 + " isn't a URI component"))(Global["encodeURIComponent"](v.location.value0)))(function (filePath) {
                        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1("/api/company/" + (company.id + ("/user/" + (userId + ("/document/" + filePath)))))))(function () {
                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(Control_Category.identity(Control_Category.categoryFn));
                        });
                    });
                });
            }));
        };
        return _failSingleFile(v);
    };
};
module.exports = {
    Token: Token,
    statuses: statuses,
    fileSizeLimit: fileSizeLimit,
    supportedFileTypes: supportedFileTypes,
    uploadError: uploadError,
    index_: index_,
    updateFiles: updateFiles,
    listFiles: listFiles,
    "_offset": _offset,
    enqueueFiles: enqueueFiles,
    uploadFile: uploadFile,
    uploadSingleFile: uploadSingleFile,
    deleteFile: deleteFile,
    downloadFile: downloadFile,
    "_failSingleFile": _failSingleFile,
    "_setAsError": _setAsError,
    genFileUploadToken: genFileUploadToken,
    sendUploadReq: sendUploadReq,
    reportUpload: reportUpload
};
