// Generated by purs version 0.13.8
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var EnumGenericCodecs = require("../EnumGenericCodecs/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Unregistered = (function () {
    function Unregistered() {

    };
    Unregistered.value = new Unregistered();
    return Unregistered;
})();
var CreditCheckPassed = (function () {
    function CreditCheckPassed() {

    };
    CreditCheckPassed.value = new CreditCheckPassed();
    return CreditCheckPassed;
})();
var OwnerInfoGenerated = (function () {
    function OwnerInfoGenerated() {

    };
    OwnerInfoGenerated.value = new OwnerInfoGenerated();
    return OwnerInfoGenerated;
})();
var OwnerInfoGenerateFailed = (function () {
    function OwnerInfoGenerateFailed() {

    };
    OwnerInfoGenerateFailed.value = new OwnerInfoGenerateFailed();
    return OwnerInfoGenerateFailed;
})();
var ApplicantVerified = (function () {
    function ApplicantVerified() {

    };
    ApplicantVerified.value = new ApplicantVerified();
    return ApplicantVerified;
})();
var FailedApplicantVerification = (function () {
    function FailedApplicantVerification() {

    };
    FailedApplicantVerification.value = new FailedApplicantVerification();
    return FailedApplicantVerification;
})();
var KYCFailed = (function () {
    function KYCFailed() {

    };
    KYCFailed.value = new KYCFailed();
    return KYCFailed;
})();
var OwnershipVerified = (function () {
    function OwnershipVerified() {

    };
    OwnershipVerified.value = new OwnershipVerified();
    return OwnershipVerified;
})();
var BusinessDetailsAdded = (function () {
    function BusinessDetailsAdded() {

    };
    BusinessDetailsAdded.value = new BusinessDetailsAdded();
    return BusinessDetailsAdded;
})();
var PaymentDetailsAdded = (function () {
    function PaymentDetailsAdded() {

    };
    PaymentDetailsAdded.value = new PaymentDetailsAdded();
    return PaymentDetailsAdded;
})();
var PendingAssessment = (function () {
    function PendingAssessment() {

    };
    PendingAssessment.value = new PendingAssessment();
    return PendingAssessment;
})();
var Registered = (function () {
    function Registered() {

    };
    Registered.value = new Registered();
    return Registered;
})();
var CreditCheckFailed = (function () {
    function CreditCheckFailed() {

    };
    CreditCheckFailed.value = new CreditCheckFailed();
    return CreditCheckFailed;
})();
var Declined = (function () {
    function Declined() {

    };
    Declined.value = new Declined();
    return Declined;
})();
var Delete = (function () {
    function Delete() {

    };
    Delete.value = new Delete();
    return Delete;
})();
var renderCompanyStatus = function (v) {
    if (v instanceof Unregistered) {
        return "Unregistered";
    };
    if (v instanceof CreditCheckFailed) {
        return "Declined";
    };
    if (v instanceof KYCFailed) {
        return "Pending";
    };
    if (v instanceof CreditCheckPassed) {
        return "Pending";
    };
    if (v instanceof OwnerInfoGenerated) {
        return "Pending";
    };
    if (v instanceof OwnerInfoGenerateFailed) {
        return "Pending";
    };
    if (v instanceof ApplicantVerified) {
        return "Pending";
    };
    if (v instanceof FailedApplicantVerification) {
        return "Pending";
    };
    if (v instanceof OwnershipVerified) {
        return "Pending";
    };
    if (v instanceof BusinessDetailsAdded) {
        return "Pending";
    };
    if (v instanceof PaymentDetailsAdded) {
        return "Pending";
    };
    if (v instanceof PendingAssessment) {
        return "Pending";
    };
    if (v instanceof Registered) {
        return "Registered";
    };
    if (v instanceof Delete) {
        return "Delete";
    };
    if (v instanceof Declined) {
        return "Declined";
    };
    throw new Error("Failed pattern match at Model.CompanyStatus (line 38, column 1 - line 38, column 47): " + [ v.constructor.name ]);
};
var genCompanyStatus = new Data_Generic_Rep.Generic(function (x) {
    if (x instanceof Unregistered) {
        return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
    };
    if (x instanceof CreditCheckPassed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
    };
    if (x instanceof OwnerInfoGenerated) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
    };
    if (x instanceof OwnerInfoGenerateFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
    };
    if (x instanceof ApplicantVerified) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
    };
    if (x instanceof FailedApplicantVerification) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
    };
    if (x instanceof KYCFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
    };
    if (x instanceof OwnershipVerified) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))));
    };
    if (x instanceof BusinessDetailsAdded) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))));
    };
    if (x instanceof PaymentDetailsAdded) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))))));
    };
    if (x instanceof PendingAssessment) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))))));
    };
    if (x instanceof Registered) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))))))));
    };
    if (x instanceof CreditCheckFailed) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))))))));
    };
    if (x instanceof Declined) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))))))))));
    };
    if (x instanceof Delete) {
        return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))))))))))));
    };
    throw new Error("Failed pattern match at Model.CompanyStatus (line 27, column 1 - line 27, column 60): " + [ x.constructor.name ]);
}, function (x) {
    if (x instanceof Data_Generic_Rep.Inl) {
        return Unregistered.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
        return CreditCheckPassed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
        return OwnerInfoGenerated.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
        return OwnerInfoGenerateFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
        return ApplicantVerified.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
        return FailedApplicantVerification.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
        return KYCFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
        return OwnershipVerified.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
        return BusinessDetailsAdded.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
        return PaymentDetailsAdded.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))) {
        return PendingAssessment.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))) {
        return Registered.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))) {
        return CreditCheckFailed.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))))) {
        return Declined.value;
    };
    if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))))))))) {
        return Delete.value;
    };
    throw new Error("Failed pattern match at Model.CompanyStatus (line 27, column 1 - line 27, column 60): " + [ x.constructor.name ]);
});
var readForeignCompanyStatus = new Simple_JSON.ReadForeign(EnumGenericCodecs.enumReadForeign(genCompanyStatus)(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Unregistered";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "CreditCheckPassed";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "OwnerInfoGenerated";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "OwnerInfoGenerateFailed";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "ApplicantVerified";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "FailedApplicantVerification";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "KYCFailed";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "OwnershipVerified";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "BusinessDetailsAdded";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "PaymentDetailsAdded";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "PendingAssessment";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Registered";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "CreditCheckFailed";
})))(EnumGenericCodecs.sumEnumReadForeign(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Declined";
})))(EnumGenericCodecs.constructorEnumReadForeign(new Data_Symbol.IsSymbol(function () {
    return "Delete";
}))))))))))))))))));
var showCompanyStatus = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genCompanyStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Unregistered";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CreditCheckPassed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "OwnerInfoGenerated";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "OwnerInfoGenerateFailed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "ApplicantVerified";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "FailedApplicantVerification";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "KYCFailed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "OwnershipVerified";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "BusinessDetailsAdded";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "PaymentDetailsAdded";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "PendingAssessment";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Registered";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "CreditCheckFailed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Declined";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
    return "Delete";
}))))))))))))))))));
var writeForeignCompanyStatus = new Simple_JSON.WriteForeign((function () {
    var $132 = Simple_JSON.write(Simple_JSON.writeForeignString);
    var $133 = Data_Show.show(showCompanyStatus);
    return function ($134) {
        return $132($133($134));
    };
})());
var eqCompanyStatus = new Data_Eq.Eq(function (x) {
    return function (y) {
        if (x instanceof Unregistered && y instanceof Unregistered) {
            return true;
        };
        if (x instanceof CreditCheckPassed && y instanceof CreditCheckPassed) {
            return true;
        };
        if (x instanceof OwnerInfoGenerated && y instanceof OwnerInfoGenerated) {
            return true;
        };
        if (x instanceof OwnerInfoGenerateFailed && y instanceof OwnerInfoGenerateFailed) {
            return true;
        };
        if (x instanceof ApplicantVerified && y instanceof ApplicantVerified) {
            return true;
        };
        if (x instanceof FailedApplicantVerification && y instanceof FailedApplicantVerification) {
            return true;
        };
        if (x instanceof KYCFailed && y instanceof KYCFailed) {
            return true;
        };
        if (x instanceof OwnershipVerified && y instanceof OwnershipVerified) {
            return true;
        };
        if (x instanceof BusinessDetailsAdded && y instanceof BusinessDetailsAdded) {
            return true;
        };
        if (x instanceof PaymentDetailsAdded && y instanceof PaymentDetailsAdded) {
            return true;
        };
        if (x instanceof PendingAssessment && y instanceof PendingAssessment) {
            return true;
        };
        if (x instanceof Registered && y instanceof Registered) {
            return true;
        };
        if (x instanceof CreditCheckFailed && y instanceof CreditCheckFailed) {
            return true;
        };
        if (x instanceof Declined && y instanceof Declined) {
            return true;
        };
        if (x instanceof Delete && y instanceof Delete) {
            return true;
        };
        return false;
    };
});
var ordCompanyStatus = new Data_Ord.Ord(function () {
    return eqCompanyStatus;
}, function (x) {
    return function (y) {
        if (x instanceof Unregistered && y instanceof Unregistered) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Unregistered) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Unregistered) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof CreditCheckPassed && y instanceof CreditCheckPassed) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof CreditCheckPassed) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof CreditCheckPassed) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof OwnerInfoGenerated && y instanceof OwnerInfoGenerated) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof OwnerInfoGenerated) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof OwnerInfoGenerated) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof OwnerInfoGenerateFailed && y instanceof OwnerInfoGenerateFailed) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof OwnerInfoGenerateFailed) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof OwnerInfoGenerateFailed) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof ApplicantVerified && y instanceof ApplicantVerified) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof ApplicantVerified) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof ApplicantVerified) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof FailedApplicantVerification && y instanceof FailedApplicantVerification) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof FailedApplicantVerification) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof FailedApplicantVerification) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof KYCFailed && y instanceof KYCFailed) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof KYCFailed) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof KYCFailed) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof OwnershipVerified && y instanceof OwnershipVerified) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof OwnershipVerified) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof OwnershipVerified) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof BusinessDetailsAdded && y instanceof BusinessDetailsAdded) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof BusinessDetailsAdded) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof BusinessDetailsAdded) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof PaymentDetailsAdded && y instanceof PaymentDetailsAdded) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof PaymentDetailsAdded) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof PaymentDetailsAdded) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof PendingAssessment && y instanceof PendingAssessment) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof PendingAssessment) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof PendingAssessment) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Registered && y instanceof Registered) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Registered) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Registered) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof CreditCheckFailed && y instanceof CreditCheckFailed) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof CreditCheckFailed) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof CreditCheckFailed) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Declined && y instanceof Declined) {
            return Data_Ordering.EQ.value;
        };
        if (x instanceof Declined) {
            return Data_Ordering.LT.value;
        };
        if (y instanceof Declined) {
            return Data_Ordering.GT.value;
        };
        if (x instanceof Delete && y instanceof Delete) {
            return Data_Ordering.EQ.value;
        };
        throw new Error("Failed pattern match at Model.CompanyStatus (line 26, column 1 - line 26, column 54): " + [ x.constructor.name, y.constructor.name ]);
    };
});
module.exports = {
    Unregistered: Unregistered,
    CreditCheckPassed: CreditCheckPassed,
    OwnerInfoGenerated: OwnerInfoGenerated,
    OwnerInfoGenerateFailed: OwnerInfoGenerateFailed,
    ApplicantVerified: ApplicantVerified,
    FailedApplicantVerification: FailedApplicantVerification,
    KYCFailed: KYCFailed,
    OwnershipVerified: OwnershipVerified,
    BusinessDetailsAdded: BusinessDetailsAdded,
    PaymentDetailsAdded: PaymentDetailsAdded,
    PendingAssessment: PendingAssessment,
    Registered: Registered,
    CreditCheckFailed: CreditCheckFailed,
    Declined: Declined,
    Delete: Delete,
    renderCompanyStatus: renderCompanyStatus,
    eqCompanyStatus: eqCompanyStatus,
    ordCompanyStatus: ordCompanyStatus,
    genCompanyStatus: genCompanyStatus,
    writeForeignCompanyStatus: writeForeignCompanyStatus,
    showCompanyStatus: showCompanyStatus,
    readForeignCompanyStatus: readForeignCompanyStatus
};
