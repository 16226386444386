// Generated by purs version 0.13.8
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Model_PaymentError = require("../Model.PaymentError/index.js");
var Model_Status = require("../Model.Status/index.js");
var Model_TransactionType = require("../Model.TransactionType/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var unsafeConvertLine = (function (dictPartial) {
    var $4 = Data_Either.fromRight();
    var $5 = Simple_JSON.read(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "amount";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bankAccountName";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bankAccountNumber";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bsbNumber";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "dateOccurred";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lodgementReference";
    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "payeeId";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "payeeName";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionType";
    }))(Model_TransactionType.readForeignTransactionType)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()()));
    return function ($6) {
        return $4($5($6));
    };
})();
var unsafeConvertBatch = (function (dictPartial) {
    var $7 = Data_Either.fromRight();
    var $8 = Simple_JSON.read(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "batchDetail";
    }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "authoriserDetails";
    }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "authorisedDate";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "email";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "authoriserRequired";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bankStatement";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "error";
    }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "code";
    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "message";
    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "source";
    }))(Simple_JSON.readMaybe(Model_PaymentError.readForeignPaymentErrorSource))(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "estimatedRewardsPoints";
    }))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "fees";
    }))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "journalMemo";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lodgementReference";
    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "paymentDetails";
    }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bankAccount";
    }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "accountName";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "accountNumber";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bsbNumber";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "card";
    }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "expiry";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "number";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "scheme";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "type";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(Simple_JSON.readFieldsNil)()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "paymentId";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "processDate";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "remitterName";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "requestedBy";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "status";
    }))(Model_Status.readForeignBatchStatus)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "submittedDate";
    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "totalAmount";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lines";
    }))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "amount";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bankAccountName";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bankAccountNumber";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "bsbNumber";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "dateOccurred";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "id";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "lodgementReference";
    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "payeeId";
    }))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "payeeName";
    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
        return "transactionType";
    }))(Model_TransactionType.readForeignTransactionType)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())))(Simple_JSON.readFieldsNil)()())()()));
    return function ($9) {
        return $7($8($9));
    };
})();
var createLineViewModel = function (l) {
    return {
        id: l.id,
        payeeName: l.payeeName,
        amount: l.amount,
        transactionType: Data_Show.show(Model_TransactionType.showTransactionType)(l.transactionType),
        bsbNumber: l.bsbNumber,
        bankAccountNumber: l.bankAccountNumber,
        lodgementReference: Data_Maybe.fromMaybe("")(l.lodgementReference)
    };
};
var createBatchDetailViewModel = function (b) {
    return {
        id: b.id,
        journalMemo: b.journalMemo,
        requestedBy: b.requestedBy,
        submittedDate: Data_Maybe.fromMaybe("")(b.submittedDate),
        submittedTime: Data_Maybe.fromMaybe("")(b.submittedDate),
        totalAmount: b.totalAmount,
        status: b.status,
        authorisedBy: Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.email;
        })(b.authoriserDetails),
        authorisedDate: Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.authorisedDate;
        })(Data_Array.head(b.authoriserDetails))),
        estimatedRewardsPoints: b.estimatedRewardsPoints
    };
};
var convertBatchDetail = Simple_JSON.read(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "authoriserDetails";
}))(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "authorisedDate";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "email";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "authoriserRequired";
}))(Simple_JSON.readInt)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "bankStatement";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "error";
}))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "code";
}))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "message";
}))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "source";
}))(Simple_JSON.readMaybe(Model_PaymentError.readForeignPaymentErrorSource))(Simple_JSON.readFieldsNil)()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "estimatedRewardsPoints";
}))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "fees";
}))(Simple_JSON.readMaybe(Simple_JSON.readInt))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "journalMemo";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "lodgementReference";
}))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "paymentDetails";
}))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "bankAccount";
}))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "accountName";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "accountNumber";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "bsbNumber";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "card";
}))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "expiry";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "id";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "number";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "scheme";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "type";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()())()())()())()())()())))(Simple_JSON.readFieldsNil)()())()())))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "paymentId";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "processDate";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "remitterName";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "requestedBy";
}))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "status";
}))(Model_Status.readForeignBatchStatus)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "submittedDate";
}))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
    return "totalAmount";
}))(Simple_JSON.readInt)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()()));
module.exports = {
    createBatchDetailViewModel: createBatchDetailViewModel,
    createLineViewModel: createLineViewModel,
    convertBatchDetail: convertBatchDetail,
    unsafeConvertLine: unsafeConvertLine,
    unsafeConvertBatch: unsafeConvertBatch
};
