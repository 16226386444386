// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Config = require("../Config/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var login = function (state) {
    return $foreign.redirectTo(Config.config.apiHost + ("/login" + Data_Maybe.maybe("")(function (v) {
        return "?state=" + v;
    })(state)));
};
var _stepUp = function (dictLacks) {
    return function (url) {
        return function (redirect) {
            return function (header) {
                var redirectToLogin = function (v) {
                    return $foreign.redirectTo(Config.config.apiHost + ("/login/stepup?state=" + v.state));
                };
                var parseState = function (v) {
                    if (v.status === 200) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Bifunctor.lmap(Data_Either.bifunctorEither)(Data_Function["const"](500))(Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                            return "state";
                        }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()()))(v.body)));
                    };
                    if (v.status === 401) {
                        return Data_Functor.voidRight(Effect_Aff.functorAff)(new Data_Either.Left(401))(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                            var $15 = SessionStorage.saveLocation(redirect)();
                            return login(Data_Maybe.Just.create($15))();
                        }));
                    };
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left(v.status));
                };
                return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.get()(url)(header)))(function (resp) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left(500))))(parseState)(resp))(function (result) {
                        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))(redirectToLogin)(result)))(function () {
                            return Control_Applicative.pure(Effect_Aff.applicativeAff)(result);
                        });
                    });
                });
            };
        };
    };
};
var stepUp = function (dictLacks) {
    return function (redirect) {
        return function (header) {
            return _stepUp()("/state/signature?redirect=" + redirect)(redirect)(header);
        };
    };
};
var stepUpFromArl = function (dictLacks) {
    return function (redirect) {
        return function (header) {
            return _stepUp()("/arl/state/signature?redirect=" + redirect)(redirect)(header);
        };
    };
};
module.exports = {
    login: login,
    stepUp: stepUp,
    stepUpFromArl: stepUpFromArl,
    redirectTo: $foreign.redirectTo,
    replaceHistory: $foreign.replaceHistory
};
