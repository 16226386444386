import React from 'react'
import './DashboardHeader.sass'
import BusinessDetailsIcon from '../img/illustration_business_list.svg'

const DashboardHeader = ({ title, subtitle, description }) => {
  return (
    <div className="dashboard-header">
      <div className="dashboard-header__content">
        <h1 className="header__title">
          {title}
          <br />
          {subtitle}
        </h1>
        <p className="header__text">{description}</p>
      </div>
      <img
        className="dashboard-header__img"
        src={BusinessDetailsIcon}
        alt="building"
      />
    </div>
  )
}

export default DashboardHeader
