import React, { useContext } from 'react'
import withPsState from '../withPsState'
import {Icons, Navigation, Badge} from '@myob/myob-widgets'
import { Brand, helpLink } from './NavBar'
import LeanEngage from './LeanEngage'
import { getUserIdOrEmpty } from 'purs/SessionStorage'
import {readCompanyWithDefault, deconstructRewardsDetails, getRewardsPoints, readCompany} from 'purs/Model.Company'
import { isJust, Nothing, maybe } from 'purs/Data.Maybe'
import * as r from 'ramda'
import {hush} from 'purs/Data.Either'
import './NavBarForBusiness.sass'
import { config } from '../psConfig'
import SelfEnrollmentModal from "./SelfEnrollmentModal";
import RewardsThanksModal from "./RewardsThanksModal";
import { Loading, Error, SelfEnrollmentPopup, RewardsThanksPopup, initState, selfEnrollment, setSelfEnrollModalLoading, showSelfEnrollmentModal, closeModal } from "purs/NavBarForBusiness"
import {Administrator} from "purs/Model.User";

const SwitchBusinessLink = () => (<Navigation.Link
                              key="switchBusiness"
                              label="Switch businesses"
                              url="/companies"
                            />)

const LogoutMenu = () => (<Navigation.MenuLink
                      key="logout"
                      url="/logout"
                      label="Log out"
                      icon={<Icons.SignOut />}
                    />)

export const minimumSecondary = (businessName, productType) => [
  <SwitchBusinessLink />,
  <Navigation.Menu
    key="companyName"
    label={businessName}
    url="#"
    icon={<Icons.Caret />}
    onSelect={() => 'selected'}
    items={[<LogoutMenu />]}
  />,
  helpLink(productType)
]

const secondary = (businessName, productType) => [
  <SwitchBusinessLink />,
  <Navigation.Menu
    key="companyName"
    label={businessName}
    url="#"
    icon={<Icons.Caret />}
    onSelect={() => 'selected'}
    items={[
      <Navigation.MenuLink
        key="manage-business-details"
        url="/business-details"
        label="Business details"
      />,
      <Navigation.MenuLink
        key="manage-users"
        url="/manage/users"
        label="Manage users"
      />,
      <LogoutMenu />
    ]}
  />,
  helpLink(productType)
]

const showRewardsEntry = (rewardFromState, dispatch) => {
  const rewardsPointsBadge = maybe([])(points => {
   return [
    <li class="flx-navbar__menu-item">
      <Badge color="light-grey" className="rewards-points-badge">
        {points.toLocaleString()} pts
      </Badge>
    </li>
   ]
  })(getRewardsPoints())

  const rewardsBtn = (
    <li class="rewards-sso-menu-item flx-navbar__menu-item">
      <a class="flx-navbar__menu-link"
         id="rewards-sso"
         target="_blank"
         rel="noopener noreferrer"
         href={config.rewardsSSOLink}>MYOB Rewards</a>
    </li>
  )

  const rewardsEnrollmentLink = (
    <li class="flx-navbar__menu-item">
      <span id="self-enroll-link" type="link"
              onClick={() => {dispatch(showSelfEnrollmentModal)}}
              className="flx-navbar__menu-link" >MYOB Rewards</span>
    </li>

  )
  const rewardsDetails = rewardFromState && isJust(rewardFromState) ? rewardFromState : deconstructRewardsDetails()
  return maybe([])(detail => {
    const isRewardsEnrolled = isJust(detail.enrolledAt)
    if(isRewardsEnrolled) {
      return [rewardsBtn, ...rewardsPointsBadge]
    }

    const isSkipped = !detail.isAccepted
    const company = hush(readCompany())
    const userRole = r.pathOr('', ['value0', 'userCurrentRole'])(company)
    return (isSkipped && userRole instanceof Administrator) ? [rewardsEnrollmentLink] : []
  })(rewardsDetails)
}

const NavBarForBusiness = ({ arlBusiness, rewards = Nothing.value }) => {
  const { state, dispatch } = useContext(context)
  const { name, companyId, product } = arlBusiness || readCompanyWithDefault()
  const dashboardLink = (
    <Navigation.Link
      key="dashboard"
      label="Payments"
      url="/"
      active={true}
    />
  )
  const hideModal = () => {
    window.location.reload(false)
  }

  return (
    <>
      <Navigation
        brand={Brand}
        primary={[dashboardLink, ...showRewardsEntry(rewards, dispatch)]}
        secondary={secondary(name, product)}
        disableScalingPrimaryMenu={true}
      />
      <LeanEngage
        companyId={companyId}
        companyName={name}
        userId={getUserIdOrEmpty()}
      />
      {
        maybe(<></>)(m => {
          switch (m.constructor) {
            case RewardsThanksPopup :
              return (<RewardsThanksModal onCancel={hideModal}/>)
            case SelfEnrollmentPopup :
              const isLoading = m.value0.status.constructor === Loading
              const isError = m.value0.status.constructor === Error
              return (<SelfEnrollmentModal
                isLoading={isLoading}
                showErrorAlert={isError}
                onCancel={() => dispatch(closeModal)}
                onContinue={() => {
                    dispatch(setSelfEnrollModalLoading)
                    dispatch(selfEnrollment(companyId))
                }}
              />)
          }
        })(state.modal)
      }
    </>
  )
}

const { context, Component } = withPsState(
  NavBarForBusiness,
  initState,
  () => s => s
)

export default Component
