import NavBarForBusiness from '../components/NavBarForBusiness'
import {
  FormTemplate,
  Button,
  Card,
  PageHead,
  ButtonRow
} from '@myob/myob-widgets'
import AlertBanner from '../components/AlertBanner'
import { Danger, Warning } from 'purs/AlertBanner'
import React, { useContext } from 'react'
import withState from '../withPsState'
import {
  setInitialState,
  updateUserRole,
  isUpdatingSelf,
  ResponseError,
  SuccessResponse,
  BadRequestError,
  initState
} from 'purs/Page.Manage.User.Details'
import './Manage.User.Details.sass'
import { isJust } from 'purs/Data.Maybe'
import {
  readUser,
  Submitter,
  None,
  Pending,
  NotRequired
} from 'purs/Model.User'
import { either } from 'purs/Data.Either'
import ManageUsersDetailsForm from '../components/Manage.User.Details.Form'
import Error from '../components/Error'
import { Link, Redirect } from 'react-router-dom'

const UserDetailCard = () => {
  const { state } = useContext(context)
  const hasSuccessResponse =
    isJust(state.responseType) &&
    state.responseType.value0 instanceof SuccessResponse
  const hasResponseError =
    isJust(state.responseType) &&
    state.responseType.value0 instanceof ResponseError
  if (hasSuccessResponse) {
    const directSuccess =
      state.selectedRole === Submitter.value ||
      state.selectedRole === None.value
    return (
      <Redirect
        to={{
          pathname: '/manage/users',
          state: directSuccess ? NotRequired.value : Pending.value
        }}
      />
    )
  } else if (hasResponseError) {
    return (
      <Card>
        <Error httpError={{ httpError: state.responseType.value0.value0.code }}>
          <Link className="msg-link" to="/manage/users">
            Back to users
          </Link>
        </Error>
      </Card>
    )
  } else {
    return <ManageUsersDetailsForm />
  }
}

const PageAlert = () => {
  const { state } = useContext(context)
  const hasBadRequest =
    isJust(state.responseType) &&
    state.responseType.value0 instanceof BadRequestError

  return hasBadRequest ? (
    <AlertBanner type={new Danger()}>
      {state.responseType.value0.value0.replace(/"/g, '')}
    </AlertBanner>
  ) : state.user.kycStatus instanceof Pending ? (
    <AlertBanner type={new Warning()}>
      The user has been notified of the changes to their role via email. Until
      they complete their account setup, the change to their level of access
      will remain pending.
    </AlertBanner>
  ) : (
    <></>
  )
}

const PageHeader = () => {
  const { state, dispatch } = useContext(context)
  const hasResponseError =
    isJust(state.responseType) &&
    state.responseType.value0 instanceof ResponseError
  const hasChange = state.selectedRole !== state.user.nominatedRole
  return (
    <PageHead title="Manage User">
      <ButtonRow>
        <Button id="btn-manage-user-cancel-link" type="secondary">
          <Link className="button-link" to="/manage/users">
            Cancel
          </Link>
        </Button>
        {hasChange && !hasResponseError && (
          <Button
            id="btn-manage-user-save"
            type="primary"
            disabled={isUpdatingSelf(state.user.id)()}
            onClick={() => dispatch(updateUserRole)}
          >
            Save
          </Button>
        )}
      </ButtonRow>
    </PageHead>
  )
}

const ManageUsersDetails = () => (
  <div className="manage_user_page">
    <NavBarForBusiness />
    <FormTemplate alert={<PageAlert />} pageHead={<PageHeader />}>
      <UserDetailCard />
    </FormTemplate>
  </div>
)

function initUserInfo({ history }) {
  return either(e => () => {
    console.error('CANNOT DECODE User Info', e)
    history.push('/manage/users')
  })(userInfo => () => setInitialState(userInfo))(
    readUser(history.location.state)
  )
}

export const { context, Component } = withState(
  ManageUsersDetails,
  initState,
  initUserInfo
)
export default Component
