import React from 'react'
import { Spinner } from '@myob/myob-widgets'
import config from '../config'
import { removeCookie } from 'purs/CookieJar'

const Logout = () => {
  clear()
  window.analytics && window.analytics.reset()
  window.location.href = config.apiHost + '/logout'
  return <Spinner />
}

const clear = () => {
  window.localStorage.clear()
  window.sessionStorage.clear()
  removeCookie('tsec-csrf')()
}

export default Logout
