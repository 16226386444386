// Generated by purs version 0.13.8
"use strict";
var Config = require("../Config/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Bitraversable = require("../Data.Bitraversable/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyAccess = require("../Model.CompanyAccess/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Model_CompanySubscription = require("../Model.CompanySubscription/index.js");
var Model_User = require("../Model.User/index.js");
var SessionStorage = require("../SessionStorage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var ResponseError = (function () {
    function ResponseError(value0) {
        this.value0 = value0;
    };
    ResponseError.create = function (value0) {
        return new ResponseError(value0);
    };
    return ResponseError;
})();
var SuccessResponse = (function () {
    function SuccessResponse() {

    };
    SuccessResponse.value = new SuccessResponse();
    return SuccessResponse;
})();
var ForbiddenToUpdateRole = (function () {
    function ForbiddenToUpdateRole() {

    };
    ForbiddenToUpdateRole.value = new ForbiddenToUpdateRole();
    return ForbiddenToUpdateRole;
})();
var RedirectFromKYCSuccess = (function () {
    function RedirectFromKYCSuccess() {

    };
    RedirectFromKYCSuccess.value = new RedirectFromKYCSuccess();
    return RedirectFromKYCSuccess;
})();
var RedirectFromKYCForbidden = (function () {
    function RedirectFromKYCForbidden() {

    };
    RedirectFromKYCForbidden.value = new RedirectFromKYCForbidden();
    return RedirectFromKYCForbidden;
})();
var showModal = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        kycModal: {
            display: true,
            disabled: false
        },
        kycFailedAlert: state.kycFailedAlert,
        responseType: state.responseType,
        spinner: state.spinner
    });
};
var sendUpdateToAuthoriserRequest = function (companyId) {
    return Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.put()("/company/" + (companyId + "/authoriser"))("")({}));
};
var initState = {
    spinner: {
        display: true
    },
    kycFailedAlert: {
        display: false
    },
    kycModal: {
        disabled: false,
        display: false
    },
    responseType: Data_Maybe.Nothing.value
};
var hideModal = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        kycModal: {
            display: false,
            disabled: true
        },
        kycFailedAlert: state.kycFailedAlert,
        responseType: state.responseType,
        spinner: state.spinner
    });
};
var _storeCompany = (function () {
    var verifyResp = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
        var $22 = Data_Bifunctor.lmap(Data_Either.bifunctorEither)(Data_Function["const"](500));
        var $23 = Simple_JSON.readJSON(Simple_JSON.readArray(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "abn";
        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "active";
        }))(Simple_JSON.readBoolean)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "cdfId";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "country";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "inTheCloud";
        }))(Simple_JSON.readBoolean)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "name";
        }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "onboardOnBehalf";
        }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "product";
        }))(Simple_JSON.readMaybe(Model_CompanySubscription.productTypeReadForeign))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "requireMoreDocument";
        }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "serialNumber";
        }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "status";
        }))(Model_CompanyStatus.readForeignCompanyStatus)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "userCurrentRole";
        }))(Model_User.roleReadForeign)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "userNominatedRole";
        }))(Simple_JSON.readMaybe(Model_User.roleReadForeign))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
            return "userRole";
        }))(Model_CompanyAccess.readForeignCompanyStatus)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())));
        return function ($24) {
            return $22($23($24));
        };
    })())(Fetch.matchResp(200));
    var verifyCompany = (function () {
        var $25 = Data_Functor.map(Data_Either.functorEither)(Model_Company.toCompany);
        var $26 = Data_Either.note(404);
        var $27 = Data_Array.filter((function () {
            var $29 = Data_Eq.eq(Model_CompanyStatus.eqCompanyStatus)(Model_CompanyStatus.Registered.value);
            return function ($30) {
                return $29((function (v) {
                    return v.status;
                })($30));
            };
        })());
        return function ($28) {
            return $25($26(Data_Array.head($27($28))));
        };
    })();
    var store = Data_Profunctor_Choice.splitChoice(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Control_Applicative.pure(Effect_Aff.applicativeAff))(Control_Apply.apply(Control_Apply.applyFn)(Data_Functor.voidRight(Effect_Aff.functorAff))((function () {
        var $31 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
        return function ($32) {
            return $31(Model_Company.saveCompany($32));
        };
    })()));
    var $33 = Data_Bitraversable.bisequence(Data_Either.bitraversableEither)(Effect_Aff.applicativeAff);
    var $34 = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)(verifyCompany)(verifyResp);
    return function ($35) {
        return $33(store($34($35)));
    };
})();
var _stepUp = function (state) {
    return function (companyId) {
        var stepUpSuccess = Data_Function["const"](state);
        var stepUpFail = Data_Function["const"]({
            responseType: new Data_Maybe.Just(new ResponseError({
                code: 500
            })),
            kycFailedAlert: state.kycFailedAlert,
            kycModal: state.kycModal,
            spinner: state.spinner
        });
        return Data_Functor.map(Effect_Aff.functorAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(stepUpFail)(stepUpSuccess))(Helper_AuthActions.stepUp()("/dashboard/" + (companyId + "/kyc"))({}));
    };
};
var handleResponse = function (companyId) {
    return function (state) {
        return function (resp) {
            var noSpinnerState = {
                spinner: {
                    display: false
                },
                kycFailedAlert: state.kycFailedAlert,
                kycModal: state.kycModal,
                responseType: state.responseType
            };
            var internalErrorState = {
                responseType: new Data_Maybe.Just(new ResponseError({
                    code: 500
                })),
                kycFailedAlert: noSpinnerState.kycFailedAlert,
                kycModal: noSpinnerState.kycModal,
                spinner: noSpinnerState.spinner
            };
            if (resp.status === 201) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    responseType: new Data_Maybe.Just(SuccessResponse.value),
                    kycFailedAlert: noSpinnerState.kycFailedAlert,
                    kycModal: noSpinnerState.kycModal,
                    spinner: noSpinnerState.spinner
                });
            };
            if (resp.status === 412) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)(noSpinnerState);
            };
            if (resp.status === 400) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    kycFailedAlert: {
                        display: true
                    },
                    kycModal: {
                        display: false,
                        disabled: true
                    },
                    responseType: noSpinnerState.responseType,
                    spinner: noSpinnerState.spinner
                });
            };
            if (resp.status === 403) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    responseType: new Data_Maybe.Just(ForbiddenToUpdateRole.value),
                    kycFailedAlert: noSpinnerState.kycFailedAlert,
                    kycModal: noSpinnerState.kycModal,
                    spinner: noSpinnerState.spinner
                });
            };
            if (resp.status === 401) {
                return _stepUp(noSpinnerState)(companyId);
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)(internalErrorState);
        };
    };
};
var _updateRoleAsAuthoriser = function (state) {
    return function (company) {
        var noSpinnerState = {
            spinner: {
                display: false
            },
            kycFailedAlert: state.kycFailedAlert,
            kycModal: state.kycModal,
            responseType: state.responseType
        };
        var internalErrorState = {
            responseType: new Data_Maybe.Just(new ResponseError({
                code: 500
            })),
            kycFailedAlert: noSpinnerState.kycFailedAlert,
            kycModal: noSpinnerState.kycModal,
            spinner: noSpinnerState.spinner
        };
        var handleException = Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(internalErrorState));
        return Control_Bind.bind(Effect_Aff.bindAff)(sendUpdateToAuthoriserRequest(company.companyId))(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(handleException)(handleResponse(company.companyId)(state)));
    };
};
var _handleCompanyError = function (state) {
    return function (id) {
        return function (errorCode) {
            var noSpinnerState = {
                spinner: {
                    display: false
                },
                kycFailedAlert: state.kycFailedAlert,
                kycModal: state.kycModal,
                responseType: state.responseType
            };
            if (errorCode === 401) {
                return Data_Functor.voidLeft(Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                    var $36 = SessionStorage.saveLocation(Config.config.uiHost + ("/dashboard/" + (id + "/kyc")))();
                    return Helper_AuthActions.login(Data_Maybe.Just.create($36))();
                }))(state);
            };
            if (errorCode === 403) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    responseType: new Data_Maybe.Just(new ResponseError({
                        code: 403
                    })),
                    kycFailedAlert: noSpinnerState.kycFailedAlert,
                    kycModal: noSpinnerState.kycModal,
                    spinner: noSpinnerState.spinner
                });
            };
            if (errorCode === 404) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    responseType: new Data_Maybe.Just(new ResponseError({
                        code: 404
                    })),
                    kycFailedAlert: noSpinnerState.kycFailedAlert,
                    kycModal: noSpinnerState.kycModal,
                    spinner: noSpinnerState.spinner
                });
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                responseType: new Data_Maybe.Just(new ResponseError({
                    code: 500
                })),
                kycFailedAlert: noSpinnerState.kycFailedAlert,
                kycModal: noSpinnerState.kycModal,
                spinner: noSpinnerState.spinner
            });
        };
    };
};
var updateRole = function (id) {
    return function (state) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Fetch.get()("/company?au=true&online=true&id=" + id)({})))(function (resp) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(new Data_Either.Left(500))))(_storeCompany)(resp))(function (companyResp) {
                return Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(_handleCompanyError(state)(id))(_updateRoleAsAuthoriser(state))(companyResp);
            });
        });
    };
};
module.exports = {
    initState: initState,
    updateRole: updateRole,
    showModal: showModal,
    hideModal: hideModal,
    sendUpdateToAuthoriserRequest: sendUpdateToAuthoriserRequest,
    RedirectFromKYCSuccess: RedirectFromKYCSuccess,
    RedirectFromKYCForbidden: RedirectFromKYCForbidden,
    ResponseError: ResponseError,
    SuccessResponse: SuccessResponse,
    ForbiddenToUpdateRole: ForbiddenToUpdateRole,
    handleResponse: handleResponse
};
