import React from 'react'
import NumberFormat from 'react-number-format'
import {centsToDollars} from 'purs/BatchAmount'

const FormattedAmount = ({amount}) => (
  <NumberFormat
    thousandSeparator={true}
    displayType="text"
    isNumericString={true}
    value={centsToDollars(amount)}
  />
)

export default FormattedAmount
