// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Profunctor_Choice = require("../Data.Profunctor.Choice/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Model_Company = require("../Model.Company/index.js");
var Model_CompanyOnboardModal = require("../Model.CompanyOnboardModal/index.js");
var Model_CompanyStatus = require("../Model.CompanyStatus/index.js");
var Segment = require("../Segment/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var userRoleOptions = function (state) {
    if (state.company instanceof Data_Maybe.Just && (state.company.value0.entityType instanceof Data_Maybe.Just && state.company.value0.entityType.value0 === "Individual/Sole Trader")) {
        return Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(Model_CompanyOnboardModal.userRoleShow))([ Model_CompanyOnboardModal.Owner.value, Model_CompanyOnboardModal.Employee.value, Model_CompanyOnboardModal.Bookkeeper.value, Model_CompanyOnboardModal.Accountant.value, Model_CompanyOnboardModal.Other.value ]);
    };
    return Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(Model_CompanyOnboardModal.userRoleShow))([ Model_CompanyOnboardModal.Owner.value, Model_CompanyOnboardModal.Director.value, Model_CompanyOnboardModal.Employee.value, Model_CompanyOnboardModal.Bookkeeper.value, Model_CompanyOnboardModal.Accountant.value, Model_CompanyOnboardModal.Other.value ]);
};
var stepUpDashboard = function (state) {
    var stepUpSuccess = Data_Function["const"](Control_Applicative.pure(Effect_Aff.applicativeAff)(state));
    var stepUpFail = Data_Function["const"](Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.redirectTo("/dashboard"))(state)));
    return Control_Bind.bindFlipped(Effect_Aff.bindAff)(Data_Profunctor_Choice.fanin(Control_Category.categoryFn)(Data_Profunctor_Choice.choiceFn)(stepUpFail)(stepUpSuccess))(Helper_AuthActions.stepUp()("/dashboard")({}));
};
var selectUserRole = function (role) {
    return function (state) {
        return Control_Applicative.pure(Effect_Aff.applicativeAff)({
            roleSelectModal: {
                selectedRole: Data_Either.hush(Model_CompanyOnboardModal.readUserRole(role)),
                display: state.roleSelectModal.display,
                errorCode: state.roleSelectModal.errorCode,
                spinner: state.roleSelectModal.spinner
            },
            company: state.company,
            onBehalfConfirmModal: state.onBehalfConfirmModal,
            stepUpModal: state.stepUpModal,
            uboConfirmModal: state.uboConfirmModal
        });
    };
};
var redirectToCompanyListPage = function (state) {
    return Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.redirectTo("/companies"))(state);
};
var nextModal = function (state) {
    if (state.roleSelectModal.selectedRole instanceof Data_Maybe.Just && state.roleSelectModal.selectedRole.value0 instanceof Model_CompanyOnboardModal.Owner) {
        return {
            roleSelectModal: state.roleSelectModal,
            uboConfirmModal: {
                display: true,
                errorCode: state.uboConfirmModal.errorCode,
                disableButton: state.uboConfirmModal.disableButton
            },
            onBehalfConfirmModal: state.onBehalfConfirmModal,
            stepUpModal: state.stepUpModal,
            company: state.company
        };
    };
    if (state.roleSelectModal.selectedRole instanceof Data_Maybe.Just && state.roleSelectModal.selectedRole.value0 instanceof Model_CompanyOnboardModal.Director) {
        return {
            roleSelectModal: state.roleSelectModal,
            uboConfirmModal: {
                display: true,
                errorCode: state.uboConfirmModal.errorCode,
                disableButton: state.uboConfirmModal.disableButton
            },
            onBehalfConfirmModal: state.onBehalfConfirmModal,
            stepUpModal: state.stepUpModal,
            company: state.company
        };
    };
    return {
        roleSelectModal: state.roleSelectModal,
        uboConfirmModal: state.uboConfirmModal,
        onBehalfConfirmModal: {
            display: true,
            nextButton: state.onBehalfConfirmModal.nextButton
        },
        stepUpModal: state.stepUpModal,
        company: state.company
    };
};
var initState = {
    roleSelectModal: {
        display: true,
        spinner: {
            display: true
        },
        selectedRole: Data_Maybe.Nothing.value,
        errorCode: Data_Maybe.Nothing.value
    },
    uboConfirmModal: {
        display: false,
        errorCode: Data_Maybe.Nothing.value,
        disableButton: false
    },
    onBehalfConfirmModal: {
        display: false,
        nextButton: {
            disabled: true
        }
    },
    stepUpModal: {
        display: false
    },
    company: Data_Maybe.Nothing.value
};
var fetchCompany = function (v) {
    return function (state) {
        if (v instanceof Data_Maybe.Nothing) {
            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(redirectToCompanyListPage(state));
        };
        if (v instanceof Data_Maybe.Just) {
            var redirectToCompanyOnboardModal = Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.redirectTo("/companies/" + v.value0))(state);
            var finishLoading = {
                roleSelectModal: {
                    display: state.roleSelectModal.display,
                    spinner: {
                        display: false
                    },
                    selectedRole: state.roleSelectModal.selectedRole,
                    errorCode: state.roleSelectModal.errorCode
                },
                uboConfirmModal: state.uboConfirmModal,
                onBehalfConfirmModal: state.onBehalfConfirmModal,
                stepUpModal: state.stepUpModal,
                company: state.company
            };
            return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.recover({
                status: 500,
                body: ""
            })(Fetch.get()("/company/" + (v.value0 + "/onboard-role"))({})))(function (resp) {
                if (resp.status === 200) {
                    var v1 = Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "abn";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "acn";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "entityType";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "id";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "industry";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readRecord()(Simple_JSON.readFieldsNil)))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "location";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readString))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "name";
                    }))(Simple_JSON.readString)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "onboardOnBehalf";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "requireMoreDocument";
                    }))(Simple_JSON.readMaybe(Simple_JSON.readBoolean))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                        return "status";
                    }))(Model_CompanyStatus.readForeignCompanyStatus)(Simple_JSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()()))(resp.body);
                    if (v1 instanceof Data_Either.Right) {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                            Model_Company.updateCompanyStatus(v1.value0.status)();
                            var result = (function () {
                                if (v1.value0.status instanceof Model_CompanyStatus.Unregistered) {
                                    return redirectToCompanyOnboardModal();
                                };
                                if (v1.value0.status instanceof Model_CompanyStatus.CreditCheckFailed) {
                                    return redirectToCompanyOnboardModal();
                                };
                                if (v1.value0.status instanceof Model_CompanyStatus.Registered) {
                                    return Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.redirectTo("/"))(state)();
                                };
                                if (Data_Maybe.isNothing(v1.value0.onboardOnBehalf)) {
                                    return {
                                        company: new Data_Maybe.Just(v1.value0),
                                        onBehalfConfirmModal: finishLoading.onBehalfConfirmModal,
                                        roleSelectModal: finishLoading.roleSelectModal,
                                        stepUpModal: finishLoading.stepUpModal,
                                        uboConfirmModal: finishLoading.uboConfirmModal
                                    };
                                };
                                return Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.redirectTo("/dashboard"))(state)();
                            })();
                            return result;
                        });
                    };
                    if (v1 instanceof Data_Either.Left) {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.redirectTo("/companies"))(state));
                    };
                    throw new Error("Failed pattern match at Page.OnboardUserRoleModal (line 124, column 7 - line 134, column 64): " + [ v1.constructor.name ]);
                };
                if (resp.status === 401) {
                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.login(Data_Maybe.Nothing.value))(state));
                };
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    roleSelectModal: {
                        errorCode: new Data_Maybe.Just(resp.status),
                        display: finishLoading.roleSelectModal.display,
                        selectedRole: finishLoading.roleSelectModal.selectedRole,
                        spinner: finishLoading.roleSelectModal.spinner
                    },
                    company: finishLoading.company,
                    onBehalfConfirmModal: finishLoading.onBehalfConfirmModal,
                    stepUpModal: finishLoading.stepUpModal,
                    uboConfirmModal: finishLoading.uboConfirmModal
                });
            });
        };
        throw new Error("Failed pattern match at Page.OnboardUserRoleModal (line 118, column 1 - line 118, column 49): " + [ v.constructor.name, state.constructor.name ]);
    };
};
var enableOnBehalfConfirmNextButton = function (b) {
    return function (state) {
        var trackFileUpload = function (v) {
            if (v) {
                return Segment.tracking(Segment.OnboardUploadFile.value);
            };
            if (!v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at Page.OnboardUserRoleModal (line 103, column 5 - line 103, column 46): " + [ v.constructor.name ]);
        };
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(trackFileUpload(b)))(function () {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                onBehalfConfirmModal: {
                    nextButton: {
                        disabled: !b
                    },
                    display: state.onBehalfConfirmModal.display
                },
                company: state.company,
                roleSelectModal: state.roleSelectModal,
                stepUpModal: state.stepUpModal,
                uboConfirmModal: state.uboConfirmModal
            });
        });
    };
};
var disableFinalButton = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        uboConfirmModal: {
            disableButton: true,
            display: state.uboConfirmModal.display,
            errorCode: state.uboConfirmModal.errorCode
        },
        company: state.company,
        onBehalfConfirmModal: state.onBehalfConfirmModal,
        roleSelectModal: state.roleSelectModal,
        stepUpModal: state.stepUpModal
    });
};
var closeAllModals = function (state) {
    return {
        roleSelectModal: {
            display: false,
            spinner: state.roleSelectModal.spinner,
            selectedRole: state.roleSelectModal.selectedRole,
            errorCode: state.roleSelectModal.errorCode
        },
        uboConfirmModal: {
            display: false,
            errorCode: state.uboConfirmModal.errorCode,
            disableButton: state.uboConfirmModal.disableButton
        },
        onBehalfConfirmModal: {
            display: false,
            nextButton: state.onBehalfConfirmModal.nextButton
        },
        stepUpModal: {
            display: false
        },
        company: state.company
    };
};
var roleCancel = function (state) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
        var v = closeAllModals(state);
        return {
            roleSelectModal: {
                display: true,
                errorCode: v.roleSelectModal.errorCode,
                selectedRole: v.roleSelectModal.selectedRole,
                spinner: v.roleSelectModal.spinner
            },
            company: v.company,
            onBehalfConfirmModal: v.onBehalfConfirmModal,
            stepUpModal: v.stepUpModal,
            uboConfirmModal: v.uboConfirmModal
        };
    })());
};
var roleConfirm = (function () {
    var $41 = Control_Applicative.pure(Effect_Aff.applicativeAff);
    return function ($42) {
        return $41(nextModal(closeAllModals($42)));
    };
})();
var saveOnboardRole = function (state) {
    var selectedRole = Data_Maybe.maybe("")(Data_Show.show(Model_CompanyOnboardModal.userRoleShow))(state.roleSelectModal.selectedRole);
    var reqBody = Simple_JSON.writeJSON(Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
        return "role";
    }))(Simple_JSON.writeForeignMaybe(Model_CompanyOnboardModal.userRoleWriteForeign))(Simple_JSON.nilWriteForeignFields)()()()))({
        role: state.roleSelectModal.selectedRole
    });
    var companyId = Data_Maybe.maybe("")(function (v) {
        return v.id;
    })(state.company);
    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Segment.tracking(new Segment.OnboardSelectRole(selectedRole))))(function () {
        return Control_Bind.bind(Effect_Aff.bindAff)(Fetch.recover({
            status: 500,
            body: ""
        })(Fetch.post()("/company/" + (companyId + "/onboard-role"))(reqBody)({})))(function (resp) {
            if (resp.status === 204) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)((function () {
                    var v = closeAllModals(state);
                    return {
                        stepUpModal: {
                            display: true
                        },
                        company: v.company,
                        onBehalfConfirmModal: v.onBehalfConfirmModal,
                        roleSelectModal: v.roleSelectModal,
                        uboConfirmModal: v.uboConfirmModal
                    };
                })());
            };
            if (resp.status === 401) {
                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.voidLeft(Effect.functorEffect)(Helper_AuthActions.login(Data_Maybe.Nothing.value))(state));
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                uboConfirmModal: {
                    errorCode: new Data_Maybe.Just(resp.status),
                    disableButton: false,
                    display: state.uboConfirmModal.display
                },
                company: state.company,
                onBehalfConfirmModal: state.onBehalfConfirmModal,
                roleSelectModal: state.roleSelectModal,
                stepUpModal: state.stepUpModal
            });
        });
    });
};
module.exports = {
    initState: initState,
    closeAllModals: closeAllModals,
    selectUserRole: selectUserRole,
    roleConfirm: roleConfirm,
    roleCancel: roleCancel,
    disableFinalButton: disableFinalButton,
    enableOnBehalfConfirmNextButton: enableOnBehalfConfirmNextButton,
    redirectToCompanyListPage: redirectToCompanyListPage,
    stepUpDashboard: stepUpDashboard,
    fetchCompany: fetchCompany,
    nextModal: nextModal,
    userRoleOptions: userRoleOptions,
    saveOnboardRole: saveOnboardRole
};
