const config = {
  uiHost: process.env.REACT_APP_PURPLEPAY_UI_HOST || 'http://localhost:3000',
  apiHost: (process.env.REACT_APP_PURPLEPAY_API_HOST || '') + '/api',
  clientId:
    process.env.REACT_APP_AUTH0_CLIENT_ID || 'KWaUvyBZNBT5V5wTljAAnS8kW6q8OE2j',
  mydotResourceId:
    process.env.REACT_APP_MY_DOT_CLIENT_ID ||
    'd311aa59-aa9c-446a-bfe3-d5e03fdd3d3f',
  authHost: process.env.REACT_APP_AUTH0_HOST || 'https://sit.id.myob.com',
  tokenStorageName: 'idToken',
  companyStorageName: 'companyInfo',
  leanEngageSurveyId: process.env.REACT_APP_LEAN_ENGAGE_ID,
  stepUpAcr: 'http://schemas.openid.net/pape/policies/2007/06/multi-factor',
  harmonyId: process.env.REACT_APP_HARMONY_ID || 'MYOB54725',
  harmonyToken:
    process.env.REACT_APP_HARMONY_TOKEN || 'oxp9ZBrV8eOdmWNoWdTrPBnaXOWRPEpH',
  greenIDAccountId: process.env.REACT_APP_GREENID_ACCOUNT_ID || 'myob_au',
  greenIDApiCode: process.env.REACT_APP_GREENID_API_CODE || '4e8-T5e-dnQ-zdx',
  rewardsSSOLink: process.env.REACT_APP_REWARDS_SSO_LINK || 'https://sit.id.myob.com/samlp/sQjAkqex1Cmu4HoDZv2ODP5jGfQiw3XM',
  cors: process.env.REACT_APP_CORS || 'same-origin',
  creditFee: 1.5,
  debitFee: 0.1,
  bankAccountBatchFee: '4c',
  bankAccountPerTransactionFee: '4c',
  productDisclosureStatementUrl:
    'https://www.myob.com/content/dam/public-website/docs/terms-conditions/MYOB-Payment-Services-PDS.pdf',
  financialServicesGuideUrl:
    'https://www.myob.com/content/dam/public-website/docs/enterprise/myob-financial-services-payment-guide.pdf',
  privacyDisclosureStatementUrl: 'https://www.myob.com/au/privacy-policy',
  arlPaymentFaqsUrl: 'https://help.myob.com/wiki/x/KoGFAg',
  newEssentialPaymentFaqsUrl: 'https://help.myob.com/wiki/x/4wG0Aw',
  userStorageName: 'userId',
  nonceStorageName: 'nonce',
  masterCardRewardsTermsAndConditionsUrl:
    'https://www.myob.com/content/dam/public-website/docs/terms-conditions/MYOB-Rewards-Terms.pdf',
  paymentSignupUrl: 'https://www.myob.com/au/comms/payments/signup',
  rewardsStorageName: 'rewardsDetails',
  joinRewardsStorageName: 'joinRewards',
  kycInfoStorageName: 'kycInfo',
  kycResultStorageName: 'kycResult',
  subscribeUpdatesURL:
    'https://forms.office.com/Pages/ResponsePage.aspx?id=__VH7LjHSEiGWRHCxCTRIBhqMcilpL9On1yif9p2Uz5URUI1U1FRWVVUVFBJVjdKSkZSVzhMUzFWMC4u',
  storageHost: process.env.REACT_APP_STORAGE_HOST || 'http://localhost:7357/storage'
}

exports.config = config
