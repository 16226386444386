import React from 'react'
import Error from '../components/Error'
import NavBarForBusiness from '../components/NavBarForBusiness'
import { readCompany } from 'purs/Model.Company'
import { BaseTemplate } from '@myob/myob-widgets'
import './ErrorPage.sass'
import { either } from 'purs/Data.Either'
const ErrorPage = ({ errorCode }) => {
  const company = either(() => {})(a => a)(readCompany())
  return (
    <React.Fragment>
      <NavBarForBusiness arlBusiness={company} />
      <BaseTemplate containerClassName="error-container">
        <Error httpError={errorCode} />
      </BaseTemplate>
    </React.Fragment>
  )
}

export default ErrorPage
