import React from 'react'
import NavBarForBusiness from './NavBarForBusiness'
import { BaseTemplate } from '@myob/myob-widgets'

const WithNavBar = Component => props => (
  <React.Fragment>
    <NavBarForBusiness />
    <BaseTemplate>
      <Component {...props} />
    </BaseTemplate>
  </React.Fragment>
)

export default WithNavBar
