import React, { useContext } from 'react'
import DashboardContent from '../components/DashboardContent'
import { BaseTemplate } from '@myob/myob-widgets'
import NavBarForBusiness from '../components/NavBarForBusiness'
import { readCompany } from 'purs/Model.Company'
import AddNewCard from '../components/AddCardModal'
import RewardSignUpModal from '../components/RewardSignUpModal'
import AddContactDetails from '../components/AddContactModal'
import CompanyUBOModal from '../components/CompanyUBOModal'
import AboutBusinessModal from '../components/AboutBusinessModal'
import VerificationPausedModal from '../components/VerificationPausedModal'
import OwnerInfoGenerateFailedModal from '../components/OwnerInfoGenerateFailedModal'
import withPsState from '../withPsState'
import {
  initState,
  fetchCompany,
  enableSavingStatus,
  iframeUrlWithOwnerInfo,
  joinRewards
} from 'purs/Dashboard'
import DashboardHeader from '../components/DashboardHeader'
import DashboardFailedAlert from '../components/DashboardFailedAlert'
import { either } from 'purs/Data.Either'
import { cleanJoinRewards } from 'purs/Model.Rewards'
import { readCompanyWithDefault } from 'purs/Model.Company'
import AlertBanner from '../components/AlertBanner'
import { Info } from 'purs/AlertBanner'

const hideModal = () => {
  window.location.reload(false)
  cleanJoinRewards()
}

const Dashboard = ({ history }) => {
  const { state, dispatch } = useContext(context)
  const company = readCompany()
  const companyId = either(() => {})(a => a.companyId)(company)

  const { title, subtitle, description } = state.applicationComplete.display
    ? {
        title: '',
        subtitle: 'Phew, all done!',
        description: "You've completed your application."
      }
    : {
        title: 'Welcome,',
        subtitle: readCompanyWithDefault().name,
        description: 'Set yourself up with the secure way to pay.'
      }

  return (
    <div className="dashboard">
      <NavBarForBusiness arlBusiness={company.value0} />
      <BaseTemplate>
        <AlertBanner type={Info.value} containerOff={true}>
          <p>
            Need help with getting payments set up? Call us on{' '}
            <a href="tel:1300783674">1300 783 674</a>
          </p>
        </AlertBanner>
        {state.alert.display && (
          <DashboardFailedAlert alertType={state.alert.alertType} />
        )}
        <DashboardHeader
          title={title}
          subtitle={subtitle}
          description={description}
        />
        <DashboardContent history={history} />
      </BaseTemplate>
      {state.uboModal.display && <CompanyUBOModal />}
      {state.kycModal.display && (
        <AddContactDetails
          callBack={hideModal}
          iframeUrl={iframeUrlWithOwnerInfo(state)}
          isAdmin={state.kycModal.isAdmin}
        />
      )}
      {state.ownerInfoGenerateFailedModal.display && (
        <OwnerInfoGenerateFailedModal />
      )}
      {state.verificationPausedModal.display && <VerificationPausedModal />}
      {state.newCardModal.display && (
        <AddNewCard
          companyId={companyId}
          onClose={() => hideModal()}
        />
      )}
      {state.rewardSignUpModal.display && (
        <RewardSignUpModal
          onCancel={() => hideModal()}
          onContinue={rewardsAgreed => {
            dispatch(enableSavingStatus)
            dispatch(joinRewards(companyId)(rewardsAgreed))
          }}
        />
      )}
      {state.aboutBusinessModal.display && (
        <AboutBusinessModal companyId={companyId} />
      )}
    </div>
  )
}

export const { context, Component } = withPsState(
  Dashboard,
  initState,
  () => fetchCompany
)

export default Component
