import React from 'react'
import { userKyc } from 'purs/OnboardingKYCModalContent'
import AddContactModalContent from '../components/AddContactModalContent'

const UserKYCModalContent = () => {
  const register = (verificationId, callback) => {
    return userKyc(verificationId)(callback)()
  }

  return (
    <AddContactModalContent
      register={register}
      isSelf={true}
      isUpdatingRole={true}
    />
  )
}

export default UserKYCModalContent
