import React, { useContext } from 'react'
import { context } from '../pages/Dashboard'
import { shouldRedirectTo, allSteps, enableSubmitBtn, showPageSpinner, submitOnboardApplication, updateFinishedFiles } from 'purs/Dashboard'
import DashboardStep from './DashboardStep'
import { ButtonRow, Spinner, Card, Columns } from '@myob/myob-widgets'
import ApplicationComplete from './ApplicationComplete'
import Error from './Error'
import { isJust } from 'purs/Data.Maybe'
import { Icons } from '@myob/myob-widgets'
import { Link } from 'react-router-dom'
import UserFileUploader from './UserFileUploader'
import config from '../config'
import * as r from 'ramda'
import './DashboardContent.sass'

const Steps = ({ companyStatus }) => {
  const { dispatch } = useContext(context)

  return allSteps(companyStatus).map((step, index) => (
    <DashboardStep
      id={`dashboard-step-${index}`}
      key={`dashboard-step-${index}`}
      imageName={step.imageName}
      name={step.name}
      description={step.description}
      effort={step.effort}
      paused={step.paused}
      done={step.done}
      disabled={step.disabled}
      onClick={() =>
        step.paused ? dispatch(step.clickPaused) : dispatch(step.clickStartNow)
      }
    />
  ))
}

const DashboardContent = ({ history }) => {
  const { state, dispatch } = useContext(context)

  if (state.spinner.display) {
    return <Spinner />
  }

  if (isJust(state.errorCode)) {
    switch (state.errorCode.value0) {
      case 500:
        return <Error httpError={500} />
      default:
        return <Error httpError={404} />
    }
  }

  if (isJust(state.company)) {
    const redirectUrl = shouldRedirectTo(state.company.value0)
    if (isJust(redirectUrl)) {
      history.push(redirectUrl.value0)
      return <></>
    }

    if (state.applicationComplete.display) {
      return (
        <Card>
          <ApplicationComplete
            title={'Application Completed!'}
            description={
              "We may need you to provide additional information to proceed with your application. One of our team will be in touch in the next 1-2 business days. Once your application is approved, you'll be able to use the service."
            }
          >
            <Link to={'/companies'}>
              <Icons.LeftChevron size="12px" /> Back to business list
            </Link>
          </ApplicationComplete>
        </Card>
      )
    }
    return (
    <>
      <Steps companyStatus={state.company.value0.status} />
      {state.userFileSection.display && (<Card id="upload-file-card" >
        <Columns>
          <div className="step-upload-text-column">
            <h3 className="no-margin">Provide evidence of authority</h3>
            <p className="no-margin">
              We need to know that you&apos;ve got the authority to make payments on behalf of the business. Upload written evidence of authority or email <a href="mailto:payments_support@myob.com">payments_support@myob.com</a>.
            </p>
            <p className="no-margin">Upload your files as <b>PDF, DOC, JPEG, PNG or TIFF</b> and make sure it&#39;s below <b>10MB</b>.</p>
            <p>Need an example? <a href={config.letterOfAuthorityURL} target="_blank">Download authorisation form</a></p>
          </div>
          <div className="step-upload-container-column">
            <UserFileUploader existingFiles={[]} onUpdate={files => {
                  dispatch(updateFinishedFiles(r.any(r.propEq('state', 'finished'), files)))
              }}/>
          </div>
        </Columns>
      </Card>)}
      <ButtonRow>
        <button id="btn-dashboard-submit" type="button" className="btn btn-primary dashboard-submit-btn"
          disabled={!enableSubmitBtn(state)} onClick={() => {dispatch(showPageSpinner); dispatch(submitOnboardApplication)}}>
          Submit application
        </button>
      </ButtonRow>
    </>)

  } else return <></>
}
export default DashboardContent
