import React from 'react'
import {Button, Modal, Alert, Spinner} from '@myob/myob-widgets'
import { useQuery } from 'react-query'
import axios from 'axios'
import DefaultPaymentMethods from './DefaultPaymentMethod'

const ConfirmAuthPaymentModal = ({ onCancel, onContinue, isFutureDate, companyId}) => {
  const { data, isLoading, isError } =
    useQuery(
      ["payment-method", companyId],
      () => {
        const isFromARL = !!axios.defaults.headers.common['Authorization']
        const url = isFromARL ? `/api/arl/company/${companyId}/payment-method/no-mfa` : `/api/company/${companyId}/payment-method/no-mfa`
        return axios.get(url)
      },
      { enabled: !!companyId, cacheTime: 0 }
    )

  const paymentMethods = data?.data || { cards: [], bankAccounts: []}
  const paymentMethodEmpty = paymentMethods.cards.length === 0 && paymentMethods.bankAccounts.length === 0

  const ModalBody = () => (
    <>
      {
        isError && <Alert type="danger">
          Oops, looks like something went wrong! Please refresh your browser. Or contact us on{' '}
          <a href="tel:1300783674">1300 783 674</a>
        </Alert>
      }
      {
        isFutureDate && <Alert type="warning">
          <span>
            We don&apos;t currently support future dated payments. If you proceed, your card will be debited today.
          </span>
        </Alert>
      }
      <h3>Payment method</h3>
      <p>
        We will make this payment using the payment method you have set as default. <br/>
        Review this method is correct below before clicking confirm and pay.
      </p>
      <DefaultPaymentMethods paymentMethods={data?.data}/>
      <p style={{marginTop: '2rem'}}>
      If you need to change the default payment method to make this payment, return to saved payment methods. You will need to come back and resubmit this request.
      </p>
    </>
  )
  const modalOrEmpty = (!isError && paymentMethodEmpty) ? (<Alert type="danger">
    You do not currently have any valid payment methods to make this payment from. Please return to the payment methods screen to update your payment methods.
  </Alert>) : <ModalBody/>

  return (
    <Modal modalId="confirm-authorise-payment" title="Confirm Payment" onCancel={onCancel}>
      <Modal.Body>
        { isLoading ? <Spinner/> : modalOrEmpty }
      </Modal.Body>
      <Modal.Footer>
        <Button id="btn-confirm-auth-modal-back" type="secondary" className="btn btn-default" onClick={onCancel}> Cancel </Button>
        <Button id="btn-confirm-auth-modal-auth" className="confirm-auth" onClick={onContinue}
                disabled={isError || isLoading || paymentMethodEmpty}> Confirm and pay </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmAuthPaymentModal
