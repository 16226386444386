import * as React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Home from './pages/Home'
import BatchDetail from './pages/BatchDetail'
import Triage from './pages/Triage'
import BusinessManage from './pages/BusinessManage'
import WithNavBar from './components/WithNavBar'
import Preference from './pages/Preference'
import ManageUsers from './pages/Manage.Users'
import ManageUserDetails from './pages/Manage.User.Details'
import Companies from './pages/Companies'
import GetStarted from './pages/GetStarted'
import OnboardUserRoleModal from './pages/OnboardUserRoleSelect'
import NewCardRedirect from './pages/NewCardRedirect'
import PaymentRedirect from './pages/PaymentRedirect'
import Dashboard from './pages/Dashboard'
import Logout from './pages/Logout'
import OnboardingKYCModalContent from './pages/OnboardingKYCModalContent'
import UserKYCModalContent from './pages/UserKYCModalContent'
import './App.sass'
import ErrorPage from './pages/ErrorPage'
import safeRedirect, { homepageRedirect } from './filters/SafeRedirect'
import unsafeRedirect from './filters/UnsafeRedirect'
import { unsafeSaveUserId } from 'purs/App'
import KYCDashboard from './pages/KYCDashboard'
import { initGAPathOptMidware, dispatchRouteChangeEvent } from './helper/segment.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import axios from 'axios'
import Cookies from 'js-cookie'
import { getKeyValueFromLocation } from 'purs/Helper.RouteQuery'
import { Right } from 'purs/Data.Either'

export const App = () => {
  const location = useLocation()

  React.useEffect(() => {
    unsafeSaveUserId()

    axios.defaults.headers.common['x-tsec-csrf'] = Cookies.get('tsec-csrf')
    const token = getKeyValueFromLocation(location)('token')
    if(token instanceof Right) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token.value0}`
    }

    const pathToTransform = [
      '/batch/:id/payment',
      '/batch/:id',
      '/manage/users/:id',
      '/dashboard/:id/kyc',
      '/companies/:id/get-started',
      '/companies/:id/select-role',
      '/companies/:id'
    ]
    initGAPathOptMidware(pathToTransform)
  }, [])

  React.useEffect(() => {
    dispatchRouteChangeEvent()
  }, [location])

  return (
    <div>
      <Switch>
        <Route exact={true} path="/companies" component={Companies} />
        <Route
          exact={true}
          path="/dashboard"
          component={safeRedirect(Dashboard)}
        />
        <Route
          exact={true}
          path="/batch/:batchId/payment"
          component={unsafeRedirect(PaymentRedirect)}
        />
        <Route
          exact={true}
          path="/batch/:batchId"
          component={BatchDetail}
        />
        <Route exact={true} path="/triage" component={Triage} />
        <Route exact={true} path="/signup" component={Triage} />
        <Route
          exact={true}
          path="/business-details"
          component={WithNavBar(Preference)}
        />
        <Route
          exact={true}
          path="/manage"
          component={safeRedirect(BusinessManage)}
        />
        <Route
          exact={true}
          path="/manage/users"
          component={safeRedirect(ManageUsers)}
        />
        <Route
          exact={true}
          path="/manage/users/:id"
          component={safeRedirect(ManageUserDetails)}
        />
        <Route exact={true} path="/logout" component={Logout} />
        <Route
          exact={false}
          path="/onboardkycmodal"
          component={OnboardingKYCModalContent}
        />
        <Route
          exact={true}
          path="/userkycmodal"
          component={UserKYCModalContent}
        />
        <Route
          exact={true}
          path="/dashboard/:companyId/kyc"
          component={safeRedirect(KYCDashboard)}
        />
        <Route
          exact={true}
          path="/companies/:companyId/get-started"
          component={GetStarted}
        />
        <Route
          exact={true}
          path="/companies/:companyId/select-role"
          component={OnboardUserRoleModal}
        />
        <Route
          exact={true}
          path="/companies/:companyId"
          component={Companies}
        />
        <Route
          exact={true}
          path="/tokenize/card"
          component={NewCardRedirect}
        />
        <Route exact={true} path="/" component={homepageRedirect(Home)} />
        <Route
          exact={true}
          path="/error"
          component={() => <ErrorPage errorCode={500} />}
        />
        <Route component={() => <ErrorPage errorCode={404} />} />
      </Switch>
    </div>
  )
}

const QueryCacheApp = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 5
      }
    }
  })
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  )
}
export default QueryCacheApp
