import React from 'react'
import { send, subscribe, channel } from 'purs/Signal.Channel'
import { unsafePerformEffect } from 'purs/Effect.Unsafe'
import { pure } from 'purs/Control.Applicative'
import { applicativeEffect } from 'purs/Effect'
import { runAff_ } from 'purs/Effect.Aff'
import Signal, { functorSignal } from 'purs/Signal'

export function performEff(eff, setState) {
  try {
    if (eff && eff.tag) {
      return unsafePerformEffect(
        runAff_(a => {
          if (a.value0) {
            return () => {
              console.debug(`withState perform result in:`, a)
              return setState(a.value0)
            }
          } else
            return () => {
              throw 'Missing Value in Eff' + a
            }
        })(eff)
      )
    } else if (typeof eff === 'function') {
      let newState = unsafePerformEffect(eff)
      return setState(newState)
    }
  } catch (e) {
    console.error(`[CRITICAL ERROR]: Eff cannot be perform`, eff, `DUE to:`, e)
  }
}

export default function withState(WrappedComponent, initState, initAction) {
  const doNothing = () => {
    return pure(applicativeEffect)(initState)
  }
  const context = React.createContext({
    state: initState,
    dispatch: action => pure(applicativeEffect)({})
  })

  class Component extends React.Component {
    constructor(props) {
      super(props)
      this.state = initState
      this.channel = unsafePerformEffect(channel(doNothing))
    }

    componentDidMount() {
      unsafePerformEffect(this.subscribeChannel())
      unsafePerformEffect(send(this.channel)(initAction(this.props)))
    }
    componentDidUpdate(prevProp) {
      if (prevProp !== this.props)
        unsafePerformEffect(send(this.channel)(initAction(this.props)))
    }
    subscribeChannel() {
      this.signal = Signal.squigglyMap(functorSignal)(fn => {
        console.debug(`withState performing Eff:`, fn)
        return pure(applicativeEffect)(
          performEff(fn(this.state), this.setState.bind(this))
        )
      })(subscribe(this.channel))
      return Signal.runSignal(this.signal)
    }

    render() {
      return (
        <context.Provider
          value={{
            state: this.state,
            dispatch: action => {
              unsafePerformEffect(send(this.channel)(action))
            }
          }}
        >
          <WrappedComponent {...this.props} />
        </context.Provider>
      )
    }
  }

  return { context, Component }
}
