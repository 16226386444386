// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_Date_Component = require("../Data.Date.Component/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_DateTime_Instant = require("../Data.DateTime.Instant/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Profunctor_Strong = require("../Data.Profunctor.Strong/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Fetch = require("../Fetch/index.js");
var Helper_AuthActions = require("../Helper.AuthActions/index.js");
var Page_BusinessManage = require("../Page.BusinessManage/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var showDeleteCardModal = function (yes) {
    return function (state) {
        return Control_Applicative.pure(Effect.applicativeEffect)({
            deleteCardModal: {
                show: yes,
                httpError: state.deleteCardModal.httpError
            },
            companyId: state.companyId,
            fetchPaymentMethods: state.fetchPaymentMethods,
            newCardModal: state.newCardModal,
            paymentMethods: state.paymentMethods,
            stepUpError: state.stepUpError
        });
    };
};
var showAddCardModal = function (yes) {
    return function (state) {
        return Control_Applicative.pure(Effect.applicativeEffect)({
            newCardModal: {
                isLoading: yes,
                show: yes,
                cardIframeLink: state.newCardModal.cardIframeLink,
                httpError: state.newCardModal.httpError
            },
            companyId: state.companyId,
            deleteCardModal: state.deleteCardModal,
            fetchPaymentMethods: state.fetchPaymentMethods,
            paymentMethods: state.paymentMethods,
            stepUpError: state.stepUpError
        });
    };
};
var fetchPaymentLink = function (state) {
    var readBody = function (a) {
        return {
            status: a.status,
            body: Simple_JSON.readJSON(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
                return "paymentPageUrl";
            }))(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()()))(a.body)
        };
    };
    if (state.companyId instanceof Data_Maybe.Just) {
        return Fetch.recover({
            newCardModal: {
                httpError: new Data_Maybe.Just(500),
                cardIframeLink: state.newCardModal.cardIframeLink,
                isLoading: state.newCardModal.isLoading,
                show: state.newCardModal.show
            },
            companyId: state.companyId,
            deleteCardModal: state.deleteCardModal,
            fetchPaymentMethods: state.fetchPaymentMethods,
            paymentMethods: state.paymentMethods,
            stepUpError: state.stepUpError
        })(Control_Bind.bind(Effect_Aff.bindAff)(Fetch.get()("/company/" + (state.companyId.value0 + "/card/tokenizeLink"))({}))(function (x) {
            var v = readBody(x);
            if (v.status === 401) {
                return Data_Functor.voidLeft(Effect_Aff.functorAff)(Helper_AuthActions.stepUp()("/manage")({}))(state);
            };
            if (v.body instanceof Data_Either.Left) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    newCardModal: {
                        isLoading: false,
                        show: true,
                        cardIframeLink: Data_Maybe.Nothing.value,
                        httpError: new Data_Maybe.Just(v.status)
                    },
                    companyId: state.companyId,
                    deleteCardModal: state.deleteCardModal,
                    fetchPaymentMethods: state.fetchPaymentMethods,
                    paymentMethods: state.paymentMethods,
                    stepUpError: state.stepUpError
                });
            };
            if (v.body instanceof Data_Either.Right) {
                return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                    newCardModal: {
                        isLoading: false,
                        show: true,
                        cardIframeLink: new Data_Maybe.Just(v.body.value0.paymentPageUrl),
                        httpError: Data_Maybe.Nothing.value
                    },
                    companyId: state.companyId,
                    deleteCardModal: state.deleteCardModal,
                    fetchPaymentMethods: state.fetchPaymentMethods,
                    paymentMethods: state.paymentMethods,
                    stepUpError: state.stepUpError
                });
            };
            throw new Error("Failed pattern match at CardManage (line 38, column 5 - line 51, column 28): " + [ v.constructor.name ]);
        }));
    };
    if (state.companyId instanceof Data_Maybe.Nothing) {
        return Data_Functor.voidLeft(Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Helper_AuthActions.redirectTo("/companies")))(state);
    };
    throw new Error("Failed pattern match at CardManage (line 35, column 26 - line 52, column 59): " + [ state.companyId.constructor.name ]);
};
var deleteCard = function (state) {
    var v = Data_Array.head(state.paymentMethods.cards);
    if (state.companyId instanceof Data_Maybe.Just && v instanceof Data_Maybe.Just) {
        return Fetch.recover({
            deleteCardModal: {
                httpError: new Data_Maybe.Just(500),
                show: state.deleteCardModal.show
            },
            companyId: state.companyId,
            fetchPaymentMethods: state.fetchPaymentMethods,
            newCardModal: state.newCardModal,
            paymentMethods: state.paymentMethods,
            stepUpError: state.stepUpError
        })(Control_Bind.bind(Effect_Aff.bindAff)(Fetch["delete"]()("/company/" + (state.companyId.value0 + ("/card/" + v.value0.id)))({}))(function (v2) {
            if (v2.status === 204) {
                return Page_BusinessManage.load(Helper_AuthActions.redirectTo)({
                    fetchPaymentMethods: state.fetchPaymentMethods,
                    newCardModal: state.newCardModal,
                    deleteCardModal: {
                        show: false,
                        httpError: state.deleteCardModal.httpError
                    },
                    companyId: state.companyId,
                    paymentMethods: state.paymentMethods,
                    stepUpError: state.stepUpError
                });
            };
            if (v2.status === 401) {
                return Data_Functor.voidLeft(Effect_Aff.functorAff)(Helper_AuthActions.stepUp()("/manage")({}))(state);
            };
            return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                deleteCardModal: {
                    show: false,
                    httpError: new Data_Maybe.Just(v2.status)
                },
                companyId: state.companyId,
                fetchPaymentMethods: state.fetchPaymentMethods,
                newCardModal: state.newCardModal,
                paymentMethods: state.paymentMethods,
                stepUpError: state.stepUpError
            });
        }));
    };
    return Control_Applicative.pure(Effect_Aff.applicativeAff)({
        deleteCardModal: {
            show: false,
            httpError: new Data_Maybe.Just(500)
        },
        companyId: state.companyId,
        fetchPaymentMethods: state.fetchPaymentMethods,
        newCardModal: state.newCardModal,
        paymentMethods: state.paymentMethods,
        stepUpError: state.stepUpError
    });
};
var _maskCardNo = (function () {
    var dots = Data_Monoid.power(Data_Monoid.monoidString)("\u2022")(4);
    var $47 = Data_Semigroup.append(Data_Semigroup.semigroupString)(dots);
    var $48 = Data_Semigroup.append(Data_Semigroup.semigroupString)("\xa0");
    var $49 = Data_String_CodeUnits.takeRight(4);
    return function ($50) {
        return $47($48($49($50)));
    };
})();
var _isActive = function (now) {
    return function (expiry) {
        var expiredCard = {
            color: "red",
            status: "Expired"
        };
        var activeCard = {
            color: "green",
            status: "Active"
        };
        if (expiry instanceof Data_Maybe.Just && Data_Ord.greaterThanOrEq(Data_Date.ordDate)(expiry.value0)(now)) {
            return activeCard;
        };
        return expiredCard;
    };
};
var _checkScheme = function (v) {
    if (v === "mastercard") {
        return {
            scheme: "mastercard",
            alt: "Master Icon",
            style: "mastercard-img"
        };
    };
    return {
        scheme: "visa",
        alt: "Visa Icon",
        style: "visa-img"
    };
};
var _checkExpiry = function (now) {
    var toEnums = Data_Profunctor_Strong.splitStrong(Control_Category.categoryFn)(Data_Profunctor_Strong.strongFn)(Control_Bind.composeKleisli(Data_Maybe.bindMaybe)(Data_Int.fromString)(Data_Enum.toEnum(Data_Date_Component.boundedEnumYear)))(Control_Bind.composeKleisli(Data_Maybe.bindMaybe)(Data_Int.fromString)(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth)));
    var toDate = Control_Apply.apply(Control_Apply.applyFn)(Data_Tuple.uncurry(Control_Apply.lift3(Data_Maybe.applyMaybe)(Data_Date.canonicalDate)))(Data_Tuple.uncurry(Control_Apply.lift2(Data_Maybe.applyMaybe)(Data_Date.lastDayOfMonth)));
    var splitDate = Data_Profunctor_Strong.fanout(Control_Category.categoryFn)(Data_Profunctor_Strong.strongFn)((function () {
        var $51 = Data_Semigroup.append(Data_Semigroup.semigroupString)("20");
        var $52 = Data_String_CodeUnits.takeRight(2);
        return function ($53) {
            return $51($52($53));
        };
    })())(Data_String_CodeUnits.take(2));
    var $54 = _isActive(now);
    return function ($55) {
        return $54(toDate(toEnums(splitDate($55))));
    };
};
var checkCard = function (v) {
    return function (milliseconds) {
        var now = Data_DateTime.date(Data_DateTime_Instant.toDateTime(milliseconds));
        return {
            scheme: _checkScheme(v.scheme),
            expiry: _checkExpiry(now)(v.expiry),
            number: _maskCardNo(v.number)
        };
    };
};
module.exports = {
    showAddCardModal: showAddCardModal,
    showDeleteCardModal: showDeleteCardModal,
    fetchPaymentLink: fetchPaymentLink,
    deleteCard: deleteCard,
    checkCard: checkCard,
    "_checkScheme": _checkScheme,
    "_checkExpiry": _checkExpiry,
    "_isActive": _isActive,
    "_maskCardNo": _maskCardNo
};
