import React, { useContext, useState } from 'react'
import { context } from '../pages/Companies'
import { StandardTemplate } from '@myob/myob-widgets'
import { renderTableBody, renderSortableTableHeader } from './Batch.Table'
import Error from './Error'
import CompanyName from './CompanyName'
import { sortCompanyBy } from 'purs/CompaniesPanel'
import { renderCompanyStatus } from 'purs/Model.CompanyStatus'
import { either } from 'purs/Data.Either'
import { show } from 'purs/Data.Show'
import { showCompanyAccess } from 'purs/Model.CompanyAccess'
import { showCompanyAccount } from 'purs/Model.CompanyAccount'

const format = dispatch => [
  {
    key: 'name',
    description: 'Business name',
    sortable: true,
    width: '60%',
    align: 'left',
    component: CompanyName
  },
  {
    key: 'access',
    description: 'Permissions',
    tooltip:
      'Permissions give you different levels of access to make payments for a business.',
    width: 'flex-3',
    align: 'left',
    component: props => <span>{show(showCompanyAccess)(props.access)}</span>
  },
  {
    key: 'account',
    description: 'Account',
    sortable: true,
    width: 'flex-2',
    align: 'left',
    component: props => <span>{show(showCompanyAccount)(props.account)}</span>
  },
  {
    key: 'status',
    description: 'Status',
    sortable: true,
    width: 'flex-2',
    align: 'left',
    component: props => <span>{renderCompanyStatus(props.status)}</span>
  }
]

const emptyWarningTitle = {
  title: "Looks like you haven't got any businesses to list.",
  subtitle: 'For access to this page, chat to your administrator.'
}

const CompanyListHeader = () => {
  const { dispatch } = useContext(context)
  const [sortIcon, setSortIcon] = useState({ column: 'name', descending: true })
  return renderSortableTableHeader(format(dispatch), sortIcon, key => {
    const isDescending = key === sortIcon.column ? !sortIcon.descending : true
    dispatch(sortCompanyBy(key)(isDescending))
    setSortIcon({ column: key, descending: isDescending })
  })
}

const CompanyListTable = () => {
  const { state, dispatch } = useContext(context)
  return either(error => {
    return <Error httpError={error} />
  })(companies => {
    return companies.length > 0 ? (
      renderTableBody(companies, format(dispatch))
    ) : (
      <Error>
        <h1 className="message__title">{emptyWarningTitle.title}</h1>
        <h3 className="message__subtitle">{emptyWarningTitle.subtitle}</h3>
      </Error>
    )
  })(state.companyList)
}

const CompaniesPanel = () => {
  return (
    <React.Fragment>
      <StandardTemplate
        pageHead={
          <div className="home-header__content">
            <div className="home-header__content--main">
              <h1 className="header__title">Get started with payments</h1>
              <p className="header__text">
                {
                  "Here's a list of your businesses; select the one you'd like to use."
                }
              </p>
            </div>
            <div className="background-img" />
          </div>
        }
        tableHeader={CompanyListHeader()}
        sticky="all"
        wcagAA={true}
      >
        <CompanyListTable />
      </StandardTemplate>
    </React.Fragment>
  )
}

export default CompaniesPanel
