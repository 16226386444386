import React from 'react'
import { Link } from 'react-router-dom'
import { Active } from 'purs/Model.CompanyAccount'
import { isAdmin } from 'purs/Model.CompanyAccess'
import { Text } from './Batch.Table'
import { showCompanyStatus, renderCompanyStatus } from 'purs/Model.CompanyStatus'
import { clickCompanyNameAction } from 'purs/Company'
import { isUserPendingAuthorisation } from 'purs/Model.User'
import { show } from 'purs/Data.Show'
import { isNothing } from 'purs/Data.Maybe'

function LinkToOnboardModal(company) {
  return company.account instanceof Active && isAdmin(company.access) ? (
    <Link
      className="onboard-link"
      to={`/companies/${company.companyId}/get-started`}
      onClick={() => clickCompanyNameAction(company)()}
    >
      {company.name}
    </Link>
  ) : (
    Text('name')(company)
  )
}

function LinkToDashboard(company) {
  if (company.account instanceof Active && isAdmin(company.access)) {
    return (
      <Link
        to="/dashboard"
        onClick={() => clickCompanyNameAction(company)()}
      >
        {company.name}
      </Link>
    )
  } else {
    return Text('name')(company)
  }
}

function LinkToUserRoleModal(company) {
  return (
    <Link
      to={`/companies/${company.companyId}/select-role`}
      onClick={() => clickCompanyNameAction(company)()}
    >
      {company.name}
    </Link>
  )
}

const CompanyName = company => {
  switch (show(showCompanyStatus)(company.status)) {
    case 'Registered': {
      const redirectTo = isPendingAuth =>
        isPendingAuth ? `/dashboard/` + company.id + `/kyc` : `/`
      return (
        <Link
          to={redirectTo(
            isUserPendingAuthorisation(company.userCurrentRole)(
              company.userNominatedRole
            )
          )}
          onClick={() => clickCompanyNameAction(company)()}
        >
          {company.name}{' '}
        </Link>
      )
    }
    case 'CreditCheckFailed':
    case 'Unregistered':
      return LinkToOnboardModal(company)
    case 'CreditCheckPassed':
    case 'OwnerInfoGenerated':
    case 'OwnerInfoGenerateFailed':
      return isNothing(company.onboardOnBehalf) ? LinkToUserRoleModal(company) : LinkToDashboard(company)
    case 'Declined':
      return LinkToDashboard(company)
    default:
      return renderCompanyStatus(company.status) === "Pending" ? LinkToDashboard(company) : Text('name')(company)
  }
}

export default CompanyName
